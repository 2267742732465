import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { Company, ListParams, ListResponse, Pagination } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { companyActions, selectName, selectPagination } from './companySlice';

type Params = { payload: Company; type: string };

function* fetchCompanyList(param: ListParams) {
  const pagination: Pagination = yield select(selectPagination);
  try {
    const response: ListResponse<Company> = yield call(settingApi.getCompaniesByParam, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(companyActions.fetchCompanyListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Company list', error);
  }
}

function* fetchAllCompanyList(param: ListParams) {
  try {
    const response: ListResponse<Company> = yield call(settingApi.getAllCompany);
    yield put(companyActions.fetchCompanyListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Company list', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectName);
    yield put(companyActions.search({ name }));
  } catch (error) {
    console.log('Failed to fetch company', error);
  }
}

function* createCompany(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: Company = yield call(settingApi.createCompany, param.payload);
    yield put(companyActions.createCompanySuccess(response));
    yield put(companyActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateCompany(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: Company = yield call(settingApi.updateCompany, param.payload);
    yield put(companyActions.updateCompanySuccess(response));
    yield put(companyActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type CompanyDetailParams = { payload: { id: string }; type: string };
function* fetchCompanyDetail(param: CompanyDetailParams) {
  try {
    const id = param.payload.id;
    const response: Company = yield call(settingApi.findCompanyById, id);
    yield put(companyActions.fetchCompanySuccess(response));
  } catch (error) {
    console.log('Failed to find Company by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteCompany(param: DeleteParams) {
  try {
    const id = param.payload.id;
    const name: string = yield select(selectName);
    yield call(settingApi.removeCompany, id);
    yield put(companyActions.deleteCompanySuccess());
    yield put(companyActions.search({ name }));
  } catch (error) {
    console.log('Failed to delete company', error);
    yield put(companyActions.deleteError());
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Company> = yield call(settingApi.searchCompanyByParams, {
      companyName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(companyActions.fetchCompanyListSuccess(response));
  } catch (error) {
    console.log('Failed to search company', error);
  }
}

export default function* CompanySaga() {
  yield takeLatest(companyActions.fetchCompanyList.type, fetchCompanyList);
  yield takeLatest(companyActions.fetchAllCompanyList.type, fetchAllCompanyList);
  yield takeEvery(companyActions.createCompany.type, createCompany);
  yield takeEvery(companyActions.updateCompany.type, updateCompany);
  yield takeLatest(companyActions.fetchCompanyDetail.type, fetchCompanyDetail);
  yield takeLatest(companyActions.deleteCompany.type, deleteCompany);
  yield takeLatest(companyActions.changePage.type, changePage);
  yield takeLatest(companyActions.search.type, search);
}
