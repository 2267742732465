import { ListParams, ListResponse, User, UserResponse } from 'models';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const authApi = {
  refresh(): Promise<UserResponse> {
    const url = `${SCIMMS_API}/auth/refresh`;
    return axiosClient.get(url);
  },
  getAllTraineeList(params: ListParams): Promise<ListResponse<User>> {
    const url = `${SCIMMS_API}/auth/customer-training/trainee-list`;
    return axiosClient.get(url, {
      params,
    });
  },
};

export default authApi;
