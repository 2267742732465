import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { CertificateAdmin, ListParams, ListResponse, Pagination } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { certificateAdminActions, selectName, selectPagination } from './certificateAdminSlice';

type Params = { payload: CertificateAdmin; type: string };

function* fetchCertificateAdminList(param: ListParams) {
  const pagination: Pagination = yield select(selectPagination);
  try {
    const response: ListResponse<CertificateAdmin> = yield call(settingApi.getCertificateAdminByParam, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(certificateAdminActions.fetchCertificateAdminListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch CertificateAdmin list', error);
  }
}

function* fetchAllCertificateAdminList(param: ListParams) {
  try {
    const response: ListResponse<CertificateAdmin> = yield call(settingApi.getAllCertificateAdmin);
    yield put(certificateAdminActions.fetchCertificateAdminListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch CertificateAdmin list', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectName);
    yield put(certificateAdminActions.search({ name }));
  } catch (error) {
    console.log('Failed to fetch CertificateAdmin', error);
  }
}

function* createCertificateAdmin(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: CertificateAdmin = yield call(settingApi.createCertificateAdmin, param.payload);
    yield put(certificateAdminActions.createCertificateAdminSuccess(response));
    yield put(certificateAdminActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateCertificateAdmin(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: CertificateAdmin = yield call(settingApi.updateCertificateAdmin, param.payload);
    yield put(certificateAdminActions.updateCertificateAdminSuccess(response));
    yield put(certificateAdminActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type CertificateAdminDetailParams = { payload: { id: string }; type: string };
function* fetchCertificateAdminDetail(param: CertificateAdminDetailParams) {
  try {
    const id = param.payload.id;
    const response: CertificateAdmin = yield call(settingApi.findCertificateAdminById, id);
    yield put(certificateAdminActions.fetchCertificateAdminSuccess(response));
  } catch (error) {
    console.log('Failed to find CertificateAdmin by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
 function* deleteCertificateAdmin(param: DeleteParams) {
  try {
    const id = param.payload.id;
    const name: string = yield select(selectName);
    yield call(settingApi.removeCertificateAdmin, id);
    yield put(certificateAdminActions.deleteCertificateAdminSuccess());
    yield put(certificateAdminActions.search({ name }));
  } catch (error) {
    console.log('Failed to delete CertificateAdmin', error);
    yield put(certificateAdminActions.deleteError());
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<CertificateAdmin> = yield call(settingApi.searchCertificateAdminByParams, {
      certificateName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(certificateAdminActions.fetchCertificateAdminListSuccess(response));
  } catch (error) {
    console.log('Failed to search Certificate', error);
  }
}

export default function* CertificateAdminSaga() {
  yield takeLatest(certificateAdminActions.fetchCertificateAdminList.type, fetchCertificateAdminList);
  yield takeLatest(certificateAdminActions.fetchAllCertificateAdminList.type, fetchAllCertificateAdminList);
  yield takeEvery(certificateAdminActions.createCertificateAdmin.type, createCertificateAdmin);
  yield takeEvery(certificateAdminActions.updateCertificateAdmin.type, updateCertificateAdmin);
  yield takeLatest(certificateAdminActions.fetchCertificateAdminDetail.type, fetchCertificateAdminDetail);
  yield takeLatest(certificateAdminActions.deleteCertificateAdmin.type, deleteCertificateAdmin);
  yield takeLatest(certificateAdminActions.changePage.type, changePage);
  yield takeLatest(certificateAdminActions.search.type, search);
}
