import { Button, Form, Input, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Company } from 'models';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { chargerModelActions, selectChargeModelLoad } from '../chargerModelSlice';

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const company = useAppSelector(
    (state) => state.company.list.filter((item: Company) => item.id === id)[0],
  );
  const loaded = useAppSelector(selectChargeModelLoad);

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name || '',
    });
  }, [company, form]);

  useEffect(() => {
    if (id) dispatch(chargerModelActions.fetchChargerModelDetail({ id }));
  }, [id, dispatch]);

  const resetForm = () => {
    form.setFieldsValue({
      name: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      if (uid === id) {
        notification.success({
          message: 'Company updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if (id) {
        notification.success({
          message: 'Company record updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/charger-model'),
          duration: 2.5,
        });
      } else {
        notification.success({
          message: 'Company record created successfully',
          placement: 'bottomRight',
          onClose: () => resetForm(),
          duration: 2.5,
        });
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const company: Company = form.getFieldsValue();
    if (id) dispatch(chargerModelActions.updateChargerModel({ ...company, id }));
    else
      dispatch(
        chargerModelActions.createChargerModel({
          ...company,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='form-inspection'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Form.Item
        label='Company Name'
        name='name'
        rules={[{ required: true, message: 'Please input Company Name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormEdit;
