import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import { selectPagination, serialNumberActions } from '../serialNumberSlice';
import ModalCreate from './ModalCreate';
import TableData from './TableData';

const SerialNumberPage: React.FC = () => {
  const isSCAdminSystemAdmin = TokenService.isSCAdminSystemAdmin();
  const dispatch = useAppDispatch();
  const chargerModelOption = useAppSelector(selectChargerModels);
  const pagination = useAppSelector(selectPagination);

  const onSearch = () => {
    dispatch(serialNumberActions.changePage({ pageNo: 1, size: pagination.size }));
    dispatch(
      serialNumberActions.search({
        filter: { name: searchName, chargerModelId },
      }),
    );
  };
  useEffect(() => {
    dispatch(chargerModelActions.search({ name: '', pagination: { size: 100000 } }));
  }, [dispatch]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomState, setRandomState] = useState('');
  const handleCancel = () => {
    setIdEdit('');
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIdEdit('');
    setIsModalVisible(false);
  };
  const [idEdit, setIdEdit] = useState('');
  const [chargerModelId, setChargerModelId] = useState('');
  const [searchName, setSearchName] = useState('');

  const handleChangeChargerModel = (chargerModelId: string) => {
    setChargerModelId(chargerModelId);
    dispatch(
      serialNumberActions.search({
        filter: { name: searchName, chargerModelId },
      }),
    );
  };

  const handleChangeName = (evt: any) => {
    setSearchName(evt.currentTarget.value);
  };

  return (
    <>
      {isSCAdminSystemAdmin && (
        <div className='user-wrapper'>
          <div className='user-head'>
            <div className='user-head-search'>
              <Row style={{ width: '97%' }}>
                <Col span={10}>
                  <Select
                    dropdownMatchSelectWidth={false}
                    placeholder='Select Charger Model'
                    allowClear
                    style={{ width: '90%' }}
                    onChange={handleChangeChargerModel}
                  >
                    {chargerModelOption.map((item, index) => (
                      <Select.Option value={item.id} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={10}>
                  <Input placeholder='serial number' onChange={handleChangeName} />
                </Col>
                <Col span={4} style={{ paddingLeft: '3%' }}>
                  <Button
                    style={{ padding: '4px 15px' }}
                    icon={<SearchOutlined />}
                    className='ant-btn-primary'
                    onClick={onSearch}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <div className='user-content'>
            <div className='user-content-add'>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsModalVisible(true);
                  setIdEdit('');
                }}
              >
                Add Serial Number
              </Button>
            </div>
            <TableData
              setIsModalVisible={setIsModalVisible}
              setIdEdit={setIdEdit}
              randomState={randomState}
            />
          </div>
          {isModalVisible && (
            <ModalCreate
              id={idEdit}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOk}
              setRandomState={setRandomState}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SerialNumberPage;
