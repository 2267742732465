/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse, Training } from 'models';

export interface TrainingState {
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  selectedIdsExcel: React.Key[];
  selectedTrainingIdsExcel: string[];
  list: Training[];
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
}

export interface ActionParam {
  id: string;
  flag: boolean;
}

export interface Filter {
  pageNo?: string;
  pageSize?: string;
  customer_name?: string;
  charger_model_name?: string;
  training_date_from?: string;
  training_date_to?: string;
  trainer_name?: string;
  trainer_company_name?: string;
  training_method?: string;
  country_name?: string;
  site_name?: string;
  training_content?: string;
  trainee_name?: string;
  certificate?: string;
}

const initialState: TrainingState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  selectedIdsExcel: [],
  selectedTrainingIdsExcel: [],
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    pageNo: '0',
    pageSize: '10',
    customer_name: '',
    charger_model_name: '',
    training_date_from: '',
    training_date_to: '',
    trainer_name: '',
    trainer_company_name: '',
    training_method: '',
    country_name: '',
    site_name: '',
    training_content: '',
    trainee_name: '',
  },
  exportPdf: [],
};

const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    fetchTrainingList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchTrainingListSuccess(state, action: PayloadAction<ListResponse<Training>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data || action.payload || [];
    },
    fetchTrainingListFailed(state) {
      state.loading = false;
    },
    createTraining(state, action: PayloadAction<Training>) {
      state.loading = true;
      state.loaded = false;
    },
    upsertTraining(state, action: PayloadAction<Training>) {
      state.loaded = false;
    },
    createTrainingSuccess(state, action: PayloadAction<Training>) {
      state.loading = false;
      state.loaded = true;
    },
    upsertTrainingSuccess(state) {
      state.loaded = true;
    },
    selectedIdExport(state, action: PayloadAction<{ keys: React.Key[] }>) {
      // find all training ids of list current
      const trainingIds = state.list.map((item) => item.id);
      // check exist in keys payload
      const keys = action.payload.keys;
      const uncheckTrainings = trainingIds.filter((item) => !keys.includes(item.toString()));
      // remove unchecked training from state
      const idsState = state.selectedIdsExcel.filter(
        (item) => !uncheckTrainings.includes(item.toString()),
      );
      const selectedIdsExcel = new Set([...idsState, ...keys]);
      state.selectedIdsExcel = Array.from(selectedIdsExcel);
    },
    unSelectedIdExport(state) {
      state.selectedIdsExcel = [];
    },
    selectedTrainingIdExport(state, action: PayloadAction<{ trainingIds: string[] }>) {
      // find all training ids of list current
      const trainingIds = state.list.map((item) => item.id);
      // check exist in keys payload
      const ids = action.payload.trainingIds;
      const uncheckTrainings = trainingIds.filter((item) => !ids.includes(item.toString()));
      // remove unchecked training from state
      const idsState = state.selectedTrainingIdsExcel.filter(
        (item) => !uncheckTrainings.includes(item.toString()),
      );
      const selectedTrainingIdsExcel = new Set([...idsState, ...ids]);
      state.selectedTrainingIdsExcel = Array.from(selectedTrainingIdsExcel);
    },
    unselectedTrainingIdExport(state) {
      state.selectedTrainingIdsExcel = [];
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
    search(state, action: PayloadAction<{ filter: Filter; pagination?: Pagination }>) {
      const { filter, pagination } = action.payload;
      state.filter = filter;
      state.list = [];
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      }
    },
    deleteTraining(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deleteTrainingSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
    fetchTrainingDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchTrainingDetailSuccess(state, action: PayloadAction<Training>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
  },
});

// Actions
export const trainingActions = trainingSlice.actions;

// Selectors
export const selectTrainingList = (state: RootState) => state.training.list;
export const selectTrainingLoad = (state: RootState) => state.training.loaded;
export const selectTrainingLoading = (state: RootState) => state.training.loading;

export const selectPagination = (state: RootState) => state.training.pagination;
export const selectFilter = (state: RootState) => state.training.filter;
export const selectDeleting = (state: RootState) => state.training.deleting;
export const selectedIdsExcel = (state: RootState) => state.training.selectedIdsExcel;
export const selectedTrainingIdsExcel = (state: RootState) =>
  state.training.selectedTrainingIdsExcel;

// Reducer
const trainingReducer = trainingSlice.reducer;
export default trainingReducer;
