export enum TicketStatus {
  Open,
  Closed,
}

export enum TicketSeverity {
  Urgent,
  High,
  Medium,
  Low,
}

export enum TicketEventLog {
  ASSIGN = 'Assign',
  ACCEPT = 'Accept',
  DECLINE = 'Decline',
}
