import { Button, Form, Tabs } from 'antd';
import { Ticket } from 'models';
import React from 'react';
import { SiteAppointment } from './siteAppointment';
import { SiteAttendance } from './siteAttendance';
interface ISitePage {
  data: Ticket | undefined;
  prevStep: () => void;
  nextStep: (ticket: Ticket) => void;
}
export const SitePage: React.FC<ISitePage> = ({ data, prevStep, nextStep }) => {
  const handleNext = () => {
    if (data) nextStep(data);
  };
  return (
    <div className='ticket-site'>
      <Tabs>
        <Tabs.TabPane tab='Site Appointment' key='item-2'>
          <SiteAppointment ticket={data} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Site Attendance' key='item-3'>
          <SiteAttendance ticket={data} />
        </Tabs.TabPane>
      </Tabs>
      <Form className='list-field' style={{ marginTop: 30 }}>
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
