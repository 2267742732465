import { AutoComplete, Button, DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { chargerModelActions } from 'features/settings/charger-model/chargerModelSlice';
import { selectCompanies } from 'features/settings/company/companySlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { CommissionRegion } from 'models';
import { FlagService } from 'models/enum/chargerModel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const BasicInfo: React.FC<{
  nextStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, inspOtherRegion }) => {
  const [form] = useForm();

  const disabled = TokenService.isDisabled();
  const [isValid, setIsValid] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const listSerialNumbers = useAppSelector(selectSerialNumbers);

  const [optionCompanies, setOptionCompanies] = useState<DataType[]>([]);
  const companies = useAppSelector(selectCompanies);
  useEffect(() => {
    if (companies) {
      const info: DataType[] = [];
      const obj = companies;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setOptionCompanies(info);
    }
  }, [companies]);

  const [countryData, setCountryData] = useState<DataType[]>([]);
  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  useEffect(() => {
    if (listCountry) {
      const info: DataType[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);

  useEffect(() => {
    dispatch(
      serialNumberActions.search({
        filter: { name: '', chargerModelId: '' },
        pagination: { size: 10000 },
      }),
    );
    dispatch(
      chargerModelActions.fetchChargerModelList({
        flag_service: FlagService.CM,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: DataType[] = [];
      const obj = listSerialNumbers;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      form.setFieldValue('serial_number', listSerialNumbers[0].name);
    }
  }, [listSerialNumbers]);

  useEffect(() => {
    if (inspOtherRegion) {
      const engineerCompany = optionCompanies.find(
        (company) => company.id === inspOtherRegion.company_id,
      );
      form.setFieldsValue({
        commissioning_date:
          (inspOtherRegion?.commissioning_date
            ? moment(new Date(inspOtherRegion?.commissioning_date))
            : null) || format(new Date(), 'yyyy-MM-dd'),
        engineer_name: inspOtherRegion.engineer_name || '',
        engineer_company: engineerCompany?.label || '',
        charger_owner: inspOtherRegion.charger_owner?.name || inspOtherRegion.charger_owner || '',
        country: inspOtherRegion.country?.name || inspOtherRegion.country || '',
        site_name: inspOtherRegion.site_name || '',
        detailed_address: inspOtherRegion.detailed_address || '',
      });
      handleFormChange();
    }
  }, [inspOtherRegion, form, optionCompanies]);

  const handleNext = (values: any) => {
    const inspectForm: CommissionRegion = form.getFieldsValue();
    const company_id =
      optionCompanies.find((company) => company.label === form.getFieldValue('engineer_company'))
        ?.id || form.getFieldValue('engineer_company');
    const country = listCountry.find(
      (country) =>
        country.id === form.getFieldValue('country') ||
        country.name === form.getFieldValue('country'),
    );
    const charger_owner = companies.find(
      (company) =>
        company.id === form.getFieldValue('charger_owner') ||
        company.name === form.getFieldValue('charger_owner'),
    );
    const updateObj = {
      ...inspOtherRegion,
      ...inspectForm,
      country,
      charger_owner,
      company_id,
    };
    nextStep({ ...updateObj });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormChange = () => {
    if (hasFieldEmpty()) setIsValid(false);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setIsValid(!hasErrors);
    }
  };

  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue()).some((field) => {
      return !form.getFieldValue(field)?.toString()?.trim();
    });
  };

  return (
    <Form
      name='form-other-region'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={handleNext}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
      onFieldsChange={handleFormChange}
    >
      <Form.Item
        label='Commissioning Date'
        name='commissioning_date'
        rules={[{ required: true, message: 'Please input Commissioning Date!' }]}
      >
        <DatePicker disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Commissioning Engineer Name'
        name='engineer_name'
        rules={[{ required: true, message: 'Please input Commissioning Engineer Name!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Commissioning Engineer Company'
        name='engineer_company'
        rules={[{ required: true, message: 'Please input Commissioning Engineer Company!' }]}
      >
        <AutoComplete
          disabled={disabled}
          options={optionCompanies}
          filterOption={(inputValue, option) =>
            option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
          }
        />
      </Form.Item>
      <Form.Item label='Charger Owner' name='charger_owner' rules={[{ required: true }]}>
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Charger Owner'
          allowClear
          disabled={disabled}
        >
          {optionCompanies.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Country'
        name='country'
        rules={[{ required: true, message: 'Please input Country!' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Country'
          allowClear
          disabled={disabled}
        >
          {countryData.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Site Name'
        name='site_name'
        rules={[{ required: true, message: 'Please input Site Name!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Detailed Address'
        name='detailed_address'
        rules={[{ required: true, message: 'Please input Detailed Address!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <div className='action-form'>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
            disabled={!isValid}
          >
            Next step
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default BasicInfo;
