import settingApi from 'api/settingApi';
import { ListParams, ListResponse, Pagination, Policy } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { policyActions, selectPagination } from './policySlice';
import { notification } from 'antd';

type Params = { payload: Policy; type: string };

function* fetchPolicyList(param: ListParams) {
  try {
    const response: ListResponse<Policy> = yield call(settingApi.getAllPolicy, {
      pageNo: param._page,
      pageSize: 200,
    });
    yield put(policyActions.fetchPolicyListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Policy list', error);
  }
}

function* findForPublic(param: ListParams) {
  try {
    const response: ListResponse<Policy> = yield call(settingApi.findForPublicPolicy, {
      pageNo: 1,
      pageSize: 200,
    });
    yield put(policyActions.fetchPolicyListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch public', error);
  }
}

function* createPolicy(param: Params) {
  try {
    const response: Policy = yield call(settingApi.createPolicy, param.payload);
    yield put(policyActions.createPolicySuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updatePolicy(param: Params) {
  try {
    console.log(param.payload);
    const response: Policy = yield call(settingApi.updatePolicy, param.payload);
    yield put(policyActions.updatePolicySuccess(response));
    yield put(policyActions.fetchPolicyList({}));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* toggleStatusPolicy(param: Params) {
  try {
    const response: Policy = yield call(settingApi.publicPolicy, param.payload);
    yield put(policyActions.updatePolicySuccess(response));
    yield put(policyActions.fetchPolicyList({}));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Policy> = yield call(settingApi.searchChargerModelByParams, {
      chargerModelName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(policyActions.fetchPolicyListSuccess(response));
  } catch (error) {
    console.log('Failed to search terms', error);
  }
}

type PolicyDetailParams = { payload: { id: string }; type: string };
function* fetchPolicyDetail(param: PolicyDetailParams) {
  try {
    const id = param.payload.id;
    const response: Policy = yield call(settingApi.findPolicyById, id);
    yield put(policyActions.fetchPolicySuccess(response));
  } catch (error) {
    console.log('Failed to find Policy by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deletePolicy(param: DeleteParams) {
  try {
    const id = param.payload.id;
    console.log('id:-', id);
    yield call(settingApi.remove, id);
    yield put(policyActions.deletePolicySuccess());
    yield put(policyActions.fetchPolicyList({}));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

export default function* PolicySaga() {
  yield takeLatest(policyActions.fetchPolicyList.type, fetchPolicyList);
  yield takeEvery(policyActions.createPolicy.type, createPolicy);
  yield takeEvery(policyActions.updatePolicy.type, updatePolicy);
  yield takeLatest(policyActions.fetchPolicyDetail.type, fetchPolicyDetail);
  yield takeLatest(policyActions.deletePolicy.type, deletePolicy);
  yield takeLatest(policyActions.toggleStatusPolicy.type, toggleStatusPolicy);
  yield takeLatest(policyActions.search.type, search);
  yield takeLatest(policyActions.findForPublic.type, findForPublic);
}
