import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const RadioValue = {
  C: '0',
  NC: '1',
  NA: '2',
};

const appearance_checks_tpl = [
  {
    no: 1,
    Content: 'All components of the equipment are free from stains, scratches, deformations.',
    Method: 'Visually',
  },
  {
    no: 2,
    Content:
      'Name plate and other signages including safety warning signs are accurate, clear and complete.',
    Method: 'Visually',
  },
  {
    no: 3,
    Content:
      'Charging cable is complete without damage. No water or dust in the charging connector. The insulation cap is complete.',
    Method: 'Visually',
  },
  {
    no: 4,
    Content: 'LED indicators work normally.',
    Method: 'Visually',
  },
  {
    no: 5,
    Content: 'Emergency stop button should not be pressed.',
    Method: 'Visual / Manual',
    value: '0',
    remarks: 'abcd',
  },
];

const Step4: React.FC<{
  nextStep: any;
  prevStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  useEffect(() => {
    if (data) {
      const isDisabledRemarkData: { [key: string]: boolean } = {};
      const appearance_checks = data.appearance_checks?.map((item) => {
        const fieldName = `appearance_checks_note_${item.no}`;
        isDisabledRemarkData[fieldName] = isRequiredRemark(`${item.no}`, item.value);
        return {
          [`appearance_checks_${item.no}`]: item.value,
          [`appearance_checks_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(appearance_checks),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const isRequiredRemark = (itemNo: string, value: string) => {
    return (
      ['20', '21', '29', '30', '31', '32'].includes(itemNo) &&
      [RadioValue.C, RadioValue.NC].includes(value)
    );
  };

  const handleNext = () => {
    const inspectForm = form.getFieldsValue();
    const appearance_checks = [...(data?.appearance_checks || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['appearance_checks_' + item.no],
        remarks: inspectForm['appearance_checks_note_' + item.no],
      };
    });

    nextStep({ ...data, appearance_checks });
  };

  return (
    <div className='form-wrapper'>
      <h3>Appearance checks</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.appearance_checks || []).map((item, idx) => {
          const check_before_data = appearance_checks_tpl.find((data) => data.no === +item.no);
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + check_before_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'appearance_checks_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={RadioValue.C}>C</Radio>
                  <Radio value={RadioValue.NC}>NC</Radio>
                  <Radio value={RadioValue.NA}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'appearance_checks_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Remarks' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            // onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step4;
