import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import FilterForm from './FilterForm';
import TableData from './TableData';

const CertificationPage: React.FC = () => {
  const isSCAdminSystemAdmin = TokenService.isSCAdminSystemAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSCAdminSystemAdmin) navigate('/');
  }, [isSCAdminSystemAdmin]);

  return (
    <div className='camis-page-wrapper certification-dashboard'>
      <div className='user-content certification-content'>
        <FilterForm />
        <TableData />
      </div>
    </div>
  );
};

export default CertificationPage;
