import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAppDispatch } from 'app/hooks';
import SearchCustom from 'components/common/Search';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { userActions } from '../userSlice';
import TableData from './TableData';

const UserPage: React.FC = () => {
  const isSystemAdmin = TokenService.isSystemAdmin();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSearch = (username: string) => {
    dispatch(userActions.search({ username, isFirst: true }));
  };

  useEffect(() => {
    if (!isSystemAdmin) navigate('/');
  }, [isSystemAdmin]);
  return (
    <>
      {isSystemAdmin && (
        <div className='user-wrapper'>
          <div className='user-head'>
            <div className='user-head-search'>
              <SearchCustom handleSearch={onSearch} />
            </div>
          </div>
          <div className='user-content'>
            <div className='user-content-add'>
              <Link to='/user/create'>
                <Button type='primary' icon={<PlusOutlined />}>
                  Add user
                </Button>
              </Link>
            </div>
            <TableData />
          </div>
        </div>
      )}
    </>
  );
};

export default UserPage;
