import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, message, Table, Upload, UploadFile, UploadProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';
import { ImageCustom } from 'components/common/ImageCustom';
import { Asset, Training, TrainingUser } from 'models';
import React, { useEffect, useState } from 'react';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getThumbnailForVideo,
  removeExtension,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';
import ModalActionTraininee from './ModalActionTrainee';

export const Trainees: React.FC<{
  training: Training | undefined;
  data: TrainingUser[];
  handleChange: any;
}> = ({ training, data, handleChange }) => {
  const [traineeData, setTraineeData] = useState<TrainingUser[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imagePaths, setImagePaths] = useState<Asset[]>([]);
  const [idxEdit, setIdxEdit] = useState(-1);
  const isSystemAdmin = TokenService.isSystemAdmin();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState<Asset>({});
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      attachment: training?.attachment,
    });
    const isChange = JSON.stringify(training?.attachment?.assets) !== JSON.stringify(imagePaths);
    if (training?.attachment && isChange) {
      setFileUploadList(training.attachment.assets, true);
      if (training.attachment.assets) setImagePaths(training.attachment.assets);
    }
  }, [training, form]);

  const setFileUploadList = async (list: Asset[] | undefined, isOpenPopup?: boolean) => {
    const setImgList = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList(isOpenPopup ? list : [...fileList, list[0]]);
      });
    };
    const uploadList = list?.map((item) => getFileUpload(item)) || [];
    setImgList(uploadList);
  };

  const getFileUpload = async (asset: Asset) => {
    const url = asset.thumb || asset.url || '';
    const resUrl = (await fetchImage(url)) as { link: string };
    const result: UploadFile = {
      uid: resUrl?.link || '',
      url: resUrl?.link || '',
      name: asset.url || '',
    };
    return result;
  };

  useEffect(() => {
    if (fileUpload.url) {
      setImagePaths([
        ...imagePaths,
        {
          url: fileUpload.url,
          thumb: fileUpload.thumb,
        },
      ]);
      setFileUploadList([{ url: fileUpload.url, thumb: fileUpload.thumb }], false);
    }
  }, [fileUpload]);

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    fileList: fileList,
    customRequest: async ({ file, filename }) => {
      if ((file as File).type.startsWith('image')) {
        const fileName = generateFileName((file as File).name || '');
        const path = `training/trainee/${fileName}`;
        const resImg = (await fetchImageForUpload(path)) as { link: string };
        const formData = new FormData();
        formData.append('image', file);
        uploadMedia(resImg?.link, file as Blob)
          .then(async () => {
            setFileUpload({ url: path });
            message.success(`${fileName} file uploaded successfully`);
            handleChange({
              attachment: {
                ...training?.attachment,
                assets: [
                  ...imagePaths,
                  {
                    url: path,
                  },
                ],
              },
            });
          })
          .catch((err) => {
            console.log(err);
            message.error(`${fileName} file upload failed.`);
          });
      } else {
        const fileNameVideo = generateFileName((file as File).name || '');
        const path = `training/trainee/${fileNameVideo}`;
        const resImg = (await fetchImageForUpload(path)) as { link: string };

        uploadMedia(resImg?.link, file as Blob)
          .then(async () => {
            const fileUrl = URL.createObjectURL(file as Blob);
            const thumb = await getThumbnailForVideo(fileUrl);
            const thumbnail = `training/trainee/${removeExtension(fileNameVideo) + '.jpeg'}`;
            const resThumb = (await fetchImageForUpload(thumbnail)) as {
              link: string;
            };
            fetch(thumb)
              .then((res) => res.blob())
              .then((blob) => {
                uploadMedia(resThumb?.link, blob)
                  .then(async () => {
                    setFileUpload({ url: path, thumb: thumbnail });
                    message.success(`${filename} file uploaded successfully`);
                    handleChange({
                      attachment: {
                        ...training?.attachment,
                        assets: [
                          ...imagePaths,
                          {
                            url: path,
                            thumb: thumbnail,
                          },
                        ],
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    message.error(`${filename} file upload failed.`);
                  });
              });
          })
          .catch((err) => {
            console.log(err);
            message.error(`${filename} file upload failed.`);
          });
      }
    },
    onRemove(file) {
      const imagePathsabc = fileList.filter((el) => el.name !== file.name);
      setFileList(imagePathsabc);
      const imagePathsUpdate = imagePaths.filter((el) => el.url !== file.name);
      setImagePaths(imagePathsUpdate.map((item) => ({ url: item.url })));
      handleChange({
        attachment: {
          ...training?.attachment,
          assets: imagePathsUpdate,
        },
      });
    },
  };

  const isEdit = isSystemAdmin || !training;

  const [isCertification, setIsCertification] = useState(false);

  useEffect(() => {
    if (data && training) {
      const hasCertificate = 'certificate' in training && training.certificate !== null;
      setIsCertification(hasCertificate);
      const info: TrainingUser[] = data as TrainingUser[];
      setTraineeData(info);
    }
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUpsert = (data: TrainingUser) => {
    let traineeList = [...traineeData];
    const tmp = [...traineeData];
    tmp[idxEdit] = data;
    traineeList = tmp;
    setTraineeData(traineeList);
    handleChange({ training_users: traineeList });
  };

  const columns: ColumnsType<TrainingUser> = [
    {
      title: 'Trainee Signature',
      dataIndex: 'trainee_signature',
      key: 'trainee_signature',
      render: (_: unknown, record: TrainingUser) => {
        return (
          <ImageCustom
            className='user-avatar'
            type='link'
            url={record.trainee?.trainee_signature || ''}
          />
        );
      },
    },
    {
      title: 'Trainee Name',
      dataIndex: 'trainee_name',
      key: 'trainee_name',
      render: (_: unknown, record: TrainingUser) => {
        return `${
          record.trainee?.user
            ? `${record.trainee?.user?.firstname} ${record.trainee?.user?.lastname} (${record.trainee?.user?.username})`
            : record.trainee?.name
        }`;
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: TrainingUser, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isEdit ? 'cursor' : 'pointer',
                opacity: !isEdit ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isEdit);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  return (
    <div className='form-wrapper' style={{ display: 'contents' }}>
      <Table dataSource={traineeData} columns={columns} scroll={{ x: 800 }} pagination={false} />
      <Form layout='vertical'>
        <Form.Item
          label='Upload Hard Copy'
          valuePropName='file'
          name='attachment'
          style={{ marginTop: '2rem' }}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
      <ModalActionTraininee
        data={traineeData[idxEdit]}
        isCertification={isCertification}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
