import { authSaga } from 'features/auth/authSaga';
import inspectionSaga from 'features/inspection/inspectionSaga';
import inspOtherRegionSaga from 'features/inspOtherRegion/inspOtherRegionSaga';
import maintenanceOtherRegionsSaga from 'features/maintenanceOtherRegion/maintenanceOtherRegionsSaga';
import categorySaga from 'features/settings/category/categorySaga';
import chargerModelSaga from 'features/settings/charger-model/chargerModelSaga';
import companySaga from 'features/settings/company/companySaga';
import certificateAdminSaga from 'features/settings/certificate-admin/certificateAdminSaga';
import countrySaga from 'features/settings/country/countrySaga';
import policySaga from 'features/settings/policy/policySaga';
import provinceSaga from 'features/settings/province/provinceSaga';
import serialNumberSaga from 'features/settings/serial-number/serialNumberSaga';
import termsSaga from 'features/settings/terms/termsSaga';
import userSaga from 'features/user/userSaga';
import { all } from 'redux-saga/effects';
import ticketSaga from '../features/tickets/ticketSaga';
import trainingSaga from '../features/training/trainingSaga';
import certificationSaga from '../features/certification/certificationSaga';
import problemCategorySaga from 'features/settings/problemCategory/problemCategorySaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    inspectionSaga(),
    inspOtherRegionSaga(),
    ticketSaga(),
    countrySaga(),
    companySaga(),
    termsSaga(),
    policySaga(),
    chargerModelSaga(),
    categorySaga(),
    provinceSaga(),
    serialNumberSaga(),
    trainingSaga(),
    certificationSaga(),
    maintenanceOtherRegionsSaga(),
    certificateAdminSaga(),
    problemCategorySaga(),
  ]);
}
