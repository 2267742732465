import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const Step6: React.FC<{
  nextStep: any;
  prevStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const insulation_performance_c_tpl = [
    {
      no: 1,
      Content:
        'Ensure resistance of output of DC+ relay to PE is not less than 1 MΩ (GB/T 10MΩ) and record this value.',
      Method: 'Measurement',
    },
    {
      no: 2,
      Content:
        'Ensure resistance of output of DC- relay to PE is not less than 1 MΩ (GB/T 10MΩ) and record this value.',
      Method: 'Measurement',
    },
    {
      no: 3,
      Content: 'Ensure resistance of L1 to PE is not less than 10 MΩ and record this value.',
      Method: 'Measurement',
    },
    {
      no: 4,
      Content: 'Ensure resistance of L2 to PE is not less than 10 MΩ and record this value.',
      Method: 'Measurement',
    },
    {
      no: 5,
      Content: 'Ensure resistance of L3 to PE is not less than 10 MΩ and record this value.',
      Method: 'Measurement',
    },
  ];

  useEffect(() => {
    if (data) {
      const insulation_performance_c = data.insulation_performance_c?.map((item) => {
        return {
          [`insulation_performance_c_${item.no}`]: item.value,
          [`insulation_performance_c_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(insulation_performance_c),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = () => {
    const inspectForm = form.getFieldsValue();
    const insulation_performance_c = [...(data?.insulation_performance_c || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['insulation_performance_c_' + item.no],
        remarks: inspectForm['insulation_performance_c_note_' + item.no],
      };
    });

    nextStep({ ...data, insulation_performance_c });
  };

  return (
    <div className='form-wrapper'>
      <h3>Insulation Performance Check</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.insulation_performance_c || []).map((item, idx) => {
          const insulation_performance_c_data = insulation_performance_c_tpl.find(
            (data) => data.no === +item.no,
          );
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + insulation_performance_c_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'insulation_performance_c_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'insulation_performance_c_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Ohms (Ω)' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step6;
