import { Col, Divider, Row, Space } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  ticketActions,
  totalTicketSeverityHigh,
  totalTicketSeverityLow,
  totalTicketSeverityMedium,
  totalTicketSeverityUrgent,
  totalTicketStatusClose,
  totalTicketStatusOpen,
} from 'features/tickets/ticketSlice';
import React, { useEffect } from 'react';
import { CardRun } from './CardRun';
import { NumberRun } from './NumberRun';

export interface Info {
  title: string;
  value: string;
}

const OverviewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(ticketActions.fetchCountStatus({}));
    dispatch(ticketActions.fetchCountSeverity({}));
    dispatch(ticketActions.fetchTrendingDay({}));
  }, [dispatch]);
  const openTicket = useAppSelector(totalTicketStatusOpen);
  const closeTicket = useAppSelector(totalTicketStatusClose);
  const severityUrgent = useAppSelector(totalTicketSeverityUrgent);
  const severityHigh = useAppSelector(totalTicketSeverityHigh);
  const severityMedium = useAppSelector(totalTicketSeverityMedium);
  const severityLow = useAppSelector(totalTicketSeverityLow);
  const topCardInfo = [
    { title: 'Total Ticket', value: (closeTicket + openTicket).toString(), borderColor: '#e98d3e' },
    { title: 'Open Ticket', value: openTicket.toString(), borderColor: '#666ee8' },
    { title: 'Close Ticket', value: closeTicket.toString(), borderColor: '#ff4961' },
  ];

  const trendInfo = [
    { title: 'Urgent', value: severityUrgent.toString(), borderColor: '#e98d3e' },
    { title: 'High', value: severityHigh.toString(), borderColor: '#666ee8' },
    { title: 'Medium', value: severityMedium.toString(), borderColor: '#ff4961' },
    { title: 'Low', value: severityLow.toString(), borderColor: '' },
  ];
  const timeShowNumber = 6;
  return (
    <div className='camis-page-wrapper'>
      <div className='user-content'>
        <Row gutter={[30, 30]}>
          {topCardInfo.map((item, index) => (
            <NumberRun
              key={index}
              index={index}
              item={item}
              borderColor={item.borderColor}
              numInc={Math.round(+item.value / timeShowNumber)}
            />
          ))}
        </Row>
        <div className='total-severity'>
          <Divider orientation='left'>Ticket By Severity</Divider>
        </div>
        <Row gutter={[30, 30]}>
          {trendInfo.map((item, index) => (
            <Col key={index} span={6}>
              <CardRun
                key={index}
                item={item}
                numInc={Math.round(+item.value / timeShowNumber)}
                borderColor={item.borderColor}
              />
            </Col>
          ))}
        </Row>

        {/* <Row gutter={[30, 30]}>
                    <Col span={12}>
                        {info.length === 0 ? (
                            <div className="notification-entry">No data</div>
                        ) : (
                            <div className="boxInfoList camis-card">
                                <div className={'camis-heading d-flex justify-content-between'}>
                                    <div>
                                        <h3>Unresolved tickets</h3>
                                        <div className={"sub-title"}>Group:<span>Support</span></div>
                                    </div>
                                    <Button
                                        type={'link'}
                                        onClick={() => {
                                            console.log('View details');
                                        }}
                                    >{'View details'}</Button>
                                </div>
                                {info.map((item, index) => (
                                    <Row key={index} style={{alignItems: 'center'}} className={"info-list-item"}>
                                        <Col span={item.title === "" ? 24 : 12}
                                             className={"b"}>{item.title}</Col>
                                        <Col span={item.title === "" ? 24 : 12}
                                             className={"c"}
                                             style={{textAlign: 'right', color: '#9d9da5'}}>{item.value}</Col>
                                    </Row>
                                ))}
                            </div>
                        )}
                    </Col>
                    <Col span={12}>
                        <div className="boxInfoList camis-card">
                            <div className={'camis-heading d-flex justify-content-between'}>
                                <div>
                                    <h3>Tasks</h3>
                                    <div className={"sub-title"}>Today</div>
                                </div>
                                <Button
                                    type={'link'}
                                    onClick={() => {
                                        console.log('View all');
                                    }}
                                >{'View all'}</Button>
                            </div>
                        </div>
                    </Col>
                </Row> */}
      </div>
    </div>
  );
};

export default OverviewPage;
