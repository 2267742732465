/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import ticketApi from 'api/ticketApi';
import {
  ITicketSeverity,
  ITicketStatus,
  ITicketTrendingDay,
  ListResponse,
  Ticket,
  TicketRequest,
} from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ActionParam,
  Filter,
  Pagination,
  selectFilter,
  selectPagination,
  ticketActions,
} from './ticketSlice';

function* fetchTicketList() {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Ticket> = yield call(ticketApi.getAll, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(ticketActions.fetchTicketListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch ticket list', error);
    yield put(ticketActions.fetchTicketListFailed());
  }
}

type Params = { payload: Ticket; type: string };
function* upsertTicket(param: Params) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield call(ticketApi.upsert, param.payload);
    yield put(ticketActions.upsertTicketSuccess());
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type AcceptDeclineParams = { payload: ActionParam; type: string };
function* acceptDecline(param: AcceptDeclineParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield call(ticketApi.acceptDecline, param.payload);
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type ClosedTicketParams = { payload: TicketRequest; type: string };
function* closed(param: ClosedTicketParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield call(ticketApi.close, param.payload);
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* upsert(param: ClosedTicketParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield call(ticketApi.close, param.payload);
    yield put(ticketActions.upsertRecordSuccess());
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type PageParams = { payload: Pagination; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* changePage(param: PageParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type SearchParams = { payload: Filter; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<Ticket> = yield call(ticketApi.searchByParams, {
      ...filter,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(ticketActions.fetchTicketListSuccess(response));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteTicket(param: DeleteParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectFilter);
    const id = param.payload.id;
    yield call(ticketApi.remove, id);
    yield put(ticketActions.deleteTicketSuccess());
    yield put(ticketActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type TicketDetailParams = { payload: { id: string }; type: string };
function* fetchTicketDetail(param: TicketDetailParams) {
  try {
    const id = param.payload.id;
    const response: Ticket = yield call(ticketApi.getById, id);
    yield put(ticketActions.fetchTicketDetailSuccess(response));
  } catch (error) {
    console.log('Failed to find ticket by id', error);
  }
}

function* fetchCountStatus(param: TicketDetailParams) {
  try {
    const id = param.payload.id;
    const response: ITicketStatus[] = yield call(ticketApi.getCountStatus, id);
    yield put(ticketActions.fetchCountStatusSuccess(response));
  } catch (error) {
    console.log('Failed to fetch count status', error);
  }
}

function* fetchCountSeverity(param: TicketDetailParams) {
  try {
    const id = param.payload.id;
    const response: ITicketSeverity[] = yield call(ticketApi.getCountSeverity, id);
    yield put(ticketActions.fetchCountSeveritySuccess(response));
  } catch (error) {
    console.log('Failed to fetch count severity', error);
  }
}

function* fetchTrendingDay(param: TicketDetailParams) {
  try {
    const id = param.payload.id;
    const response: ITicketTrendingDay[] = yield call(ticketApi.getDataChart, id);
    yield put(ticketActions.fetchTrendingDaySuccess(response));
  } catch (error) {
    console.log('Failed to fetch count trending day', error);
  }
}

//type Params = { payload: User; type: string };
function* createTicket(param: Params) {
  try {
    const response: Ticket = yield call(ticketApi.create, param.payload);
    yield put(ticketActions.createTicketSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

export default function* ticketSaga() {
  yield takeLatest(ticketActions.fetchTicketList.type, fetchTicketList);
  yield takeEvery(ticketActions.upsertTicket.type, upsertTicket);
  yield takeEvery(ticketActions.changePage.type, changePage);
  yield takeLatest(ticketActions.search.type, search);
  yield takeLatest(ticketActions.deleteTicket.type, deleteTicket);
  yield takeEvery(ticketActions.createTicket.type, createTicket);
  yield takeLatest(ticketActions.fetchTicketDetail.type, fetchTicketDetail);
  yield takeLatest(ticketActions.acceptDecline.type, acceptDecline);
  yield takeLatest(ticketActions.close.type, closed);
  yield takeLatest(ticketActions.upsertRecord.type, upsert);
  yield takeLatest(ticketActions.fetchCountSeverity.type, fetchCountSeverity);
  yield takeLatest(ticketActions.fetchCountStatus.type, fetchCountStatus);
  yield takeLatest(ticketActions.fetchTrendingDay.type, fetchTrendingDay);
}
