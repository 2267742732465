import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { Country, ListParams, ListResponse, Pagination } from 'models';
import { Category } from 'models/category';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { categoryActions, selectName, selectPagination } from './categorySlice';

type Params = { payload: Category; type: string };

function* fetchCategoryList(param: ListParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Category> = yield call(settingApi.getAllCategory, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(categoryActions.fetchCategoryListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Category list', error);
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Category> = yield call(settingApi.searchCategoryByParams, {
      categoryName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(categoryActions.fetchCategoryListSuccess(response));
  } catch (error) {
    console.log('Failed to search Category', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectName);
    yield put(categoryActions.search({ name }));
  } catch (error) {
    console.log('Failed to create Category', error);
  }
}

function* createCategory(param: Params) {
  try {
    const response: Country = yield call(settingApi.createCategory, param.payload);
    yield put(categoryActions.createCategorySuccess(response));
    const name: string = yield select(selectName);
    yield put(categoryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateCategory(param: Params) {
  try {
    const response: Country = yield call(settingApi.updateCategory, param.payload);
    yield put(categoryActions.updateCategorySuccess(response));
    const name: string = yield select(selectName);
    yield put(categoryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type CategoryDetailParams = { payload: { id: string }; type: string };
function* fetchCategoryDetail(param: CategoryDetailParams) {
  try {
    const id = param.payload.id;
    const response: Category = yield call(settingApi.findCategoryById, id);
    yield put(categoryActions.fetchCategorySuccess(response));
  } catch (error) {
    console.log('Failed to find Category by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteCategory(param: DeleteParams) {
  try {
    const id = param.payload.id;

    yield call(settingApi.removeCategory, id);
    yield put(categoryActions.deleteCategorySuccess());
    const name: string = yield select(selectName);
    yield put(categoryActions.search({ name }));
  } catch (error) {
    console.log('Failed to delete Category', error);
  }
}

export default function* categorySaga() {
  yield takeLatest(categoryActions.fetchCategoryList.type, fetchCategoryList);
  yield takeEvery(categoryActions.createCategory.type, createCategory);
  yield takeEvery(categoryActions.updateCategory.type, updateCategory);
  yield takeLatest(categoryActions.fetchCategoryDetail.type, fetchCategoryDetail);
  yield takeLatest(categoryActions.deleteCategory.type, deleteCategory);
  yield takeLatest(categoryActions.changePage.type, changePage);
  yield takeLatest(categoryActions.search.type, search);
}
