import { Button, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Terms } from 'models';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TokenService from 'utils/token.service';
import { selectInspectionLoad, termsActions } from '../termsSlice';

import JoditEditor from 'jodit-react';

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const Terms = useAppSelector(
    (state) => state.terms.list.filter((item: Terms) => item.id === id)[0],
  );
  const loaded = useAppSelector(selectInspectionLoad);

  const editor = useRef(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    form.setFieldsValue({
      name: Terms?.name || '',
    });
  }, [Terms, form]);

  useEffect(() => {
    if (id) dispatch(termsActions.fetchTermsDetail({ id }));
  }, [id, dispatch]);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      if (uid === id) {
        notification.success({
          message: 'Terms updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if (id) {
        notification.success({
          message: 'Terms record updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/settings/terms'),
          duration: 2.5,
        });
      } else {
        notification.success({
          message: 'Terms record created successfully',
          placement: 'bottomRight',
          onClose: () => {
            resetForm();
            navigate('/settings/terms');
          },
          duration: 2.5,
        });
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const Terms: Terms = form.getFieldsValue();
    if (id) dispatch(termsActions.updateTerms({ ...Terms, id }));
    else
      dispatch(
        termsActions.createTerms({
          ...Terms,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='form-terms'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Form.Item
        label=''
        name='name'
        rules={[{ required: true, message: 'Please input Terms Name!' }]}
      >
        {/*<Input />*/}
        <JoditEditor
          ref={editor}
          value={content}
          //config={config}
          //tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormEdit;
