import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Image } from 'components/common/Image';
import { inspectionActions, selectInspectionLoad } from 'features/inspection/inspectionSlice';
import { Asset, Inspection } from 'models';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SCIMMS_API } from 'utils/constants';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getFileName,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const formatDate = (date: string) => {
  return (date ? moment(new Date(date)) : null) || moment(new Date());
};
export const SubmitForm: React.FC<{
  prevStep: any;
  hasChange: boolean;
  inspection: Inspection | undefined;
}> = ({ prevStep, inspection, hasChange }) => {
  const { formTypeFilter } = useParams();
  const [disabled, setDisabled] = useState(true);
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loaded = useAppSelector(selectInspectionLoad);
  const disabledForm = TokenService.isDisabled();
  useEffect(() => {
    setDisabled(hasChange);
  }, [hasChange]);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: getFileName(url) || '',
      };
    };
    if (fileUpload) {
      const asset = getImage({ url: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (inspection) {
      form.setFieldsValue({
        next_inspection_date: formatDate(inspection.next_inspection_date),
        lew_name: inspection.lew_name,
        lew_license: inspection.lew_license,
        lew_signature: inspection.lew_signature,
        lew_date: formatDate(inspection.lew_date),
        equip_name: inspection.equip_name,
        equip_company: inspection.equip_company,
        equip_designation: inspection.equip_designation,
        equip_signature: inspection.equip_signature,
        equip_date: formatDate(inspection.equip_date),
        representative_name: inspection.representative_name,
        representative_company: inspection.representative_company,
        representative_designation: inspection.representative_designation,
        representative_signature: inspection.representative_signature,
        representative_date: formatDate(inspection.representative_date),
      });
      if (inspection?.attachment) {
        setFileUpload(inspection?.attachment);
        const setImgList = async (list: any) => {
          Promise.all(list).then((list) => {
            setFileList(list);
          });
        };
        const getImage = async (asset: Asset) => {
          const url = (asset.thumb ? asset.thumb : asset.url) || '';
          const resUrl = (await fetchImage(url)) as { link: string };
          return {
            uid: resUrl?.link || '',
            url: resUrl?.link || '',
            name: getFileName(url) || '',
          };
        };
        setImgList([getImage({ url: inspection.attachment })]);
      }
    }
  }, [inspection, form]);
  const openNotification = useCallback(() => {
    if (loaded) {
      notification.success({
        message: 'Inspection record updated successfully',
        placement: 'bottomRight',
        duration: 2,
        onClose: () => navigate(`/inspection/${formTypeFilter?.replace('_', '')}`),
      });
    }
  }, [loaded, navigate]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const props: UploadProps = {
    name: 'file',
    fileList: fileList,
    customRequest: async ({ file }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `attachment/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async () => {
          setFileUpload(path);
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
    onRemove() {
      setFileList([]);
      setFileUpload('');
    },
  };

  const submitForm = () => {
    const formSubmit = {
      ...inspection,
      ...form.getFieldsValue(),
      attachment: fileUpload,
      authority_req: JSON.stringify(inspection?.authority_req),
      ext_env_check: JSON.stringify(inspection?.ext_env_check),
      func_check_test: JSON.stringify(inspection?.func_check_test),
      electrical_check_test: JSON.stringify(inspection?.electrical_check_test),
    };
    dispatch(inspectionActions.upsertInspection(formSubmit));
  };

  const handleFormChange = () => {
    if (hasFieldEmpty()) setDisabled(true);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setDisabled(hasErrors);
    }
  };
  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue())
      .filter((field) => field !== 'attachment')
      .some((field) => {
        return !form.getFieldValue(field)?.toString()?.trim();
      });
  };
  return (
    <div className='form-wrapper'>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={submitForm}
        form={form}
        onFieldsChange={handleFormChange}
      >
        <Form.Item label='Recommendation date of next inspection' name='next_inspection_date'>
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>
        {inspection?.form_type === 'Annex_B' && (
          <>
            <Form.Item label='Name of LEW' name='lew_name'>
              <Input disabled={disabledForm} />
            </Form.Item>
            <Form.Item label='License' name='lew_license'>
              <Input disabled={disabledForm} />
            </Form.Item>
            <Form.Item label='Signature' name='lew_signature'>
              <Image type='base64' url={`${SCIMMS_API}/upload/file/${inspection.lew_signature}`} />
              {/* <img src="/images/signature.png" alt="" style={{ width: 100 }} /> */}
            </Form.Item>
            <Form.Item label='Date' name='lew_date'>
              <DatePicker
                disabled={disabledForm}
                style={{
                  width: '70%',
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item label='Name of equipment specialist' name='equip_name'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Company' name='equip_company'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Designation' name='equip_designation'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Signature' name='equip_signature'>
          <Image
            type='base64'
            url={`${SCIMMS_API}/upload/file/${inspection?.equip_signature || ''}`}
          />
          {/* <img src="/images/signature.png" alt="" style={{ width: 100 }} /> */}
        </Form.Item>
        <Form.Item label='Date' name='equip_date'>
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>
        <Form.Item label='Name of owner/operator representative' name='representative_name'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Company' name='representative_company'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Designation' name='representative_designation'>
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item label='Signature' name='representative_signature'>
          <Image
            type='base64'
            url={`${SCIMMS_API}/upload/file/${inspection?.representative_signature || ''}`}
          />
          {/* <img src="/images/signature.png" alt="" style={{ width: 100 }} /> */}
        </Form.Item>
        <Form.Item label='Date' name='representative_date'>
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>

        <Form.Item label='Attachment' valuePropName='file' name='attachment'>
          <Upload {...props} disabled={disabledForm}>
            <Button icon={<UploadOutlined />} disabled={disabledForm}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      <div className='action-form'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        {!disabledForm && (
          <Button
            type='primary'
            onClick={submitForm}
            htmlType='submit'
            className={`btn-submit-form`}
            disabled={disabled}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
