import { useAppDispatch } from 'app/hooks';
import React, { useEffect } from 'react';
import { StepTrainingForm } from './steps/Index';
import { trainingActions } from '../trainingSlice';
const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) dispatch(trainingActions.fetchTrainingDetail({ id }));
  }, [id, dispatch]);

  return <StepTrainingForm id={id} />;
};

export default FormEdit;
