/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  ITicketSeverity,
  ITicketStatus,
  ITicketTrendingDay,
  ListParams,
  ListResponse,
  Ticket,
  TicketRequest,
  User,
} from 'models';
import { TicketSeverity, TicketStatus } from 'models/enum/ticket';
import { IProblemCategory } from 'models/problemCategory';
import moment from 'moment';

export interface TicketState {
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  selectedIdsExcel: React.Key[];
  selectedTicketIdsExcel: string[];
  list: Ticket[];
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
  statusCount: ITicketStatus[];
  severityCount: ITicketSeverity[];
  trendingDay: ITicketTrendingDay[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
}

export interface ActionParam {
  id: string;
  flag: boolean;
}

export interface ClosedTicketParam {
  id: string;
  closedDate: Date;
  status: string;
}

export interface Filter {
  created_date_from?: string;
  created_date_to?: string;
  closed_date_from?: string;
  closed_date_to?: string;
  appointment_date_from?: string;
  appointment_date_to?: string;
  attendance_date_from?: string;
  attendance_date_to?: string;
  status?: string;
  severity?: string;
  ticketSearch?: string;
  countryName?: string;
  call_center?: string;
}

export interface TicketPayload {
  data: Ticket;
  navigate: () => void;
}
const initialState: TicketState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  selectedIdsExcel: [],
  selectedTicketIdsExcel: [],
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    created_date_from: '',
    created_date_to: '',
    closed_date_from: '',
    closed_date_to: '',
    status: '',
    severity: '',
  },
  exportPdf: [],
  statusCount: [],
  severityCount: [],
  trendingDay: [],
};

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    fetchTicketList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchTicketListSuccess(state, action: PayloadAction<ListResponse<Ticket>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data || action.payload || [];
    },
    fetchTicketListFailed(state) {
      state.loading = false;
    },
    createTicket(state, action: PayloadAction<User>) {
      state.loading = true;
      state.loaded = false;
    },
    upsertTicket(state, action: PayloadAction<Ticket>) {
      state.loaded = false;
    },
    createTicketSuccess(state, action: PayloadAction<Ticket>) {
      state.loading = false;
      state.loaded = true;
    },
    upsertTicketSuccess(state) {
      state.loaded = true;
    },
    selectedIdExport(state, action: PayloadAction<{ keys: React.Key[] }>) {
      // find all ticket ids of list current
      const ticketIds = state.list.map((item) => item.id);
      // check exist in keys payload
      const keys = action.payload.keys;
      const uncheckTickets = ticketIds.filter((item) => !keys.includes(item.toString()));
      // remove unchecked ticket from state
      const idsState = state.selectedIdsExcel.filter(
        (item) => !uncheckTickets.includes(item.toString()),
      );
      const selectedIdsExcel = new Set([...idsState, ...keys]);
      state.selectedIdsExcel = Array.from(selectedIdsExcel);
    },
    unSelectedIdExport(state) {
      state.selectedIdsExcel = [];
    },
    selectedTicketIdExport(state, action: PayloadAction<{ ticketIds: string[] }>) {
      // find all ticket ids of list current
      const ticketIds = state.list.map((item) => item.ticket_id);
      // check exist in keys payload
      const ids = action.payload.ticketIds;
      const uncheckTickets = ticketIds.filter((item) => !ids.includes(item.toString()));
      // remove unchecked ticket from state
      const idsState = state.selectedTicketIdsExcel.filter(
        (item) => !uncheckTickets.includes(item.toString()),
      );
      const selectedTicketIdsExcel = new Set([...idsState, ...ids]);
      state.selectedTicketIdsExcel = Array.from(selectedTicketIdsExcel);
    },
    unSelectedTicketIdExport(state) {
      state.selectedTicketIdsExcel = [];
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
    search(state, action: PayloadAction<{ filter: Filter; pagination?: Pagination }>) {
      const { filter, pagination } = action.payload;
      state.filter = filter;
      state.list = [];
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      }
    },
    deleteTicket(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deleteTicketSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
    fetchTicketDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchCountStatus(state, action: PayloadAction<{}>) {},
    fetchCountStatusSuccess(state, action: PayloadAction<ITicketStatus[]>) {
      state.statusCount = action.payload;
    },
    fetchCountSeverity(state, action: PayloadAction<{}>) {},
    fetchCountSeveritySuccess(state, action: PayloadAction<ITicketSeverity[]>) {
      state.severityCount = action.payload;
    },
    fetchTrendingDay(state, action: PayloadAction<{}>) {},
    fetchTrendingDaySuccess(state, action: PayloadAction<ITicketTrendingDay[]>) {
      state.trendingDay = action.payload.reverse();
    },
    acceptDecline(state, action: PayloadAction<{ id: string | undefined; flag: boolean }>) {},
    close(state, action: PayloadAction<TicketRequest>) {},
    upsertRecord(state, action: PayloadAction<TicketRequest>) {
      state.loaded = false;
    },
    upsertRecordSuccess(state) {
      state.loaded = true;
    },
    fetchTicketDetailSuccess(state, action: PayloadAction<Ticket>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
  },
});

// Actions
export const ticketActions = ticketSlice.actions;

// Selectors
export const selectTicketList = (state: RootState) => state.ticket.list;
/*export const selectTicket = (state: RootState) => state.ticket;
export const selectTicketList = (state: RootState) => state.ticket.list;
*/
export const selectTicketLoad = (state: RootState) => state.ticket.loaded;
export const selectTicketLoading = (state: RootState) => state.ticket.loading;

export const selectPagination = (state: RootState) => state.ticket.pagination;
export const selectFilter = (state: RootState) => state.ticket.filter;
export const selectDeleting = (state: RootState) => state.ticket.deleting;
export const selectedIdsExcel = (state: RootState) => state.ticket.selectedIdsExcel;
export const selectedTicketIdsExcel = (state: RootState) => state.ticket.selectedTicketIdsExcel;
export const totalTicketStatusClose = (state: RootState) =>
  +(
    state.ticket.statusCount.find((item) => +item.status === TicketStatus.Closed)?.statusCount || 0
  );
export const totalTicketStatusOpen = (state: RootState) =>
  +(
    state.ticket.statusCount.find((item: ITicketStatus) => +item.status === TicketStatus.Open)
      ?.statusCount || 0
  );
export const totalTicketSeverityUrgent = (state: RootState) =>
  +(
    state.ticket.severityCount.find((item) => +item.severity === TicketSeverity.Urgent)
      ?.severityCount || 0
  );
export const totalTicketSeverityHigh = (state: RootState) =>
  +(
    state.ticket.severityCount.find((item) => +item.severity === TicketSeverity.High)
      ?.severityCount || 0
  );
export const totalTicketSeverityMedium = (state: RootState) =>
  +(
    state.ticket.severityCount.find((item) => +item.severity === TicketSeverity.Medium)
      ?.severityCount || 0
  );
export const totalTicketSeverityLow = (state: RootState) =>
  +(
    state.ticket.severityCount.find((item) => +item.severity === TicketSeverity.Low)
      ?.severityCount || 0
  );

export const rangeTrendingDate = (state: RootState) => {
  const dates =
    state.ticket.trendingDay.map((td) => moment(td.createdDay).format('YYYY/MM/DD')) || [];
  const dateSet = new Set(dates);
  return dates.length ? Array.from(dateSet).sort() : [];
};

export const maxCountTrendingDay = (state: RootState) => {
  let max = 0;
  const trendingDay = state.ticket?.trendingDay;
  if (trendingDay) {
    for (const td of trendingDay) {
      const statusCount = +td.statusCount;
      if (max < statusCount) max = statusCount;
    }
  }
  return max;
};

export const openTrendingDay = (state: RootState) =>
  state.ticket.trendingDay.filter((o) => +o.status === TicketStatus.Open);
export const closedTrendingDay = (state: RootState) =>
  state.ticket.trendingDay.filter((o) => +o.status === TicketStatus.Closed);
/*
export const selectExport = (state: RootState) => state.ticket.exportPdf;
export const selectTotalRecords = (state: RootState) =>
    state.ticket.pagination.totalRecords;
*/

// Reducer
const ticketReducer = ticketSlice.reducer;
export default ticketReducer;
