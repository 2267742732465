import { Button, notification, Tabs } from 'antd';
import Form from 'antd/es/form/Form';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { Ticket } from 'models';
import { TicketStatus } from 'models/enum/ticket';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMine } from 'utils/function';
import TokenService from 'utils/token.service';
import { selectTicketLoad, ticketActions } from '../../ticketSlice';
import { InterimContainmentAction } from './interimContainmentAction';
import { PermanentSolutions } from './permanentSolutions';
import { RootCause } from './rootCause';
import { SparePartUsed } from './sparePartUsed';

const toStringArrayTicket = (ticket: Ticket) => {
  return {
    ...ticket,
    site_attendance: JSON.stringify(ticket.site_attendance),
    root_cause: JSON.stringify(ticket.root_cause),
    interim_containment: JSON.stringify(ticket.interim_containment),
    permanent_solution: JSON.stringify(ticket.permanent_solution),
    spare_part_used: JSON.stringify(ticket.spare_part_used),
    problem_description_photo: JSON.stringify(ticket.problem_description_photo),
  };
};
const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};

interface IForm {
  prevStep: () => void;
  hasChange: boolean;
  ticket: Ticket | undefined;
}
export const SubmitForm: React.FC<IForm> = ({ prevStep, ticket, hasChange }) => {
  const [disabled, setDisabled] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loaded = useAppSelector(selectTicketLoad);
  const isSystemAdmin = TokenService.isSystemAdmin();

  const disabledForm =
    isSystemAdmin ||
    (ticket?.status !== undefined &&
      isOpen(ticket?.status) &&
      isMine(ticket?.service_engineer || ''));
  const [ticketData, setTicketData] = useState(ticket);
  useEffect(() => {
    setDisabled(hasChange);
  }, [hasChange]);

  useEffect(() => {
    if (ticket) {
      form.setFieldsValue({
        root_cause: ticket?.root_cause,
        interim_containment_action: ticket?.interim_containment,
        permanent_solution: ticket?.permanent_solution,
      });
    }
  }, [ticket, form]);
  const openNotification = useCallback(() => {
    if (loaded) {
      notification.success({
        message: 'Ticket record updated successfully',
        placement: 'bottomRight',
        duration: 2,
        onClose: () => navigate('/ticket'),
      });
    }
  }, [loaded, navigate]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const submitForm = () => {
    if (ticketData) {
      const ticket = toStringArrayTicket({
        ...ticketData,
        ticket_event_list: ticketData.ticket_event_list?.filter((item) => item.event_type),
      });
      dispatch(ticketActions.upsertRecord(ticket));
    }
  };
  const handleChange = (data: Ticket) => {
    setTicketData({ ...ticketData, ...data });
  };

  return (
    <div className='form-wrapper ticket-detail'>
      <Form form={form}>
        <Tabs>
          <Tabs.TabPane tab='Interim Containment Action' key='item-2' className='tab-one'>
            <InterimContainmentAction
              ticket={ticket}
              data={ticketData?.interim_containment || []}
              handleChange={handleChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Root Cause' key='item-3'>
            <RootCause
              ticket={ticket}
              handleChange={handleChange}
              data={ticketData?.root_cause || []}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Permanent Solutions' key='item-4'>
            <PermanentSolutions
              ticket={ticket}
              data={ticketData?.permanent_solution || []}
              handleChange={handleChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Spare Part Used' key='item-5'>
            <SparePartUsed
              ticket={ticket}
              data={ticketData?.spare_part_used || []}
              handleChange={handleChange}
            />
          </Tabs.TabPane>
        </Tabs>
      </Form>

      <div className='action-form action-form-ticket'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        {disabledForm && (
          <Button
            type='primary'
            onClick={submitForm}
            htmlType='submit'
            className={`btn-submit-form`}
            disabled={disabled}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
