import React, { useEffect, useState } from 'react';
import { fetchImage } from 'utils/function';

export const Attachment: React.FC<{ attachment: string }> = ({ attachment }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (attachment) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchImage(attachment).then((res: any) => setUrl(res?.link || ''));
    }
  }, [attachment]);
  return (
    <a className='user-content-attachment' href={url}>
      {attachment}
    </a>
  );
};
