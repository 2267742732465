import { notification } from 'antd';
import settingApi from 'api/settingApi';
import {
  ChargerModel,
  InspectionChargerModelCertificatedUser,
  ListParams,
  ListResponse,
  Pagination,
} from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { chargerModelActions, selectName, selectPagination } from './chargerModelSlice';
import { FlagService } from 'models/enum/chargerModel';

type Params = { payload: ChargerModel; type: string };

function* fetchChargerModelList(param: ListParams) {
  try {
    const { flag_service } = param.payload;
    if (flag_service) {
      const pagination: Pagination = yield select(selectPagination);
      const response: ListResponse<ChargerModel> = yield call(settingApi.getAllChargerModelByName, {
        pageNo: param._page || pagination.pageNo,
        pageSize: param._limit || pagination.size,
        flag_service,
      });
      yield put(chargerModelActions.fetchChargerModelListSuccess(response));
    } else {
      const name: string = yield select(selectName);
      yield put(chargerModelActions.search({ name }));
    }
  } catch (error) {
    console.log('Failed to fetch ChargerModel list', error);
  }
}

function* fetchChargerModelCertificatedUser() {
  try {
    const response: InspectionChargerModelCertificatedUser[] = yield call(
      settingApi.getAllChargerModelCertificatedUser,
    );
    yield put(chargerModelActions.fetchChargerModelCertificatedUserSuccess(response));
  } catch (error) {
    console.log('Failed to fetch ChargerModel list', error);
  }
}

type SearchParams = { payload: { name: string; flag_service?: FlagService }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<ChargerModel> = yield call(settingApi.searchChargerModelByParams, {
      chargerModelName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      flag_service: param.payload.flag_service,
    });
    yield put(chargerModelActions.fetchChargerModelListSuccess(response));
  } catch (error) {
    console.log('Failed to search charger model', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectName);
    yield put(chargerModelActions.search({ name }));
  } catch (error) {
    console.log('Failed to fetch company', error);
  }
}

function* createChargerModel(param: Params) {
  try {
    const response: ChargerModel = yield call(settingApi.createChargerModel, param.payload);
    yield put(chargerModelActions.createChargerModelSuccess(response));
    const name: string = yield select(selectName);
    yield put(chargerModelActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateChargerModel(param: Params) {
  try {
    const response: ChargerModel = yield call(settingApi.updateChargerModel, param.payload);
    yield put(chargerModelActions.updateChargerModelSuccess(response));
    const name: string = yield select(selectName);
    const pagination: Pagination = yield select(selectPagination);
    yield put(chargerModelActions.search({ name, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type ChargerModelDetailParams = { payload: { id: string }; type: string };
function* fetchChargerModelDetail(param: ChargerModelDetailParams) {
  try {
    const id = param.payload.id;
    const response: ChargerModel = yield call(settingApi.findChargerModelById, id);
    yield put(chargerModelActions.fetchChargerModelSuccess(response));
  } catch (error) {
    console.log('Failed to find ChargerModel by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteChargerModel(param: DeleteParams) {
  try {
    const id = param.payload.id;

    yield call(settingApi.removeChargerModel, id);
    yield put(chargerModelActions.deleteChargerModelSuccess());
    const name: string = yield select(selectName);
    yield put(chargerModelActions.search({ name }));
  } catch (error) {
    console.log('Failed to delete ChargerModel', error);
    yield put(chargerModelActions.deleteError());
  }
}

export default function* chargerModelSaga() {
  yield takeLatest(chargerModelActions.fetchChargerModelList.type, fetchChargerModelList);
  yield takeLatest(
    chargerModelActions.fetchChargerModelCertificatedUser.type,
    fetchChargerModelCertificatedUser,
  );
  yield takeEvery(chargerModelActions.createChargerModel.type, createChargerModel);
  yield takeEvery(chargerModelActions.updateChargerModel.type, updateChargerModel);
  yield takeLatest(chargerModelActions.fetchChargerModelDetail.type, fetchChargerModelDetail);
  yield takeLatest(chargerModelActions.deleteChargerModel.type, deleteChargerModel);
  yield takeLatest(chargerModelActions.changePage.type, changePage);
  yield takeLatest(chargerModelActions.search.type, search);
}
