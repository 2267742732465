import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CertificationStatus } from 'models/enum/certification';
import React, { useEffect } from 'react';
import {
  certificationActions,
  selectCertificationSuspend,
} from '../certificationSlice';

const ModalSuspend: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const certificationOption = useAppSelector(
    (state) =>
      state.certification?.list?.filter((item) => item.id === id),
  );

  const suspended = useAppSelector(selectCertificationSuspend);

  useEffect(() => {
    if (suspended) {
      form.resetFields();
      setIsModalVisible(false);
      notification.success({
        message: 'Suspend user successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [suspended]);

  const onFinish = (values: any) => {
    const certification: any = form.getFieldsValue();
    dispatch(certificationActions.suspendCertification({
      id,
      status: CertificationStatus.Suspended,
      Remarks: certification.Remarks,
    }));
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<div>Suspend User</div>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Username'
          name='username'
        >
          <Select
            defaultValue={certificationOption[0]?.trainee.user.id}
            disabled
            options={[
              {
                value: certificationOption[0]?.trainee.user.id,
                label: certificationOption[0]?.trainee.user.username
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label='Remarks'
          name='Remarks'
          rules={[{ required: true, message: 'Please input Remarks!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalSuspend;
