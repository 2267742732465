import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
const { Search } = Input;

const SearchCustom: React.FC<{ handleSearch: any }> = ({ handleSearch }) => {
  const onSearch = (keySearch: any) => {
    handleSearch(keySearch);
  };
  return (
    <>
      <Search
        className='common-search'
        placeholder='input search content'
        enterButton={
          <>
            <SearchOutlined />
            Search
          </>
        }
        onSearch={onSearch}
      />
    </>
  );
};
export default SearchCustom;
