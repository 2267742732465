/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import certificationApi from 'api/certificationApi';
import {
  ListResponse,
  Certification,
} from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Filter,
  Pagination,
  selectFilter,
  selectPagination,
  certificationActions,
} from './certificationSlice';

function* fetchCertificationList() {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Certification> = yield call(certificationApi.getAll, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(certificationActions.fetchCertificationListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch certification list', error);
    yield put(certificationActions.fetchCertificationListFailed());
  }
}

type Params = { payload: Certification; type: string };

type PageParams = { payload: Pagination; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* changePage(param: PageParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(certificationActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type SearchParams = { payload: Filter; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<Certification> = yield call(certificationApi.searchByParams, {
      ...filter,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(certificationActions.fetchCertificationListSuccess(response));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

function* suspendCertification(param: Params) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    const response: Certification = yield call(certificationApi.suspendCertification, param.payload);
    yield put(certificationActions.suspendCertificationSuccess(response));
    yield put(certificationActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

export default function* certificationSaga() {
  yield takeLatest(certificationActions.fetchCertificationList.type, fetchCertificationList);
  yield takeEvery(certificationActions.changePage.type, changePage);
  yield takeLatest(certificationActions.search.type, search);
  yield takeEvery(certificationActions.suspendCertification.type, suspendCertification);
}
