import { Image } from 'components/common/Image';
import { Inspection } from 'models';
import React from 'react';
import { SCIMMS_API } from 'utils/constants';
import { Pagination } from './pagination';
// const representative_date = new Date(datas.representative_date)
//   .toISOString()
//   .split("T")[0];
export const Page5: React.FC<{ datas: Inspection | undefined }> = ({ datas }) => {
  const representative_date = new Date(datas?.representative_date || '')
    .toISOString()
    .split('T')[0];
  return (
    <>
      {datas && (
        <div className='page-wrapper'>
          <div className='page-date'>TR 25-1:2022</div>
          <div className='page-content'>
            <h4>The inspection was witnessed by owner/operator of EVCS</h4>
            <div className='text-input'>
              <strong className='text-input-title'>Name of owner/operator representative:</strong>
              <p className='text-input__value'>{datas.representative_name}</p>
            </div>
            <div className='text-input text-input-3'>
              <div className='text-input-left'>
                <p className='text-input__title'>Company:</p>
                <p className='text-input__value'>{datas.representative_company}</p>
              </div>
              <div className='text-input-right'>
                <p className='text-input__title'>Designation:</p>
                <p className='text-input__value'>{datas.representative_designation}</p>
              </div>
            </div>
            <div className='text-input text-input-3'>
              <div className='text-input-left'>
                <p className='text-input__title'>Signature:</p>
                <p className='text-input__value'>
                  <Image
                    type='base64'
                    className='signature'
                    url={`${SCIMMS_API}/upload/file/${datas.representative_signature || ''}`}
                  />
                </p>
              </div>
              <div className='text-input-right'>
                <p className='text-input__title'>Date:</p>
                <p className='text-input__value'>{representative_date}</p>
              </div>
            </div>
            <p>(Extract from TR 25-1:2022)</p>
            <p>
              NOTE1 -It is the responsibility of the operator/owner of the EVCSs to perform the
              necessary maintenance routines as recommended by the equipment specialist or
              manufacturer to ensure the safe use of the EVCS and any potential hazard due to lack
              of maintenance.
            </p>
            <p>NOTE 2 - Fault reporting procedure</p>
            <p>
              If any EV charging station or charging point is found to be unsafe or unsuitable for
              operation, the following steps shall be taken:
            </p>
            <ul>
              <li>a) The supply to the equipment shall be switched off;</li>
              <li>
                b) The means of isolation shall be off and locked to prevent re-connection of
                supply;
              </li>
              <li>
                c) A clear label notifying users that the equipment is out of service shall be
                displayed prominently;and
              </li>
              <li>d) The operator/owner shall be informed immediately.</li>
            </ul>

            <div className='scale-height' />
            <Pagination page={5} totalPage={5} />
          </div>
        </div>
      )}
    </>
  );
};
