import { ListParams, ListResponse, Training } from 'models';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const ENDPOINTS = {
  CUSTOMER_TRAINING: '/customer-training',
  FIND_BY_PARAMS: '/find-by-params',
};

const trainingApi = {
  getAll(params: ListParams): Promise<ListResponse<Training>> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchByParams(params: ListParams): Promise<ListResponse<Training>> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}${ENDPOINTS.FIND_BY_PARAMS}`;
    return axiosClient.get(url, {
      params,
    });
  },

  upsert(param: Training): Promise<Training> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  getById(id: string): Promise<Training> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}/one?id=${id}`;
    return axiosClient.get(url);
  },

  create(param: Training): Promise<Training> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  add(data: Training): Promise<Training> {
    const url = ENDPOINTS.CUSTOMER_TRAINING;
    return axiosClient.post(url, data);
  },

  update(data: Partial<Training>): Promise<Training> {
    const url = `${ENDPOINTS.CUSTOMER_TRAINING}/${data.id}`;
    return axiosClient.patch(url, data);
  },

  remove(id: string): Promise<any> {
    const url = `${SCIMMS_API}${ENDPOINTS.CUSTOMER_TRAINING}/${id}`;
    return axiosClient.delete(url);
  },
};

export default trainingApi;
