/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse, Pagination } from 'models';
import { User } from '../../models/user';

export interface UserPayload {
  username: string;
  password: string;
}

export interface UserEngineer {
  username: string;
  firstname: string;
  lastname: string;
}

export interface UserState {
  list: User[];
  loading: boolean;
  loaded: boolean;
  pagination: Pagination;
  engineers?: UserEngineer[];
  filter: {
    username: string;
  };
}

const initialState: UserState = {
  loading: false,
  loaded: false,
  list: [],
  engineers: [],
  filter: {
    username: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchUserListSuccess(state, action: PayloadAction<ListResponse<User>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination.totalRecords = action.payload.totalRecords || state.pagination.totalRecords;
      state.list = action.payload.data;
    },
    createUser(state, action: PayloadAction<User>) {
      state.loading = true;
      state.loaded = false;
    },
    createUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
      state.loaded = true;
    },
    fetchUserDetail(state, action: PayloadAction<{ id: string | undefined }>) {
      state.list = [];
    },
    fetchUserSuccess(state, action: PayloadAction<User>) {
      // if (state.list.length)
      //   state.list.map((el) => {
      //     if (el.id === action.payload.id) {
      //       return Object.assign({}, el, action.payload);
      //     }
      //     return el;
      //   });
      // else state.list = [action.payload];
      state.list = [action.payload];
    },
    updateUser(state, action: PayloadAction<User>) {
      state.loading = true;
      state.loaded = false;
    },
    updateUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
      state.loaded = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    search(
      state,
      action: PayloadAction<{ username: string; isFirst?: boolean; pagination?: Pagination }>,
    ) {
      const { username, isFirst, pagination } = action.payload;
      state.loading = false;
      if (isFirst) {
        state.pagination = {
          ...state.pagination,
          pageNo: 1,
          size: 10,
        };
      } else if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      } else {
        state.pagination = {
          ...state.pagination,
          size: 10,
        };
      }
      state.filter = {
        ...state.filter,
        username: username,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
    fetchUserEngineers(state, action: PayloadAction<{}>) {},
    fetchUserEngineersSuccess(state, action: PayloadAction<UserEngineer[]>) {
      state.engineers = action.payload;
    },
  },
});

// Actions
export const userActions = userSlice.actions;

// selector
export const selectInspectionLoad = (state: RootState) => state.user.loaded;
export const selectPagination = (state: RootState) => state.user.pagination;
export const selectUsername = (state: RootState) => state.user.filter.username;
export const selectUserEngineers = (state: RootState) => state.user.engineers;
export const selectUsers = (state: RootState) => state.user.list;

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;
