import React from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import PrivateUserRoute from 'components/common/PrivateUserRoute';
import { AdminLayout } from 'components/Layout';
import LoginPage from 'features/auth/pages/LoginPage';
import EditInspectionPage from 'features/inspection/pages/EditInspectionPage';
import InspectionPage from 'features/inspection/pages/InspectionPage';
import OverviewPage from 'features/overview/pages/OverviewPage';
import CompanyPage from 'features/settings/company/pages/CompanyPage';
import EditCompanyPage from 'features/settings/company/pages/EditCompanyPage';
import CountryPage from 'features/settings/country/pages/CountryPage';
import EditCountryPage from 'features/settings/country/pages/EditCountryPage';
import EditPolicyPage from 'features/settings/policy/pages/EditPolicyPage';
import PolicyPage from 'features/settings/policy/pages/PolicyPage';
import EditTermsPage from 'features/settings/terms/pages/EditTermsPage';
import TermsPage from 'features/settings/terms/pages/TermsPage';
import EditTicketPage from 'features/tickets/pages/EditTicketPage';
import TicketPage from 'features/tickets/pages/TicketPage';
import CertificationPage from 'features/certification/pages/CertificationPage';
import EditUserPage from 'features/user/pages/EditUserPage';
import TrainingPage from 'features/training/pages/TrainingPage';
import EditTrainingPage from 'features/training/pages/EditTrainingPage';
import UserPage from 'features/user/pages/UserPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/main.scss';

import PolicyViewPage from 'features/auth/pages/PolicyViewPage';
import CategoryPage from 'features/settings/category/pages/CategoryPage';
import ProblemCategoryPage from 'features/settings/problemCategory/pages/ProblemCategoryPage';
import CertificateAdminPage from 'features/settings/certificate-admin/pages/CertificateAdminPage';
import ChargerModelPage from 'features/settings/charger-model/pages/ChargerModelPage';
import EditChargerModelPage from 'features/settings/charger-model/pages/EditChargerModelPage';
import ProvincePage from 'features/settings/province/pages/ProvincePage';
import SerialNumberPage from 'features/settings/serial-number/pages/SerialNumberPage';
import TokenService from 'utils/token.service';
import InspOtherRegionPage from 'features/inspOtherRegion/pages/InspOtherRegionPage';
import EditCommissionOtherRegionPage from 'features/inspOtherRegion/pages/EditInspectionPage';
import MaintenanceOtherRegionsPage from 'features/maintenanceOtherRegion/pages/MaintenanceOtherRegionsPage';
import EditMaintenanceOtherRegionsPage from 'features/maintenanceOtherRegion/pages/EditMaintenanceOtherRegionsPage';
import NotFound from 'features/error/pages/NotFound';

import routesString from 'routes_string';
import { InspectionForm } from 'models/enum/inspection';
import { Role } from 'models/enum/role';

const App: React.FC = () => {
  const isSystemAdmin = TokenService.getUser()?.role === Role.SYSTEM_ADMIN;
  const isAdmin = TokenService.getUser()?.role === Role.SC_ADMIN;

  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/privacy-policy' element={<PolicyViewPage />} />
      <Route
        path='/'
        element={
          <PrivateRoute>
            <AdminLayout />
          </PrivateRoute>
        }
      >
        {isAdmin || isSystemAdmin ? (
          <Route path='/' element={<Navigate to='/overview' replace />} />
        ) : (
          <Route path='/' element={<Navigate to='/ticket' replace />} />
        )}
        <Route
          path='/inspection/annexB'
          element={<InspectionPage formTypeFilter={InspectionForm.ANNEX_B} />}
        />
        <Route path='/commissioning/otherRegions' element={<InspOtherRegionPage />} />
        <Route
          path='/commissioning/otherRegions/edit/:id'
          element={<EditCommissionOtherRegionPage />}
        />
        <Route
          path='/inspection/annexC'
          element={<InspectionPage formTypeFilter={InspectionForm.ANNEX_C} />}
        />
        <Route
          path={routesString.MAINTENANCE_OTHER_REGIONS}
          element={<MaintenanceOtherRegionsPage />}
        />
        <Route
          path={`${routesString.MAINTENANCE_OTHER_REGIONS}/edit/:id`}
          element={<EditMaintenanceOtherRegionsPage />}
        />
        <Route path='/inspection/:formTypeFilter/edit/:id' element={<EditInspectionPage />} />
        <Route path='/overview' element={<OverviewPage />} />
        <Route path='/ticket' element={<TicketPage />} />
        <Route path='/ticket/edit/:id' element={<EditTicketPage />} />
        <Route path='/ticket/create' element={<EditTicketPage />} />
        <Route path='/ticket/create' element={<EditTicketPage />} />
        <Route path='/training' element={<TrainingPage />} />
        <Route path='/training/edit/:id' element={<EditTrainingPage />} />
        <Route path='/certification' element={<CertificationPage />} />
        <Route path='/settings/country' element={<CountryPage />} />
        <Route
          path='/country/create'
          element={
            <PrivateUserRoute>
              <EditCountryPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/country/edit/:id'
          element={
            <PrivateUserRoute>
              <EditCountryPage />
            </PrivateUserRoute>
          }
        />
        <Route path='/settings/company' element={<CompanyPage />} />
        <Route
          path='/company/create'
          element={
            <PrivateUserRoute>
              <EditCompanyPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/company/edit/:id'
          element={
            <PrivateUserRoute>
              <EditCompanyPage />
            </PrivateUserRoute>
          }
        />
        <Route path='/settings/charger-model' element={<ChargerModelPage />} />
        <Route
          path='/charger-model/create'
          element={
            <PrivateUserRoute>
              <EditChargerModelPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/charger-model/edit/:id'
          element={
            <PrivateUserRoute>
              <EditChargerModelPage />
            </PrivateUserRoute>
          }
        />
        <Route path='/settings/terms' element={<TermsPage />} />
        <Route
          path='/terms/create'
          element={
            <PrivateUserRoute>
              <EditTermsPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/terms/edit/:id'
          element={
            <PrivateUserRoute>
              <EditTermsPage />
            </PrivateUserRoute>
          }
        />
        <Route path='/settings/policy' element={<PolicyPage />} />
        <Route
          path='/policy/create'
          element={
            <PrivateUserRoute>
              <EditPolicyPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/policy/edit/:id'
          element={
            <PrivateUserRoute>
              <EditPolicyPage />
            </PrivateUserRoute>
          }
        />
        <Route path={routesString.SETTINGS_CATEGORY} element={<CategoryPage />} />
        <Route path='/settings/certificate-admin' element={<CertificateAdminPage />} />
        <Route path={routesString.SETTINGS_PROBLEM_CATEGORY} element={<ProblemCategoryPage />} />
        <Route path='/settings/country/province' element={<ProvincePage />} />
        <Route path='/settings/charger-model/serial-number' element={<SerialNumberPage />} />
        <Route
          path='/user'
          element={
            <PrivateUserRoute>
              <UserPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/user/create'
          element={
            <PrivateUserRoute>
              <EditUserPage />
            </PrivateUserRoute>
          }
        />
        <Route
          path='/user/edit/:id'
          element={
            <PrivateUserRoute>
              <EditUserPage />
            </PrivateUserRoute>
          }
        />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default App;
