import React, { useEffect, useState } from 'react';
import { Info } from './OverviewPage';
interface ICardRun {
  item: Info;
  numInc: number;
  borderColor: string;
}
export const CardRun: React.FC<ICardRun> = ({ item, numInc, borderColor }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    let intervalId: any = null;
    if (value < +item.value && numInc >= 1)
      intervalId = setInterval(() => {
        setValue(value + numInc <= +item.value ? value + numInc : +item.value);
      }, 200);
    else setValue(+item.value);

    return () => clearInterval(intervalId);
  }, [value, item, numInc]);
  return (
    <div
      className={'camis-card overview-top-card mgb-30 hover-effect'}
      style={{ borderColor: `${borderColor}` }}
    >
      <label>{item.title}</label>
      {item.value !== '' && <div className={'c'}>{value}</div>}
    </div>
  );
};
