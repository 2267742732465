/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { MaintenanceRegion, ListParams, ListResponse } from 'models';
import { sortDateByStringMaintenanceOtherRegion } from 'utils/function';

export interface MaintenanceOtherRegionState {
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  list: MaintenanceRegion[];
  detail: MaintenanceRegion | null;
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
  selectedIdsExcel: string[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
}

export interface Filter {
  maintenance_date_from?: string;
  maintenance_date_to?: string;
  engineer_name?: string;
  company_id?: string;
  charger_owner_name?: string;
  country_name?: string;
  site_name?: string;
  charger_model_name?: string;
  sn_Number?: string;
  work_order_ID?: string;
  conclusion_result?: string;
  specialist_name?: string;
  specialist_company_name?: string;
  representative_name?: string;
  representative_company_name?: string;
}

export interface MaintenanceOtherRegionPayload {
  data: MaintenanceRegion;
  navigate: any;
}
const initialState: MaintenanceOtherRegionState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  detail: null,
  selectedIdsExcel: [],
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    maintenance_date_from: '',
    maintenance_date_to: '',
    engineer_name: '',
    company_id: '',
    charger_owner_name: '',
    country_name: '',
    site_name: '',
    charger_model_name: '',
    sn_Number: '',
    work_order_ID: '',
    conclusion_result: '',
    specialist_name: '',
    specialist_company_name: '',
    representative_name: '',
    representative_company_name: '',
  },
  exportPdf: [],
};

const maintenanceOtherRegionSlice = createSlice({
  name: 'maintenanceOtherRegion',
  initialState,
  reducers: {
    fetchMaintenanceList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchMaintenanceListSuccess(state, action: PayloadAction<ListResponse<MaintenanceRegion>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || 0,
      };

      state.list = action.payload.data.sort(sortDateByStringMaintenanceOtherRegion);
    },
    fetchMaintenanceListFailed(state) {
      state.loading = false;
    },
    upsertMaintenance(state, action: PayloadAction<MaintenanceRegion>) {
      state.loaded = false;
    },
    upsertMaintenanceSuccess(state) {
      state.loaded = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    search(state, action: PayloadAction<Filter>) {
      state.list = [];
      state.filter = action.payload;
    },
    deleteMaintenance(state, action: PayloadAction<{ ids: string[] }>) {
      state.deleting = true;
    },
    deleteMaintenanceSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    deleteMaintenanceFailed(state) {
      state.deleting = false;
    },
    deleteRecord(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deleteRecordSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    deleteRecordFailed(state) {
      state.deleting = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
    fetchMaintenanceOtherRegionDetail(state, action: PayloadAction<{ id: string | undefined }>) {
      state.detail = null;
    },
    fetchMaintenanceOtherRegionDetailSuccess(state, action: PayloadAction<MaintenanceRegion>) {
      state.detail = action.payload;
    },
    selectedIdsExport(state, action: PayloadAction<{ Ids: string[] }>) {
      // find all record ids of list current
      const Ids = state.list.map((item) => item.id);
      // check exist in keys payload
      const ids = action.payload.Ids;
      const uncheckRecords = Ids.filter((item) => !ids.includes(item.toString()));
      // remove unchecked record from state
      const idsState = state.selectedIdsExcel.filter(
        (item) => !uncheckRecords.includes(item.toString()),
      );
      const selectedIdsExcel = new Set([...idsState, ...ids]);
      state.selectedIdsExcel = Array.from(selectedIdsExcel);
    },
    unSelectedIdsExport(state) {
      state.selectedIdsExcel = [];
    },
  },
});

// Actions
export const maintenanceOtherRegionActions = maintenanceOtherRegionSlice.actions;

// Selectors
export const selectMaintenanceOtherRegion = (state: RootState) => state.maintenanceOtherRegion;
export const selectMaintenanceOtherRegionList = (state: RootState) =>
  state.maintenanceOtherRegion.list;
export const selectMaintenanceOtherRegionLoad = (state: RootState) =>
  state.maintenanceOtherRegion.loaded;
export const selectMaintenanceOtherRegionLoading = (state: RootState) =>
  state.maintenanceOtherRegion.loading;

export const selectPagination = (state: RootState) => state.maintenanceOtherRegion.pagination;
export const selectFilter = (state: RootState) => state.maintenanceOtherRegion.filter;
export const selectDeleting = (state: RootState) => state.maintenanceOtherRegion.deleting;
export const selectExport = (state: RootState) => state.maintenanceOtherRegion.exportPdf;
export const selectTotalRecords = (state: RootState) =>
  state.maintenanceOtherRegion.pagination.totalRecords;
export const selectedIdsExcel = (state: RootState) => state.maintenanceOtherRegion.selectedIdsExcel;

// Reducer
const maintenanceOtherRegionsReducer = maintenanceOtherRegionSlice.reducer;
export default maintenanceOtherRegionsReducer;
