/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse, Pagination } from 'models';
import { Province } from 'models/province';

export interface Filter {
  name: string;
  countryId: string;
}
export interface ProvinceState {
  list: Province[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
  upserted: boolean;
  pagination: Pagination;
  filter: Filter;
  error: boolean;
}

const initialState: ProvinceState = {
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
  upserted: false,
  error: false,
  list: [],
  filter: {
    name: '',
    countryId: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const provinceSlice = createSlice({
  name: 'province',
  initialState,
  reducers: {
    fetchProvinceList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload._page || state.pagination.pageNo,
        size: action.payload._limit || state.pagination.size,
      };
    },
    fetchProvinceListSuccess(state, action: PayloadAction<ListResponse<Province>>) {
      state.loading = false;
      state.loaded = true;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data;
    },
    createProvince(state, action: PayloadAction<Province>) {
      state.error = false;
      state.deleted = false;
      state.loading = true;
      state.upserted = false;
    },
    createProvinceSuccess(state, action: PayloadAction<Province>) {
      state.loading = false;
      state.upserted = true;
    },
    updateProvince(state, action: PayloadAction<Province>) {
      state.error = false;
      state.deleted = false;
      state.loading = true;
      state.upserted = false;
    },
    updateProvinceSuccess(state, action: PayloadAction<Province>) {
      state.loading = false;
      state.upserted = true;
    },
    fetchProvinceDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchProvinceByCountry(state, action: PayloadAction<{ countryId: string | undefined }>) {},
    fetchProvinceSuccess(state, action: PayloadAction<Province>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    search(
      state,
      action: PayloadAction<{
        name: string;
        countryId: string;
        pagination?: Pagination;
      }>,
    ) {
      state.list = [];
      state.error = false;
      state.deleted = false;
      const { name, countryId, pagination } = action.payload;
      state.filter = { name, countryId };
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      }
    },
    deleteProvince(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
      state.error = false;
      state.deleted = false;
    },
    deleteProvinceSuccess(state) {
      state.loading = true;
      state.deleting = false;
      state.deleted = true;
    },
    deleteError(state) {
      state.error = true;
      state.loading = false;
      state.deleting = false;
      state.deleted = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      state.error = false;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const provinceActions = provinceSlice.actions;

// selector
export const selectProvinceLoad = (state: RootState) => state.province.loaded;
export const selectProvinceUpserted = (state: RootState) => state.province.upserted;
export const selectPagination = (state: RootState) => state.province.pagination;
export const selectName = (state: RootState) => state.province.filter.name;
export const selectFilter = (state: RootState) => state.province.filter;
export const selectDeleting = (state: RootState) => state.province.deleting;
export const selectDeleted = (state: RootState) => state.province.deleted;
export const selectError = (state: RootState) => state.province.error;
export const selectProvinces = (state: RootState) => state.province.list;
export const selectProvinceLoading = (state: RootState) => state.province.loading;

// Reducer
const provinceReducer = provinceSlice.reducer;
export default provinceReducer;
