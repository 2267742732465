/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { Terms } from '../../../models/terms';

export interface TermsState {
  list: Terms[];
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: TermsState = {
  loading: false,
  deleting: false,
  loaded: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 200,
    totalRecords: 0,
  },
};

const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    fetchTermsList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    findForPublic(state) {},
    fetchTermsListSuccess(state, action: PayloadAction<ListResponse<Terms>>) {
      state.loading = false;
      state.loaded = false;
      state.list = action.payload.data;
    },
    createTerms(state, action: PayloadAction<Terms>) {
      state.loading = true;
      state.loaded = false;
    },
    createTermsSuccess(state, action: PayloadAction<Terms>) {
      state.loading = false;
      state.loaded = true;
    },
    updateTerms(state, action: PayloadAction<Terms>) {
      state.loading = true;
      state.loaded = false;
    },
    toggleStatusTerms(state, action: PayloadAction<Terms>) {},
    updateTermsSuccess(state, action: PayloadAction<Terms>) {
      state.loading = false;
      state.loaded = true;
    },
    fetchTermsDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchTermsSuccess(state, action: PayloadAction<Terms>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteTerms(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deleteTermsSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
  },
});

// Actions
export const termsActions = termsSlice.actions;

// selector
export const selectInspectionLoad = (state: RootState) => state.terms.loaded;
export const selectPagination = (state: RootState) => state.terms.pagination;
export const selectName = (state: RootState) => state.terms.filter.name;
export const selectDeleting = (state: RootState) => state.terms.deleting;
export const selectTermsLoading = (state: RootState) => state.terms.loading;

// Reducer
const termsReducer = termsSlice.reducer;
export default termsReducer;
