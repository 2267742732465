import { Navigate } from 'react-router-dom';
import { history } from 'helpers';
import React from 'react';
import TokenService from 'utils/token.service';

const PrivateRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const isLoggedIn = Boolean(TokenService.getLocalAccessToken());

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' state={{ from: history.location }} />;
  }

  // authorized so return child components
  return children;
};

export default PrivateRoute;
