import { CheckOutlined } from '@ant-design/icons';
import { Inspection } from 'models';
import React from 'react';
import { Pagination } from './pagination';
import moment from 'moment';


const ext_env_check_maintenance = [
  {
    no: 1,
    name: 'Installed outside hazardous zones where flammable/combustible gas or material may be present',
    titleNo: 'B1',
  },
  {
    no: 2,
    name: 'Enclosure is not dented, damaged, corroded or in any rusty condition',
    titleNo: 'B2',
  },
  {
    no: 3,
    name: 'Space around the EVCS is adequate for easy access and maintenance work',
    titleNo: 'B3',
  },
  {
    no: 4,
    name: 'Sealing rubber of all doors are in order, and doors including lockset (if any) can be opened and closed easily',
    titleNo: 'B4',
  },
  {
    no: 5,
    name: 'No sign of insects inside EVCS. Openings or vents are not blocked, no excess foreign particles',
    titleNo: 'B5',
  },
  {
    no: 6,
    name: 'Detachable parts are not loose or falling off and not in a rusty condition',
    titleNo: 'B6',
  },
  {
    no: 7,
    name: 'No visible moisture, waterlogging or burnt marks at the EVCS and connector charging pins',
    titleNo: 'B7',
  },
  {
    no: 8,
    name: 'Floor or wall mounting of EVCS remains rigid and strong ',
    titleNo: 'B8',
  },
  {
    no: 9,
    name: 'Vehicle connector cable, mounting and support is secured and not damaged',
    titleNo: 'B9',
  },
  { no: 10, name: 'Electrical warning labels is clear and prominent', titleNo: 'B10' },
  {
    no: 11,
    name: 'Source of DB for EVCS is clearly labeled and electrical source DB is accessible for operation',
    titleNo: 'B11',
  },
  {
    no: 12,
    name: 'Bollards or continuous kerb(s) or other means of crash protection is installed provide adequete protection against moving vehicle',
    titleNo: 'B12',
  },
  {
    no: 13,
    name: 'Charger specification plate is clear, legible and prominent according TR25 requirement',
    titleNo: 'B13',
  },
  {
    no: 14,
    name: 'Owner/operator contact details label is legible and prominent',
    titleNo: 'B14',
  },
  {
    no: 15,
    name: 'Fail-safe emergency stop button (in red and yellow) is prominent and not damaged. For outdoor installation it shall be weatherproof. Clear directional signs shall be provided for multiple charger installation',
    titleNo: 'B15',
  },
  { no: 16, name: 'Adequate lighting for charging operation', titleNo: 'B16' },
  {
    no: 17,
    name: 'Inspection label provided as below.',
    note: '2022-12-01',
    titleNo: 'B17',
  },
  {
    no: 18,
    name: 'Evcss display and all UI accessories(eg, press button, RFID reader etc.) are not damaged and working properly',
    titleNo: 'B18',
  },
  {
    no: 19,
    name: 'Incoming power supply cable including circuit protective conductor connections and termination(s) are properly made and tightened',
    titleNo: 'B19',
  },
  {
    no: 20,
    name: 'Measurement of PP-PE resistance value and compare with IEC given value',
    titleNo: 'B20',
  },
  {
    no: 21,
    name: 'Manufacturer handbook and instruction manuals given to owner/operator',
    titleNo: 'B21',
  }
];

const func_check_test_maintenance = [
  {
    no: 1,
    name: 'Inspect RCCB(s) protecting connecting points shall be at least type A 30mA of symbol and perform manual trip test to all RCCB(s) for Mode 2/2A/2B/3 chargers\nFor mode 4 chargers, earth leakage protection device trip setting should comply with requirements in 411.5.3L of SS 638:2018\nMode 2A and 2B chargers with galvanic isolation shall be at least type AC \n',
    value: '0',
    titleNo: 'C1',
  },
  {
    no: 2,
    name: 'Perform the sequence of normal start and stop on every charging point',
    value: '0',
    titleNo: 'C2',
  },
  {
    no: 3,
    name: 'While charging, check EV ventilation fan (if any) is working',
    value: '0',
    titleNo: 'C3',
  },
  {
    no: 4,
    name: 'While charging, test all the fail-safe emergency stop button(s) to ensure it is functioning.(EVSE shall reset to state A upon releasing)',
    value: '0',
    titleNo: 'C4',
  },
  {
    no: 5,
    name: 'Perform CP short fault simulation for every charging point.(EVSE shall reset to state A when fault is clear)',
    value: '0',
    titleNo: 'C5',
  },
  {
    no: 6,
    name: '(For AC charger) - connector 1\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '2',
    titleNo: 'C6',
  },
  {
    no: 7,
    name: '(For AC charger) - connector 2(if any)\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '2',
    titleNo: 'C7',
  },
  {
    no: 8,
    name: '(For DC charger) - connector 1\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C8',
  },
  {
    no: 9,
    name: '(For DC charger) - connector 2(if any)\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C9',
  },
];

const ext_env_check_commisioning = [
  {
    no: 1,
    name: 'Installed outside hazardous zones where flammable/combustible gas or material may be present',
    value: '0',
    titleNo: 'B1',
  },
  {
    no: 2,
    name: 'Enclosure is not dented, damaged, corroded or in any rusty condition',
    value: '0',
    titleNo: 'B2',
  },
  {
    no: 3,
    name: 'Space around the EVCS is adequate for easy access and maintenance work',
    value: '0',
    titleNo: 'B3',
  },
  {
    no: 4,
    name: 'Sealing rubber of all doors are in order, and doors including lockset (if any) can be opened and closed easily',
    value: '0',
    titleNo: 'B4',
  },
  {
    no: 5,
    name: 'No sign of insects inside EVCS. Openings or vents are not blocked, no excess foreign particles',
    value: '0',
    titleNo: 'B5',
  },
  {
    no: 6,
    name: 'Detachable parts are not loose or falling off and not in a rusty condition',
    value: '0',
    titleNo: 'B6',
  },
  {
    no: 7,
    name: 'No visible moisture, waterlogging or burnt marks at the EVCS and connector charging pins',
    value: '0',
    titleNo: 'B7',
  },
  {
    no: 8,
    name: 'Floor or wall mounting of EVCS remains rigid and strong ',
    value: '0',
    titleNo: 'B8',
  },
  {
    no: 9,
    name: 'Vehicle connector cable, mounting and support is secured and not damaged',
    value: '0',
    titleNo: 'B9',
  },
  { no: 10, name: 'Electrical warning labels is clear and prominent', value: '0', titleNo: 'B10' },
  {
    no: 11,
    name: 'Source of DB for EVCS is clearly labeled and electrical source DB is accessible for operation',
    value: '0',
    titleNo: 'B11',
  },
  {
    no: 12,
    name: 'Bollards or continuous kerb(s) or other means of crash protection is installed provide adequete protection against moving vehicle',
    value: '0',
    titleNo: 'B12',
  },
  {
    no: 13,
    name: 'Charger specification plate is clear, legible and prominent according TR25 requirement',
    value: '0',
    titleNo: 'B13',
  },
  {
    no: 14,
    name: 'Owner/operator contact details label is legible and prominent',
    value: '0',
    titleNo: 'B14',
  },
  {
    no: 15,
    name: 'Fail-safe emergency stop button (in red and yellow) is prominent and not damaged. For outdoor installation it shall be weatherproof. Clear directional signs shall be provided for multiple charger installation',
    value: '0',
    titleNo: 'B15',
  },
  { no: 16, name: 'Adequate lighting for charging operation', value: '0', titleNo: 'B16' },
  {
    no: 17,
    name: 'Inspection label provided as below.',
    value: '0',
    note: '2022-12-30',
    titleNo: 'B17',
  },
  {
    no: 18,
    name: 'Evcss display and all UI accessories(eg, press button, RFID reader etc.) are not damaged and working properly',
    value: '0',
    titleNo: 'B18',
  },
  {
    no: 19,
    name: 'Incoming power supply cable including circuit protective conductor connections and termination(s) are properly made and tightened',
    value: '0',
    titleNo: 'B19',
  },
  {
    no: 20,
    name: 'Measurement of PP-PE resistance value and compare with IEC given value',
    value: '0',
    note: '0.1',
    titleNo: 'B20',
  },
  {
    no: 21,
    name: 'Manufacturer handbook and instruction manuals given to owner/operator',
    value: '0',
    titleNo: 'B21',
  },
];

const func_check_test_commisioning = [
  {
    no: 1,
    name: 'Inspect RCCB(s) protecting connecting points shall be at least type A 30mA of symbol and perform manual trip test to all RCCB(s) for Mode 2/2A/2B/3 chargers\nFor mode 4 chargers, earth leakage protection device trip setting should comply with requirements in 411.5.3L of SS 638:2018\nMode 2A and 2B chargers with galvanic isolation shall be at least type AC \n',
    value: '0',
    titleNo: 'C1',
  },
  {
    no: 2,
    name: 'Perform the sequence of normal start and stop on every charging point',
    value: '0',
    titleNo: 'C2',
  },
  {
    no: 3,
    name: 'While charging, check EV ventilation fan (if any) is working',
    value: '0',
    titleNo: 'C3',
  },
  {
    no: 4,
    name: 'While charging, test all the fail-safe emergency stop button(s) to ensure it is functioning.(EVSE shall reset to state A upon releasing)',
    value: '0',
    titleNo: 'C4',
  },
  {
    no: 5,
    name: 'Perform CP short fault simulation for every charging point.(EVSE shall reset to state A when fault is clear)',
    value: '0',
    titleNo: 'C5',
  },
  {
    no: 6,
    name: '(For AC charger) - connector 1\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C6',
  },
  {
    no: 7,
    name: '(For AC charger) - connector 2(if any)\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C7',
  },
  {
    no: 8,
    name: '(For DC charger) - connector 1\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C8',
  },
  {
    no: 9,
    name: '(For DC charger) - connector 2(if any)\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C9',
  },
];


export const Page2: React.FC<{ datas: Inspection | undefined }> = ({ datas }) => {
  const isAnnexB = datas?.form_type === 'Annex_B';
  return (
    <>
      {datas && (
        <div className='page-wrapper'>
          <div className='page-date'>TR 25-1:2022</div>
          <div className='page-content'>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}></th>
                  <th rowSpan={2}>Description</th>
                  <th colSpan={3}>Compliance</th>
                  <th rowSpan={2}>
                    Remarks/
                    <br />
                    measurement value
                  </th>
                </tr>
                <tr>
                  <th>C</th>
                  <th>NC</th>
                  <th>NA</th>
                </tr>
              </thead>
              <tbody>
                {(
                  (isAnnexB ? datas.ext_env_check?.slice(8) : datas.ext_env_check?.slice(11)) || []
                ).map((item) => {
                  const ext_env_check = (isAnnexB ? ext_env_check_commisioning : ext_env_check_maintenance).find((data)=>data.no === item.no);
                  return (
                    <tr key={item.no}>
                      <td>B{item.no}</td>
                      <td>
                        {ext_env_check?.name}
                        {item.no === 17 && (
                          <>
                            <br />
                            <img src='/images/img1.png' alt='' />
                          </>
                        )}
                      </td>
                      <td>{item.value === '0' && <CheckOutlined />}</td>
                      <td>{item.value === '1' && <CheckOutlined />}</td>
                      <td>{item.value === '2' && <CheckOutlined />}</td>
                      <td>
                        {item.name === 'Inspection label provided as below.'
                          ? moment(item.note).format('YYYY-MM-DD')
                          : item.note}
                      </td>
                    </tr>
                  );
                })}
                <tr className='row-full'>
                  <td>C</td>
                  <td colSpan={5}>
                    <strong>
                      Functinnality checks and tests (with EVCS power TURN-ON at isolator)
                    </strong>
                  </td>
                </tr>
                {(
                  (isAnnexB
                    ? datas.func_check_test?.slice(0, 3)
                    : datas.func_check_test?.slice(0, 4)) || []
                ).map((item) => {
                  const func_check_test = (isAnnexB ? func_check_test_commisioning : func_check_test_maintenance).find((data)=>data.no === item.no);
                  return (
                    <tr key={item.no + item.name}>
                      <td>C{item.no}</td>
                      <td>{func_check_test?.name}</td>
                      <td>{item.value === '0' && <CheckOutlined />}</td>
                      <td>{item.value === '1' && <CheckOutlined />}</td>
                      <td>{item.value === '2' && <CheckOutlined />}</td>
                      <td>{item.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination page={2} totalPage={isAnnexB ? 5 : 4} />
          </div>
        </div>
      )}
    </>
  );
};
