import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import TokenService from 'utils/token.service';
import TableData from './TableData';

const PolicyPage: React.FC = () => {
  const isSCAdminSystemAdmin = TokenService.isSCAdminSystemAdmin();
  return (
    <>
      {isSCAdminSystemAdmin && (
        <div className='user-wrapper'>
          <div className='user-content'>
            <div className='user-content-add'>
              <Link to='/Policy/create'>
                <Button type='primary' icon={<PlusOutlined />}>
                  Add Policy
                </Button>
              </Link>
            </div>
            <TableData />
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyPage;
