import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ImageCustom } from 'components/common/ImageCustom';
import {
  inspOtherRegionActions,
  selectInspectionLoad,
} from 'features/inspOtherRegion/inspOtherRegionSlice';
import { Asset, CommissionRegion } from 'models';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getFileName,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const formatDate = (date: string) => {
  return (date ? moment(new Date(date)) : null) || moment(new Date());
};
export const SubmitForm: React.FC<{
  prevStep: any;
  hasChange: boolean;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ prevStep, inspOtherRegion, hasChange }) => {
  const [disabled, setDisabled] = useState(true);
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [engineerSignatureFile, setEngineerSignatureFile] = useState<Asset>({});
  const [customerSignatureFile, setCustomerSignatureFile] = useState<Asset>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loaded = useAppSelector(selectInspectionLoad);
  const disabledForm = TokenService.isDisabled();
  useEffect(() => {
    setDisabled(hasChange);
  }, [hasChange]);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: getFileName(url) || '',
      };
    };
    if (fileUpload) {
      const asset = getImage({ url: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (inspOtherRegion) {
      form.setFieldsValue({
        conclusion_result: inspOtherRegion.conclusion_result,
        conclusion_remark: inspOtherRegion.conclusion_remark,
        engineer_signature: inspOtherRegion.engineer_signature,
        engineer_name: inspOtherRegion.engineer_name,
        customer_signature: inspOtherRegion.engineer_signature,
        customer_name: inspOtherRegion.customer_name,
        conf_date: formatDate(inspOtherRegion.conf_date),
      });
      if (inspOtherRegion?.attachment) {
        setFileUpload(inspOtherRegion?.attachment);
        const setImgList = async (list: any) => {
          Promise.all(list).then((list) => {
            setFileList(list);
          });
        };
        const getImage = async (asset: Asset) => {
          const url = (asset.thumb ? asset.thumb : asset.url) || '';
          const resUrl = (await fetchImage(url)) as { link: string };
          return {
            uid: resUrl?.link || '',
            url: resUrl?.link || '',
            name: getFileName(url) || '',
          };
        };
        setImgList([getImage({ url: inspOtherRegion.attachment })]);
        setFileUpload(inspOtherRegion.attachment);
      }
      setEngineerSignatureFile({ url: inspOtherRegion?.engineer_signature });
      setCustomerSignatureFile({ url: inspOtherRegion?.customer_signature });
    }
  }, [inspOtherRegion, form]);
  const openNotification = useCallback(() => {
    if (loaded) {
      notification.success({
        message: 'Inspection record updated successfully',
        placement: 'bottomRight',
        duration: 2,
        onClose: () => navigate(`/commissioning/otherRegions`),
      });
    }
  }, [loaded, navigate]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const props: UploadProps = {
    name: 'file',
    fileList: fileList,
    customRequest: async ({ onSuccess, onError, file, filename }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `attachment/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async (resp) => {
          setFileUpload(path);
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
    onRemove(file) {
      setFileList([]);
      setFileUpload('');
    },
  };

  const submitForm = () => {
    const formSubmit: CommissionRegion = {
      ...inspOtherRegion,
      ...form.getFieldsValue(),
      attachment: fileUpload,
      installation_recheck: JSON.stringify(inspOtherRegion?.installation_recheck),
      check_before_PO: JSON.stringify(inspOtherRegion?.check_before_PO),
      check_after_PO: JSON.stringify(inspOtherRegion?.check_after_PO),
      charging_function: JSON.stringify(inspOtherRegion?.charging_function),
    };
    dispatch(inspOtherRegionActions.upsertInspection(formSubmit));
  };

  const handleFormChange = () => {
    if (hasFieldEmpty()) setDisabled(true);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setDisabled(hasErrors);
    }
  };
  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue())
      .filter((field) => field !== 'attachment' && field !== 'conclusion_remark')
      .some((field) => {
        return !form.getFieldValue(field)?.toString()?.trim();
      });
  };

  return (
    <div className='form-wrapper'>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={submitForm}
        form={form}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          label='Conclusion'
          key='1'
          initialValue='Pass'
          name={'conclusion_result'}
          className='annexfield-result'
          rules={[{ required: true, message: 'Please input Conclusion!' }]}
        >
          <Radio.Group disabled={disabledForm}>
            <Radio value={'Pass'}>Pass</Radio>
            <Radio value={'Fail'}>Fail</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='&nbsp;' className='annexfield-note' name={'conclusion_remark'}>
          <Input disabled={disabledForm} className='input-remarks' />
        </Form.Item>
        <Form.Item
          label='Commissioning Engineer Signature'
          name='engineer_signature'
          rules={[{ required: true, message: 'Please input Commissioning Engineer Signature!' }]}
        >
          <ImageCustom
            className='input-signature'
            type='link'
            url={engineerSignatureFile.url || ''}
          />
        </Form.Item>
        <Form.Item
          label='Commissioning Engineer Name'
          name='engineer_name'
          rules={[{ required: true, message: 'Please input Commissioning Engineer Name!' }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label='Customer Signature'
          name='customer_signature'
          rules={[{ required: true, message: 'Please input Customer Signature!' }]}
        >
          <ImageCustom
            className='input-signature'
            type='link'
            url={customerSignatureFile.url || ''}
          />
        </Form.Item>
        <Form.Item
          label='Customer Name'
          name='customer_name'
          rules={[{ required: true, message: 'Please input Customer Name!' }]}
        >
          <Input disabled={disabledForm} />
        </Form.Item>
        <Form.Item
          label='Date'
          name='conf_date'
          rules={[{ required: true, message: 'Please input Date!' }]}
        >
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>

        <Form.Item label='Upload Hard Copy' valuePropName='file' name='attachment'>
          <Upload {...props} disabled={disabledForm}>
            <Button icon={<UploadOutlined />} disabled={disabledForm}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      <div className='action-form'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        {!disabledForm && (
          <Button
            type='primary'
            onClick={submitForm}
            htmlType='submit'
            className={`btn-submit-form`}
            disabled={disabled}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
