import { PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { CommissionRegion, ConnectorsResponse } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import ModalConnector from './ModalConnector';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const DetailsEVSE: React.FC<{
  nextStep: any;
  prevStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);
  const isSystemAdmin = TokenService.isSystemAdmin();
  const [isValid, setIsValid] = useState<boolean>(true);

  const disabled = TokenService.isDisabled();
  const chargerModelOption = useAppSelector(selectChargerModels);
  const dispatch = useAppDispatch();
  const [serialNumberData, setSerialNumberData] = useState<DataType[]>([]);
  const listSerialNumbers = useAppSelector(selectSerialNumbers);
  const [connectors, setConnectors] = useState<ConnectorsResponse[]>([]);

  const handleOk = () => {
    return;
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFormChange = () => {
    if (hasFieldEmpty() || connectors.length === 0) setIsValid(false);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setIsValid(!hasErrors);
    }
  };

  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue())
      .filter((item) => item !== '')
      .some((field) => {
        return !form.getFieldValue(field)?.toString()?.trim();
      });
  };

  const handleUpsert = (data: any) => {
    let connectorsData = [...connectors];
    if (idxEdit < 0) {
      connectorsData = [...connectorsData, data];
    } else {
      const tmp = [...connectorsData];
      tmp[idxEdit] = data;
      connectorsData = tmp;
    }
    setConnectors(connectorsData);
  };

  const columns: ColumnsType<ConnectorsResponse> = [
    {
      title: 'Connector ID',
      dataIndex: 'connector_ID',
      key: 'connector_ID',
      render: (_: unknown, record: ConnectorsResponse) => {
        return record.connector_ID || '';
      },
    },
    {
      title: 'Connector Type',
      dataIndex: 'connector_type',
      key: 'connector_type',
      render: (_: unknown, record: ConnectorsResponse) => {
        return record.connector_type || '';
      },
    },
    {
      title: 'Charging cable length (m)',
      dataIndex: 'cableLength',
      key: 'cableLength',
      render: (_: unknown, record: ConnectorsResponse) => {
        return record.cable_length || '';
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: ConnectorsResponse, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isSystemAdmin ? 'cursor' : 'pointer',
                opacity: !isSystemAdmin ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isSystemAdmin);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      serialNumberActions.search({
        filter: { name: '', chargerModelId: '' },
        pagination: { size: 10000 },
      }),
    );
    dispatch(chargerModelActions.fetchChargerModelList({}));
  }, [dispatch]);

  useEffect(() => {
    handleFormChange();
  }, [connectors]);

  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: DataType[] = [];
      const obj = listSerialNumbers;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setSerialNumberData(info1);
    }
  }, [listSerialNumbers]);

  useEffect(() => {
    if (inspOtherRegion) {
      const connectors = [...inspOtherRegion.connectors].sort((left, right) => {
        return moment.utc(left.updatedAt).diff(moment.utc(right.updatedAt));
      });
      setConnectors(connectors || []);
      form.setFieldsValue({
        charger_model: inspOtherRegion.charger_model?.id || '',
        serial_number: inspOtherRegion.serial_number.name || '',
        firmware_version: inspOtherRegion.firmware_version || '',
        backend: inspOtherRegion.backend || '',
      });
    }
  }, [inspOtherRegion, form]);

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  const handleNext = (values: any) => {
    const inspectForm: CommissionRegion = form.getFieldsValue();
    let serial_number = {
      name: form.getFieldValue('serial_number'),
    };
    const serialNumberSearch = listSerialNumbers.find(
      (serialNumber) =>
        serialNumber.id === form.getFieldValue('charger_model') ||
        serialNumber.name === form.getFieldValue('charger_model'),
    );

    if (serialNumberSearch) {
      serial_number = serialNumberSearch;
    }
    const charger_model = chargerModelOption.find(
      (chargerModel) => chargerModel.id === form.getFieldValue('charger_model'),
    );
    const updateObj = {
      ...inspOtherRegion,
      ...inspectForm,
      connectors,
      serial_number,
      charger_model,
    };
    nextStep({ ...updateObj });
  };

  return (
    <Form
      name='form-other-region'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={handleNext}
      autoComplete='off'
      form={form}
      onFieldsChange={handleFormChange}
    >
      <Form.Item
        label='Charger Model'
        name='charger_model'
        rules={[{ required: true, message: 'Please input Charger Model!' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Charger Model'
          allowClear
          disabled={disabled}
        >
          {chargerModelOption.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Serial Number'
        name='serial_number'
        rules={[{ required: true, message: 'Please input Serial Number!' }]}
      >
        <AutoComplete
          disabled={disabled}
          options={serialNumberData}
          filterOption={(inputValue, option) =>
            option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
          }
        />
      </Form.Item>
      <Form.Item
        label='Connectors'
        name=''
        rules={[{ required: true, message: 'Please input Connectors!' }]}
      >
        <div className='form-wrapper'>
          <Table dataSource={connectors} columns={columns} pagination={false} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
            <div
              className={`ant-form-item-explain-error hidden-visible ${
                connectors.length !== 0 ? 'hidden-visible' : ''
              }`}
            >
              Please add at least one Connector!
            </div>
            <Button type='primary' icon={<PlusOutlined />} onClick={handleAdd} disabled={disabled}>
              Add Connectors
            </Button>
          </div>
          <ModalConnector
            data={connectors[idxEdit]}
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            setIsModalVisible={setIsModalVisible}
            onFinish={handleUpsert}
          />
        </div>
      </Form.Item>
      <Form.Item
        label='Firmware Version'
        name='firmware_version'
        rules={[{ required: true, message: 'Please input Firmware Version!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Backend'
        name='backend'
        rules={[{ required: true, message: 'Please input Backend!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item className='ant-form-item-submit action-form'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          onClick={handleNext}
          className={`btn-submit-form`}
          disabled={!isValid}
        >
          Next step
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DetailsEVSE;
