/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { Policy } from '../../../models/policy';

export interface PolicyState {
  list: Policy[];
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: PolicyState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    fetchPolicyList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    findForPublic(state) {},
    fetchPolicyListSuccess(state, action: PayloadAction<ListResponse<Policy>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data;
    },
    createPolicy(state, action: PayloadAction<Policy>) {
      state.loading = true;
      state.loaded = false;
    },
    createPolicySuccess(state, action: PayloadAction<Policy>) {
      state.loading = false;
      state.loaded = true;
    },
    updatePolicy(state, action: PayloadAction<Policy>) {
      state.loading = true;
      state.loaded = false;
    },
    updatePolicySuccess(state, action: PayloadAction<Policy>) {
      state.loading = false;
      state.loaded = true;
    },
    toggleStatusPolicy(state, action: PayloadAction<Policy>) {},
    fetchPolicyDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    search(state, action: PayloadAction<{ name: string }>) {
      state.filter = action.payload;
    },
    fetchPolicySuccess(state, action: PayloadAction<Policy>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deletePolicy(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deletePolicySuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
  },
});

// Actions
export const policyActions = policySlice.actions;

// selector
export const selectInspectionLoad = (state: RootState) => state.policy.loaded;
export const selectPagination = (state: RootState) => state.policy.pagination;
export const selectName = (state: RootState) => state.policy.filter.name;
export const selectDeleting = (state: RootState) => state.policy.deleting;
export const selectPolicyLoading = (state: RootState) => state.policy.loading;

// Reducer
const policyReducer = policySlice.reducer;
export default policyReducer;
