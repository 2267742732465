import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SiteAppointment as SiteAppointmentModel, Ticket } from 'models';
import React, { useEffect, useState } from 'react';
import { formatYYYYMMDD } from 'utils/function';

export const SiteAppointment: React.FC<{
  ticket: Ticket | undefined;
}> = ({ ticket }) => {
  const [appointmentData, setAppointmentData] = useState<SiteAppointmentModel[]>([]);
  useEffect(() => {
    const siteAppointment = ticket?.site_appointment;
    if (siteAppointment) {
      setAppointmentData(siteAppointment);
    }
  }, [ticket?.site_attendance]);

  const columnsAttendance: ColumnsType<SiteAppointmentModel> = [
    {
      title: 'Date',
      dataIndex: 'appointment_date',
      key: 'appointment_date',
      render: (_: unknown, record: SiteAppointmentModel) => {
        return formatYYYYMMDD(record.appointment_date || '');
      },
    },
    {
      title: 'Time',
      dataIndex: 'appointment_time',
      key: 'appointment_time',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: 'Username',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
  ];

  return (
    <div className='form-wrapper'>
      <Table
        pagination={false}
        dataSource={appointmentData}
        columns={columnsAttendance}
        scroll={{ x: 800 }}
      />
    </div>
  );
};
