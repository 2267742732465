import { notification } from 'antd';
import authApi from 'api/authApi';
import userApi from 'api/userApi';
import { ListResponse, User, UserResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import TokenService from 'utils/token.service';
import { authActions, LoginPayload } from './authSlice';

type LoginParam = { payload: LoginPayload; type: string };
function* handleLogin(param: LoginParam) {
  try {
    const response: UserResponse = yield call(userApi.login, {
      username: param.payload.username,
      password: param.payload.password,
    });
    TokenService.setUser(response);
    yield put(authActions.loginSuccess({ ...response }));
    // TODO: change after
  } catch (error: any) {
    if (
      error?.response?.data?.message ===
      'Your account is not activated yet, please inform the Administrator to activate it.'
    ) {
      notification.error({
        message: error.response.data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
    } else {
      yield put(authActions.loginFailed()); // Dispatch action
    }
  }
}

function* logout() {
  try {
    yield call(userApi.logout);
  } catch (error) {
    // yield put(authActions.loginFailed()); // Dispatch action
  }
}

function* fetchTraineeList() {
  try {
    const response: ListResponse<User> = yield call(authApi.getAllTraineeList, {});
    yield put(authActions.fetchTraineeListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch trainee list', error);
    yield put(authActions.fetchTraineeListFailed());
  }
}

export function* authSaga() {
  yield takeLatest(authActions.login.type, handleLogin);
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.fetchTraineeList.type, fetchTraineeList);
}
