import { Steps } from 'antd';
import { useAppSelector } from 'app/hooks';
import { Inspection } from 'models';
import React, { useEffect, useState } from 'react';
import BasicInfo from './basicInfo';
import { Checkfield } from './checkField';
import { ElectricalCheck } from './ElectricalCheck';
import { SubmitForm } from './submitForm';

export const StepForm: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [current, setCurrent] = useState(0);
  const [hasChange, setHasChange] = useState(false);
  const [data, setData] = useState<Inspection>();
  const inspection = useAppSelector(
    (state) => state.inspection.list.filter((item: Inspection) => item.id === id)[0],
  );

  useEffect(() => {
    if (data) {
      setHasChange(JSON.stringify(data) === JSON.stringify(inspection));
    }
  }, [data, inspection]);

  useEffect(() => {
    if (inspection) {
      setData(inspection);
    }
  }, [inspection]);

  const next = (datas: Inspection) => {
    setCurrent(current + 1);
    setData({ ...datas });
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps =
    inspection?.form_type === 'Annex_B'
      ? [
          {
            title: 'Choose form',
            content: <BasicInfo nextStep={next} inspection={data} />,
          },
          {
            title: 'Step 2',
            content: <Checkfield nextStep={next} prevStep={prev} inspection={data} isMaintenance={false} />,
          },
          {
            title: 'Step 3',
            content: <ElectricalCheck nextStep={next} prevStep={prev} inspection={data} />,
          },
          {
            title: 'Step 4',
            content: <SubmitForm hasChange={hasChange} prevStep={prev} inspection={data} />,
          },
        ]
      : [
          {
            title: 'Choose form',
            content: <BasicInfo nextStep={next} inspection={data} />,
          },
          {
            title: 'Step 2',
            content: <Checkfield nextStep={next} prevStep={prev} inspection={data} isMaintenance={true} />,
          },
          {
            title: 'Step 3',
            content: <SubmitForm hasChange={hasChange} prevStep={prev} inspection={data} />,
          },
        ];
  return (
    <div className='step-form'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
};
