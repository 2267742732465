import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Info } from './OverviewPage';

export const NumberRun: React.FC<{
  item: Info;
  numInc: number;
  index: number;
  borderColor: string;
}> = ({ item, numInc, index, borderColor }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    let intervalId: any = null;
    if (value < +item.value && numInc >= 1)
      intervalId = setInterval(() => {
        setValue(value + numInc <= +item.value ? value + numInc : +item.value);
      }, 200);
    else setValue(+item.value);
    return () => clearInterval(intervalId);
  }, [value, item, numInc]);
  return (
    <>
      <Col key={index} span={6}>
        <div
          style={{ borderColor: `${borderColor}` }}
          className={`camis-card overview-top-card mgb-30 hover-effect`}
        >
          <label>{item.title}</label>
          <div>{value}</div>
        </div>
      </Col>
    </>
  );
};
