/* eslint-disable react/no-unescaped-entities */
import { CheckOutlined } from '@ant-design/icons';
import { Inspection } from 'models';
import React from 'react';
import { Pagination } from './pagination';


const func_check_test_maintenance = [
  {
    no: 1,
    name: 'Inspect RCCB(s) protecting connecting points shall be at least type A 30mA of symbol and perform manual trip test to all RCCB(s) for Mode 2/2A/2B/3 chargers\nFor mode 4 chargers, earth leakage protection device trip setting should comply with requirements in 411.5.3L of SS 638:2018\nMode 2A and 2B chargers with galvanic isolation shall be at least type AC \n',
    value: '0',
    titleNo: 'C1',
  },
  {
    no: 2,
    name: 'Perform the sequence of normal start and stop on every charging point',
    value: '0',
    titleNo: 'C2',
  },
  {
    no: 3,
    name: 'While charging, check EV ventilation fan (if any) is working',
    value: '0',
    titleNo: 'C3',
  },
  {
    no: 4,
    name: 'While charging, test all the fail-safe emergency stop button(s) to ensure it is functioning.(EVSE shall reset to state A upon releasing)',
    value: '0',
    titleNo: 'C4',
  },
  {
    no: 5,
    name: 'Perform CP short fault simulation for every charging point.(EVSE shall reset to state A when fault is clear)',
    value: '0',
    titleNo: 'C5',
  },
  {
    no: 6,
    name: '(For AC charger) - connector 1\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '2',
    titleNo: 'C6',
  },
  {
    no: 7,
    name: '(For AC charger) - connector 2(if any)\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '2',
    titleNo: 'C7',
  },
  {
    no: 8,
    name: '(For DC charger) - connector 1\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C8',
  },
  {
    no: 9,
    name: '(For DC charger) - connector 2(if any)\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C9',
  },
];

const func_check_test_commisioning = [
  {
    no: 1,
    name: 'Inspect RCCB(s) protecting connecting points shall be at least type A 30mA of symbol and perform manual trip test to all RCCB(s) for Mode 2/2A/2B/3 chargers\nFor mode 4 chargers, earth leakage protection device trip setting should comply with requirements in 411.5.3L of SS 638:2018\nMode 2A and 2B chargers with galvanic isolation shall be at least type AC \n',
    value: '0',
    titleNo: 'C1',
  },
  {
    no: 2,
    name: 'Perform the sequence of normal start and stop on every charging point',
    value: '0',
    titleNo: 'C2',
  },
  {
    no: 3,
    name: 'While charging, check EV ventilation fan (if any) is working',
    value: '0',
    titleNo: 'C3',
  },
  {
    no: 4,
    name: 'While charging, test all the fail-safe emergency stop button(s) to ensure it is functioning.(EVSE shall reset to state A upon releasing)',
    value: '0',
    titleNo: 'C4',
  },
  {
    no: 5,
    name: 'Perform CP short fault simulation for every charging point.(EVSE shall reset to state A when fault is clear)',
    value: '0',
    titleNo: 'C5',
  },
  {
    no: 6,
    name: '(For AC charger) - connector 1\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C6',
  },
  {
    no: 7,
    name: '(For AC charger) - connector 2(if any)\nPerform functionality test for mode 2/2A/2B/3 charging point EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C7',
  },
  {
    no: 8,
    name: '(For DC charger) - connector 1\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C8',
  },
  {
    no: 9,
    name: '(For DC charger) - connector 2(if any)\nPerform functionality test for mode 4 charging point (IEC 61851-23) EVCS is able to complete energisation and de-energisation sequence',
    value: '0',
    titleNo: 'C9',
  },
];

const electrical_check_test = [
  {
    no: 1,
    name: 'Perform earth loop impedance test and record value complies with SS 638 (For TT and TNS system)',
    value: '0',
    note: '0.01',
    titleNo: 'E1',
  },
  {
    no: 2,
    name: 'Record the incoming power supply and charging cable insulation resistance value is more than 1 M-Ohm',
    value: '0',
    titleNo: 'E2',
  },
  {
    no: 3,
    name: 'Tripping time of 30mA RCCB type A (min) as measured by an RCCB tester using AC and DC injection curve is acceptable according to SS 97 tripping time for mode 2 and mode 3 chargers \nFor mode 4 charger, the trip setting should comply with 411.5.3L of SS 638:2018 \n',
    value: '0',
    note: '0',
    titleNo: 'E3',
  },
  {
    no: 4,
    name: 'EVCS installation tally with single line diagram (SLD) provided by owner/operator.\nMeans of isolation, lockable at OFF position (2P for 1ph and 3P/4P for 3ph), is provided for maintenance switching \n',
    value: '0',
    titleNo: 'E4',
  },
  {
    no: 5,
    name: 'Rating of incoming cable from source DB and charging cable are adequate for max rated charging current. ',
    value: '0',
    titleNo: 'E5',
  },
];

export const Page3: React.FC<{ datas: Inspection | undefined }> = ({ datas }) => {
  const isAnnexB = datas?.form_type === 'Annex_B';
  const next_inspection_date = datas?.next_inspection_date
    ? new Date(datas?.next_inspection_date || '').toISOString().split('T')[0]
    : '';
  return (
    <>
      {datas && (
        <div className='page-wrapper'>
          <div className='page-date'>TR 25-1:2022</div>
          <div className='page-content'>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}></th>
                  <th rowSpan={2}>Description</th>
                  <th colSpan={3}>Compliance</th>
                  <th rowSpan={2}>
                    Remarks/
                    <br />
                    measurement value
                  </th>
                </tr>
                <tr>
                  <th>C</th>
                  <th>NC</th>
                  <th>NA</th>
                </tr>
              </thead>
              <tbody>
                {(
                  (isAnnexB ? datas.func_check_test?.slice(3) : datas.func_check_test?.slice(4)) ||
                  []
                ).map((item) => {
                  const func_check_test = (isAnnexB ? func_check_test_commisioning : func_check_test_maintenance).find((data)=>data.no === item.no);
                  return (
                    <tr key={item.no}>
                      <td>{`C${item.no}`}</td>
                      <td>{func_check_test?.name}</td>
                      <td>{item.value === '0' && <CheckOutlined />}</td>
                      <td>{item.value === '1' && <CheckOutlined />}</td>
                      <td>{item.value === '2' && <CheckOutlined />}</td>
                      <td>{item.note}</td>
                    </tr>
                  );
                })}
                <tr className='row-full'>
                  <td>D</td>
                  <td colSpan={5}>
                    <strong>
                      Additional item not listed but required in the manufacture`s instructions
                    </strong>
                    <em>(Please list accordingly or indicate as NA where appropriate)</em>
                  </td>
                </tr>
                <tr>
                  <td>D1</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>D2</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>D3</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {isAnnexB && (
              <>
                <h4 className='text-underline'>To be performed by skilled person(LEW)</h4>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2}></th>
                      <th rowSpan={2}>Description</th>
                      <th colSpan={3}>Compliance</th>
                      <th rowSpan={2}>
                        Remarks/
                        <br />
                        measurement value
                      </th>
                    </tr>
                    <tr>
                      <th>C</th>
                      <th>NC</th>
                      <th>NA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='row-full'>
                      <td>E</td>
                      <td colSpan={5}>
                        <strong>Electrical checks and tests</strong>
                      </td>
                    </tr>
                    {datas.electrical_check_test?.slice(0, 2).map((item) => {
                      const electrical_data = electrical_check_test.find((data)=>data.no === item.no);
                      return (
                        <tr key={item.no}>
                          <td>{`E${item.no}`}</td>
                          <td>{electrical_data?.name}</td>
                          <td>{item.value === '0' && <CheckOutlined />}</td>
                          <td>{item.value === '1' && <CheckOutlined />}</td>
                          <td>{item.value === '2' && <CheckOutlined />}</td>
                          <td>{item.note}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
            {!isAnnexB && (
              <>
                <p>1) Measured values where required shall be recorded in this report</p>
                <p>
                  2) EV charging station installed in petrol kiosks shall comply with ECDF's
                  requirements.
                </p>
                <p className='mb-0'>
                  <strong>General remarks:</strong>
                </p>
                <p className='text-full-row'>{/* {datas.address} */}</p>
                <div className='text-input'>
                  <strong className='text-input-title'>Recommended date of next inspection:</strong>
                  <p className='text-input__value'>{next_inspection_date}</p>
                </div>
                <div className='text-note'>
                  <p>(Extract from TR 25-1:2022)</p>
                  <p>Non-restricted access EVCS - every 6 months (required)</p>
                </div>
                <div className='text-note'>
                  <p></p>
                  <p>Restricted access EVCS - every 24 months (recommended)</p>
                </div>
                <h4>Endorsed by equipment specialist</h4>
                <p>
                  I have inspected and tested the EVCS. To the best of my knowledge, I declare that
                  EVCS is <br /> *fit and safe / unfit and unsafe for operation
                </p>
                <p>*Please delete as appropriate</p>
              </>
            )}
            <Pagination page={3} totalPage={isAnnexB ? 5 : 4} />
          </div>
        </div>
      )}
    </>
  );
};
