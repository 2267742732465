import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { selectCompanies } from 'features/settings/company/companySlice';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { CommissionRegion, Inspection } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SCIMMS_API } from 'utils/constants';
import { exportPdf } from 'utils/function';
import TokenService from 'utils/token.service';
import { JSZipUtils } from '../../../utils/jszip-utils';
import {
  inspOtherRegionActions,
  selectDeleting,
  selectInspectionLoading,
  selectPagination,
} from '../inspOtherRegionSlice';
import { Attachment } from './Attachment';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Inspection;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const companies = useAppSelector(selectCompanies);
  const isSystemAdmin = TokenService.isSystemAdmin();
  const loading = useAppSelector(selectInspectionLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [disabledDownload, setDisabledDownload] = useState<{ [key: string]: boolean }>({});
  const data: CommissionRegion[] = useAppSelector((state) => {
    const isExistedVideos: { [key: string]: boolean } = {};
    const result = state.inspOtherRegion.list.map((item) => {
      if (
        item.conclusion?.some((conclusion) =>
          conclusion.session_list?.some((session) =>
            session.upload?.videos?.some((video) => video.url),
          ),
        )
      ) {
        isExistedVideos[item.id] = false;
      } else {
        isExistedVideos[item.id] = true;
      }
      return { key: item.id, ...item };
    });
    if (JSON.stringify(isExistedVideos) !== JSON.stringify(disabledDownload)) {
      setDisabledDownload(isExistedVideos);
    }
    return result;
  });

  useEffect(() => {
    dispatch(inspOtherRegionActions.changePage({ pageNo: 1 }));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Other Regions record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    } else {
      setIsModalVisible(false);
    }
  }, [deleting, openNotification]);

  // useEffect(() => {
  //   if (!deleting) {
  //     setIsModalVisible(false);
  //   }
  // }, [deleting]);

  const handleDeleteInspection = () => {
    setIsModalVisible(true);
  };

  const handleExportPdf = async (id: string) => {
    exportPdf(`/commission-region/export-pdf?id=${id}`, 'commissioning-report.pdf');
  };

  const handleDownloadVideos = (id: string) => {
    const videos: { url: string; name: string }[] = [];
    const dataById: CommissionRegion | undefined = data.find((item) => item.id === id);
    if (dataById?.conclusion) {
      dataById?.conclusion.forEach((item) => {
        const chargingNumber: number = +item?.name?.replace('charging test ', '') || 0;
        item?.session_list?.forEach((session, sessionIndex) => {
          session?.upload?.videos?.forEach((video, videoIndex) => {
            if (video.url) {
              const lastIndex = video.url.lastIndexOf('.');
              const filenameExtens = video.url.substring(lastIndex);
              videos.push({
                url: video.url,
                name: `Video_CT${chargingNumber}_CS${+sessionIndex + 1}_${
                  +videoIndex + 1
                }${filenameExtens}`,
              });
            }
          });
        });
      });
    }
    videos.length ? zipVideos(videos) : '';
  };

  const zipVideos = async (videos: { url: string; name: string }[]) => {
    const zip = new JSZip();
    for (const video of videos) {
      await fetchVideo(video.url).then((res: any) => {
        zip.file(video.name, urlToPromise(res?.link || ''), { binary: true });
      });
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'videos.zip');
    });
  };

  const urlToPromise = (url: string): Promise<any> => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  function fetchVideo(url: string): Promise<string> {
    return new Promise((resolve) => {
      const jwt = TokenService.getLocalAccessToken();

      // Make a headers object that we can add to the request
      const headers = new Headers({
        authorization: 'Bearer ' + jwt,
      });
      const lastIndex = url.lastIndexOf('/');
      const folder = url.substring(0, lastIndex);
      const filename = url.substring(lastIndex + 1);
      const urlFetch = `${SCIMMS_API}/upload/signedurl-view?folder=${folder}&filename=${filename}`;
      // Make the request and wait for the response
      window.fetch(urlFetch, { headers }).then((response) => {
        const res = response.json();
        return resolve(res);
      });
    });
  }

  const columns: ColumnsType<CommissionRegion> = [
    {
      title: 'No',
      width: 80,
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
      fixed: 'left',
    },
    {
      title: 'Commissioning Date',
      dataIndex: 'commissioning_date',
      render: (_: unknown, record: CommissionRegion) => {
        return format(new Date(record.commissioning_date), 'yyyy-MM-dd');
      },
      width: 150,
    },
    {
      title: 'Commissioning Engineer Name',
      dataIndex: 'engineer_name',
      width: 200,
      render: (_: unknown, record: CommissionRegion) => {
        return record.engineer_name || '';
      },
    },
    {
      title: 'Commissioning Engineer Company',
      dataIndex: 'company_id',
      width: 250,
      render: (_: unknown, record: CommissionRegion) => {
        return record?.company_id
          ? companies.find((company) => company.id === record?.company_id)?.name
          : record?.company_id;
      },
    },
    {
      title: 'Charger Owner',
      dataIndex: 'charger_owner',
      width: 200,
      render: (_: unknown, record: CommissionRegion) => {
        return record?.charger_owner?.name || '';
      },
    },
    {
      title: 'Country',
      dataIndex: 'serial_number',
      width: 150,
      render: (_: unknown, record: CommissionRegion) => {
        return record?.country?.name || '';
      },
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      width: 200,
      render: (_: unknown, record: CommissionRegion) => {
        return record.site_name || '';
      },
    },
    {
      title: 'Detailed Address',
      dataIndex: 'detailed_address',
      width: 250,
    },
    {
      title: 'Charger Model',
      dataIndex: 'charger_model',
      width: 250,
      render: (_: unknown, record: CommissionRegion) => {
        return record?.charger_model?.name || '';
      },
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 250,
      render: (_: unknown, record: CommissionRegion) => {
        return record?.serial_number?.name || '';
      },
    },
    {
      title: 'Upload Hard Copy',
      dataIndex: 'attachment',
      width: 300,
      render: (_: unknown, record: CommissionRegion) => {
        return record.attachment ? <Attachment attachment={record.attachment} /> : '';
      },
    },
    {
      title: 'Action',
      width: 150,
      fixed: 'right',
      render: (_: unknown, record: CommissionRegion) => {
        return (
          <>
            <Space size='middle'>
              {isSystemAdmin ? (
                <>
                  <Link to={`/commissioning/otherRegions/edit/${record.id}`}>Edit</Link> |
                </>
              ) : (
                <>
                  <Link to={`/commissioning/otherRegions/edit/${record.id}`}>View</Link> |
                </>
              )}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      disabled={disabledDownload[record.id]}
                      onClick={() => handleDownloadVideos(record.id)}
                    >
                      <span
                        style={{
                          color: disabledDownload[record.id] ? 'rgba(0, 0, 0, 0.25)' : '#e98d3e',
                          cursor: disabledDownload[record.id] ? 'not-allowed' : 'pointer',
                        }}
                      >
                        Download videos
                      </span>
                    </Menu.Item>
                    <Menu.Item>
                      <span
                        onClick={() => handleExportPdf(record.id)}
                        style={{ color: '#e98d3e', cursor: 'pointer' }}
                      >
                        Export pdf
                      </span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <a>
                  More <DownOutlined />
                </a>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number) => {
    dispatch(inspOtherRegionActions.changePage({ pageNo: evt }));
  };

  const handleOk = () => {
    dispatch(inspOtherRegionActions.deleteInspection({ ids: selectedRowKeys as string[] }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Form form={form} component={false}>
      {isSystemAdmin && (
        <Row gutter={16} justify='end' className='btn-group-export'>
          <Col className='gutter-row' span={4} style={{ textAlign: 'right' }}>
            <Button
              disabled={selectedRowKeys.length === 0}
              onClick={handleDeleteInspection}
              type='primary'
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Col>
        </Row>
      )}
      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        rowSelection={isSystemAdmin ? rowSelection : undefined}
        pagination={{
          total: pagination.totalRecords,
          pageSize: pagination.size,
          onChange: handleChangePage,
        }}
        scroll={{ x: 2330 }}
      />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete Other Region?</p>
      </Modal>
    </Form>
  );
};

export default TableData;
