import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import SearchCustom from 'components/common/Search';
import React, { useState } from 'react';
import TokenService from 'utils/token.service';
import { problemCategoryActions, selectPagination } from '../problemCategorySlice';
import ModalForm from './ModalForm';
import TableData from './TableData';

const ProblemCategoryPage: React.FC = () => {
  const isSCAdminSystemAdmin = TokenService.isSCAdminSystemAdmin();
  const pagination = useAppSelector(selectPagination);

  const dispatch = useAppDispatch();
  const onSearch = (name: string) => {
    dispatch(problemCategoryActions.changePage({ pageNo: 1, size: pagination.size }));
    dispatch(problemCategoryActions.search({ name }));
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomState, setRandomState] = useState('');
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const [idEdit, setIdEdit] = useState('');

  return (
    <>
      {isSCAdminSystemAdmin && (
        <div className='user-wrapper'>
          <div className='user-head'>
            <div className='user-head-search'>
              <SearchCustom handleSearch={onSearch} />
            </div>
          </div>
          <div className='user-content'>
            <div className='user-content-add'>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsModalVisible(true);
                  setIdEdit('');
                }}
              >
                Add Problem Category
              </Button>
            </div>
            <TableData
              setIsModalVisible={setIsModalVisible}
              setIdEdit={setIdEdit}
              randomState={randomState}
            />
          </div>
          <ModalForm
            id={idEdit}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            setRandomState={setRandomState}
          />
        </div>
      )}
    </>
  );
};

export default ProblemCategoryPage;
