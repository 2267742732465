import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Inspection } from 'models';
import React, { useEffect } from 'react';
import TokenService from 'utils/token.service';
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};
interface ICheck {
  [key: string]: string;
}

const electrical_check_test = [
  {
    no: 1,
    name: 'Perform earth loop impedance test and record value complies with SS 638 (For TT and TNS system)',
    value: '0',
    note: '0.01',
    titleNo: 'E1',
  },
  {
    no: 2,
    name: 'Record the incoming power supply and charging cable insulation resistance value is more than 1 M-Ohm',
    value: '0',
    titleNo: 'E2',
  },
  {
    no: 3,
    name: 'Tripping time of 30mA RCCB type A (min) as measured by an RCCB tester using AC and DC injection curve is acceptable according to SS 97 tripping time for mode 2 and mode 3 chargers \nFor mode 4 charger, the trip setting should comply with 411.5.3L of SS 638:2018 \n',
    value: '0',
    note: '0',
    titleNo: 'E3',
  },
  {
    no: 4,
    name: 'EVCS installation tally with single line diagram (SLD) provided by owner/operator.\nMeans of isolation, lockable at OFF position (2P for 1ph and 3P/4P for 3ph), is provided for maintenance switching \n',
    value: '0',
    titleNo: 'E4',
  },
  {
    no: 5,
    name: 'Rating of incoming cable from source DB and charging cable are adequate for max rated charging current. ',
    value: '0',
    titleNo: 'E5',
  },
];

export const ElectricalCheck: React.FC<{
  nextStep: any;
  prevStep: any;
  inspection: Inspection | undefined;
}> = ({ nextStep, prevStep, inspection }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();
  useEffect(() => {
    if (inspection) {
      const electrical_check_test = inspection.electrical_check_test?.map((item) => {
        return {
          [`electrical_check_test_${item.no}`]: item.value,
          [`electrical_check_test_note_${item.no}`]: item.note,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(electrical_check_test),
      };
      form.setFieldsValue(initForm);
    }
  }, [inspection, form]);

  const handleNext = (values: any) => {
    const inspectForm = form.getFieldsValue();
    const electrical_check_test = [...(inspection?.electrical_check_test || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['electrical_check_test_' + item.no],
        note: inspectForm['electrical_check_test_note_' + item.no],
      };
    });

    nextStep({ ...inspection, electrical_check_test });
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' form={form} onFinish={handleNext}>
        {(inspection?.electrical_check_test || []).map((item, idx) => {
          const electrical_data = electrical_check_test.find((data) => data.no === item.no);
          return (
            <React.Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={
                  electrical_data?.titleNo
                    ? electrical_data?.titleNo + '. ' + electrical_data?.name
                    : electrical_data?.name
                }
                key={`${item.no}_${item.value}`}
                initialValue={item.value}
                name={'electrical_check_test_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.name}
                key={item.no}
                initialValue={item.note}
                name={'electrical_check_test_note_' + item.no}
              >
                <Input
                  disabled={disabled}
                  className='input-remarks'
                  placeholder='Remarks/measurement value'
                />
              </Form.Item>
            </React.Fragment>
          );
        })}

        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
