import { format } from 'date-fns';
import {
  CommissionRegion,
  Inspection,
  ITicketTrendingDay,
  MaintenanceRegion,
  SelectOption,
} from 'models';
import moment from 'moment';
import { Buffer } from 'buffer';
import { SCIMMS_API } from './constants';
import TokenService from './token.service';
import axios from 'axios';
import { SerialNumberList } from 'models/serialNumber';
import { utcToZonedTime } from 'date-fns-tz';

export const sortDateByString = (inspectionA: Inspection, inspectionB: Inspection) => {
  return new Date(inspectionB.updatedAt).getTime() - new Date(inspectionA.updatedAt).getTime();
};

export const sortDateByStringOtherRegion = (
  inspectionA: CommissionRegion,
  inspectionB: CommissionRegion,
) => {
  return new Date(inspectionB.updatedAt).getTime() - new Date(inspectionA.updatedAt).getTime();
};

export const sortDateByStringMaintenanceOtherRegion = (
  inspectionA: MaintenanceRegion,
  inspectionB: MaintenanceRegion,
) => {
  return new Date(inspectionB.updatedAt).getTime() - new Date(inspectionA.updatedAt).getTime();
};

export const fetchFileByUrl = (url: string) => {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
    });

    // Make the request and wait for the response
    window.fetch(url, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
      // return response;
    });
    // .then((response) => response.blob())
    // .then((blob) => blobToBase64(blob))
    // .then((base64) => resolve(base64));
  });
};

export const exString = (string: any, numb = 10, words = false) => {
  if (string?.toString().length > 0) {
    if (words) {
      const wordArray = string.trim().split(' ', numb);
      return wordArray[0] + '...';
    } else {
      const word = string.trim().substr(0, numb);
      let plus = '';
      if (string.length > numb) {
        plus = '...';
      }
      return word + plus;
    }
  }
};

export const chargerModelOption = [
  { title: 'Artemis 7', value: 'Artemis 7', icon: 'aurora-series.png' },
  { title: 'Artemis 11', value: 'Artemis 11', icon: 'aurora-series.png' },
  { title: 'Aurora 7', value: 'Aurora 7', icon: 'aurora-series.png' },
  { title: 'Aurora 11', value: 'Aurora 11', icon: 'aurora-gb.png' },
  { title: 'Aurora 22', value: 'Aurora 22', icon: 'aurora-series.png' },
  { title: 'Saturn 22', value: 'Saturn 22', icon: 'aurora-series.png' },
  { title: 'Venus 30', value: 'Venus 30', icon: 'venus-30.png' },
  { title: 'Athena 60', value: 'Athena 60', icon: 'aurora-series.png' },
  { title: 'Jupiter 60', value: 'Jupiter 60', icon: 'jupiter-60.png' },
  { title: 'Jupiter 60 V2', value: 'Jupiter 60 V2', icon: 'jupiter-60-v2.png' },
  {
    title: 'Jupiter 60 V2.5',
    value: 'Jupiter 60 V2.5',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 120 V2',
    value: 'Titan Premium 120 V2',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 150 V2',
    value: 'Titan Premium 150 V2',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 180 V2',
    value: 'Titan Premium 180 V2',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 120 V3',
    value: 'Titan Premium 120 V3',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 150 V3',
    value: 'Titan Premium 150 V3',
    icon: 'jupiter-60-v2.png',
  },
  {
    title: 'Titan Premium 180 V3',
    value: 'Titan Premium 180 V3',
    icon: 'jupiter-60-v2.png',
  },
  { title: 'Nova', value: 'Nova', icon: 'nova-360-terminal d.png' },
  { title: 'Neptune', value: 'Neptune', icon: 'aurora-series.png' },
];

export const durationByHours = (start: string, end: string | undefined) => {
  if (!end) return '';
  const duration = moment.duration(moment(end).diff(moment(start)));
  return duration.asHours().toFixed(1);
};

export const formatYYYYMMDD = (date: string) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
};

export const formatDatetime = (date: string) => {
  return date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '';
};

export const getUTC8DateTimeFormat = (date: Date | string | number) => {
  return `${format(utcToZonedTime(date, 'Asia/Singapore'), 'yyyy-MM-dd HH:mm:ss')} UTC+08:00`;
};

export function fetchImage(url: string) {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
    });
    const lastIndex = url.lastIndexOf('/');
    const folder = url.substring(0, lastIndex);
    const filename = url.substring(lastIndex + 1);
    const urlFetch = `${SCIMMS_API}/upload/signedurl-view?folder=${folder}&filename=${filename}`;
    // Make the request and wait for the response
    window.fetch(urlFetch, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
    });
  });
}

export function fetchImageForUpload(url: string) {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
    });
    const lastIndex = url.lastIndexOf('/');
    const folder = url.substring(0, lastIndex);
    const filename = url.substring(lastIndex + 1);
    const urlFetch = `${SCIMMS_API}/upload/signedurl?folder=${folder}&filename=${filename}`;
    // Make the request and wait for the response
    window.fetch(urlFetch, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
    });
  });
}

export const generateFileName = (filename: string) => {
  return TokenService.getUser().username + '_' + Date.now() + '_' + filename;
};

export const makeFileUpload = (file: string) => {
  return {
    uid: file || '',
    url: file || '',
    name: file || '',
  };
};

export async function getThumbnailForVideo(videoUrl: string) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener('seeked', () => resolve(''));
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas?.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL('image/jpeg');
  return imageUrl;
}
export const uploadMedia = (url: string, formData: any) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: formData,
  });
};

export const removeExtension = (filename: string) => {
  return filename.split('.').slice(0, -1).join('.');
};
const atob = (data: string) => Buffer.from(data, 'base64').toString('ascii');
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getFileName = (path: string) => {
  const lastIndex = path.lastIndexOf('/');
  return path.substring(lastIndex + 1);
};

// user
export const isMine = (username: string | null) => {
  return username === TokenService.getUser().username;
};

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const match = arr[0].match(/:(.*?);/);
  if (match !== null) {
    const mime = match[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  return null;
}

export const blobToFile = (blob: Blob, fileName: string): File => {
  return new File([blob], fileName, {
    type: blob.type,
  });
};

export const rangeDate = (date: string) => {
  let curDate = moment(date);
  const range = [];
  for (let i = 0; i < 15; i++) {
    range.push(curDate.format('YYYY/MM/DD'));
    curDate = curDate.subtract(1, 'days');
  }
  return range.reverse();
};

export const mappingData = (dates: string[], data: ITicketTrendingDay[]) => {
  if (data.length > 0) {
    const dataMap: { [key: string]: ITicketTrendingDay } = {};
    data.forEach((d) => {
      const key = moment(d.createdDay).format('YYYY/MM/DD');
      dataMap['' + key + ''] = d;
    });
    const result = dates.map((d: string) => {
      return dataMap[d] ? +dataMap[d].statusCount : 0;
    });
    return result;
  }
  return [];
};

export const exportExcel = async (filePath: string, data: any, fileName?: string) => {
  try {
    await axios
      .put(`${SCIMMS_API}${filePath}`, data, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then((res) => new Blob([res.data]))
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName ? fileName : 'ticket_list.xlsx');
        document.body.appendChild(link);
        link.click();
      });
  } catch (error) {
    console.log(error);
  }
  // console.log(data);
  // fetch(`${SCIMMS_API}${filePath}`, {
  //   method: "PUT",
  //   headers: {
  //     Authorization: "Bearer " + TokenService.getLocalAccessToken(),
  //   },
  //   body: data,
  // })
  //   .then((res) => res.blob())
  //   .then((blob) => {
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.setAttribute("download", "ticket_list.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
};

export const exportPdf = async (filePath: string, fileName: string) => {
  try {
    await axios
      .post(`${SCIMMS_API}${filePath}`, null, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then((res) => new Blob([res.data]))
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  } catch (error) {
    console.log(error);
  }
};

export const removeEmptyAttributes = (obj: { [key: string]: any }) => {
  return Object.entries(obj).reduce(
    (previous: { [key: string]: any }, [key, value]) =>
      value ? ((previous[key] = value), previous) : previous,
    {},
  );
};

export const findIdSerialByName = (name: string, serialNumbers: SerialNumberList[]) => {
  const serialItem = serialNumbers.find((serial) => serial.label === name);
  return serialItem?.id || '';
};

export const updateValueObj = <T>(dataObj: T[], firstItem?: SelectOption) => {
  const valObj: SelectOption[] = [];
  (Object.keys(dataObj) as (keyof typeof dataObj)[]).forEach((key) => {
    const obj2: any = dataObj[key];
    valObj.push({
      label: obj2.name || '',
      value: obj2.name || '',
      id: obj2.id || '',
    });
  });

  if (firstItem) valObj.unshift(firstItem);

  return valObj;
};

type ItemTable = {
  id?: string;
};

export const mappingDataTable = <T extends ItemTable>(dataTable: T[]) => {
  return (
    dataTable.map((item) => {
      return { key: item.id, ...item };
    }) || []
  );
};
