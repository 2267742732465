import { Navigate, useParams } from 'react-router-dom';
import { history } from 'helpers';
import React from 'react';
import { Role } from 'models/enum/role';
import TokenService from 'utils/token.service';

const PrivateUserRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { id } = useParams();
  const role = TokenService.getUser().role;
  const uid = TokenService.getUser().id;

  if (role === Role.USER && id !== uid) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/inspection' state={{ from: history.location }} />;
  }

  // authorized so return child components
  return children;
};

export default PrivateUserRoute;
