import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Country, ChargerModel, User, CertificateAdmin } from 'models';
import {
  certificateAdminActions,
  selectCertificateAdmins,
} from 'features/settings/certificate-admin/certificateAdminSlice';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { companyActions, selectCompanies } from 'features/settings/company/companySlice';
import { userActions, selectUsers } from 'features/user/userSlice';
import React, { useEffect, useState } from 'react';
import { Filter, certificationActions } from '../certificationSlice';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const FilterForm: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = useForm();

  const certificateAdminOption = useAppSelector(selectCertificateAdmins);
  const chargerModelOption = useAppSelector(selectChargerModels);
  const companiesOption = useAppSelector(selectCompanies);
  const userNameOption = useAppSelector(selectUsers);

  const [certificateAdminData, setCertificateAdminData] = useState<DataType[]>([]);
  const [chargerModelData, setChargerModelData] = useState<DataType[]>([]);
  const [companiesData, setCompaniesData] = useState<DataType[]>([]);
  const [usersData, setUsersData] = useState<DataType[]>([]);

  useEffect(() => {
    if (certificateAdminOption) {
      const info: DataType[] = [];
      const obj = certificateAdminOption;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: CertificateAdmin = obj[key] as CertificateAdmin;
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCertificateAdminData(info);
    }
  }, [certificateAdminOption]);

  useEffect(() => {
    if (chargerModelOption) {
      const info: DataType[] = [];
      const obj = chargerModelOption;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: ChargerModel = obj[key] as ChargerModel;
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setChargerModelData(info);
    }
  }, [chargerModelOption]);

  useEffect(() => {
    if (companiesOption) {
      const info: DataType[] = [];
      const obj = companiesOption;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: Country = obj[key] as Country;
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCompaniesData(info);
    }
  }, [companiesOption]);

  useEffect(() => {
    if (userNameOption) {
      const info: DataType[] = [];
      const obj = userNameOption;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: User = obj[key] as User;
        info.push({
          label: obj2.username || '',
          value: obj2.username || '',
          id: obj2.id || '',
        });
      });
      setUsersData(info);
    }
  }, [userNameOption]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      certificateAdminActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(
      chargerModelActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(
      companyActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(
      userActions.search({
        username: '',
        pagination: { pageNo: 1, size: 100000 },
      })
    );
  }, [dispatch]);

  const onSearch = () => {
    const val = form.getFieldsValue();
    const searchParams: Filter = {};
    let training_date_from = '';
    let training_date_to = '';
    if (val.trainingDate && val.trainingDate.length > 0) {
      training_date_from = val.trainingDate[0].format('YYYY-MM-DD');
      training_date_to = val.trainingDate[1].format('YYYY-MM-DD');
      searchParams['training_date_from'] = training_date_from;
      searchParams['training_date_to'] = training_date_to;
    }

    let expiry_date_from = '';
    let expiry_date_to = '';
    if (val.expiryDate && val.expiryDate.length > 0) {
      expiry_date_from = val.expiryDate[0].format('YYYY-MM-DD');
      expiry_date_to = val.expiryDate[1].format('YYYY-MM-DD');
      searchParams['expiry_date_from'] = expiry_date_from;
      searchParams['expiry_date_to'] = expiry_date_to;
    }
    if (val.certificate) {
      searchParams['certificate'] = val.certificate;
    }
    if (val.status !== '' && val.status !== undefined) {
      searchParams['status'] = val.status;
    }
    if (val.chargerModel !== '' && val.chargerModel !== undefined) {
      searchParams['charger_model_name'] = val.chargerModel;
    }
    if (val.username !== '' && val.username !== undefined) {
      searchParams['certificate_username'] = val.username;
    }
    if (val.company !== '' && val.company !== undefined) {
      searchParams['company_name'] = val.company;
    }
    dispatch(certificationActions.unSelectedIdExport());
    dispatch(certificationActions.unSelectedCertificationIdExport());
    dispatch(certificationActions.search({ filter: searchParams, pagination: { pageNo: 1 } }));
  };
  return (
    <div className='certification-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Certificate' name='certificate' className='short-title'>
              <AutoComplete
                allowClear
                options={certificateAdminData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Model' name='chargerModel' className='short-title'>
              <AutoComplete
                allowClear
                options={chargerModelData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Company' name='company' className='short-title'>
              <AutoComplete
                allowClear
                options={companiesData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Username' name='username' className='short-title'>
              <AutoComplete
                allowClear
                options={usersData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Status' name='status' className='short-title'>
              <Select>
                <Select.Option value={''}>All</Select.Option>
                <Select.Option value={'Valid'}>Valid</Select.Option>
                <Select.Option value={'Expired'}>Expired</Select.Option>
                <Select.Option value={'Suspended'}>Suspended</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item className='short-title'>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Training Date' name='trainingDate' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Expiry Date' name='expiryDate' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
