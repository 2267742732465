import { DownOutlined, ExportOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { Inspection, MaintenanceRegion } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { exportExcel, exportPdf } from 'utils/function';
import TokenService from 'utils/token.service';
import {
  maintenanceOtherRegionActions,
  selectDeleting,
  selectedIdsExcel,
  selectMaintenanceOtherRegionLoading,
  selectPagination,
} from '../maintenanceOtherRegionsSlice';
import { Attachment } from './Attachment';

import routesString from 'routes_string';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Inspection;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const selectedIdsExcels = useAppSelector(selectedIdsExcel);
  const [ids, setIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const isSystemAdmin = TokenService.isSystemAdmin();
  const loading = useAppSelector(selectMaintenanceOtherRegionLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [disabledDownload, setDisabledDownload] = useState<{ [key: string]: boolean }>({});
  const data: MaintenanceRegion[] = useAppSelector((state) => {
    const isExistedVideos: { [key: string]: boolean } = {};
    const result = state.maintenanceOtherRegion.list.map((item) => {
      if (
        item.conclusion?.some((conclusion) =>
          conclusion.session_list?.some((session) =>
            session.upload?.videos?.some((video) => video.url),
          ),
        )
      ) {
        isExistedVideos[item.id] = false;
      } else {
        isExistedVideos[item.id] = true;
      }
      return { key: item.id, ...item };
    });
    if (JSON.stringify(isExistedVideos) !== JSON.stringify(disabledDownload)) {
      setDisabledDownload(isExistedVideos);
    }
    return result;
  });

  useEffect(() => {
    setSelectedRowKeys(selectedIdsExcels);
  }, [selectedIdsExcels]);

  useEffect(() => {
    dispatch(maintenanceOtherRegionActions.unSelectedIdsExport());

    dispatch(maintenanceOtherRegionActions.changePage({ pageNo: 1 }));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    } else {
      setIsModalVisible(false);
    }
  }, [deleting, openNotification]);

  // useEffect(() => {
  //   if (!deleting) {
  //     setIsModalVisible(false);
  //   }
  // }, [deleting]);

  const handleDelete = (id: string) => {
    setIsModalVisible(true);
    setIdDelete(id);
  };

  const handleExportPdf = async (id: string) => {
    exportPdf(`/maintenance-region/export-pdf?id=${id}`, 'preventive-maintenance-report.pdf');
  };

  // Export Excel
  const handleExportExcelsAll = () => {
    exportExcel('/maintenance-region/export/by-ids', { ids: [] }, `maintenance_all.xlsx`);
  };

  const handleExportExcelSelected = () => {
    exportExcel(
      '/maintenance-region/export/by-ids',
      { ids: [...selectedIdsExcels] },
      `maintenance_selected.xlsx`,
    );
  };

  const columns: ColumnsType<MaintenanceRegion> = [
    {
      title: 'No',
      width: 80,
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
      fixed: 'left',
    },
    {
      title: 'Work Order ID',
      dataIndex: 'work_order_ID',
      width: 150,
    },
    {
      title: 'Charger Model',
      dataIndex: 'charger_model',
      width: 250,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.charger_model?.name || '';
      },
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: 250,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.serial_number?.name || '';
      },
    },
    {
      title: 'Charger Owner',
      dataIndex: 'charger_owner',
      width: 200,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.charger_owner?.name || '';
      },
    },
    {
      title: 'Maintenance Date',
      dataIndex: 'maintenance_date',
      render: (_: unknown, record: MaintenanceRegion) => {
        return format(new Date(record.maintenance_date), 'yyyy-MM-dd');
      },
      width: 150,
    },
    {
      title: 'Country',
      dataIndex: 'serial_number',
      width: 150,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.country?.name || '';
      },
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      width: 200,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record.site_name || '';
      },
    },
    {
      title: 'Detailed Address',
      dataIndex: 'detailed_address',
      width: 250,
    },
    {
      title: 'Conclusion',
      dataIndex: 'conclusion_result',
      width: 120,
    },
    {
      title: 'Name of equipment specialist',
      dataIndex: 'specialist_name',
      width: 200,
    },
    {
      title: 'Company of equipment specialist',
      dataIndex: 'specialist_company',
      width: 200,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.specialist_company?.name || '';
      },
    },
    {
      title: 'Name of owner / operator representative',
      dataIndex: 'representative_name',
      width: 200,
    },
    {
      title: 'Company of owner / operator representative',
      dataIndex: 'representative_company',
      width: 200,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record?.representative_company?.name || '';
      },
    },
    {
      title: 'Upload Hard Copy',
      dataIndex: 'attachment',
      width: 300,
      render: (_: unknown, record: MaintenanceRegion) => {
        return record.attachment ? <Attachment attachment={record.attachment} /> : '';
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: 150,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (_: unknown, record: MaintenanceRegion) => {
        return format(new Date(record.createdAt), 'yyyy-MM-dd');
      },
      width: 150,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      width: 150,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      render: (_: unknown, record: MaintenanceRegion) => {
        return format(new Date(record.updatedAt), 'yyyy-MM-dd');
      },
      width: 150,
    },
    {
      title: 'Action',
      width: 150,
      fixed: 'right',
      render: (_: unknown, record: MaintenanceRegion) => {
        return (
          <>
            <Space size='middle'>
              {isSystemAdmin ? (
                <>
                  <Link to={`${routesString.MAINTENANCE_OTHER_REGIONS}/edit/${record.id}`}>
                    Edit
                  </Link>
                  |
                </>
              ) : (
                <>
                  <Link to={`${routesString.MAINTENANCE_OTHER_REGIONS}/edit/${record.id}`}>
                    View
                  </Link>
                  |
                </>
              )}
              <Dropdown
                overlay={
                  <div className='ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light'>
                    <div
                      className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                      onClick={() => handleExportPdf(record.id)}
                      style={{ color: '#e98d3e', cursor: 'pointer' }}
                    >
                      Export pdf
                    </div>
                    {isSystemAdmin && (
                      <div
                        className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                        onClick={() => handleDelete(record.id)}
                        style={{ color: '#e98d3e', cursor: 'pointer' }}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                }
              >
                <a>
                  More <DownOutlined />
                </a>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: MaintenanceRegion[]) => {
    if (newSelectedRowKeys.length) {
      const IDs = selectedRows.map((row: MaintenanceRegion) => row.id);
      setIds(IDs);
      dispatch(maintenanceOtherRegionActions.selectedIdsExport({ Ids: IDs }));
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      dispatch(maintenanceOtherRegionActions.unSelectedIdsExport());
      setIds([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number) => {
    dispatch(maintenanceOtherRegionActions.changePage({ pageNo: evt }));
  };

  // Delete one record
  const handleOk = () => {
    dispatch(maintenanceOtherRegionActions.deleteRecord({ id: idDelete as string }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Form form={form} component={false}>
      <Row gutter={16} justify='end' className='btn-group-export'>
        <Col className='gutter-row' span={4}>
          <Button
            disabled={!ids.length}
            onClick={handleExportExcelSelected}
            type='primary'
            icon={<ExportOutlined />}
            style={{ marginRight: 16 }}
            block
            className='btn-export'
          >
            Export Selected To Excel
          </Button>
        </Col>
        <Col className='gutter-row' span={4}>
          <Button
            onClick={handleExportExcelsAll}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export All To Excel
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        rowSelection={isSystemAdmin ? rowSelection : undefined}
        pagination={{
          total: pagination.totalRecords,
          pageSize: pagination.size,
          onChange: handleChangePage,
        }}
        scroll={{ x: 2330 }}
      />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete record?</p>
      </Modal>
    </Form>
  );
};

export default TableData;
