import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { termsActions } from 'features/settings/terms/termsSlice';
import { Policy } from 'models';
import { TermStatus } from 'models/enum/terms';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { policyActions } from '../../settings/policy/policySlice';
import { authActions, selectIsError, selectIsLoggedIn } from '../authSlice';
interface DataType {
  value?: string;
}
const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const hasError = useAppSelector(selectIsError);
  const isLogin = useAppSelector(selectIsLoggedIn);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('Privacy policy');
  const [modalData, setModalData] = useState<DataType[]>([]);
  const Policy =
    useAppSelector(
      (state) => state.policy.list?.filter((item: Policy) => item.status === TermStatus.Public)[0],
    ) || undefined;
  const TermsCondition =
    useAppSelector(
      (state) => state.terms.list?.filter((item: Policy) => item.status === TermStatus.Public)[0],
    ) || undefined;

  useEffect(() => {
    dispatch(policyActions.findForPublic());
    dispatch(termsActions.findForPublic());
  }, [dispatch]);

  useEffect(() => {
    if (isLogin) {
      if (TokenService.isAdmin() || TokenService.isSystemAdmin()) {
        navigate('/overview');
      } else {
        navigate('/ticket');
      }
    }
  }, [isLogin, navigate]);
  const onFinish = (values: any) => {
    dispatch(
      authActions.login({
        username: form.getFieldValue('username'),
        password: form.getFieldValue('password'),
      }),
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const showModalTerm = () => {
    const info = [{ value: TermsCondition?.name || 'We will update the terms soon.' }];
    setModalData(info);
    setTitle('Terms & Conditions');
    showModal();
  };
  const showModalPolicy = () => {
    const info = [{ value: Policy?.name || 'We will update the policy soon.' }];
    setModalData(info);
    setTitle('Privacy policy');
    showModal();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeItem = (evt: any) => {
    const isValidForm = isValidFormField();
    setDisabledSubmit(!isValidForm);
  };

  const isValidFormField = () => {
    return Object.values(form.getFieldsValue()).every((item) => !!item);
  };
  return (
    <>
      {/* <div id="login-condition">
        <Button type="link" onClick={showModalTerm}>
          Terms & Conditions
        </Button>
        <Button type="link" onClick={showModalPolicy}>
          Privacy policy
        </Button>
      </div> */}
      <div id='login-wrapper'>
        {/* <div className='login-title'>
        <Title>Sign in</Title>
        <Button className='btn-close'>
              <img src='/images/icon-close.svg' alt='' />
        </Button>
      </div> */}

        <div className='login-content'>
          {hasError && (
            <div style={{ color: 'red', marginBottom: 0.8 }}>Incorrect username or password</div>
          )}
          <Form
            name='login-form'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            form={form}
          >
            <Form.Item
              label='User name'
              name='username'
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input onChange={handleChangeItem} />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password onChange={handleChangeItem} />
            </Form.Item>
            <Form.Item
              className='chk-terms-condition-policy'
              name='termsCondition'
              valuePropName='checked'
            >
              <Checkbox onChange={handleChangeItem}>
                I agree to{' '}
                <Button type='link' onClick={showModalTerm} style={{ padding: 'unset' }}>
                  Terms & Conditions of Use
                </Button>
              </Checkbox>
            </Form.Item>
            <Form.Item
              className='chk-terms-condition-policy'
              name='privacyPolicy'
              valuePropName='checked'
            >
              <Checkbox onChange={handleChangeItem}>
                I agree to{' '}
                <Button type='link' onClick={showModalPolicy} style={{ padding: 'unset' }}>
                  Privacy policy
                </Button>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='btn-submit'
                disabled={disabledSubmit}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          {/* <div className='login-other'>
          <Divider plain>Hoặc</Divider>
          <div className='login-other-list'>
            <Button type='link' href='#' className='login-facebook'>
              <img src='./images/icon-face.svg' alt='' />
            </Button>
            <Button type='link' href='#'>
              <img src='./images/icon-google.svg' alt='' />
            </Button>
            <Button type='link' href='#'>
              <img src='./images/icon-icloud.svg' alt='' />
            </Button>
          </div>
        </div> */}
          {/* <div className='login-confirm-register'>
          Chưa có tài khoản?
          <Button type='link' href='#'>
            Đăng ký ngay!
          </Button>
        </div> */}
        </div>
      </div>
      <Modal
        width={800}
        title={title}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalData && modalData.length > 0 && (
          <>
            <div dangerouslySetInnerHTML={{ __html: `${modalData[0].value}` }} />
          </>
        )}
      </Modal>
    </>
  );
};
export default LoginPage;
