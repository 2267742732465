import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ImageCustom } from 'components/common/ImageCustom';
import { categoryActions, selectCategories } from 'features/settings/category/categorySlice';
import { Rootcause, Ticket } from 'models';
import { TicketStatus } from 'models/enum/ticket';
import React, { useEffect, useState } from 'react';
import { getUTC8DateTimeFormat, isMine } from 'utils/function';
import TokenService from 'utils/token.service';
import ModalActionRootCause from './ModalActionRootCause';
const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};
export const RootCause: React.FC<{
  ticket: Ticket | undefined;
  data: Rootcause[];
  handleChange: any;
}> = ({ ticket, data, handleChange }) => {
  const [rootcauseData, setRootcauseData] = useState<Rootcause[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);
  const isSystemAdmin = TokenService.isSystemAdmin();
  const dispatch = useAppDispatch();

  const categories = useAppSelector(selectCategories);

  const isEdit =
    isSystemAdmin ||
    (ticket?.status !== undefined &&
      isOpen(ticket?.status) &&
      isMine(ticket?.service_engineer || ''));

  useEffect(() => {
    if (data) {
      const info: Rootcause[] = data as Rootcause[];
      setRootcauseData(info);
    }
  }, [data]);

  useEffect(() => {
    dispatch(categoryActions.fetchCategoryList({}));
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUpsert = (data: Rootcause) => {
    let rootCause = [...rootcauseData];
    if (idxEdit < 0) {
      rootCause = [...rootCause, data];
    } else {
      const tmp = [...rootcauseData];
      tmp[idxEdit] = data;
      rootCause = tmp;
    }
    setRootcauseData(rootCause);
    handleChange({ root_cause: rootCause });
  };

  const columns: ColumnsType<Rootcause> = [
    {
      title: 'Root cause',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Category',
      dataIndex: 'category_id',
      key: 'category_id',
      render: (_: unknown, record: Rootcause) => {
        const categoryName = categories.filter((item) => item.id === record.category?.id);
        return categoryName[0] ? categoryName[0].name : '';
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_: unknown, record: Rootcause) => {
        return getUTC8DateTimeFormat(record.time || '');
      },
    },
    {
      title: 'Upload Image',
      dataIndex: 'assets',
      key: 'assets',
      render: (_: unknown, record: Rootcause) => {
        return record?.assets?.map((item) => (
          <ImageCustom
            key={item.url}
            className='user-avatar'
            type='link'
            url={(item.thumb ? `${item.thumb}` : item.url) || ''}
            video={item.thumb ? `${item.url}` : ''}
          />
        ));
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: Rootcause, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isEdit ? 'cursor' : 'pointer',
                opacity: !isEdit ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isEdit);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  return (
    <div className='form-wrapper'>
      <Table dataSource={rootcauseData} columns={columns} scroll={{ x: 800 }} pagination={false} />
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        <Button type='primary' icon={<PlusOutlined />} onClick={handleAdd} disabled={!isEdit}>
          Add Root Cause
        </Button>
      </div>
      <ModalActionRootCause
        data={rootcauseData[idxEdit]}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
