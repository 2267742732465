import Sidebar from 'components/common/Sidebar';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';

export const AdminLayout: React.FC = () => {
  // const dispatch = useAppDispatch();
  // const { pathname } = useLocation();
  // const params = useParams();
  // if (pathname.includes("inspection") && !!params?.id) {
  //   dispatch(inspectionActions.fetchInspectionList({ username: "" }));
  // } else {
  //   dispatch(userActions.search({ username: "" }));
  // }
  return (
    <div>
      <Header />
      <div className='admin-wrapper'>
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
};
