import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { policyActions } from '../../settings/policy/policySlice';
import { Policy } from 'models';
import { TermStatus } from 'models/enum/terms';
interface DataType {
  value?: string;
}
const PolicyViewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [policyData, setPolicyData] = useState<DataType[]>([]);
  const Policy =
    useAppSelector(
      (state) => state.policy.list?.filter((item: Policy) => item.status === TermStatus.Public)[0],
    ) || undefined;

  useEffect(() => {
    const info = [{ value: Policy?.name || '' }];
    setPolicyData(info);
  }, [Policy]);

  useEffect(() => {
    dispatch(policyActions.fetchPolicyList({}));
  }, [dispatch]);

  return (
    <>
      {policyData && policyData.length > 0 && (
        <div dangerouslySetInnerHTML={{ __html: `${policyData[0].value}` }} />
      )}
    </>
  );
};
export default PolicyViewPage;
