import { DownOutlined, ExportOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { chargerModelActions } from 'features/settings/charger-model/chargerModelSlice';
import { companyActions } from 'features/settings/company/companySlice';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Training, TrainingContent, TrainingUser } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FORMAT_YMD, SCIMMS_API } from 'utils/constants';
import { exportPdf } from 'utils/function';
import TokenService from 'utils/token.service';
import { exportExcel } from 'utils/function';
import { JSZipUtils } from '../../../utils/jszip-utils';
import {
  selectDeleting,
  selectedIdsExcel,
  selectedTrainingIdsExcel,
  selectPagination,
  selectTrainingLoading,
  trainingActions,
} from '../trainingSlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: Training;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state) =>
      state.training?.list?.map((item) => {
        return { key: item.id, ...item };
      }) || [],
  );
  const selectedIdsExcels = useAppSelector(selectedIdsExcel);
  const selectedTrainingIdsExcels = useAppSelector(selectedTrainingIdsExcel);

  const isSystemAdmin = TokenService.isSystemAdmin();

  const loading = useAppSelector(selectTrainingLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selectedIdsExcels);
  }, [selectedIdsExcels]);

  useEffect(() => {
    dispatch(trainingActions.unSelectedIdExport());
    dispatch(trainingActions.unselectedTrainingIdExport());
    dispatch(
      trainingActions.search({
        filter: {},
        pagination: { pageNo: 1, size: pagination.size },
      }),
    );
    dispatch(
      // chargerModelActions.fetchChargerModelList({})
      chargerModelActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(companyActions.search({ name: '', pagination: { size: 100000 } }));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Training record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);

  const handleDownloadAttachments = (id: string) => {
    const dataById: Training | undefined = data.find((item: Training) => item.id === id);
    const assets =
      dataById?.attachment?.assets?.map((asset) => {
        const url = asset.url;
        const lastIndex = url?.lastIndexOf('/') || -1;
        const filename = lastIndex > -1 ? url?.substring(lastIndex + 1) : '';
        return {
          url: url || '',
          name: filename || '',
        };
      }) || [];
    assets.length ? zipMedias('attachments.zip', assets) : '';
  };

  const zipMedias = async (filename: string, videos: { url: string; name: string }[]) => {
    const zip = new JSZip();
    for (const video of videos) {
      await fetchMedia(video.url).then((res: any) => {
        zip.file(video.name, urlToPromise(res?.link || ''), { binary: true });
      });
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, filename);
    });
  };

  const urlToPromise = (url: string): Promise<any> => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  function fetchMedia(url: string): Promise<string> {
    return new Promise((resolve) => {
      const jwt = TokenService.getLocalAccessToken();

      // Make a headers object that we can add to the request
      const headers = new Headers({
        authorization: 'Bearer ' + jwt,
      });
      const lastIndex = url.lastIndexOf('/');
      const folder = url.substring(0, lastIndex);
      const filename = url.substring(lastIndex + 1);
      const urlFetch = `${SCIMMS_API}/upload/signedurl-view?folder=${folder}&filename=${filename}`;
      // Make the request and wait for the response
      window.fetch(urlFetch, { headers }).then((response) => {
        const res = response.json();
        return resolve(res);
      });
    });
  }
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  useEffect(() => {
    if (loading) {
      setIsModalVisible(false);
    }
  }, [loading]);

  const columns: ColumnsType<Training> = [
    {
      title: 'Certificate',
      dataIndex: 'certificate',
      key: 'certificate',
      render: (_: unknown, record: Training) => {
        return record.certificate?.name ?? 'None';
      },
      width: 200,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (_: unknown, record: Training) => {
        return record.customer;
      },
    },
    {
      title: 'Charger Model',
      dataIndex: 'charger_model',
      key: 'charger_model',
      render: (_: unknown, record: Training) => {
        return record.charger_model.name;
      },
    },
    {
      title: 'Training Date',
      dataIndex: 'training_date',
      key: 'training_date',
      render: (_: unknown, record: Training) => {
        return record.training_date ? format(new Date(record.training_date), FORMAT_YMD) : '';
      },
      width: 120,
    },
    {
      title: 'Trainer Name',
      dataIndex: 'trainer_name',
      key: 'trainer_name',
      render: (_: unknown, record: Training) => {
        return record.trainer_name;
      },
    },
    {
      title: 'Trainer Company',
      dataIndex: 'trainer_company',
      key: 'trainer_company',
      render: (_: unknown, record: Training) => {
        return record.trainer_company.name;
      },
    },
    {
      title: 'Training Method',
      dataIndex: 'training_method',
      key: 'training_method',
      render: (_: unknown, record: Training) => {
        return record.training_method;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (_: unknown, record: Training) => {
        return record.country?.name;
      },
      width: 120,
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      render: (_: unknown, record: Training) => {
        return record.site_name;
      },
    },
    {
      title: 'Detailed Address',
      dataIndex: 'detail_address',
      key: 'detail_address',
      render: (_: unknown, record: Training) => {
        return record.detailed_address;
      },
      width: 180,
    },
    {
      title: 'Training Contents',
      dataIndex: 'training_contents',
      key: 'training_contents',
      width: 200,
      render: (_: unknown, record: Training) => {
        const trainingContents: TrainingContent[] = [];
        const obj = record?.content_list
          ?.slice()
          ?.sort((a, b) => parseFloat(a?.index_name || '') - parseFloat(b?.index_name || ''));

        (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
          const obj2: TrainingContent = obj[key] as TrainingContent;

          trainingContents.push({
            training_content: obj2.training_content ?? '',
          });
        });
        return trainingContents.map((item, index) => (
          <div key={index}>
            {index + 1}. {item.training_content}
          </div>
        ));
      },
    },
    {
      title: 'Trainee Names',
      dataIndex: 'trainee_names',
      key: 'trainee_names',
      render: (_: unknown, record: Training) => {
        const trainingUsers: TrainingUser[] = [];
        const obj = record?.training_users
          ?.slice()
          ?.sort(
            (a, b) =>
              parseFloat(a?.trainee?.index_name || '') - parseFloat(b?.trainee?.index_name || ''),
          );

        (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
          const obj2: TrainingUser = obj[key] as TrainingUser;

          trainingUsers.push({
            ...obj2,
          });
        });

        return trainingUsers.map((item, idx) => (
          <div key={item.id}>
            {idx + 1}.{' '}
            {item.trainee?.user
              ? item.trainee?.user?.username
                ? `${item.trainee?.user?.username} - ${item.trainee?.user?.firstname}${' '}
            ${item.trainee?.user?.lastname}`
                : `${item.trainee?.user?.firstname ?? ''}${' '}
            ${item.trainee?.user?.lastname ?? ''}`
              : item.trainee?.name}
          </div>
        ));
      },
      width: 230,
    },
    {
      title: 'Upload Hard Copy',
      dataIndex: 'upload_hard_copy',
      key: 'upload_hard_copy',
      render: (_: unknown, record: Training) => {
        return record?.attachment ? (
          <div
            style={{ color: '#e98d3e', cursor: 'pointer' }}
            onClick={() => handleDownloadAttachments(record.id)}
          >
            download
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Create By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (_: unknown, record: Training) => {
        return record.createdBy;
      },
      width: 120,
    },
    {
      title: 'Create Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: unknown, record: Training) => {
        return record.createdAt ? format(new Date(record.createdAt), FORMAT_YMD) : '';
      },
      width: 120,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: (_: unknown, record: Training) => {
        return record.updatedBy;
      },
      width: 120,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_: unknown, record: Training) => {
        return record.updatedAt ? format(new Date(record.updatedAt), FORMAT_YMD) : '';
      },
      width: 120,
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (_: unknown, record: Training) => {
        return (
          <Space size='middle'>
            {isSystemAdmin ? (
              <>
                <Link to={`/training/edit/${record.id}`}>Edit</Link> |
              </>
            ) : (
              <>
                <Link to={`/training/edit/${record.id}`}>View</Link> |
              </>
            )}
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <span
                      onClick={() => {
                        exportPdfRow(record.id as string);
                      }}
                      style={{ color: '#e98d3e', cursor: 'pointer' }}
                    >
                      Export pdf
                    </span>
                  </Menu.Item>
                  {isSystemAdmin && (
                    <Menu.Item>
                      <span
                        onClick={() => {
                          setIsModalVisible(true);
                          setIdDelete(record.id as string);
                        }}
                        style={{ color: '#e98d3e', cursor: 'pointer' }}
                      >
                        Delete
                      </span>
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <a>
                More <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Training[]) => {
    if (newSelectedRowKeys.length) {
      dispatch(trainingActions.selectedIdExport({ keys: newSelectedRowKeys }));
      const trainingIDs = selectedRows.map((row: Training) => row.id as string);
      dispatch(trainingActions.selectedTrainingIdExport({ trainingIds: trainingIDs }));
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      dispatch(trainingActions.unSelectedIdExport());
      dispatch(trainingActions.unselectedTrainingIdExport());
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number, size: number) => {
    dispatch(trainingActions.changePage({ pageNo: evt, size }));
  };

  const handleOk = () => {
    dispatch(trainingActions.deleteTraining({ id: idDelete as string }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showTotal = (total: number, range: [number, number]) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const handleExportExcels = () => {
    exportExcel(
      '/customer-training/export/by-ids',
      { ids: selectedTrainingIdsExcels },
      'training_list.xlsx',
    );
  };

  const handleExportAllExcels = () => {
    exportExcel('/customer-training/export/by-ids', { ids: [] }, 'training_list.xlsx');
  };

  const exportPdfRow = (trainingId: string) => {
    exportPdf(`/customer-training/export-pdf?id=${trainingId}`, 'training-report.pdf');
  };

  return (
    <Form form={form} component={false}>
      <Row gutter={16} justify='end' style={{ marginBottom: 24 }}>
        <Col className='gutter-row' span={4}>
          <Button
            disabled={!selectedTrainingIdsExcels.length}
            onClick={handleExportExcels}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export Selected To Excel
          </Button>
        </Col>
        <Col className='gutter-row' span={4}>
          <Button
            onClick={handleExportAllExcels}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export All To Excel
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: true,
          total: pagination.totalRecords,
          onChange: handleChangePage,
          showTotal: showTotal,
          className: 'vpbs-pagination',
        }}
        scroll={{ x: 2800 }}
      />
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete training?</p>
      </Modal>
    </Form>
  );
};

export default TableData;
