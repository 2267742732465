import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Upload, UploadFile, UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppSelector } from 'app/hooks';
import { selectCategories } from 'features/settings/category/categorySlice';
import { Asset, Rootcause } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getThumbnailForVideo,
  removeExtension,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const ModalActionRootCause: React.FC<{
  data: Rootcause;
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  onFinish: any;
}> = ({ data, isModalVisible, handleOk, handleCancel, setIsModalVisible, onFinish }) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [paths, setPaths] = useState<Asset[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const categoryOption = useAppSelector(selectCategories);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([...fileList, list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: resUrl?.link || '',
      };
    };
    if (fileUpload) {
      setPaths([...paths, { url: fileUpload }]);
      const asset = getImage({ thumb: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        note: data?.note || '',
        assets: data?.assets || [],
        category_id: categoryOption.filter((item) => item.id === data?.category?.id)[0]
          ? data?.category?.id
          : '',
      });
      const setImgList = async (list: any) => {
        Promise.all(list).then((list) => {
          setFileList(list);
        });
      };
      const getImage = async (asset: Asset) => {
        const url = (asset.thumb ? asset.thumb : asset.url) || '';
        const resUrl = (await fetchImage(url)) as { link: string };
        return {
          uid: resUrl?.link || '',
          url: resUrl?.link || '',
          name: resUrl?.link || '',
        };
      };
      if (data?.assets) {
        const list = data?.assets.map((asset) => getImage(asset));
        setImgList(list);
      } else {
        setImgList([]);
      }
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    const categoryId = form.getFieldValue('category_id');
    const category = categoryOption.find((item) => item.id === categoryId);
    onFinish({
      note: form.getFieldValue('note'),
      assets: [...(data?.assets || []), ...paths],
      username: TokenService.getUser().username,
      time: moment(new Date()).toDate(),
      category,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const props: UploadProps = {
    name: 'image',
    fileList: fileList,
    customRequest: async ({ onSuccess, onError, file, filename }) => {
      if ((file as File).type.startsWith('image')) {
        const fileName = generateFileName((file as File).name || '');
        const path = `ticket/root_cause/${fileName}`;
        const resImg = (await fetchImageForUpload(path)) as { link: string };
        // const formData = new FormData();
        // formData.append("image", file);
        uploadMedia(resImg?.link, file as Blob)
          .then(async (resp) => {
            setFileUpload(path);
            message.success(`${fileName} file uploaded successfully`);
          })
          .catch((err) => {
            console.log(err);
            message.error(`${fileName} file upload failed.`);
          });
      } else {
        const fileNameVideo = generateFileName((file as File).name || '');
        const path = `ticket/root_cause/${fileNameVideo}`;
        const resImg = (await fetchImageForUpload(path)) as { link: string };
        // const formData = new FormData();
        // formData.append("image", file);
        uploadMedia(resImg?.link, file as Blob)
          .then(async (resp) => {
            // const fileUrl = URL.createObjectURL(file as Blob);
            // const thumb = await getThumbnailForVideo(fileUrl);
            // const fileNameThumb = generateFileName();
            const fileUrl = URL.createObjectURL(file as Blob);
            const thumb = await getThumbnailForVideo(fileUrl);
            const thumbnail = `ticket/root_cause/${removeExtension(fileNameVideo) + '.jpeg'}`;
            const resThumb = (await fetchImageForUpload(thumbnail)) as {
              link: string;
            };
            // const thumbFile = dataURLtoFile(
            //   b64toBlob(thumb, "image/jpeg"),
            //   removeExtension(fileNameVideo) + ".jpeg"
            // );
            // const thumbFile = blobToFile(
            //   b64toBlob(thumb, "image/png"),
            //   removeExtension(fileNameVideo) + ".png"
            // );
            // const thumbFile = Buffer.from(thumb, "binary");
            const thumbFile = new File([thumb], removeExtension(fileNameVideo) + '.jpeg', {
              type: 'image/jpeg',
            });
            // // new Blob([window.atob(thumb)], {
            // //   type: "image/png",
            // // });
            const formData = new FormData();
            formData.append('image', thumbFile, removeExtension(fileNameVideo) + '.jpeg');
            // const thumbFile = dataURLtoFile(
            //   thumb,
            //   removeExtension(fileNameVideo) + ".jpeg"
            // );
            uploadMedia(resThumb?.link, thumbFile)
              .then(async (resp) => {
                setFileUpload(thumbnail);
                // setFileList([...fileList, makeFileUpload(resThumb?.link)]);
                message.success(`${filename} file uploaded successfully`);
                // form.setFieldValue("icon", res?.link);
              })
              .catch((err) => {
                console.log(err);
                message.error(`${filename} file upload failed.`);
              });
          })
          .catch((err) => {
            console.log(err);
            message.error(`${filename} file upload failed.`);
          });

        // const fileNameVideo = generateFileName((file as File).name || "");
        // const resVideo = (await fetchImageForUpload(
        //   `ticket/root_cause/${fileNameVideo}`
        // )) as { link: string };
        // // const formData = new FormData();
        // // formData.append("image", file);
        // uploadMedia(resVideo?.link, file as Blob)
        //   .then(async (resp) => {
        //     // const fileUrl = URL.createObjectURL(file as Blob);
        //     // const thumb = await getThumbnailForVideo(fileUrl);
        //     // const fileNameThumb = generateFileName();
        //     const fileUrl = URL.createObjectURL(file as Blob);
        //     const thumb = await getThumbnailForVideo(fileUrl);
        //     const thumbnail = `ticket/root_cause/${
        //       removeExtension(fileNameVideo) + ".jpeg"
        //     }`;
        //     const resThumb = (await fetchImageForUpload(thumbnail)) as {
        //       link: string;
        //     };
        //     // const thumbFile = dataURLtoFile(
        //     //   b64toBlob(thumb, "image/jpeg"),
        //     //   removeExtension(fileNameVideo) + ".jpeg"
        //     // );
        //     // const thumbFile = blobToFile(
        //     //   b64toBlob(thumb, "image/png"),
        //     //   removeExtension(fileNameVideo) + ".png"
        //     // );
        //     // const thumbFile = Buffer.from(thumb, "binary");
        //     // const thumbFile = new File(
        //     //   [thumb],
        //     //   removeExtension(fileNameVideo) + ".jpeg",
        //     //   { type: "image/jpeg" }
        //     // );
        //     // // new Blob([window.atob(thumb)], {
        //     // //   type: "image/png",
        //     // // });
        //     // const formData = new FormData();
        //     // formData.append("image", thumbFile);
        //     const thumbFile = dataURLtoFile(
        //       thumb,
        //       removeExtension(fileNameVideo) + ".jpeg"
        //     );
        //     uploadMedia(resThumb?.link, thumbFile)
        //       .then(async (resp) => {
        //         setFileUpload(thumbnail);
        //         // setFileList([...fileList, makeFileUpload(resThumb?.link)]);
        //         message.success(`${filename} file uploaded successfully`);
        //         // form.setFieldValue("icon", res?.link);
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //         message.error(`${filename} file upload failed.`);
        //       });
        // setFileList([...fileList, makeFileUpload(res?.link)]);
        // message.success(`${fileName} file uploaded successfully`);
        // form.setFieldValue("icon", res?.link);
        // })
        // .catch((err) => {
        //   console.log(err);
        //   message.error(`${filename} file upload failed.`);
        // });
      }
    },
  };

  const handleChangeItem = (evt: any) => {
    const note = form.getFieldValue('note');
    const categoryId = form.getFieldValue('category_id');
    if (data) {
      const isValid = note !== data?.note || categoryId !== data.category?.id;
      setDisabledSubmit(!isValid);
    } else {
      const isValid = !!note && !!categoryId;
      setDisabledSubmit(!isValid);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{data ? <div>Update Root Cause</div> : <div>Create Root Cause</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item label='Root cause' name='note'>
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item label='Category' name='category_id'>
          <Select onChange={handleChangeItem}>
            {categoryOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Upload Image' valuePropName='file' name='icon'>
          <Upload
            {...props}
            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            listType='picture-card'
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionRootCause;
