import React from 'react';
import { useParams } from 'react-router-dom';
import FormEdit from './Form';

const EditCompanyPage: React.FC = () => {
  const params = useParams();
  return (
    <div className='user-wrapper'>
      <div className='user-content' style={{ padding: 24 }}>
        <FormEdit id={params.id} />
      </div>
    </div>
  );
};

export default EditCompanyPage;
