import { Button, Form, Input, InputNumber, Modal, notification, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { Policy } from 'models';
import { TermStatus } from 'models/enum/terms';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { formatYYYYMMDD } from '../../../../utils/function';
import {
  policyActions,
  selectDeleting,
  selectPagination,
  selectPolicyLoading,
} from '../policySlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Policy;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.policy.list);
  const pagination = useAppSelector(selectPagination);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const loading = useAppSelector(selectPolicyLoading);
  const [idDelete, setIdDelete] = useState('');
  const deleting = useAppSelector(selectDeleting);

  const isSystemAdmin = TokenService.isSystemAdmin();

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    console.log('dsfee eee dd', idDelete);
    dispatch(policyActions.deletePolicy({ id: idDelete as string }));
  };

  useEffect(() => {
    dispatch(policyActions.fetchPolicyList({}));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Ticket record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  useEffect(() => {
    if (loading) {
      setIsModalVisible(false);
    }
  }, [loading]);

  const toggleStatusPolicy = (policy: Policy) => {
    const policyUpdate = { ...policy };
    dispatch(policyActions.toggleStatusPolicy({ ...policyUpdate }));
  };

  const columns = [
    {
      title: 'No',
      width: '5%',
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
    },
    {
      title: 'Privacy Policy',
      dataIndex: 'name',
      width: '20%',
      editable: true,
      render: (text: any) => {
        return (
          <div className='policy-content text-ellipsis-2-line'>
            <span className='ml-2' dangerouslySetInnerHTML={{ __html: `${text}` }}></span>
          </div>
        );
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      width: '10%',
      editable: true,
      render: (_: unknown, record: Policy) => {
        return formatYYYYMMDD(record.updatedAt || '');
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
    },
    {
      title: 'Action',
      width: '20%',
      dataIndex: 'operation',
      render: (_: unknown, record: Policy) => {
        return (
          <>
            {isSystemAdmin && (
              <>
                <span
                  onClick={() => {
                    setIsModalVisible(true);
                    setIdDelete(record.id);
                  }}
                  style={{ color: '#e98d3e', cursor: 'pointer' }}
                >
                  Delete
                </span>{' '}
                |{' '}
              </>
            )}
            {record.status === TermStatus.Private && (
              <>
                <span
                  onClick={() => toggleStatusPolicy({ ...record, status: TermStatus.Public })}
                  style={{ color: '#e98d3e', cursor: 'pointer' }}
                >
                  Set Public
                </span>{' '}
                |{' '}
              </>
            )}
            {record.status === TermStatus.Public && (
              <>
                <span
                  onClick={() =>
                    toggleStatusPolicy({
                      ...record,
                      status: TermStatus.Private,
                    })
                  }
                  style={{ color: '#e98d3e', cursor: 'pointer' }}
                >
                  Set Private
                </span>{' '}
                |{' '}
              </>
            )}
            <Link to={`/policy/edit/${record.id}`} style={{ color: '#e98d3e' }}>
              Edit
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName='editable-row'
          pagination={false}
        />
      </Form>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete policy?</p>
      </Modal>
    </>
  );
};

export default TableData;
