import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ImageCustom } from 'components/common/ImageCustom';
import {
  maintenanceOtherRegionActions,
  selectMaintenanceOtherRegionLoad,
} from 'features/maintenanceOtherRegion/maintenanceOtherRegionsSlice';
import { selectCompanies } from 'features/settings/company/companySlice';
import { Asset, MaintenanceRegion } from 'models';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routesString from 'routes_string';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getFileName,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const formatDate = (date: string) => {
  return (date ? moment(new Date(date)) : null) || moment(new Date());
};
const Step8: React.FC<{
  prevStep: any;
  hasChange: boolean;
  data: MaintenanceRegion | undefined;
}> = ({ prevStep, data, hasChange }) => {
  const [disabled, setDisabled] = useState(true);
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [specialistSignatureFile, setSpecialistSignatureFile] = useState<Asset>({});
  const [representativeSignatureFile, setRepresentativeSignatureFile] = useState<Asset>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loaded = useAppSelector(selectMaintenanceOtherRegionLoad);
  const disabledForm = TokenService.isDisabled();
  useEffect(() => {
    setDisabled(hasChange);
  }, [hasChange]);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: getFileName(url) || '',
      };
    };
    if (fileUpload) {
      const asset = getImage({ url: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        conclusion_result: data.conclusion_result,
        conclusion_remark: data.conclusion_remark,
        next_inspection_date: formatDate(data.next_inspection_date),
        maintenance_date: formatDate(data.maintenance_date),
        specialist_name: data.specialist_name,
        specialist_company: data.specialist_company.id,
        specialist_signature: data.specialist_signature,
        specialist_designation: data.specialist_designation,
        specialist_date: formatDate(data.specialist_date),
        representative_name: data.representative_name,
        representative_company: data.representative_company.id,
        representative_signature: data.representative_signature,
        representative_designation: data.representative_designation,
        representative_date: formatDate(data.representative_date),
      });
      if (data?.attachment) {
        setFileUpload(data?.attachment);
        const setImgList = async (list: any) => {
          Promise.all(list).then((list) => {
            setFileList(list);
          });
        };
        const getImage = async (asset: Asset) => {
          const url = (asset.thumb ? asset.thumb : asset.url) || '';
          const resUrl = (await fetchImage(url)) as { link: string };
          return {
            uid: resUrl?.link || '',
            url: resUrl?.link || '',
            name: getFileName(url) || '',
          };
        };
        setImgList([getImage({ url: data.attachment })]);
        setFileUpload(data.attachment);
      }
      setSpecialistSignatureFile({ url: data?.specialist_signature });
      setRepresentativeSignatureFile({ url: data?.representative_signature });
    }
  }, [data, form]);
  const openNotification = useCallback(() => {
    if (loaded) {
      notification.success({
        message: 'Item record updated successfully',
        placement: 'bottomRight',
        duration: 2,
        onClose: () => navigate(routesString.MAINTENANCE_OTHER_REGIONS),
      });
    }
  }, [loaded, navigate]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const props: UploadProps = {
    name: 'file',
    fileList: fileList,
    customRequest: async ({ onSuccess, onError, file, filename }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `attachment/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async (resp) => {
          setFileUpload(path);
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
    onRemove(file) {
      setFileList([]);
      setFileUpload('');
    },
  };

  const submitForm = () => {
    const specialist_company = companies.find(
      (item) =>
        item.id === form.getFieldValue('specialist_company') ||
        item.name === form.getFieldValue('specialist_company'),
    );

    const representative_company = companies.find(
      (item) =>
        item.id === form.getFieldValue('representative_company') ||
        item.name === form.getFieldValue('representative_company'),
    );
    const formSubmit: MaintenanceRegion = {
      ...data,
      ...form.getFieldsValue(),
      attachment: fileUpload,
      station_inspection: JSON.stringify(data?.station_inspection),
      power_distribution_cc: JSON.stringify(data?.power_distribution_cc),
      appearance_checks: JSON.stringify(data?.appearance_checks),
      internal_cc: JSON.stringify(data?.internal_cc),
      insulation_performance_c: JSON.stringify(data?.insulation_performance_c),
      functional_c: JSON.stringify(data?.functional_c),
      specialist_company: {
        id: specialist_company?.id,
      },
      representative_company: {
        id: representative_company?.id,
      },
    };
    dispatch(maintenanceOtherRegionActions.upsertMaintenance(formSubmit));
  };

  const handleFormChange = () => {
    if (hasFieldEmpty()) setDisabled(true);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setDisabled(hasErrors);
    }
  };
  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue())
      .filter((field) => field !== 'attachment' && field !== 'conclusion_remark')
      .some((field) => {
        return !form.getFieldValue(field)?.toString()?.trim();
      });
  };

  const companies = useAppSelector(selectCompanies);

  return (
    <div className='form-wrapper'>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={submitForm}
        form={form}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          label='Conclusion'
          key='1'
          initialValue='Pass'
          name={'conclusion_result'}
          className='annexfield-result'
          rules={[{ required: true, message: 'Please input Conclusion!' }]}
        >
          <Radio.Group disabled={disabledForm}>
            <Radio value={'Pass'}>Pass</Radio>
            <Radio value={'Fail'}>Fail</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='&nbsp;' className='annexfield-note' name={'conclusion_remark'}>
          <Input disabled={disabledForm} className='input-remarks' placeholder='Remarks' />
        </Form.Item>
        <Form.Item
          label='Recommended date of next inspection'
          name='next_inspection_date'
          rules={[{ required: true, message: 'Please input Date!' }]}
        >
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>
        <Form.Item
          label='Name of equipment specialist'
          name='specialist_name'
          rules={[{ required: true, message: 'Please input Name of equipment specialist!' }]}
        >
          <Input placeholder='' disabled={disabledForm} />
        </Form.Item>
        <Form.Item
          label='Company'
          name='specialist_company'
          rules={[{ required: true, message: 'Please input Company!' }]}
        >
          <Select
            dropdownMatchSelectWidth={false}
            placeholder='Select Charger Model'
            allowClear
            disabled={disabledForm}
          >
            {companies.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Designation'
          name='specialist_designation'
          rules={[{ required: true, message: 'Please input Designation!' }]}
        >
          <Input placeholder='' disabled={disabledForm} />
        </Form.Item>
        <Form.Item
          label='Signature'
          name='specialist_signature'
          rules={[{ required: true, message: 'Please input Signature!' }]}
        >
          <ImageCustom
            className='input-signature'
            type='link'
            url={specialistSignatureFile.url || ''}
          />
        </Form.Item>
        <Form.Item
          label='Date'
          name='specialist_date'
          rules={[{ required: true, message: 'Please input Date!' }]}
        >
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>
        <Form.Item
          label='Name of owner / operator representative'
          name='representative_name'
          rules={[
            { required: true, message: 'Please input Name of owner / operator representative!' },
          ]}
        >
          <Input placeholder='' disabled={disabledForm} />
        </Form.Item>
        <Form.Item
          label='Company'
          name='representative_company'
          rules={[{ required: true, message: 'Please input Company!' }]}
        >
          <Select
            dropdownMatchSelectWidth={false}
            placeholder='Select Charger Model'
            allowClear
            disabled={disabledForm}
          >
            {companies.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Designation'
          name='representative_designation'
          rules={[{ required: true, message: 'Please input Designation!' }]}
        >
          <Input placeholder='' disabled={disabledForm} />
        </Form.Item>
        <Form.Item
          label='Signature'
          name='representative_signature'
          rules={[{ required: true, message: 'Please input Signature!' }]}
        >
          <ImageCustom
            className='input-signature'
            type='link'
            url={representativeSignatureFile.url || ''}
          />
        </Form.Item>

        <Form.Item
          label='Date'
          name='representative_date'
          rules={[{ required: true, message: 'Please input Date!' }]}
        >
          <DatePicker
            disabled={disabledForm}
            style={{
              width: '70%',
            }}
          />
        </Form.Item>
        <Form.Item label='Upload Hard Copy' valuePropName='file' name='attachment'>
          <Upload {...props} disabled={disabledForm}>
            <Button icon={<UploadOutlined />} disabled={disabledForm}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      <div className='action-form'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        {!disabledForm && (
          <Button
            type='primary'
            onClick={submitForm}
            htmlType='submit'
            className={`btn-submit-form`}
            disabled={disabled}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default Step8;
