import {
  ListParams,
  ListResponse,
  Country,
  Company,
  Terms,
  Policy,
  ChargerModel,
  CertificateAdmin,
  InspectionChargerModelCertificatedUser,
} from 'models';
import { Category } from 'models/category';
import { TermStatus } from 'models/enum/terms';
import { IProblemCategory } from 'models/problemCategory';
import { Province } from 'models/province';
import { SerialNumber } from 'models/serialNumber';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const ENDPOINTS = {
  PROBLEM_CATEGORY: '/problem-category',
};

const settingApi = {
  // Country
  getAllCountry(params: ListParams): Promise<ListResponse<Country>> {
    const url = `${SCIMMS_API}/country`;
    return axiosClient.get(url, {
      params,
    });
  },

  createCountry(param: Country): Promise<Country> {
    const url = `${SCIMMS_API}/country`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateCountry(param: Country): Promise<Country> {
    const url = `${SCIMMS_API}/country?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  searchCountryByParams(params: ListParams) {
    const url = `${SCIMMS_API}/country/find-by-name`;
    return axiosClient.get(url, { params });
  },

  findCountryById(id: string): Promise<Country> {
    const url = `${SCIMMS_API}/country/${id}`;
    return axiosClient.get(url);
  },

  removeCountry(id: string): Promise<any> {
    const url = `${SCIMMS_API}/country/${id}`;
    return axiosClient.delete(url);
  },

  // Company
  getCompaniesByParam(params: ListParams): Promise<ListResponse<Company>> {
    const url = `${SCIMMS_API}/company`;
    return axiosClient.get(url, {
      params,
    });
  },

  getAllCompany(): Promise<ListResponse<Company>> {
    const url = `${SCIMMS_API}/company`;
    return axiosClient.get(url);
  },

  createCompany(param: Company): Promise<Company> {
    const url = `${SCIMMS_API}/company`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  searchCompanyByParams(params: ListParams) {
    const url = `${SCIMMS_API}/company/find-by-name`;
    return axiosClient.get(url, { params });
  },

  updateCompany(param: Company): Promise<Company> {
    const url = `${SCIMMS_API}/company?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findCompanyById(id: string): Promise<Company> {
    const url = `${SCIMMS_API}/company/${id}`;
    return axiosClient.get(url);
  },

  removeCompany(id: string): Promise<any> {
    const url = `${SCIMMS_API}/company/${id}`;
    return axiosClient.delete(url);
  },

  // Category
  getAllCategory(params: ListParams): Promise<ListResponse<Category>> {
    const url = `${SCIMMS_API}/category`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchCategoryByParams(params: ListParams) {
    const url = `${SCIMMS_API}/category/find-by-params`;
    return axiosClient.get(url, { params });
  },

  createCategory(param: Category): Promise<Category> {
    const url = `${SCIMMS_API}/category`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateCategory(param: Category): Promise<Category> {
    const url = `${SCIMMS_API}/category/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findCategoryById(id: string): Promise<Category> {
    const url = `${SCIMMS_API}/category/${id}`;
    return axiosClient.get(url);
  },

  removeCategory(id: string): Promise<any> {
    const url = `${SCIMMS_API}/category/${id}`;
    return axiosClient.delete(url);
  },

  // CertificateAdmin

  getCertificateAdminByParam(params: ListParams): Promise<ListResponse<CertificateAdmin>> {
    const url = `${SCIMMS_API}/certificate`;
    return axiosClient.get(url, {
      params,
    });
  },

  getAllCertificateAdmin(): Promise<ListResponse<CertificateAdmin>> {
    const url = `${SCIMMS_API}/certificate`;
    return axiosClient.get(url);
  },

  createCertificateAdmin(param: CertificateAdmin): Promise<CertificateAdmin> {
    const url = `${SCIMMS_API}/certificate`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  searchCertificateAdminByParams(params: ListParams) {
    const url = `${SCIMMS_API}/certificate/find-by-params`;
    return axiosClient.get(url, { params });
  },

  updateCertificateAdmin(param: CertificateAdmin): Promise<CertificateAdmin> {
    const url = `${SCIMMS_API}/certificate/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findCertificateAdminById(id: string): Promise<CertificateAdmin> {
    const url = `${SCIMMS_API}/certificate/${id}`;
    return axiosClient.get(url);
  },

  removeCertificateAdmin(id: string): Promise<any> {
    const url = `${SCIMMS_API}/certificate/${id}`;
    return axiosClient.delete(url);
  },

  // Province
  getAllProvince(params: ListParams): Promise<ListResponse<Province>> {
    const url = `${SCIMMS_API}/province/find-by-params`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchProvinceByParams(params: ListParams) {
    const url = `${SCIMMS_API}/province/find-by-params`;
    return axiosClient.get(url, { params });
  },

  getAllProvinceByCountry(countryId: string): Promise<ListResponse<Province>> {
    const url = `${SCIMMS_API}/province/country/${countryId}`;
    return axiosClient.get(url);
  },

  createProvince(param: Province): Promise<Province> {
    const url = `${SCIMMS_API}/province`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateProvince(param: Province): Promise<Province> {
    const url = `${SCIMMS_API}/province/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findProvinceById(id: string): Promise<Province> {
    const url = `${SCIMMS_API}/province/${id}`;
    return axiosClient.get(url);
  },

  removeProvince(id: string): Promise<any> {
    const url = `${SCIMMS_API}/province/${id}`;
    return axiosClient.delete(url);
  },

  // Charger Model
  getAllChargerModel(params: ListParams): Promise<ListResponse<ChargerModel>> {
    const url = `${SCIMMS_API}/charger-model`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchChargerModelByParams(params: ListParams) {
    const url = `${SCIMMS_API}/charger-model/find-by-name`;
    return axiosClient.get(url, { params });
  },

  getAllChargerModelByName(params: ListParams): Promise<ListResponse<ChargerModel>> {
    const url = `${SCIMMS_API}/charger-model/find-by-name`;
    params = { ...params, pageSize: 1000 };
    return axiosClient.get(url, {
      params,
    });
  },

  getAllChargerModelCertificatedUser(): Promise<InspectionChargerModelCertificatedUser[]> {
    const url = `${SCIMMS_API}/customer-training/certificated-user/charger-model-list`;
    return axiosClient.get(url, {});
  },

  createChargerModel(param: ChargerModel): Promise<Company> {
    const url = `${SCIMMS_API}/charger-model`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateChargerModel(param: ChargerModel): Promise<ChargerModel> {
    const url = `${SCIMMS_API}/charger-model?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findChargerModelById(id: string): Promise<ChargerModel> {
    const url = `${SCIMMS_API}/charger-model/${id}`;
    return axiosClient.get(url);
  },

  removeChargerModel(id: string): Promise<any> {
    const url = `${SCIMMS_API}/charger-model/${id}`;
    return axiosClient.delete(url);
  },

  // Serial number
  getAllSerialNumber(params: ListParams): Promise<ListResponse<SerialNumber>> {
    const url = `${SCIMMS_API}/serial`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchSerialByParams(params: ListParams) {
    const url = `${SCIMMS_API}/serial/find-by-params`;
    return axiosClient.get(url, { params });
  },

  getAllSerialByChargerModel(chargerModelId: string): Promise<ListResponse<Province>> {
    const url = `${SCIMMS_API}/serial/charger-model?chargerModelId=${chargerModelId}`;
    return axiosClient.get(url);
  },

  createSerialNumber(param: SerialNumber): Promise<SerialNumber> {
    const url = `${SCIMMS_API}/serial`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateSerialNumber(param: SerialNumber): Promise<SerialNumber> {
    const url = `${SCIMMS_API}/serial/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findSerialNumberById(id: string): Promise<SerialNumber> {
    const url = `${SCIMMS_API}/serial/${id}`;
    return axiosClient.get(url);
  },

  removeSerialNumber(id: string): Promise<any> {
    const url = `${SCIMMS_API}/serial/${id}`;
    return axiosClient.delete(url);
  },

  // Terms
  getAllTerms(params: ListParams): Promise<ListResponse<Terms>> {
    const url = `${SCIMMS_API}/terms`;
    return axiosClient.get(url, {
      params,
    });
  },

  findForPublicTerm(params: ListParams): Promise<ListResponse<Policy>> {
    const url = `${SCIMMS_API}/terms/find-for-public`;
    return axiosClient.get(url, {
      params,
    });
  },

  createTerms(param: Terms): Promise<Terms> {
    const url = `${SCIMMS_API}/terms`;
    param.status = TermStatus.Private;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateTerms(param: Terms): Promise<Terms> {
    const url = `${SCIMMS_API}/terms?id=${param.id}`;
    param.status = TermStatus.Private;
    return axiosClient.put(url, {
      ...param,
    });
  },

  publicTerms(param: Terms): Promise<Terms> {
    const url = `${SCIMMS_API}/terms?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findTermsById(id: string): Promise<Terms> {
    const url = `${SCIMMS_API}/terms/${id}`;
    return axiosClient.get(url);
  },

  // Policy
  getAllPolicy(params: ListParams): Promise<ListResponse<Policy>> {
    const url = `${SCIMMS_API}/policy`;
    return axiosClient.get(url, {
      params,
    });
  },

  findForPublicPolicy(params: ListParams): Promise<ListResponse<Policy>> {
    const url = `${SCIMMS_API}/policy/find-for-public`;
    return axiosClient.get(url, {
      params,
    });
  },

  createPolicy(param: Policy): Promise<Policy> {
    const url = `${SCIMMS_API}/policy`;
    param.status = TermStatus.Private;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updatePolicy(param: Policy): Promise<Policy> {
    const url = `${SCIMMS_API}/policy?id=${param.id}`;
    param.status = TermStatus.Private;
    return axiosClient.put(url, {
      ...param,
    });
  },

  publicPolicy(param: Policy): Promise<Policy> {
    const url = `${SCIMMS_API}/policy?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findPolicyById(id: string): Promise<Policy> {
    const url = `${SCIMMS_API}/policy/${id}`;
    return axiosClient.get(url);
  },
  remove(id: string): Promise<any> {
    const url = `${SCIMMS_API}/policy/${id}`;
    return axiosClient.delete(url);
  },
  removeTerms(id: string): Promise<any> {
    const url = `${SCIMMS_API}/terms/${id}`;
    return axiosClient.delete(url);
  },

  // Problem Category
  getAllProblemCategory(params: ListParams): Promise<ListResponse<IProblemCategory>> {
    const url = `${SCIMMS_API}${ENDPOINTS.PROBLEM_CATEGORY}`;
    return axiosClient.get(url, { params });
  },

  searchProblemCategoryByParams(params: ListParams) {
    const url = `${SCIMMS_API}${ENDPOINTS.PROBLEM_CATEGORY}/find-by-params`;
    return axiosClient.get(url, { params });
  },

  createProblemCategory(param: IProblemCategory): Promise<IProblemCategory> {
    const url = `${SCIMMS_API}${ENDPOINTS.PROBLEM_CATEGORY}`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  updateProblemCategory(param: IProblemCategory): Promise<IProblemCategory> {
    const url = `${SCIMMS_API}${ENDPOINTS.PROBLEM_CATEGORY}/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },
  removeProblemCategory(id: string): Promise<any> {
    const url = `${SCIMMS_API}${ENDPOINTS.PROBLEM_CATEGORY}/${id}`;
    return axiosClient.delete(url);
  },
};

export default settingApi;
