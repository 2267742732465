import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { ListParams, ListResponse, Pagination } from 'models';
import { SerialNumber } from 'models/serialNumber';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Filter, selectFilter, selectPagination, serialNumberActions } from './serialNumberSlice';

type Params = { payload: SerialNumber; type: string };

function* fetchSerialNumberList(param: ListParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<SerialNumber> = yield call(settingApi.getAllSerialNumber, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(serialNumberActions.fetchSerialNumberListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch SerialNumber list', error);
  }
}

type SearchParams = {
  payload: { filter: Filter };
  type: string;
};
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<SerialNumber> = yield call(settingApi.searchSerialByParams, {
      serialName: param.payload.filter.name,
      chargerModelId: param.payload.filter.chargerModelId,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(serialNumberActions.fetchSerialNumberListSuccess(response));
  } catch (error) {
    console.log('Failed to search serial number by params', error);
  }
}

type GetSerialNumberByChargerModelParams = {
  payload: { chargerModelId: string };
  type: string;
};
function* fetchSerialNumberByChargerModel(param: GetSerialNumberByChargerModelParams) {
  try {
    const response: ListResponse<SerialNumber> = yield call(
      settingApi.getAllSerialByChargerModel,
      param.payload.chargerModelId,
    );
    yield put(serialNumberActions.fetchSerialNumberListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch SerialNumber list', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    yield put(serialNumberActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to create SerialNumber', error);
  }
}

function* createSerialNumber(param: Params) {
  try {
    const response: SerialNumber = yield call(settingApi.createSerialNumber, param.payload);
    yield put(serialNumberActions.createSerialNumberSuccess(response));
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(serialNumberActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateSerialNumber(param: Params) {
  try {
    const response: SerialNumber = yield call(settingApi.updateSerialNumber, param.payload);
    yield put(serialNumberActions.updateSerialNumberSuccess(response));
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(serialNumberActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type SerialNumberDetailParams = { payload: { id: string }; type: string };
function* fetchSerialNumberDetail(param: SerialNumberDetailParams) {
  try {
    const id = param.payload.id;
    const response: SerialNumber = yield call(settingApi.findProvinceById, id);
    yield put(serialNumberActions.fetchSerialNumberSuccess(response));
  } catch (error) {
    console.log('Failed to find SerialNumber by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteSerialNumber(param: DeleteParams) {
  try {
    const id = param.payload.id;
    yield call(settingApi.removeSerialNumber, id);
    yield put(serialNumberActions.deleteSerialNumberSuccess());
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(serialNumberActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to delete SerialNumber', error);
    console.log('Failed to delete Province', error);
    yield put(serialNumberActions.deleteError());
  }
}

export default function* serialNumberSaga() {
  yield takeLatest(serialNumberActions.fetchSerialNumberList.type, fetchSerialNumberList);
  yield takeEvery(serialNumberActions.createSerialNumber.type, createSerialNumber);
  yield takeEvery(serialNumberActions.updateSerialNumber.type, updateSerialNumber);
  yield takeLatest(serialNumberActions.fetchSerialNumberDetail.type, fetchSerialNumberDetail);
  yield takeLatest(serialNumberActions.deleteSerialNumber.type, deleteSerialNumber);
  yield takeLatest(serialNumberActions.changePage.type, changePage);
  yield takeLatest(serialNumberActions.search.type, search);
  yield takeLatest(
    serialNumberActions.fetchSerialNumberByChargerModel.type,
    fetchSerialNumberByChargerModel,
  );
}
