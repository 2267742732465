/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { CertificateAdmin } from '../../../models/certificateAdmin';

export interface CertificateAdminState {
  list: CertificateAdmin[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
  error: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: CertificateAdminState = {
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
  error: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const certificateAdminSlice = createSlice({
  name: 'certificateAdmin',
  initialState,
  reducers: {
    fetchCertificateAdminList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchAllCertificateAdminList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchCertificateAdminListSuccess(state, action: PayloadAction<ListResponse<CertificateAdmin>>) {
      state.loading = false;
      state.loaded = false;
      state.list = action.payload.data;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
    },
    createCertificateAdmin(state, action: PayloadAction<CertificateAdmin>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    createCertificateAdminSuccess(state, action: PayloadAction<CertificateAdmin>) {
      state.loading = false;
      state.loaded = true;
    },
    updateCertificateAdmin(state, action: PayloadAction<CertificateAdmin>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    updateCertificateAdminSuccess(state, action: PayloadAction<CertificateAdmin>) {
      state.loading = false;
      state.loaded = true;
    },
    search(state, action: PayloadAction<{ name: string; pagination?: Pagination }>) {
      state.list = [];
      state.deleted = false;
      state.error = false;
      const { pagination, name } = action.payload;
      state.filter.name = name;
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo,
          size: pagination.size,
        };
      }
    },
    fetchCertificateAdminDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchCertificateAdminSuccess(state, action: PayloadAction<CertificateAdmin>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteCertificateAdmin(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
      state.error = false;
      state.deleted = false;
    },
    deleteCertificateAdminSuccess(state) {
      state.loading = true;
      state.deleting = false;
      state.deleted = true;
    },
    deleteError(state) {
      state.error = true;
      state.loading = false;
      state.deleting = false;
      state.deleted = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const certificateAdminActions = certificateAdminSlice.actions;

// selector
export const selectInspectionLoad = (state: RootState) => state.certificateAdmin.loaded;
export const selectPagination = (state: RootState) => state.certificateAdmin.pagination;
export const selectName = (state: RootState) => state.certificateAdmin.filter.name;
export const selectDeleting = (state: RootState) => state.certificateAdmin.deleting;
export const selectDeleted = (state: RootState) => state.certificateAdmin.deleted;
export const selectError = (state: RootState) => state.certificateAdmin.error;
export const selectCertificateAdminLoading = (state: RootState) => state.certificateAdmin.loading;
export const selectCertificateAdmins = (state: RootState) => state.certificateAdmin.list;

// Reducer
const certificateAdminReducer = certificateAdminSlice.reducer;
export default certificateAdminReducer;
