import React from 'react';
interface PaginationProps {
  page: number;
  totalPage: number;
}
export const Pagination: React.FC<PaginationProps> = ({ page, totalPage }) => {
  return (
    <div className='pagination'>
      Page <span>{page}</span> of <span>{totalPage}</span>
    </div>
  );
};
