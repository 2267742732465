import { Button, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Policy } from 'models';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { selectInspectionLoad, policyActions } from '../policySlice';

import JoditEditor from 'jodit-react';

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const Policy = useAppSelector(
    (state) => state.policy.list.filter((item: Policy) => item.id === id)[0],
  );
  const loaded = useAppSelector(selectInspectionLoad);
  const editor = useRef(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    form.setFieldsValue({
      name: Policy?.name || '',
    });
  }, [Policy, form]);

  useEffect(() => {
    if (id) dispatch(policyActions.fetchPolicyDetail({ id }));
  }, [id, dispatch]);
  const token = TokenService.getLocalAccessToken();

  const resetForm = () => {
    form.setFieldsValue({
      name: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      if (uid === id) {
        notification.success({
          message: 'Policy updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if (id) {
        notification.success({
          message: 'Policy record updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/settings/policy'),
          duration: 2.5,
        });
      } else {
        notification.success({
          message: 'Policy record created successfully',
          placement: 'bottomRight',
          onClose: () => {
            resetForm();
            navigate('/settings/policy');
          },
          duration: 2.5,
        });
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  useEffect(() => setContent(Policy?.name), [Policy]);
  const onFinish = (values: any) => {
    //console.log(form.getFieldsValue());
    const Policy: Policy = form.getFieldsValue();
    if (id) dispatch(policyActions.updatePolicy({ ...Policy, id }));
    else
      dispatch(
        policyActions.createPolicy({
          ...Policy,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='form-policy'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Form.Item
        label=''
        name='name'
        rules={[{ required: true, message: 'Please input Policy Name!' }]}
      >
        {/*<Input />*/}
        <JoditEditor
          ref={editor}
          value={content}
          //config={config}
          //tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormEdit;
