import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { Country, ListParams, ListResponse, Pagination } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { countryActions, selectName, selectPagination } from './countrySlice';

type Params = { payload: Country; type: string };

function* fetchCountryList(param: ListParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Country> = yield call(settingApi.getAllCountry, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(countryActions.fetchCountryListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch country list', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectName);
    yield put(countryActions.search({ name }));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

function* createCountry(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: Country = yield call(settingApi.createCountry, param.payload);
    yield put(countryActions.createCountrySuccess(response));
    yield put(countryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateCountry(param: Params) {
  try {
    const name: string = yield select(selectName);
    const response: Country = yield call(settingApi.updateCountry, param.payload);
    yield put(countryActions.updateCountrySuccess(response));
    yield put(countryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Country> = yield call(settingApi.searchCountryByParams, {
      countryName: param.payload.name,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(countryActions.fetchCountryListSuccess(response));
  } catch (error) {
    console.log('Failed to search country', error);
  }
}

type CountryDetailParams = { payload: { id: string }; type: string };
function* fetchCountryDetail(param: CountryDetailParams) {
  try {
    const id = param.payload.id;
    const response: Country = yield call(settingApi.findCountryById, id);
    yield put(countryActions.fetchCountrySuccess(response));
  } catch (error) {
    console.log('Failed to find country by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteCountry(param: DeleteParams) {
  try {
    const name: string = yield select(selectName);
    const id = param.payload.id;
    yield call(settingApi.removeCountry, id);
    yield put(countryActions.deleteCountrySuccess());
    yield put(countryActions.search({ name }));
  } catch (error) {
    console.log('Failed to delete country', error);
    yield put(countryActions.deleteError());
  }
}

export default function* countrySaga() {
  yield takeLatest(countryActions.fetchCountryList.type, fetchCountryList);
  yield takeEvery(countryActions.createCountry.type, createCountry);
  yield takeEvery(countryActions.updateCountry.type, updateCountry);
  yield takeLatest(countryActions.fetchCountryDetail.type, fetchCountryDetail);
  yield takeLatest(countryActions.deleteCountry.type, deleteCountry);
  yield takeLatest(countryActions.changePage.type, changePage);
  yield takeLatest(countryActions.search.type, search);
}
