import { Button, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppSelector } from 'app/hooks';

import { Training } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { selectTrainingLoad, trainingActions } from '../../trainingSlice';
import { Trainees } from './trainees';

export const SubmitForm: React.FC<{
  prevStep: any;
  hasChange: boolean;
  training: Training | undefined;
  handleChange: any;
}> = ({ prevStep, training, hasChange, handleChange }) => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const loaded = useAppSelector(selectTrainingLoad);
  const isSystemAdmin = TokenService.isSystemAdmin();

  const disabledForm = isSystemAdmin || !training;

  useEffect(() => {
    setDisabled(hasChange);
  }, [hasChange]);

  useEffect(() => {
    if (training) {
      form.setFieldsValue({
        trainee: training?.trainee_list,
      });
    }
  }, [training, form]);

  const openNotification = useCallback(() => {
    if (loaded) {
      notification.success({
        message: 'Training record updated successfully',
        placement: 'bottomRight',
        duration: 2,
        onClose: () => navigate('/training'),
      });
    }
  }, [loaded, navigate]);

  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const submitForm = () => {
    if (training?.id) {
      dispatch(trainingActions.upsertTraining(training));
    } else {
      let trainingDataUpdate;
      trainingDataUpdate = { ...training };
      if (!training?.country) {
        trainingDataUpdate = { ...trainingDataUpdate, country: {} };
      }
      if (!training?.site_name) {
        trainingDataUpdate = { ...trainingDataUpdate, site_name: '' };
      }
      if (!training?.detailed_address) {
        trainingDataUpdate = { ...trainingDataUpdate, detailed_address: '' };
      }

      dispatch(trainingActions.createTraining(trainingDataUpdate as Training));
    }
  };

  return (
    <div className='form-wrapper'>
      <Trainees
        training={training}
        handleChange={handleChange}
        data={
          training?.training_users
            ?.slice()
            ?.sort(
              (a, b) =>
                parseFloat(a.trainee?.index_name || '') - parseFloat(b.trainee?.index_name || ''),
            ) || []
        }
      />
      <div className='action-form action-form-ticket'>
        <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
          Back
        </Button>
        {disabledForm && (
          <Button
            type='primary'
            onClick={submitForm}
            htmlType='submit'
            className={`btn-submit-form`}
            disabled={disabled}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
