import { MaintenanceRegion, ListParams, ListResponse } from 'models';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const endpoints = {
  MAINTENANCE_REGION: '/maintenance-region',
};

const maintenanceRegionApi = {
  upsert(param: MaintenanceRegion): Promise<MaintenanceRegion> {
    const url = `${SCIMMS_API}${endpoints.MAINTENANCE_REGION}/${param.id}`;
    return axiosClient.put(url, param);
  },

  getById(id: string): Promise<MaintenanceRegion> {
    const url = `${SCIMMS_API}${endpoints.MAINTENANCE_REGION}/one?id=${id}`;
    return axiosClient.get(url);
  },

  add(data: MaintenanceRegion): Promise<MaintenanceRegion> {
    return axiosClient.post(endpoints.MAINTENANCE_REGION, data);
  },

  update(data: Partial<MaintenanceRegion>): Promise<MaintenanceRegion> {
    const url = `${endpoints.MAINTENANCE_REGION}/${data.id}`;
    return axiosClient.patch(url, data);
  },
  remove(id: string): Promise<any> {
    const url = `${SCIMMS_API}${endpoints.MAINTENANCE_REGION}/${id}`;
    return axiosClient.delete(url);
  },
  searchByParams(params: ListParams): Promise<ListResponse<MaintenanceRegion>> {
    const url = `${SCIMMS_API}${endpoints.MAINTENANCE_REGION}/find-by-params`;
    return axiosClient.get(url, {
      params,
    });
  },
  deleteMultipleRecords(ids: any): Promise<any> {
    const url = `${SCIMMS_API}${endpoints.MAINTENANCE_REGION}`;
    return axiosClient.put(url, {
      ...ids,
    });
  },
};

export default maintenanceRegionApi;
