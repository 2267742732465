import { Button, Form, Input, notification, Modal, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CertificateAdmin } from 'models';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { selectInspectionLoad, certificateAdminActions } from '../certificateAdminSlice';

const ModalActionCertificateAdmin: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const certificateAdmin = useAppSelector(
    (state) => state.certificateAdmin.list.filter((item: CertificateAdmin) => item.id === id)[0],
  );

  useEffect(() => {
    form.setFieldsValue({
      name: certificateAdmin?.name || '', validity_period: certificateAdmin?.validity_period || '',
    });
  }, [certificateAdmin, form]);
  useEffect(() => {
    if (id !== '') dispatch(certificateAdminActions.fetchCertificateAdminDetail({ id }));
  }, [id, dispatch]);

  const loaded = useAppSelector(selectInspectionLoad);
  const resetForm = () => {
    form.setFieldsValue({
      name: '', validity_period: ''
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      const idRandom = Math.random().toString(36).substring(2);
      setRandomState(idRandom);
      if (uid === id) {
        notification.success({
          message: 'Certificate updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else if (id !== '') {
        notification.success({
          message: 'Certificate updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/settings/certificate-admin'),
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else {
        notification.success({
          message: 'Certificate record created successfully',
          placement: 'bottomRight',
          //onClose: () => resetForm(),
          onClose: () => {
            resetForm();
            navigate('/settings/certificate-admin');
          },
          duration: 2.5,
        });
        setIsModalVisible(false);
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const certificate: CertificateAdmin = form.getFieldsValue();
    if (id !== '') {
      dispatch(certificateAdminActions.updateCertificateAdmin({ ...certificate, id }));
    } else {
      dispatch(
        certificateAdminActions.createCertificateAdmin({
          ...certificate,
        }),
      );
    }
    
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{id !== '' ? <div>Update Certificate</div> : <div>Create Certificate</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Certificate'
          name='name'
          rules={[{ required: true, message: 'Please input Certificate!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Validity Period(days)'
          name='validity_period'
          rules={[{ required: true, message: 'Please input Validity Period(days)!' }]}
        >
          <InputNumber min={0} style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionCertificateAdmin;
