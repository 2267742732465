import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { ListResponse, Pagination } from 'models';
import { IProblemCategory } from 'models/problemCategory';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  problemCategoryActions,
  selectPagination,
  selectProblemCategoryName,
} from './problemCategorySlice';

type Params = { payload: IProblemCategory; type: string };

function* fetchProblemCategoryList() {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<IProblemCategory> = yield call(settingApi.getAllProblemCategory, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(problemCategoryActions.fetchProblemCategoryListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Category list', error);
  }
}

type SearchParams = { payload: { name: string }; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<IProblemCategory> = yield call(
      settingApi.searchProblemCategoryByParams,
      {
        problemCategoryName: param.payload.name,
        pageNo: pagination.pageNo,
        pageSize: pagination.size,
      },
    );
    yield put(problemCategoryActions.fetchProblemCategoryListSuccess(response));
  } catch (error) {
    console.log('Failed to search Category', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const name: string = yield select(selectProblemCategoryName);
    yield put(problemCategoryActions.search({ name }));
  } catch (error) {
    console.log('Failed to create Category', error);
  }
}

function* createProblemCategory(param: Params) {
  try {
    const response: IProblemCategory = yield call(settingApi.createProblemCategory, param.payload);
    yield put(problemCategoryActions.createProblemCategorySuccess(response));
    const name: string = yield select(selectProblemCategoryName);
    yield put(problemCategoryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateProblemCategory(param: Params) {
  try {
    const response: IProblemCategory = yield call(settingApi.updateProblemCategory, param.payload);
    yield put(problemCategoryActions.updateProblemCategorySuccess(response));
    const name: string = yield select(selectProblemCategoryName);
    yield put(problemCategoryActions.search({ name }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteProblemCategory(param: DeleteParams) {
  try {
    const id = param.payload.id;

    yield call(settingApi.removeProblemCategory, id);
    yield put(problemCategoryActions.deleteProblemCategorySuccess());
    const name: string = yield select(selectProblemCategoryName);
    yield put(problemCategoryActions.search({ name }));
  } catch (error: any) {
    yield put(problemCategoryActions.deleteProblemCategoryFailed());
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
    console.log('Failed to delete Category', error);
  }
}

export default function* problemCategorySaga() {
  yield takeLatest(problemCategoryActions.fetchProblemCategoryList.type, fetchProblemCategoryList);
  yield takeEvery(problemCategoryActions.createProblemCategory.type, createProblemCategory);
  yield takeEvery(problemCategoryActions.updateProblemCategory.type, updateProblemCategory);
  yield takeLatest(problemCategoryActions.deleteProblemCategory.type, deleteProblemCategory);
  yield takeLatest(problemCategoryActions.changePage.type, changePage);
  yield takeLatest(problemCategoryActions.search.type, search);
}
