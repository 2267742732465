import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ImageCustom } from 'components/common/ImageCustom';
import { SiteAttendance as SiteAttendanceModel, Ticket } from 'models';
import React, { useEffect, useState } from 'react';
import { getUTC8DateTimeFormat } from 'utils/function';

interface DataType {
  checkInImage?: string;
  checkInTime?: string;
  checkOutImage?: string;
  checkOutTime?: string;
  username?: string;
}

export const SiteAttendance: React.FC<{
  ticket: Ticket | undefined;
}> = ({ ticket }) => {
  const [attendanceData, setAttendanceData] = useState<DataType[]>([]);
  useEffect(() => {
    if (ticket?.site_attendance) {
      const info: DataType[] = [];
      const obj = ticket?.site_attendance;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: SiteAttendanceModel = obj[key] as SiteAttendanceModel;

        info.push({
          checkInImage: obj2.checkInImage || '',
          checkInTime: obj2.checkInTime || '',
          checkOutImage: obj2.checkOutImage || '',
          checkOutTime: obj2.checkOutTime || '',
          username: obj2.username || '',
        });
      });
      setAttendanceData(info);
    }
  }, [ticket?.site_attendance]);

  const columnsAttendance: ColumnsType<DataType> = [
    {
      title: 'Check In',
      dataIndex: 'checkInImage',
      key: 'checkInImage',
      render: (_: unknown, record: DataType) => {
        return (
          <ImageCustom
            className='user-avatar'
            type='link'
            url={record.checkInImage ? `${record.checkInImage}` : ''}
          />
        );
      },
    },
    {
      title: 'Check In Time',
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      render: (_: unknown, record: DataType) => {
        return getUTC8DateTimeFormat(record.checkInTime || '');
      },
    },
    {
      title: 'Check Out',
      dataIndex: 'checkOutImage',
      key: 'checkOutImage',
      render: (_: unknown, record: DataType) => {
        return (
          <ImageCustom
            className='user-avatar'
            type='link'
            url={record.checkOutImage ? `${record.checkOutImage}` : ''}
          />
        );
      },
    },
    {
      title: 'Check Out Time',
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      render: (_: unknown, record: DataType) => {
        return getUTC8DateTimeFormat(record.checkOutTime || '');
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
  ];

  return (
    <div className='form-wrapper'>
      <Table
        pagination={false}
        dataSource={attendanceData}
        columns={columnsAttendance}
        scroll={{ x: 800 }}
      />
    </div>
  );
};
