import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SCIMMS_API } from 'utils/constants';
import TokenService from 'utils/token.service';
const axiosClient = axios.create({
  baseURL: process.env.SCIM_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const token = TokenService.getLocalAccessToken();
    if (
      token &&
      config.headers &&
      config.headers['Authorization'].toString().split('Bearer ')[1] === 'null'
    ) {
      config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
    }
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  async (err: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/signin' && err.response) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        originalConfig.url.indexOf('auth/login') === -1
      ) {
        if (originalConfig.url.indexOf('auth/refresh') > -1) window.location.href = '/login';
        originalConfig._retry = true;

        try {
          const rs: RefreshTokenResponse = await axiosClient.post(
            `${SCIMMS_API}/auth/refresh`,
            {
              refreshToken: TokenService.getLocalRefreshToken(),
            },
            {
              headers: {
                Authorization: 'Bearer ' + TokenService.getLocalRefreshToken(),
              },
            },
          );

          TokenService.updateLocalAccessToken(rs.accessToken);
          TokenService.updateLocalRefreshToken(rs.refreshToken);
          originalConfig.headers = {
            Authorization: 'Bearer ' + TokenService.getLocalRefreshToken(),
          };
          return axiosClient(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(err);
  },
);

export default axiosClient;
