import React from 'react';
import OtherRegionsFilterForm from './OtherRegionsFilterForm';
import TableData from './TableData';

const MaintenanceOtherRegionsPage: React.FC = () => {
  return (
    <div className='user-wrapper'>
      <div className='user-content'>
        <OtherRegionsFilterForm />
        <TableData />
      </div>
    </div>
  );
};

export default MaintenanceOtherRegionsPage;
