import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CommissionRegion } from 'models';
import React, { useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

export const ChargingFunction: React.FC<{
  nextStep: any;
  prevStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const charging_function = [
    { no: 1, name: 'Charging via APP', tile: 'Start and stop charging via APP', value: '1' },
    {
      no: 2,
      name: 'Charging via swiping the RFID card',
      tile: 'Start and stop charging via swiping the RFID card',
      value: '1',
    },
    {
      no: 3,
      name: 'Charging via swiping the credit card',
      tile: 'Start and stop charging via swiping credit card',
      value: '1',
    },
    {
      no: 4,
      name: 'Door sensor',
      tile: 'EVSE should stop charging if the door is open.',
      value: '1',
    },
    {
      no: 5,
      name: 'Tilt sensor',
      tile: 'Tilt the tilt sensor inside of the EVSE when charging, the EVSE should stop charging.',
      value: '1',
    },
    {
      no: 6,
      name: 'Emergency stop',
      tile: 'When emergency stop button is pressed, the EVSE should stop.',
      value: '1',
    },
    { no: 7, name: 'Fan', tile: 'The fan can work normally.', value: '1' },
    {
      no: 8,
      name: 'Meter',
      tile: 'The meter could calculate during the charging process.',
      value: '1',
    },
    {
      no: 9,
      name: 'Charging cable',
      tile: 'The surface of charging cable is normal. Plug in and out smoothly.',
      value: '1',
    },
    {
      no: 10,
      name: 'PDUs and relays',
      tile: 'Relays should be open or closed normally.',
      value: '1',
    },
  ];

  useEffect(() => {
    if (inspOtherRegion) {
      const charging_function = inspOtherRegion.charging_function?.map((item) => {
        return {
          [`charging_function_${item.no}`]: item.value,
          [`charging_function_note_${item.no}`]: item.remark,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(charging_function),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = (values: any) => {
    const inspectForm = form.getFieldsValue();
    const charging_function = [...(inspOtherRegion?.charging_function || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['charging_function_' + item.no],
        remark: inspectForm['charging_function_note_' + item.no],
      };
    });

    nextStep({ ...inspOtherRegion, charging_function });
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(inspOtherRegion?.charging_function || []).map((item, idx) => {
          const charging_function_data = charging_function.find((data) => data.no === +item.no);
          return (
            <>
              <Form.Item
                className='annexfield'
                label={
                  idx +
                  1 +
                  '. ' +
                  charging_function_data?.name +
                  ': ' +
                  charging_function_data?.tile
                }
                key={item.no}
                initialValue={item.value}
                name={'charging_function_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.name}
                key={item.no + '_note'}
                initialValue={item.remark}
                name={'charging_function_note_' + item.no}
              >
                <Input
                  disabled={disabled}
                  className='input-remarks'
                  placeholder='Remarks/measurement value'
                />
              </Form.Item>
            </>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
