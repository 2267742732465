import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ChargerModel } from 'models';
import { FlagService } from 'models/enum/chargerModel';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SCIMMS_API } from 'utils/constants';
import { getFileName } from 'utils/function';
import TokenService from 'utils/token.service';
import { chargerModelActions, selectChargeModelLoad } from '../chargerModelSlice';

const ModalActionChargerModel: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const chargerModel = useAppSelector(
    (state) => state.chargerModel.list.filter((item: ChargerModel) => item.id === id)[0],
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const token = TokenService.getLocalAccessToken();
  useEffect(() => {
    console.log(chargerModel);
    form.setFieldsValue({
      name: chargerModel?.name || '',
      icon: chargerModel?.icon || '',
      code: chargerModel?.code || '',
      flag_service: chargerModel?.flag_service || FlagService.TI,
    });
    if (chargerModel?.icon) {
      setFileList([
        {
          uid: chargerModel?.icon,
          name: chargerModel?.icon,
          url: chargerModel?.icon,
        },
      ]);
    }
  }, [chargerModel, form]);
  useEffect(() => {
    if (id !== '') dispatch(chargerModelActions.fetchChargerModelDetail({ id }));
  }, [id, dispatch]);

  const loaded = useAppSelector(selectChargeModelLoad);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
      icon: '',
      code: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      const idRandom = Math.random().toString(36).substring(2);
      setRandomState(idRandom);
      if (uid === id) {
        notification.success({
          message: 'Charger model updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else if (id !== '') {
        notification.success({
          message: 'Charger model updated successfully',
          placement: 'bottomRight',
          // onClose: () => navigate("/settings/charger-model"),
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else {
        notification.success({
          message: 'Charger model record created successfully',
          placement: 'bottomRight',
          //onClose: () => resetForm(),
          onClose: () => {
            resetForm();
            // navigate("/settings/charger-model");
          },
          duration: 2.5,
        });
        setIsModalVisible(false);
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const chargerModel: ChargerModel = form.getFieldsValue();
    if (id !== '') {
      // const filename = getFileName(chargerModel.icon || "");
      dispatch(
        chargerModelActions.updateChargerModel({
          ...chargerModel,
          icon: chargerModel.icon,
          id,
        }),
      );
    } else
      dispatch(
        chargerModelActions.createChargerModel({
          ...chargerModel,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const props: UploadProps = {
    name: 'file',
    maxCount: 1,
    fileList: fileList,
    action: `${SCIMMS_API}/upload/charger-icon`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    onChange(info) {
      setFileList(info.fileList);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        form.setFieldValue('icon', info.file.response.url);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file) {
      setFileList([]);
      form.setFieldValue('icon', '');
    },
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{id !== '' ? <div>Update Charger Model</div> : <div>Create Charger Model</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Charger Model Name'
          name='name'
          rules={[{ required: true, message: 'Please input Charger Model Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Flag Service'
          name='flag_service'
          rules={[{ required: true, message: 'Please input Code!' }]}
        >
          <Select>
            <Select.Option value={'TI'}>Ticket</Select.Option>
            <Select.Option value={'CM'}>Inspection</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Code'
          name='code'
          rules={[{ required: true, message: 'Please input Code!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Icon'
          valuePropName='file'
          name='icon'
          rules={[{ required: true, message: 'Please attach icon file!' }]}
        >
          <Upload {...props} listType='picture-card'>
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionChargerModel;
