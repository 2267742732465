import { Button, Form, Input, Modal, Select, UploadFile } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Asset, ConnectorsResponse } from 'models';
import React, { useEffect, useState } from 'react';
import { fetchImage } from 'utils/function';

const ModalConnector: React.FC<{
  data: ConnectorsResponse;
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  onFinish: any;
}> = ({ data, isModalVisible, handleOk, handleCancel, setIsModalVisible, onFinish }) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [paths, setPaths] = useState<Asset[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([...fileList, list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: resUrl?.link || '',
      };
    };
    if (fileUpload) {
      setPaths([...paths, { url: fileUpload }]);
      const asset = getImage({ thumb: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (isModalVisible) {
      console.log(data);
      form.setFieldsValue({
        connector_ID: data?.connector_ID || '',
        connector_type: form.getFieldValue('connector_type'),
        cable_length: data?.cable_length || '',
      });
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    onFinish({
      connector_ID: form.getFieldValue('connector_ID'),
      connector_type: form.getFieldValue('connector_type'),
      cable_length: form.getFieldValue('cable_length'),
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeItem = (evt: any) => {
    setDisabledSubmit(false);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{data ? <div>Update Connector</div> : <div>Add Connector</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Connector ID'
          name='connector_ID'
          rules={[{ required: true, message: 'Please input Connector ID!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='Connector Type'
          name='connector_type'
          rules={[{ required: true, message: 'Please input Connector Type!' }]}
        >
          <Select onChange={handleChangeItem}>
            <Select.Option value='CCS1'>CCS1</Select.Option>
            <Select.Option value='CCS2'>CCS2</Select.Option>
            <Select.Option value='CHAdeMO'>CHAdeMO</Select.Option>
            <Select.Option value='GB/T'>GB/T</Select.Option>
            <Select.Option value='Type 1'>Type 1</Select.Option>
            <Select.Option value='Type 2'>Type 2</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Charging cable length (m)'
          name='cable_length'
          rules={[{ required: true, message: 'Please input Charging cable length!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalConnector;
