import { ExportOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, notification, Row, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { Certification } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { exportExcel } from 'utils/function';
import ModalSuspend from './ModalSuspend';
import {
  selectDeleting,
  selectedIdsExcel,
  selectedCertificationIdsExcel,
  selectPagination,
  selectCertificationLoading,
  certificationActions,
} from '../certificationSlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: Certification;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idSuspend, setIdSuspend] = useState('');

  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state) =>
      state.certification?.list?.map((item) => {
        return { key: item.id, ...item };
      }) || [],
  );
  const selectedIdsExcels = useAppSelector(selectedIdsExcel);
  const selectedCertificationIdsExcels = useAppSelector(selectedCertificationIdsExcel);

  const loading = useAppSelector(selectCertificationLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selectedIdsExcels);
  }, [selectedIdsExcels]);

  useEffect(() => {
    dispatch(certificationActions.unSelectedIdExport());
    dispatch(certificationActions.unSelectedCertificationIdExport());
    dispatch(
      certificationActions.search({
        filter: {},
        pagination: { pageNo: 1, size: pagination.size },
      }),
    );
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Certification record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  const columns: ColumnsType<Certification> = [
    {
      title: 'Certificate',
      dataIndex: 'certificate',
      key: 'certificate',
      fixed: 'left',
      render: (_: unknown, record: Certification) => {
        return record.training.certificate.name;
      },
    },
    {
      title: 'Charger Model',
      dataIndex: 'charger_model',
      key: 'charger_model',
      render: (_: unknown, record: Certification) => {
        return record.training.charger_model.name;
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_: unknown, record: Certification) => {
        return record.trainee.user.username;
      },
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_: unknown, record: Certification) => {
        return record.trainee.user.company?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: unknown, record: Certification) => {
        return record.status;
      },
    },
    {
      title: 'Training Date',
      dataIndex: 'training_date',
      key: 'training_date',
      render: (_: unknown, record: Certification) => {
        return record.training.training_date
          ? format(new Date(record.training.training_date), 'yyyy-MM-dd')
          : '';
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (_: unknown, record: Certification) => {
        return record.training.expiry_date
          ? format(new Date(record.training.expiry_date), 'yyyy-MM-dd')
          : '';
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'Remarks',
      key: 'Remarks',
      render: (_: unknown, record: Certification) => {
        return record.Remarks;
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_: unknown, record: Certification) => {
        return record.createdBy;
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (_: unknown, record: Certification) => {
        return record.createdAt ? format(new Date(record.createdAt), 'yyyy-MM-dd') : '';
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      render: (_: unknown, record: Certification) => {
        return record.updatedBy;
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_date',
      key: 'updated_date',
      render: (_: unknown, record: Certification) => {
        return record.updatedAt ? format(new Date(record.updatedAt), 'yyyy-MM-dd') : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (_: unknown, record: Certification) => {
        return (
          <>
            <Space size='middle'>
              <span
                onClick={() => {
                  setIsModalVisible(true);
                  setIdSuspend(record.id);
                }}
                style={{ color: '#e98d3e', cursor: 'pointer' }}
              >
                Suspend
              </span>
            </Space>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Certification[]) => {
    if (newSelectedRowKeys.length) {
      dispatch(certificationActions.selectedIdExport({ keys: newSelectedRowKeys }));
      const certificationIDs = selectedRows.map((row: Certification) => row.id);
      dispatch(
        certificationActions.selectedCertificationIdExport({ certificationIds: certificationIDs }),
      );
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      dispatch(certificationActions.unSelectedIdExport());
      dispatch(certificationActions.unSelectedCertificationIdExport());
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number, size: number) => {
    dispatch(certificationActions.changePage({ pageNo: evt, size }));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const handleExportExcels = () => {
    exportExcel(
      '/training-user/export/by-ids',
      { ids: selectedCertificationIdsExcels },
      'certification_list.xlsx',
    );
  };

  const handleExportAllExcels = () => {
    exportExcel('/training-user/export/by-ids', { ids: [] }, 'certification_list.xlsx');
  };

  return (
    <Form form={form} component={false}>
      <Row gutter={16} className='btn-group-export' justify='end'>
        <Col className='gutter-row' span={4}>
          <Button
            disabled={selectedCertificationIdsExcels.length == 0}
            onClick={handleExportExcels}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export Selected To Excel
          </Button>
        </Col>
        <Col className='gutter-row' span={4}>
          <Button
            onClick={handleExportAllExcels}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export All To Excel
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: true,
          total: pagination.totalRecords,
          onChange: handleChangePage,
          showTotal: showTotal,
          className: 'vpbs-pagination',
        }}
        scroll={{ x: 1700 }}
      />
      <ModalSuspend
        id={idSuspend}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </Form>
  );
};

export default TableData;
