import { Image, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SCIMMS_API } from 'utils/constants';
import TokenService from 'utils/token.service';

function fetchVideo(url: string) {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
    });
    const lastIndex = url.lastIndexOf('/');
    const folder = url.substring(0, lastIndex);
    const filename = url.substring(lastIndex + 1);
    const urlFetch = `${SCIMMS_API}/upload/signedurl-view?folder=${folder}&filename=${filename}`;
    // Make the request and wait for the response
    window.fetch(urlFetch, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
    });
  });
}

export const MediaCustom: React.FC<{
  url: string;
  isModalVisible: boolean;
  handleCancel: () => void;
  width?: string;
}> = ({ url, isModalVisible, handleCancel, width }) => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const [mediaUrl, setMediaUrl] = useState('');

  useEffect(() => {
    if (url) {
      fetchVideo(url).then((res: any) => {
        setMediaUrl(res.link || '');
        if (isModalVisible) {
          vidRef.current?.load();
        }
      });
    }
  }, [url]);

  return (
    <Modal
      visible={isModalVisible}
      style={{ height: 650 }}
      onCancel={handleCancel}
      className='video-modal'
      footer={null}
    >
      {['mp4', 'MOV', 'mov'].some((char) => url.endsWith(char)) ? (
        <video width={width ? width : '520'} ref={vidRef} controls>
          <source src={mediaUrl} type='video/mp4' />
        </video>
      ) : (
        <Image src={mediaUrl} alt='avatar' preview={false} />
      )}
    </Modal>
  );
};
