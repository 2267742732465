import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { ImageCustom } from 'components/common/ImageCustom';
import React from 'react';

interface HeaderUserProps {
  avatar?: string;
  name: string;
}
export const HeaderUser: React.FC<HeaderUserProps> = ({ avatar, name }) => {
  return (
    <div className='header-user-info'>
      {avatar ? (
        <ImageCustom className='user-avatar' type='link' url={`${avatar}` || ''} />
      ) : (
        <Avatar className='header-user__avatar' icon={<UserOutlined />} />
      )}

      <div className='header-user__name'>{name}</div>
    </div>
  );
};
