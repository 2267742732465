import { Steps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from 'features/auth/authSlice';
import { Training } from 'models';
import React, { useEffect, useState } from 'react';
import GeneralInfo from './generalInfo';
import { SubmitForm } from './submitForm';
import { TrainingContents } from './trainingContent';

export const StepTrainingForm: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [current, setCurrent] = useState(0);
  const [hasChange, setHasChange] = useState(false);
  const [data, setData] = useState<Training>();
  const dispatch = useAppDispatch();

  const training = useAppSelector(
    (state) => state.training?.list?.filter((item: Training) => item.id === id)[0],
  );

  useEffect(() => {
    if (data) {
      setHasChange(JSON.stringify(data) === JSON.stringify(training));
    }
  }, [data, training]);

  useEffect(() => {
    if (training) {
      setData(training);
      // Get list trainees
      dispatch(authActions.fetchTraineeList({ _limit: 9999 }));
    }
  }, [training, dispatch]);

  const handleChange = (trainingData: Training) => {
    setData({ ...data, ...trainingData });
  };

  const next = (datas: Training) => {
    setCurrent(current + 1);
    setData({ ...datas });
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: 'General Info',
      content: <GeneralInfo nextStep={next} training={data} />,
    },
    {
      title: 'Training Content',
      content: (
        <TrainingContents
          nextStep={next}
          prevStep={prev}
          training={data}
          handleChange={handleChange}
        />
      ),
    },
    {
      title: 'Trainee',
      content: (
        <SubmitForm
          hasChange={hasChange}
          prevStep={prev}
          training={data}
          handleChange={handleChange}
        />
      ),
    },
  ];
  return (
    <div className='step-form'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
};
