import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const RadioValue = {
  C: '0',
  NC: '1',
  NA: '2',
};

const internal_cc_tpl = [
  {
    no: 1,
    Content:
      'Charging cabinet, hinge and locks of are functioning normally. The inner components of the cabinet should be free of liquid stains and leakages (from coolants or condensation), rust as well as no signs of physical damages. ',
    Method: 'Visual / Manual',
  },
  {
    no: 2,
    Content: 'Input and communication terminals are tightened and free from signs of burns.',
    Method: 'Visual / Manual',
  },
  {
    no: 3,
    Content: 'PE wire of the cabinet is reliable with no signs of damage and rust.',
    Method: 'Visual / Manual',
  },
  {
    no: 4,
    Content:
      'L1, L2, L3, N, and PE are clearly marked, and the wiring is reliable and in good contact.',
    Method: 'Visual / Manual',
  },
  {
    no: 5,
    Content: 'Measure three-phase input voltage.',
    Method: 'Measurement',
  },
  {
    no: 6,
    Content: 'No short circuit is present between the DC+ and DC- of the input of charging cable.',
    Method: 'Measurement',
  },
  {
    no: 7,
    Content: 'No short circuit is present between the DC+ and DC- of the output of charging cable.',
    Method: 'Measurement',
  },
  {
    no: 8,
    Content:
      'No open circuit is present between the DC+ of the input of charging cable and DC+ of the output of charging cable.',
    Method: 'Measurement',
  },
  {
    no: 9,
    Content:
      'No open circuit is present between the DC- of the input of charging cable and DC- of the output of charging cable.',
    Method: 'Measurement',
  },
  {
    no: 10,
    Content:
      'There should be no reverse connection in DC+/DC- of the input/output of charging cable.',
    Method: 'Measurement / Visual',
  },
  {
    no: 11,
    Content: 'Ensure fireproof material at the bottom is sealed and intact.',
    Method: 'Visual',
  },
  {
    no: 12,
    Content:
      'Ensure the wiring connections of the AC input main circuit and their screws are tightened properly (such as AC input terminal connector, MCCB, AC contactor, leakage circuit breaker, junction box or terminal, PE wiring and its screws, etc.). If any screw or connection is found loose, a screwdriver must be used to tighten it.',
    Method: 'Visual / Manual / Measurement',
  },
  {
    no: 13,
    Content:
      'Ensure screw and wire connections for DC output circuit, and circuit contact points of various components are tight and in contact (such as power module, PDU output copper wire, DC circuit relay, fuse, charging gun DC terminal, PE wiring, copper plate screw, etc.). If any screw or connection is found loose, a screwdriver must be used to tighten it.',
    Method: 'Visual / Manual / Measurement',
  },
  {
    no: 14,
    Content:
      'Ensure the cotton filter is free of accumulation of particulates and foreign material, and not damaged. Else, replace it.',
    Method: 'Visual',
  },
  {
    no: 15,
    Content: 'Ensure the power module fans are clean and firmly screwed on.',
    Method: 'Visual',
  },
];

const Step5: React.FC<{
  nextStep: any;
  prevStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  useEffect(() => {
    if (data) {
      const isDisabledRemarkData: { [key: string]: boolean } = {};
      const internal_cc = data.internal_cc?.map((item) => {
        const fieldName = `internal_cc_note_${item.no}`;
        isDisabledRemarkData[fieldName] = isRequiredRemark(`${item.no}`, item.value);
        return {
          [`internal_cc_${item.no}`]: item.value,
          [`internal_cc_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(internal_cc),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const isRequiredRemark = (itemNo: string, value: string) => {
    return ['99'].includes(itemNo) && [RadioValue.C, RadioValue.NC].includes(value);
  };

  const handleNext = () => {
    const inspectForm = form.getFieldsValue();
    const internal_cc = [...(data?.internal_cc || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['internal_cc_' + item.no],
        remarks: inspectForm['internal_cc_note_' + item.no],
      };
    });

    nextStep({ ...data, internal_cc });
  };

  return (
    <div className='form-wrapper'>
      <h3>Internal Cabinet Check</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.internal_cc || []).map((item, idx) => {
          const check_after_data = internal_cc_tpl.find((data) => data.no === +item.no);
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + check_after_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'internal_cc_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'internal_cc_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Remarks' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            // onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step5;
