import { Button, Form, Image, Input, InputNumber, Modal, notification, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { ChargerModel } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { formatYYYYMMDD } from 'utils/function';
import TokenService from 'utils/token.service';
import {
  chargerModelActions,
  selectChargerModelLoading,
  selectDeleted,
  selectDeleting,
  selectError,
  selectPagination,
} from '../chargerModelSlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: ChargerModel;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC<{
  setIsModalVisible: any;
  setIdEdit: any;
  randomState: any;
}> = ({ setIsModalVisible, setIdEdit, randomState }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.chargerModel.list);
  const pagination = useAppSelector(selectPagination);
  const [idDelete, setIdDelete] = useState('');
  const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
  const loading = useAppSelector(selectChargerModelLoading);
  const deleting = useAppSelector(selectDeleting);
  const deleted = useAppSelector(selectDeleted);
  const hasError = useAppSelector(selectError);

  const isSystemAdmin = TokenService.isSystemAdmin();

  useEffect(() => {
    dispatch(chargerModelActions.changePage({ pageNo: 1, size: 10 }));
    dispatch(chargerModelActions.search({ name: '' }));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (hasError) {
      notification.error({
        message:
          'This name is used in some tickets, Please delete the corresponding tickets before deleting this country.',
        placement: 'bottomRight',
        duration: 2.5,
      });
      setIsModalVisibleDelete(false);
    } else {
      notification.success({
        message: 'Company record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [hasError]);
  useEffect(() => {
    if (deleted && isModalVisibleDelete) {
      openNotification();
    }
  }, [deleted, openNotification, isModalVisibleDelete]);

  useEffect(() => {
    if (loading) {
      setIsModalVisibleDelete(false);
    }
  }, [loading]);

  const columns = [
    {
      title: 'No',
      width: '5%',
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: '10%',
      editable: true,
      render: (_: unknown, record: ChargerModel) => {
        return <Image className='user-avatar' src={record.icon || ''} />;
      },
    },
    {
      title: 'Charger Model Name',
      dataIndex: 'name',
      width: '10%',
      editable: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      width: '15%',
      render: (_: unknown, record: ChargerModel) => {
        return formatYYYYMMDD(record.createdAt || '');
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      width: '15%',
      render: (_: unknown, record: ChargerModel) => {
        return formatYYYYMMDD(record.updatedAt || '');
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: '15%',
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      width: '15%',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '15%',
      render: (_: unknown, record: ChargerModel) => {
        return (
          <>
            {/*<Link to={`/company/edit/${record.id}`} style={{ color: "#e98d3e" }}>
                        Edit
                    </Link>*/}
            <span
              onClick={() => {
                setIsModalVisible(true);
                setIdEdit(record.id);
              }}
              style={{ color: '#e98d3e', cursor: 'pointer' }}
            >
              Edit
            </span>{' '}
            {isSystemAdmin && (
              <>
                |{' '}
                <span
                  onClick={() => {
                    setIsModalVisibleDelete(true);
                    setIdDelete(record.id);
                  }}
                  style={{ color: '#e98d3e', cursor: 'pointer' }}
                >
                  Delete
                </span>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleOk = () => {
    dispatch(chargerModelActions.deleteChargerModel({ id: idDelete as string }));
  };
  const handleCancel = () => {
    setIsModalVisibleDelete(false);
  };

  const handleChangePage = (evt: number, size: number) => {
    dispatch(chargerModelActions.changePage({ pageNo: evt, size }));
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName='editable-row'
          pagination={{
            onChange: handleChangePage,
            pageSize: pagination.size,
            total: pagination.totalRecords,
          }}
        />
      </Form>
      <Modal
        visible={isModalVisibleDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete charger model?</p>
      </Modal>
    </>
  );
};

export default TableData;
