import { DownOutlined, ExportOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { chargerModelActions } from 'features/settings/charger-model/chargerModelSlice';
import { SiteAttendance, Ticket } from 'models';
import { TicketStatus } from 'models/enum/ticket';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { durationByHours, exportExcel, isMine } from 'utils/function';
import TokenService from 'utils/token.service';
import {
  selectDeleting,
  selectedIdsExcel,
  selectedTicketIdsExcel,
  selectPagination,
  selectTicketLoading,
  ticketActions,
} from '../ticketSlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: Ticket;
  index: number;
  children: React.ReactNode;
}

const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};

const toStringArrayTicket = (ticket: Ticket) => {
  return {
    ...ticket,
    site_attendance: JSON.stringify(ticket.site_attendance),
    root_cause: JSON.stringify(ticket.root_cause),
    interim_containment: JSON.stringify(ticket.interim_containment),
    permanent_solution: JSON.stringify(ticket.permanent_solution),
    spare_part_used: JSON.stringify(ticket.spare_part_used),
    problem_description_photo: JSON.stringify(ticket.problem_description_photo),
  };
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const calculateOnsiteDuration = (data: SiteAttendance[]) => {
  if (!data.length) return '';
  const sum = data.reduce((a, b) => {
    return a + +durationByHours(b.checkInTime, b.checkOutTime);
  }, 0);
  return sum ? sum : '';
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state) =>
      state.ticket?.list?.map((item) => {
        return { key: item.id, ...item };
      }) || [],
  );
  const selectedIdsExcels = useAppSelector(selectedIdsExcel);
  const selectedTicketIdsExcels = useAppSelector(selectedTicketIdsExcel);

  const isSystemAdmin = TokenService.isSystemAdmin();

  const loading = useAppSelector(selectTicketLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [ids, setIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selectedIdsExcels);
  }, [selectedIdsExcels]);

  useEffect(() => {
    dispatch(ticketActions.unSelectedIdExport());
    dispatch(ticketActions.unSelectedTicketIdExport());
    dispatch(
      ticketActions.search({
        filter: {},
        pagination: { pageNo: 1, size: pagination.size },
      }),
    );
    dispatch(chargerModelActions.fetchChargerModelList({}));
  }, [dispatch]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Ticket record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  useEffect(() => {
    if (loading) {
      setIsModalVisible(false);
    }
  }, [loading]);

  const handleAcceptDeclineAction = (item: Ticket, flag: boolean) => {
    let ticket = toStringArrayTicket(item);
    if (flag) {
      ticket = { ...ticket, service_engineer: TokenService.getUser().username };
    }
    dispatch(
      ticketActions.close({
        ...ticket,
        assigned_engineer: null,
      }),
    );
  };
  const handleCloseAction = (item: Ticket) => {
    const closedDate = moment(new Date()).toDate();
    const ticket = toStringArrayTicket(item);
    dispatch(
      ticketActions.close({
        ...ticket,
        status: TicketStatus.Closed,
        closed_date: closedDate.toString(),
      }),
    );
  };

  const columns: ColumnsType<Ticket> = [
    {
      title: 'Ticket ID',
      dataIndex: 'ticket_id',
      key: 'ticket_id',
      fixed: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: unknown, record: Ticket) => {
        return record.status === TicketStatus.Open ? 'Open' : 'Closed';
      },
    },
    {
      title: 'Ticket Name',
      dataIndex: 'ticket_name',
      key: 'ticket_name',
      render: (_: unknown, record: Ticket) => {
        return <div className='text-ellipsis-2-line'>{record.ticket_name}</div>;
      },
    },
    {
      title: 'Serial Number',
      dataIndex: 'sn_number',
      key: 'sn_number',
      render: (_: unknown, record: Ticket) => {
        return record.serial_number.name;
      },
    },
    {
      title: 'Charger Model',
      dataIndex: 'charger_model',
      key: 'charger_model',
      render: (_: unknown, record: Ticket) => {
        return record.charger_model.name;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      render: (_: unknown, record: Ticket) => {
        return record.country.name;
      },
    },
    {
      title: 'Province',
      dataIndex: 'province_name',
      key: 'province_name',
      render: (_: unknown, record: Ticket) => {
        return record.province.name;
      },
    },
    {
      title: 'Charger Owner',
      dataIndex: 'charger_owner',
      key: 'charger_owner',
      render: (_: unknown, record: Ticket) => {
        return record.charger_owner.name;
      },
    },
    {
      title: 'Call Center',
      dataIndex: 'call_center',
      key: 'call_center',
    },
    {
      title: 'Root Cause Category',
      dataIndex: 'root_cause',
      key: 'root_cause',
      width: '99px',
      render: (_: unknown, record: Ticket) => {
        const rootCauseCategory = record.root_cause.map((item) => item.category?.name);
        const categories = new Set(rootCauseCategory);
        return Array.from(categories).join(',');
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (_: unknown, record: Ticket) => {
        return record.createdAt ? format(new Date(record.createdAt), 'yyyy-MM-dd') : '';
      },
    },
    {
      title: 'Closed Date',
      dataIndex: 'closed_date',
      key: 'closed_date',
      render: (_: unknown, record: Ticket) => {
        return record.closed_date ? record.closed_date.split('T')[0] : '';
      },
    },
    {
      title: 'Time to Solution (hours)',
      dataIndex: 'time_to_solution',
      key: 'time_to_solution',
      render: (_: unknown, record: Ticket) => {
        return record.closed_date ? durationByHours(record.createdAt, record.closed_date) : '';
      },
    },
    {
      title: 'OnSite Duration (hours)',
      dataIndex: 'onsite_duration',
      key: 'onsite_duration',
      render: (_: unknown, record: Ticket) => {
        return calculateOnsiteDuration(record.site_attendance || []);
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (_: unknown, record: Ticket) => {
        return (
          <>
            <Space size='middle'>
              {isSystemAdmin || (isOpen(record.status) && isMine(record.service_engineer)) ? (
                <>
                  <Link to={`/ticket/edit/${record.id}`}>Edit</Link> |
                </>
              ) : (
                <>
                  <Link to={`/ticket/edit/${record.id}`}>View</Link> |
                </>
              )}
              <Dropdown
                overlay={
                  <div className='ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light'>
                    {isOpen(record.status) &&
                      !isMine(record.service_engineer) &&
                      isMine(record.assigned_engineer) && (
                        <>
                          <div
                            className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                            onClick={() => {
                              handleAcceptDeclineAction(record, true);
                            }}
                            style={{ color: '#e98d3e', cursor: 'pointer' }}
                          >
                            Accept
                          </div>
                          <div
                            className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                            onClick={() => {
                              handleAcceptDeclineAction(record, false);
                            }}
                            style={{ color: '#e98d3e', cursor: 'pointer' }}
                          >
                            Decline
                          </div>
                        </>
                      )}
                    {isOpen(record.status) &&
                      (isSystemAdmin || isMine(record.service_engineer)) && (
                        <div
                          className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                          onClick={() => {
                            handleCloseAction(record);
                          }}
                          style={{ color: '#e98d3e', cursor: 'pointer' }}
                        >
                          Close
                        </div>
                      )}
                    {isSystemAdmin && (
                      <div
                        className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                        onClick={() => {
                          setIsModalVisible(true);
                          setIdDelete(record.id);
                        }}
                        style={{ color: '#e98d3e', cursor: 'pointer' }}
                      >
                        Delete
                      </div>
                    )}
                    <div
                      className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                      onClick={() => {
                        exportExcelRow(record.ticket_id);
                      }}
                      style={{ color: '#e98d3e', cursor: 'pointer' }}
                    >
                      Export Excel
                    </div>
                  </div>
                }
              >
                <a>
                  More <DownOutlined />
                </a>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Ticket[]) => {
    if (newSelectedRowKeys.length) {
      dispatch(ticketActions.selectedIdExport({ keys: newSelectedRowKeys }));
      const ticketIDs = selectedRows.map((row: Ticket) => row.ticket_id);
      setIds(ticketIDs);
      dispatch(ticketActions.selectedTicketIdExport({ ticketIds: ticketIDs }));
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      dispatch(ticketActions.unSelectedIdExport());
      dispatch(ticketActions.unSelectedTicketIdExport());
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number, size: number) => {
    dispatch(ticketActions.changePage({ pageNo: evt, size }));
  };

  const handleOk = () => {
    dispatch(ticketActions.deleteTicket({ id: idDelete as string }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  // Export Excel
  const handleExportExcels = () => {
    exportExcel('/ticket/export/by-ids', { ids: selectedTicketIdsExcels }, 'ticket_list.xlsx');
  };

  const exportExcelRow = (ticketId: string) => {
    exportExcel('/ticket/export/by-ids', { ids: [ticketId] }, 'ticket_list.xlsx');
  };

  const handleExportExcelsAll = () => {
    exportExcel('/ticket/export/by-ids', { ids: [] }, `ticket_all.xlsx`);
  };
  return (
    <Form form={form} component={false}>
      <Row gutter={16} className='btn-group-export' justify='end'>
        <Col className='gutter-row' span={4}>
          <Button
            disabled={!ids.length}
            onClick={handleExportExcels}
            type='primary'
            icon={<ExportOutlined />}
            block
          >
            Export Excel
          </Button>
        </Col>
        <Col className='gutter-row' span={4}>
          <Button
            onClick={handleExportExcelsAll}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export All To Excel
          </Button>
        </Col>
      </Row>

      {/*{isSystemAdmin && (
                <div className="user-content-add">
                    <Button
                        type="primary"
                        icon={<DeleteOutlined/>}
                        disabled={selectedRowKeys.length === 0}
                        onClick={handleDeleteTicket}

                    >
                        Delete
                    </Button>
                </div>
            )}*/}
      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        rowSelection={rowSelection}
        /*pagination={{
                    total: pagination.totalRecords,
                    onChange: handleChangePage,
                }}*/
        pagination={{
          showSizeChanger: true,
          total: pagination.totalRecords,
          onChange: handleChangePage,
          showTotal: showTotal,
          className: 'vpbs-pagination',
        }}
        scroll={{ x: 1700 }}
      />
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete ticket?</p>
      </Modal>
    </Form>
  );
};

export default TableData;
