import { Steps } from 'antd';
import { useAppSelector } from 'app/hooks';
import { MaintenanceRegion } from 'models';
import React, { useEffect, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';

export const StepForm: React.FC<{ id: string | undefined }> = () => {
  const [current, setCurrent] = useState(0);
  const [hasChange, setHasChange] = useState(false);
  const [data, setData] = useState<MaintenanceRegion>();
  const maintenanceOtherRegion = useAppSelector((state) => state.maintenanceOtherRegion.detail);

  useEffect(() => {
    if (data) {
      setHasChange(JSON.stringify(data) === JSON.stringify(maintenanceOtherRegion));
    }
  }, [data, maintenanceOtherRegion]);

  useEffect(() => {
    if (maintenanceOtherRegion) {
      setData(maintenanceOtherRegion);
    }
  }, [maintenanceOtherRegion]);

  const next = (data: MaintenanceRegion) => {
    setCurrent(current + 1);
    setData({ ...data });
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: 'Choose form',
      content: <Step1 nextStep={next} data={data} />,
    },
    {
      title: 'Step 2',
      content: <Step2 nextStep={next} prevStep={prev} data={data} />,
    },

    {
      title: 'Step 3',
      content: <Step3 nextStep={next} prevStep={prev} data={data} />,
    },
    {
      title: 'Step 4',
      content: <Step4 nextStep={next} prevStep={prev} data={data} />,
    },
    {
      title: 'Step 5',
      content: <Step5 nextStep={next} prevStep={prev} data={data} />,
    },
    {
      title: 'Step 6',
      content: <Step6 nextStep={next} prevStep={prev} data={data} />,
    },

    {
      title: 'Step 7',
      content: <Step7 nextStep={next} prevStep={prev} data={data} />,
    },
    {
      title: 'Step 8',
      content: <Step8 hasChange={hasChange} prevStep={prev} data={data} />,
    },
  ];
  return (
    <div className='step-form'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
};
