import React from 'react';
import OtherRegionFilterForm from './OtherRegionFilterForm';
import TableData from './TableData';

const InspOtherRegionPage: React.FC = () => {
  return (
    <div className='user-wrapper'>
      <div className='user-content'>
      <OtherRegionFilterForm />
      <TableData />
      </div>
    </div>
  );
};

export default InspOtherRegionPage;
