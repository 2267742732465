import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ImageCustom } from 'components/common/ImageCustom';
import { ConcurrentSession as ConcurrentSessionModel, Ticket } from 'models';
import { TicketStatus } from 'models/enum/ticket';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import ModalConcurrentSession from './ModalConcurrentSession';
const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};
export const ConcurrentSession: React.FC<{
  ticket?: Ticket | undefined;
  data: ConcurrentSessionModel[] | undefined;
  handleChange?: any;
}> = ({ ticket, data, handleChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);
  const disabled = TokenService.isDisabled();
  const [concurrentSession, setConcurrentSession] = useState<ConcurrentSessionModel[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUpsert = (data: ConcurrentSessionModel) => {
    let concurrentSessionData = [...concurrentSession];
    if (idxEdit < 0) {
      concurrentSessionData = [...concurrentSession, data];
    } else {
      const tmp = [...concurrentSession];
      tmp[idxEdit] = data;
      concurrentSessionData = tmp;
    }
    setConcurrentSession(concurrentSessionData);
    handleChange(concurrentSessionData);
  };
  useEffect(() => {
    if (data) {
      setConcurrentSession(data);
    }
  }, [data]);

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  const columns: ColumnsType<ConcurrentSessionModel> = [
    {
      title: 'Connector',
      dataIndex: 'connector_ID',
      key: 'connector_ID',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.connector_ID || '';
      },
    },
    {
      title: 'EV Model',
      dataIndex: 'ev_model',
      key: 'ev_model',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.ev_model || '';
      },
    },
    {
      title: 'Output Voltage (V)',
      dataIndex: 'output_voltage',
      key: 'output_voltage',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.output_voltage || '';
      },
    },
    {
      title: 'Output Current (A)',
      dataIndex: 'output_current',
      key: 'output_current',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.output_current || '';
      },
    },
    {
      title: 'Output Power (kW)',
      dataIndex: 'output_power',
      key: 'output_power',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.output_power || '';
      },
    },
    {
      title: 'SOC (%)',
      dataIndex: 'soc',
      key: 'soc',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.soc || '';
      },
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record.remark || '';
      },
    },
    {
      title: 'Images',
      dataIndex: 'upload',
      key: 'upload',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        return record?.upload?.images?.map((item) => (
          <ImageCustom
            key={item.url}
            className='user-avatar'
            type='link'
            url={(item.thumb ? item.thumb : item.url) || ''}
            video={item.thumb ? `${item.url}` : ''}
          />
        ));
      },
    },
    {
      title: 'Videos',
      dataIndex: 'upload',
      key: 'upload',
      render: (_: unknown, record: ConcurrentSessionModel) => {
        const videos = record?.upload?.videos?.filter((v) => !!v.url) || [];
        return videos.map((item) => (
          <ImageCustom
            key={item.url}
            className='user-avatar'
            type='link'
            url={(item.thumb ? item.thumb : item.url) || ''}
            video={item.thumb ? `${item.url}` : ''}
          />
        ));
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: ConcurrentSessionModel, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: disabled ? 'cursor' : 'pointer',
                opacity: disabled ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!disabled);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  return (
    <div className='form-wrapper'>
      <Table dataSource={data} columns={columns} scroll={{ x: 800 }} pagination={false} />
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        <Button type='primary' icon={<PlusOutlined />} onClick={handleAdd} disabled={disabled}>
          Add Concurrent Sessions
        </Button>
      </div>
      <ModalConcurrentSession
        data={data ? data[idxEdit] : undefined}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
