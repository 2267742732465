import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Pagination } from './pagination';
import { Inspection } from 'models';

const authority_req_maintenance = [
  {
    no: 1,
    name: 'No Parts replacement or modification to EVCS installation',
    titleNo: 'A1',
  },
];

const ext_env_check_maintenance = [
  {
    no: 1,
    name: 'Installed outside hazardous zones where flammable/combustible gas or material may be present',
    titleNo: 'B1',
  },
  {
    no: 2,
    name: 'Enclosure is not dented, damaged, corroded or in any rusty condition',
    titleNo: 'B2',
  },
  {
    no: 3,
    name: 'Space around the EVCS is adequate for easy access and maintenance work',
    titleNo: 'B3',
  },
  {
    no: 4,
    name: 'Sealing rubber of all doors are in order, and doors including lockset (if any) can be opened and closed easily',
    titleNo: 'B4',
  },
  {
    no: 5,
    name: 'No sign of insects inside EVCS. Openings or vents are not blocked, no excess foreign particles',
    titleNo: 'B5',
  },
  {
    no: 6,
    name: 'Detachable parts are not loose or falling off and not in a rusty condition',
    titleNo: 'B6',
  },
  {
    no: 7,
    name: 'No visible moisture, waterlogging or burnt marks at the EVCS and connector charging pins',
    titleNo: 'B7',
  },
  {
    no: 8,
    name: 'Floor or wall mounting of EVCS remains rigid and strong ',
    titleNo: 'B8',
  },
  {
    no: 9,
    name: 'Vehicle connector cable, mounting and support is secured and not damaged',
    titleNo: 'B9',
  },
  { no: 10, name: 'Electrical warning labels is clear and prominent', titleNo: 'B10' },
  {
    no: 11,
    name: 'Source of DB for EVCS is clearly labeled and electrical source DB is accessible for operation',
    titleNo: 'B11',
  },
  {
    no: 12,
    name: 'Bollards or continuous kerb(s) or other means of crash protection is installed provide adequete protection against moving vehicle',
    titleNo: 'B12',
  },
  {
    no: 13,
    name: 'Charger specification plate is clear, legible and prominent according TR25 requirement',
    titleNo: 'B13',
  },
  {
    no: 14,
    name: 'Owner/operator contact details label is legible and prominent',
    titleNo: 'B14',
  },
  {
    no: 15,
    name: 'Fail-safe emergency stop button (in red and yellow) is prominent and not damaged. For outdoor installation it shall be weatherproof. Clear directional signs shall be provided for multiple charger installation',
    titleNo: 'B15',
  },
  { no: 16, name: 'Adequate lighting for charging operation', titleNo: 'B16' },
  {
    no: 17,
    name: 'Inspection label provided as below.',
    note: '2022-12-01',
    titleNo: 'B17',
  },
  {
    no: 18,
    name: 'Evcss display and all UI accessories(eg, press button, RFID reader etc.) are not damaged and working properly',
    titleNo: 'B18',
  },
  {
    no: 19,
    name: 'Incoming power supply cable including circuit protective conductor connections and termination(s) are properly made and tightened',
    titleNo: 'B19',
  },
  {
    no: 20,
    name: 'Measurement of PP-PE resistance value and compare with IEC given value',
    titleNo: 'B20',
  },
  {
    no: 21,
    name: 'Manufacturer handbook and instruction manuals given to owner/operator',
    titleNo: 'B21',
  }
];

const authority_req_commisioning = [
  {
    no: 1,
    name: 'Letter of no objection(LNO) is obtained from relevant authority/TR25 certificate of compliance is obtained',
    value: '0',
    titleNo: 'A1',
  },
  {
    no: 2,
    name: 'For building with EMA EI License, letter of consent if obtained from building LEW',
    value: '0',
    titleNo: 'A2',
  },
];

const ext_env_check_commisioning = [
  {
    no: 1,
    name: 'Installed outside hazardous zones where flammable/combustible gas or material may be present',
    value: '0',
    titleNo: 'B1',
  },
  {
    no: 2,
    name: 'Enclosure is not dented, damaged, corroded or in any rusty condition',
    value: '0',
    titleNo: 'B2',
  },
  {
    no: 3,
    name: 'Space around the EVCS is adequate for easy access and maintenance work',
    value: '0',
    titleNo: 'B3',
  },
  {
    no: 4,
    name: 'Sealing rubber of all doors are in order, and doors including lockset (if any) can be opened and closed easily',
    value: '0',
    titleNo: 'B4',
  },
  {
    no: 5,
    name: 'No sign of insects inside EVCS. Openings or vents are not blocked, no excess foreign particles',
    value: '0',
    titleNo: 'B5',
  },
  {
    no: 6,
    name: 'Detachable parts are not loose or falling off and not in a rusty condition',
    value: '0',
    titleNo: 'B6',
  },
  {
    no: 7,
    name: 'No visible moisture, waterlogging or burnt marks at the EVCS and connector charging pins',
    value: '0',
    titleNo: 'B7',
  },
  {
    no: 8,
    name: 'Floor or wall mounting of EVCS remains rigid and strong ',
    value: '0',
    titleNo: 'B8',
  },
  {
    no: 9,
    name: 'Vehicle connector cable, mounting and support is secured and not damaged',
    value: '0',
    titleNo: 'B9',
  },
  { no: 10, name: 'Electrical warning labels is clear and prominent', value: '0', titleNo: 'B10' },
  {
    no: 11,
    name: 'Source of DB for EVCS is clearly labeled and electrical source DB is accessible for operation',
    value: '0',
    titleNo: 'B11',
  },
  {
    no: 12,
    name: 'Bollards or continuous kerb(s) or other means of crash protection is installed provide adequete protection against moving vehicle',
    value: '0',
    titleNo: 'B12',
  },
  {
    no: 13,
    name: 'Charger specification plate is clear, legible and prominent according TR25 requirement',
    value: '0',
    titleNo: 'B13',
  },
  {
    no: 14,
    name: 'Owner/operator contact details label is legible and prominent',
    value: '0',
    titleNo: 'B14',
  },
  {
    no: 15,
    name: 'Fail-safe emergency stop button (in red and yellow) is prominent and not damaged. For outdoor installation it shall be weatherproof. Clear directional signs shall be provided for multiple charger installation',
    value: '0',
    titleNo: 'B15',
  },
  { no: 16, name: 'Adequate lighting for charging operation', value: '0', titleNo: 'B16' },
  {
    no: 17,
    name: 'Inspection label provided as below.',
    value: '0',
    note: '2022-12-30',
    titleNo: 'B17',
  },
  {
    no: 18,
    name: 'Evcss display and all UI accessories(eg, press button, RFID reader etc.) are not damaged and working properly',
    value: '0',
    titleNo: 'B18',
  },
  {
    no: 19,
    name: 'Incoming power supply cable including circuit protective conductor connections and termination(s) are properly made and tightened',
    value: '0',
    titleNo: 'B19',
  },
  {
    no: 20,
    name: 'Measurement of PP-PE resistance value and compare with IEC given value',
    value: '0',
    note: '0.1',
    titleNo: 'B20',
  },
  {
    no: 21,
    name: 'Manufacturer handbook and instruction manuals given to owner/operator',
    value: '0',
    titleNo: 'B21',
  },
];

export const Page1: React.FC<{ datas: Inspection | undefined }> = ({ datas }) => {
  const isAnnexB = datas?.form_type === 'Annex_B';
  return (
    <>
      {datas && (
        <div className='page-wrapper'>
          <div className='page-date'>TR 25-1:2022</div>
          <div className='page-info'>
            <div className='page-info__heading'>{datas.form_type.replace('_', ' ')}</div>
            <div className='page-info__caption'>(normative)</div>
            <h1 className='page-title'>
              {isAnnexB
                ? 'Checklist for new charger / parts replacement / modification to EVCS'
                : 'Checklist for preventive maintainance for EVCS'}
            </h1>
          </div>
          <div className='page-content'>
            {isAnnexB && (
              <div className='list-check'>
                <strong>Purpose of use:</strong>
                <ul>
                  <li>
                    Checklist for New charger
                    <input
                      checked={datas.purpose_of_use === '0' && true}
                      type='checkbox'
                      name=''
                      id=''
                    />
                  </li>
                  <li>
                    Parts replacement
                    <input
                      checked={datas.purpose_of_use === '1' && true}
                      type='checkbox'
                      name=''
                      id=''
                    />
                  </li>
                  <li>
                    Modification to EVCS
                    <input
                      checked={datas.purpose_of_use === '2' && true}
                      type='checkbox'
                      name=''
                      id=''
                    />
                  </li>
                </ul>
              </div>
            )}
            <p>
              <strong>Particulars of EVCS</strong>
              <br />
              <em>(one report is to be used for each charger)</em>
            </p>
            <div className='text-input'>
              <strong className='text-input__title'>Brand/model of EV charger(s):</strong>
              <p className='text-input__value'>
                {datas.charger_model?.name || ''}
              </p>
            </div>
            <div className='text-input'>
              <strong className='text-input__title'>S/No.:</strong>
              <p className='text-input__value'>{datas.serial_number.name || ''}</p>
            </div>
            <div className='text-input mb-0'>
              <strong className='text-input__title'>Name of owner/operator:</strong>
              <p className='text-input__value'>{datas.owner_name}</p>
            </div>
            <em className='mb-15'>(Responsible for the EVCS)</em>
            <div className='text-input mb-0'>
              <strong className='text-input__title'>Address of installation:</strong>
              <p className='text-input__value'>{datas.address}</p>
            </div>
            <em className='mb-15'>
              (Indicate carpark lot number and floor level where applicable)
            </em>
            <h4 className='text-underline'>To be performed by equipment specialist</h4>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}></th>
                  <th rowSpan={2}>Description</th>
                  <th colSpan={3}>Compliance</th>
                  <th rowSpan={2}>
                    Remarks/
                    <br />
                    measurement value
                  </th>
                </tr>
                <tr>
                  <th>C</th>
                  <th>NC</th>
                  <th>NA</th>
                </tr>
              </thead>
              <tbody>
                <tr className='row-full'>
                  <td>A</td>
                  <td colSpan={5}>
                    <strong>Authority requirement</strong>
                  </td>
                </tr>
                {datas.authority_req?.map((item) => {
                  const authority_req = (isAnnexB ? authority_req_commisioning : authority_req_maintenance).find((data)=>data.no === item.no);
                  return (
                    <tr key={item.no + item.name}>
                      <td>A{item.no}</td>
                      <td>{authority_req?.name}</td>
                      <td>{item.value === '0' && <CheckOutlined />}</td>
                      <td>{item.value === '1' && <CheckOutlined />}</td>
                      <td>{item.value === '2' && <CheckOutlined />}</td>
                      <td>{item.note}</td>
                    </tr>
                  );
                })}
                <tr className='row-full'>
                  <td>B</td>
                  <td colSpan={5}>
                    <strong>External and environmentai checks</strong> (with EVCS power TURN-OFF at
                    isolator)
                  </td>
                </tr>
                {(
                  (isAnnexB
                    ? datas.ext_env_check?.slice(0, 8)
                    : datas.ext_env_check?.slice(0, 11)) || []
                ).map((item) => {
                  const ext_env_check = (isAnnexB ? ext_env_check_commisioning : ext_env_check_maintenance).find((data)=>data.no === item.no);
                  return (
                    <tr key={item.no + item.name}>
                      <td>B{item.no}</td>
                      <td>{ext_env_check?.name}</td>
                      <td>{item.value === '0' && <CheckOutlined />}</td>
                      <td>{item.value === '1' && <CheckOutlined />}</td>
                      <td>{item.value === '2' && <CheckOutlined />}</td>
                      <td>{item.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination page={1} totalPage={isAnnexB ? 5 : 4} />
          </div>
        </div>
      )}
    </>
  );
};
