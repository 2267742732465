import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppSelector } from 'app/hooks';
import { ImageCustom } from 'components/common/ImageCustom';
import { selectTraineeList } from 'features/auth/authSlice';
import { TrainingUser, User } from 'models';
import React, { useEffect, useState } from 'react';

const ModalActionTrainee: React.FC<{
  data: TrainingUser;
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  setIsModalVisible: (status: boolean) => void;
  onFinish: (value: any) => void;
  isCertification: boolean;
}> = ({
  data,
  isModalVisible,
  handleOk,
  handleCancel,
  setIsModalVisible,
  onFinish,
  isCertification,
}) => {
  const [form] = useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const traineeList = useAppSelector(selectTraineeList);
  const [traineeUser, setTraineeUser] = useState<User>();

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        trainee_name: data.trainee?.name || '',
        trainee_signature: data.trainee?.trainee_signature || '',
        trainee_username: data.trainee?.user
          ? `${data.trainee?.user?.firstname} ${data.trainee?.user?.lastname} (${data.trainee?.user?.username})`
          : '',
      });
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    onFinish({
      ...data,
      trainee: {
        ...data.trainee,
        name: form.getFieldValue('trainee_name'),
        user: {
          ...(traineeUser || data.trainee?.user),
        },
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeItem = () => {
    const trainee_name = form.getFieldValue('trainee_name');
    if (data) {
      const isValid = trainee_name !== data.trainee?.name && trainee_name !== '';
      setDisabledSubmit(!isValid);
    } else {
      const isValid = !!trainee_name;
      setDisabledSubmit(!isValid);
    }
  };

  const onHandleSelectUser = (id: string) => {
    setDisabledSubmit(true);
    const userSelected = traineeList.filter((item) => item.id === id);

    setTraineeUser(userSelected[0]);

    form.setFieldsValue({
      trainee_name: userSelected[0]
        ? `${userSelected[0]?.firstname} ${userSelected[0]?.lastname}`
        : '',
    });
    if (userSelected[0]) {
      setDisabledSubmit(false);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{data ? <div>Update Trainee</div> : <div>Create Trainee</div>}</>}
      forceRender
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        {data && (
          <Form.Item label='Trainee Signature' name='trainee_signature'>
            <ImageCustom
              className='user-avatar'
              type='link'
              url={data.trainee?.trainee_signature || ''}
            />
          </Form.Item>
        )}
        <Form.Item
          label='Trainee Username'
          name='trainee_username'
          rules={[{ required: isCertification, message: 'Please input Trainee Username' }]}
        >
          <Select
            dropdownMatchSelectWidth={false}
            placeholder='Select Trainee Username'
            allowClear
            onChange={onHandleSelectUser}
          >
            {traineeList.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.firstname} {item.lastname} ({item.username})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Trainee Name' name='trainee_name'>
          <Input onChange={handleChangeItem} disabled={isCertification} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionTrainee;
