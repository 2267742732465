import { UserEngineer } from 'features/user/userSlice';
import { ListParams, ListResponse, SearchUserParams, User, UserParam, UserResponse } from 'models';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const userApi = {
  login(params: UserParam): Promise<UserResponse> {
    const url = `${SCIMMS_API}/auth/login`;
    return axiosClient.post(url, { ...params });
  },

  logout(): Promise<string> {
    const url = `${SCIMMS_API}/auth/logout`;
    return axiosClient.get(url);
  },

  getAll(params: ListParams): Promise<ListResponse<User>> {
    const url = `${SCIMMS_API}/users`;
    return axiosClient.get(url, {
      params,
    });
  },

  create(param: User): Promise<User> {
    const url = `${SCIMMS_API}/auth/users`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  update(param: User): Promise<User> {
    const url = `${SCIMMS_API}/users?id=${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  findByUsername(params: SearchUserParams): Promise<ListResponse<User>> {
    const url = `${SCIMMS_API}/users/find-by-username`;
    return axiosClient.get(url, {
      params,
    });
  },
  findById(id: string): Promise<User> {
    const url = `${SCIMMS_API}/users/${id}`;
    return axiosClient.get(url);
  },
  getServiceEngineers(): Promise<UserEngineer[]> {
    const url = `${SCIMMS_API}/auth/service-engineer/list`;
    return axiosClient.get(url);
  },
};

export default userApi;
