import { ActionParam } from 'features/tickets/ticketSlice';
import {
  ITicketSeverity,
  ITicketStatus,
  ITicketTrendingDay,
  ListParams,
  ListResponse,
  Certification,
  TicketRequest,
} from 'models';
import moment from 'moment';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const certificationApi = {
  getAll(params: ListParams): Promise<ListResponse<Certification>> {
    const url = `${SCIMMS_API}/training-user`;
    return axiosClient.get(url, {
      params,
    });
  },

  searchByParams(params: ListParams): Promise<ListResponse<Certification>> {
    const url = `${SCIMMS_API}/training-user/find-by-params`;
    return axiosClient.get(url, {
      params,
    });
  },

  suspendCertification(param: any): Promise<any> {
    const url = `${SCIMMS_API}/training-user/${param.id}`;
    return axiosClient.put(url, {
      ...param,
    });
  },

  upsert(param: Certification): Promise<Certification> {
    const url = `${SCIMMS_API}/ticket/${param.id}`;
    return axiosClient.put(url, {
      ...param,
      closed_date: moment(new Date(param.createdAt || '')).format('YYYY-MM-DD HH:mm:ss'),
      reported_date: moment(new Date(param.createdAt)).format('YYYY-MM-DD HH:mm:ss'),
    });
  },

  getById(id: string): Promise<Certification> {
    const url = `${SCIMMS_API}/ticket/${id}`;
    return axiosClient.get(url);
  },

  create(param: Certification): Promise<Certification> {
    const url = `${SCIMMS_API}/ticket`;
    return axiosClient.post(url, {
      ...param,
    });
  },

  add(data: Certification): Promise<Certification> {
    const url = '/ticket';
    return axiosClient.post(url, data);
  },

  update(data: Partial<Certification>): Promise<Certification> {
    const url = `/ticket/${data.id}`;
    return axiosClient.patch(url, data);
  },

  remove(id: string): Promise<any> {
    const url = `${SCIMMS_API}/ticket/${id}`;
    return axiosClient.delete(url);
  },

  // count ticket status
  getCountStatus(id: string): Promise<ITicketStatus[]> {
    const url = `${SCIMMS_API}/ticket/status/count`;
    return axiosClient.get(url);
  },

  // count ticket by severity
  getCountSeverity(id: string): Promise<ITicketSeverity[]> {
    const url = `${SCIMMS_API}/ticket/severity/count`;
    return axiosClient.get(url);
  },

  // fetch data chart
  getDataChart(id: string): Promise<ITicketTrendingDay[]> {
    const url = `${SCIMMS_API}/ticket/trending/day`;
    return axiosClient.get(url);
  },

  acceptDecline(param: ActionParam): Promise<Certification> {
    const url = `${SCIMMS_API}/ticket/accept-decline/ticket?id=${param.id}&flag=${param.flag}`;
    return axiosClient.put(url);
  },
  //   decline(id: string, assignedEngineer: string): Promise<any> {
  //     const url = `${SCIMMS_API}/ticket/ticket-decline/decline?id=${id}`;
  //     return axiosClient.put(url);
  //   },
  close(param: TicketRequest): Promise<any> {
    const url = `${SCIMMS_API}/ticket/${param.id}`;
    return axiosClient.put(url, param);
  },
};

export default certificationApi;
