import { useAppDispatch } from 'app/hooks';
import React, { useEffect } from 'react';
import { inspOtherRegionActions } from '../inspOtherRegionSlice';
import { StepForm } from './steps/Index';

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) dispatch(inspOtherRegionActions.fetchOtherRegionDetail({ id }));
  }, [id, dispatch]);
  return <StepForm id={id} />;
};
export default FormEdit;
