import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CommissionRegion } from 'models';
import React, { useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

export const InstallationRecheck: React.FC<{
  nextStep: any;
  prevStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const installation_recheck = [
    {
      no: 1,
      name: 'Foundation',
      tile: 'Check whether the foundation is fixed completely.',
    },
    {
      no: 2,
      name: 'Power Cable',
      tile: 'Check whether the cable specifications meet the power requirements of EVSE, no break, damage, or scratch, whether the electrical connection and wiring are correct and complete, whether the connection is solid, and whether the grounding is reliable.',
    },
    {
      no: 3,
      name: 'Grounding Resistance',
      tile: 'Outside grounding resistance＜4Ω，inside grounding resistance≤0.1Ω.',
    },
    {
      no: 4,
      name: 'Insulation Resistance',
      tile: 'The insulation resistance of power cable meets the requirements.',
    },
    {
      no: 5,
      name: 'Surface',
      tile: 'The surface is clean. The charging cable is not broken. The door could be closed and open. The EVSE is not tilted.',
    },
    {
      no: 6,
      name: 'Sign',
      tile: 'No missing safety warning mark and the nameplate is clear.',
    },
    {
      no: 7,
      name: 'Requirements',
      tile: 'The EVSE meets the requirements. The accessories are complete.',
    },
    {
      no: 8,
      name: 'Others',
      tile: 'The fire-proof material is blocked in place.',
    },
  ];

  useEffect(() => {
    if (inspOtherRegion) {
      const installation_recheck = inspOtherRegion.installation_recheck?.map((item) => {
        return {
          [`installation_recheck_${item.no}`]: item.value,
          [`installation_recheck_note_${item.no}`]: item.remark,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(installation_recheck),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = (values: any) => {
    const inspectForm = form.getFieldsValue();
    const installation_recheck = [...(inspOtherRegion?.installation_recheck || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['installation_recheck_' + item.no],
        remark: inspectForm['installation_recheck_note_' + item.no],
      };
    });

    nextStep({ ...inspOtherRegion, installation_recheck });
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(inspOtherRegion?.installation_recheck || []).map((item, idx) => {
          const recheck_data = installation_recheck.find((data) => data.no === +item.no);
          return (
            <>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + recheck_data?.name + ': ' + recheck_data?.tile}
                key={item.no}
                initialValue={item.value}
                name={'installation_recheck_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.name}
                key={item.no + '_note'}
                initialValue={item.remark}
                name={'installation_recheck_note_' + item.no}
              >
                <Input
                  disabled={disabled}
                  className='input-remarks'
                  placeholder='Remarks/measurement value'
                />
              </Form.Item>
            </>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
