import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';

function fetchImageAsBase64(url: string) {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
      'Content-Type': 'image/png',
      'Content-Transfer-Encoding': 'base64',
    });

    // Make the request and wait for the response
    window.fetch(url, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
      // return response;
    });
    // .then((response) => response.blob())
    // .then((blob) => blobToBase64(blob))
    // .then((base64) => resolve(base64));
  });
}

export const Image: React.FC<{
  url: string;
  className?: string;
  type?: string;
}> = ({ url, className, type = 'link' }) => {
  const [img, setImg] = useState('');
  useEffect(() => {
    if (url)
      fetchImageAsBase64(url).then((res: any) => {
        const link = type === 'link' ? res?.link : 'data:image/jpg;base64,' + res?.link;
        setImg(link || '');
      });
    else setImg('/images/img-avatar.jpg');
  }, [url, type]);
  return <img className={className || ''} src={img} alt='avatar' />;
};
