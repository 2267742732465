import { Form, Input, InputNumber, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ImageCustom } from 'components/common/ImageCustom';
import { User } from 'models';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { selectPagination, userActions } from '../userSlice';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: User;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.user.list);
  const pagination = useAppSelector(selectPagination);

  useEffect(() => {
    dispatch(userActions.search({ username: '' }));
  }, [dispatch]);

  const handleChangePage = (evt: number) => {
    dispatch(userActions.changePage({ pageNo: evt }));
  };

  const columns = [
    {
      title: 'No',
      width: 80,
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      width: '6.77%',
      render: (_: unknown, record: User) => {
        return <ImageCustom className='user-avatar' type='link' url={`${record.avatar}` || ''} />;
      },
    },
    {
      title: 'Full Name',
      dataIndex: ['firstname', 'lastname'],
      width: '15%',
      render: (_: unknown, record: User) => {
        return record.firstname + ' ' + record.lastname;
      },
    },
    {
      title: 'username',
      dataIndex: 'username',
      width: '12%',
      editable: true,
    },
    {
      title: 'email',
      dataIndex: 'email',
      width: '17%',
      editable: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: '17%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '12.85%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '11.46%',
      render: (_: unknown, record: User) => {
        let statusName = '';
        switch (record.status) {
          case '0':
            statusName = 'Inactive';
            break;
          case '1':
            statusName = 'Active';
            break;
          default:
            statusName = 'Hold';
            break;
        }
        return (
          <span className='status-icon'>
            <span className={`dot ${statusName.toLowerCase()}`}></span>
            {statusName}
          </span>
        );
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: User) => {
        return (
          <Link to={`/user/edit/${record.id}`} style={{ color: '#e98d3e' }}>
            Edit
          </Link>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName='editable-row'
        pagination={{
          onChange: handleChangePage,
          current: pagination.pageNo,
          total: pagination.totalRecords,
        }}
      />
    </Form>
  );
};

export default TableData;
