import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const Step7: React.FC<{
  nextStep: any;
  prevStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const functional_c_tpl = [
    {
      no: 1,
      Content:
        'Start the charging and ensure charging data is properly reflected on the interface.',
      Method: 'Visual',
    },
    {
      no: 2,
      Content: 'During charging, ensure the LED indicators work normally.',
      Method: 'Visual',
    },
    {
      no: 3,
      Content:
        'Charging details such as SOC, charging voltage and current, etc. displayed on the charger screen is consistent with the App.',
      Method: 'Visual',
    },
    {
      no: 4,
      Content:
        'Ensure display on the charger screen is easily readable with no signs of cloudiness. The operations and interface should also be smooth and friendly.',
      Method: 'Visual',
    },
    {
      no: 5,
      Content:
        'During charging, opening the charging cabinet should immediately stop the charge automatically and display a warning at the screen.',
      Method: 'Visual',
    },
    {
      no: 6,
      Content:
        'During charging, pressing the emergency stop button should immediately stop the charge automatically and display a warning at the screen.',
      Method: 'Visual',
    },
    {
      no: 7,
      Content:
        'During charging, move the tilt sensor should immediately stop the charge automatically and display a warning at the screen.',
      Method: 'Visual',
    },
    {
      no: 8,
      Content:
        'Setting the upper temperature threshold to below the ambient temperature will result in the charging process to not initiate at all.',
      Method: 'Visual',
    },
    {
      no: 9,
      Content:
        'Ensure that all power modules are working properly where the status and temperature are reflected accordingly.',
      Method: 'Visual',
    },
  ];

  useEffect(() => {
    if (data) {
      const functional_c = data.functional_c?.map((item) => {
        return {
          [`functional_c_${item.no}`]: item.value,
          [`functional_c_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(functional_c),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = () => {
    const inspectForm = form.getFieldsValue();
    const functional_c = [...(data?.functional_c || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['functional_c_' + item.no],
        remarks: inspectForm['functional_c_note_' + item.no],
      };
    });

    nextStep({ ...data, functional_c });
  };

  return (
    <div className='form-wrapper'>
      <h3>Functional Check</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.functional_c || []).map((item, idx) => {
          const functional_c_data = functional_c_tpl.find((data) => data.no === +item.no);
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + functional_c_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'functional_c_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'functional_c_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Remarks' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step7;
