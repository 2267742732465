import { notification } from 'antd';
import maintenanceRegionApi from 'api/maintenanceRegionApi';
import { MaintenanceRegion, ListResponse } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Filter,
  maintenanceOtherRegionActions,
  Pagination,
  selectFilter,
  selectPagination,
} from './maintenanceOtherRegionsSlice';

function* fetchMaintenanceList(formTypeFilter: any) {
  // console.log(formTypeFilter);
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<MaintenanceRegion> = yield call(
      maintenanceRegionApi.searchByParams,
      {
        pageNo: pagination.pageNo,
        pageSize: pagination.size,
        formTypeFilter: formTypeFilter?.payload?.formTypeFilter,
      },
    );
    yield put(maintenanceOtherRegionActions.fetchMaintenanceListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch maintenance list', error);
    yield put(maintenanceOtherRegionActions.fetchMaintenanceListFailed());
  }
}

type Params = { payload: MaintenanceRegion; type: string };
function* upsertMaintenance(param: Params) {
  try {
    yield call(maintenanceRegionApi.upsert, param.payload);
    yield put(maintenanceOtherRegionActions.upsertMaintenanceSuccess());
    yield put(maintenanceOtherRegionActions.fetchMaintenanceList({ _page: 1, _limit: 10 }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    yield put(maintenanceOtherRegionActions.fetchMaintenanceList({}));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type SearchParams = { payload: Filter; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<MaintenanceRegion> = yield call(
      maintenanceRegionApi.searchByParams,
      {
        pageNo: pagination.pageNo,
        pageSize: pagination.size,
        ...filter,
        ...param.payload,
      },
    );
    yield put(maintenanceOtherRegionActions.fetchMaintenanceListSuccess(response));
  } catch (error) {
    console.log('Failed to search maintenance', error);
  }
}

type DeleteParams = { payload: { ids: string[] }; type: string };
function* deleteMaintenance(param: DeleteParams) {
  try {
    yield call(maintenanceRegionApi.deleteMultipleRecords, {
      ...param.payload,
    });
    yield put(maintenanceOtherRegionActions.deleteMaintenanceSuccess());
    yield put(maintenanceOtherRegionActions.search({}));
  } catch (error) {
    yield put(maintenanceOtherRegionActions.deleteMaintenanceFailed());
    console.log('Failed to delete other region', error);
  }
}

type DeleteItemParams = { payload: { id: string }; type: string };
function* deleteRecord(param: DeleteItemParams) {
  try {
    const id = param.payload.id;
    yield call(maintenanceRegionApi.remove, id);
    yield put(maintenanceOtherRegionActions.deleteRecordSuccess());
    yield put(maintenanceOtherRegionActions.search({}));
  } catch (error) {
    yield put(maintenanceOtherRegionActions.deleteRecordFailed());
    console.log('Failed to delete other region', error);
  }
}

type DetailParams = { payload: { id: string }; type: string };
function* fetchMaintenanceOtherRegionDetail(param: DetailParams) {
  try {
    const id = param.payload.id;
    const response: MaintenanceRegion = yield call(maintenanceRegionApi.getById, id);
    yield put(maintenanceOtherRegionActions.fetchMaintenanceOtherRegionDetailSuccess(response));
  } catch (error) {
    console.log('Failed to find maintenance by id', error);
  }
}

export default function* maintenanceOtherRegionsSaga() {
  yield takeLatest(maintenanceOtherRegionActions.fetchMaintenanceList.type, fetchMaintenanceList);
  yield takeEvery(maintenanceOtherRegionActions.upsertMaintenance.type, upsertMaintenance);
  yield takeEvery(maintenanceOtherRegionActions.changePage.type, changePage);
  yield takeLatest(maintenanceOtherRegionActions.search.type, search);
  yield takeLatest(maintenanceOtherRegionActions.deleteMaintenance.type, deleteMaintenance);
  yield takeLatest(maintenanceOtherRegionActions.deleteRecord.type, deleteRecord);
  yield takeLatest(
    maintenanceOtherRegionActions.fetchMaintenanceOtherRegionDetail.type,
    fetchMaintenanceOtherRegionDetail,
  );
}
