import { Steps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { chargerModelActions } from 'features/settings/charger-model/chargerModelSlice';
import { companyActions } from 'features/settings/company/companySlice';
import { countryActions } from 'features/settings/country/countrySlice';
import { problemCategoryActions } from 'features/settings/problemCategory/problemCategorySlice';
import { provinceActions } from 'features/settings/province/provinceSlice';
import { serialNumberActions } from 'features/settings/serial-number/serialNumberSlice';
import { userActions } from 'features/user/userSlice';
import { Ticket } from 'models';
import { FlagService } from 'models/enum/chargerModel';
import React, { useEffect, useState } from 'react';
import GeneralInfo from './generalInfo';
import { SitePage } from './sitePage';
import { SubmitForm } from './submitForm';

export const StepTicketForm: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [current, setCurrent] = useState(0);
  const [hasChange, setHasChange] = useState(false);
  const [data, setData] = useState<Ticket>();
  const dispatch = useAppDispatch();

  const ticket = useAppSelector(
    (state) => state.ticket?.list?.filter((item: Ticket) => item.id === id)[0],
  );

  useEffect(() => {
    dispatch(userActions.fetchUserEngineers({}));
    dispatch(companyActions.search({ name: '', pagination: { size: 100000 } }));
    dispatch(
      chargerModelActions.fetchChargerModelList({
        flag_service: FlagService.TI,
      }),
    );
    dispatch(
      serialNumberActions.search({
        filter: { name: '', chargerModelId: '' },
        pagination: { size: 10000 },
      }),
    );
    dispatch(provinceActions.fetchProvinceList({ _limit: 10000 }));
    dispatch(countryActions.search({ name: '', pagination: { size: 100000 } }));
    dispatch(problemCategoryActions.search({ name: '', pagination: { size: 1000 } }));
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setHasChange(JSON.stringify(data) === JSON.stringify(ticket));
    }
  }, [data, ticket]);

  useEffect(() => {
    if (ticket) {
      setData(ticket);
    }
  }, [ticket]);

  const next = (datas: Ticket) => {
    setCurrent(current + 1);
    setData({ ...datas });
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: 'General Info',
      content: <GeneralInfo nextStep={next} ticket={data} />,
    },
    {
      title: 'Site',
      content: <SitePage nextStep={next} prevStep={prev} data={data} />,
    },
    {
      title: 'Ticket Detail',
      content: <SubmitForm hasChange={hasChange} prevStep={prev} ticket={data} />,
    },
  ];
  return (
    <div className='step-form'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
};
