import { notification } from 'antd';
import inspectionApi from 'api/inspectionApi';
import { Inspection, ListResponse } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Filter,
  inspectionActions,
  Pagination,
  selectFilter,
  selectPagination,
} from './inspectionSlice';

function* fetchInspectionList(formTypeFilter: any) {
  // console.log(formTypeFilter);
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Inspection> = yield call(inspectionApi.getAll, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      formTypeFilter: formTypeFilter?.payload?.formTypeFilter,
    });
    yield put(inspectionActions.fetchInspectionListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch inspection list', error);
    yield put(inspectionActions.fetchInspectionListFailed());
  }
}

type Params = { payload: Inspection; type: string };
function* upsertInspection(param: Params) {
  try {
    yield call(inspectionApi.upsert, param.payload);
    yield put(inspectionActions.upsertInspectionSuccess());
    yield put(inspectionActions.fetchInspectionList({ _page: 1, _limit: 10 }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    yield put(inspectionActions.search({ formTypeFilter: param.payload.formTypeFilter }));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type SearchParams = { payload: Filter; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<Inspection> = yield call(inspectionApi.searchByParams, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      ...filter,
      ...param.payload,
    });
    yield put(inspectionActions.fetchInspectionListSuccess(response));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type DeleteParams = { payload: { ids: string[] }; type: string };
function* deleteInspection(param: DeleteParams) {
  try {
    yield call(inspectionApi.deleteInspection, {
      ...param.payload,
    });
    yield put(inspectionActions.deleteInspectionSuccess());
    yield put(inspectionActions.search({}));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type DeleteItemParams = { payload: { id: string; form_type: string }; type: string };
function* deleteRecord(param: DeleteItemParams) {
  try {
    const id = param.payload.id;
    const formType = param.payload.form_type;
    yield call(inspectionApi.remove, id);
    yield put(inspectionActions.deleteRecordSuccess());
    yield put(inspectionActions.search({ formTypeFilter: formType }));
  } catch (error) {
    yield put(inspectionActions.deleteRecordFailed());
    console.log('Failed to delete inspection', error);
  }
}

type InspectionDetailParams = { payload: { id: string }; type: string };
function* fetchInspectionDetail(param: InspectionDetailParams) {
  try {
    const id = param.payload.id;
    const response: Inspection = yield call(inspectionApi.getById, id);
    yield put(inspectionActions.fetchInspectionDetailSuccess(response));
  } catch (error) {
    console.log('Failed to find inspection by id', error);
  }
}

export default function* inspectionSaga() {
  yield takeLatest(inspectionActions.fetchInspectionList.type, fetchInspectionList);
  yield takeEvery(inspectionActions.upsertInspection.type, upsertInspection);
  yield takeEvery(inspectionActions.changePage.type, changePage);
  yield takeLatest(inspectionActions.search.type, search);
  yield takeLatest(inspectionActions.deleteInspection.type, deleteInspection);
  yield takeLatest(inspectionActions.deleteRecord.type, deleteRecord);
  yield takeLatest(inspectionActions.fetchInspectionDetail.type, fetchInspectionDetail);
}
