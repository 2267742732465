import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectChargerModels } from 'features/settings/charger-model/chargerModelSlice';
import { Country } from 'models';
import React, { useEffect, useState } from 'react';
import { countryActions } from '../../settings/country/countrySlice';
import { Filter, trainingActions } from '../trainingSlice';
import { selectCompanies } from 'features/settings/company/companySlice';
import {
  certificateAdminActions,
  selectCertificateAdmins,
} from 'features/settings/certificate-admin/certificateAdminSlice';
import { updateValueObj } from 'utils/function';

const { RangePicker } = DatePicker;

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const FilterForm: React.FC = () => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const chargerModels = useAppSelector(selectChargerModels);
  const companies = useAppSelector(selectCompanies);
  const certificateAdmins = useAppSelector(selectCertificateAdmins);

  const companiesOptions = updateValueObj(companies);
  const chargerModelOptions = updateValueObj(chargerModels);
  const certificateOptions = updateValueObj(certificateAdmins, {
    label: 'None',
    value: 'None',
    id: 'none',
  });

  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  const [countryData, setCountryData] = useState<DataType[]>([]);
  useEffect(() => {
    if (listCountry) {
      const info: DataType[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: Country = obj[key] as Country;
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);

  useEffect(() => {
    dispatch(
      countryActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(
      certificateAdminActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  }, [dispatch]);

  const onSearch = () => {
    const val = form.getFieldsValue();
    const searchParams: Filter = {};
    console.log('val ', val);
    let training_date_from = '';
    let training_date_to = '';
    if (val.training_date && val.training_date.length > 0) {
      training_date_from = val.training_date[0].format('YYYY-MM-DD');
      training_date_to = val.training_date[1].format('YYYY-MM-DD');
      searchParams['training_date_from'] = training_date_from;
      searchParams['training_date_to'] = training_date_to;
    }

    if (val.customer !== '' && val.customer !== undefined) {
      searchParams['customer_name'] = val.customer;
    }
    if (val.charger_model !== '' && val.charger_model !== undefined) {
      searchParams['charger_model_name'] = val.charger_model;
    }
    if (val.trainer_name !== '' && val.trainer_name !== undefined) {
      searchParams['trainer_name'] = val.trainer_name;
    }
    if (val.trainer_company !== '' && val.trainer_company !== undefined) {
      searchParams['trainer_company_name'] = val.trainer_company;
    }
    if (val.training_method !== '' && val.training_method !== undefined) {
      searchParams['training_method'] = val.training_method;
    }
    if (val.country !== '' && val.country !== undefined) {
      searchParams['country_name'] = val.country;
    }
    if (val.site_name !== '' && val.site_name !== undefined) {
      searchParams['site_name'] = val.site_name;
    }
    if (val.training_content !== '' && val.training_content !== undefined) {
      searchParams['training_content'] = val.training_content;
    }
    if (val.trainee_name !== '' && val.trainee_name !== undefined) {
      searchParams['trainee_name'] = val.trainee_name;
    }
    if (val.certificate !== '' && val.certificate !== undefined) {
      searchParams['certificate'] = val.certificate === 'None' ? 'none' : val.certificate;
    }

    console.log('param', searchParams);
    dispatch(trainingActions.unSelectedIdExport());
    dispatch(trainingActions.unselectedTrainingIdExport());
    dispatch(trainingActions.search({ filter: searchParams, pagination: { pageNo: 1 } }));
  };

  const trainingMethods = [
    { title: 'All', value: '' },
    { title: 'Online', value: 'Online' },
    { title: 'On-site', value: 'On-site' },
  ];

  return (
    <div className='training-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Certificate' name='certificate' className='short-title'>
              <AutoComplete
                allowClear
                options={certificateOptions}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Customer' name='customer' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Model' name='charger_model' className='short-title'>
              <AutoComplete
                allowClear
                options={chargerModelOptions}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Training Date' name='training_date' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Trainer Name' name='trainer_name' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Trainer Company' name='trainer_company' className='short-title'>
              <AutoComplete
                allowClear
                options={companiesOptions}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Training Method' name='training_method' className='short-title'>
              <Select allowClear>
                {trainingMethods.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Country' name='country' className='short-title'>
              <AutoComplete
                options={countryData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Site Name' name='site_name' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Training Content' name='training_content' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Trainee Name' name='trainee_name' className='short-title'>
              <Input />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={4}>
            <Form.Item className='short-title'>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
