import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ImageCustom } from 'components/common/ImageCustom';
import { ISparePartUsed, Ticket } from 'models';
import { TicketStatus } from 'models/enum/ticket';
import React, { useEffect, useState } from 'react';
import { getUTC8DateTimeFormat, isMine } from 'utils/function';
import TokenService from 'utils/token.service';
import ModalActionSparePartUsed from './ModalActionSparePartUsed';
const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};
export const SparePartUsed: React.FC<{
  ticket: Ticket | undefined;
  data: ISparePartUsed[];
  handleChange: any;
}> = ({ ticket, data, handleChange }) => {
  const [sparePartUsed, setSparePartUsed] = useState<ISparePartUsed[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);
  const isSystemAdmin = TokenService.isSystemAdmin();

  const isEdit =
    isSystemAdmin ||
    (ticket?.status !== undefined &&
      isOpen(ticket?.status) &&
      isMine(ticket?.service_engineer || ''));
  useEffect(() => {
    if (data) {
      const info: ISparePartUsed[] = data as ISparePartUsed[];
      setSparePartUsed(info);
    }
  }, [data]);

  const columns: ColumnsType<ISparePartUsed> = [
    {
      title: 'BOM No',
      dataIndex: 'bomNo',
      key: 'bomNo',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_: unknown, record: ISparePartUsed) => {
        return getUTC8DateTimeFormat(record.time || '');
      },
    },
    {
      title: 'Upload Image',
      dataIndex: 'assets',
      key: 'assets',
      render: (_: unknown, record: ISparePartUsed) => {
        return record?.assets?.map((item) => (
          <ImageCustom
            key={item.url}
            className='user-avatar'
            type='link'
            url={(item.thumb ? `${item.thumb}` : item.url) || ''}
            video={item.thumb ? `${item.url}` : ''}
          />
        ));
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: ISparePartUsed, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isEdit ? 'cursor' : 'pointer',
                opacity: !isEdit ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isEdit);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUpsert = (data: ISparePartUsed) => {
    let sparePartData = [...sparePartUsed];
    if (idxEdit < 0) {
      sparePartData = [...sparePartData, data];
    } else {
      const tmp = [...sparePartUsed];
      tmp[idxEdit] = data;
      sparePartData = tmp;
    }
    setSparePartUsed(sparePartData);
    handleChange({ spare_part_used: sparePartData });
  };

  return (
    <div className='form-wrapper'>
      <Table dataSource={sparePartUsed} columns={columns} scroll={{ x: 800 }} pagination={false} />
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        <Button type='primary' icon={<PlusOutlined />} onClick={handleAdd} disabled={!isEdit}>
          Add Spare Part Used
        </Button>
      </div>
      <ModalActionSparePartUsed
        data={sparePartUsed[idxEdit]}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
