/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  ListParams,
  ListResponse,
  Certification,
} from 'models';

export interface CertificationState {
  loading: boolean;
  suspended: boolean;
  deleting: boolean;
  loaded: boolean;
  selectedIdsExcel: React.Key[];
  selectedCertificationIdsExcel: string[];
  list: Certification[];
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
}

export interface ActionParam {
  id: string;
  flag: boolean;
}

export interface ClosedCertificationParam {
  id: string;
  closedDate: Date;
  status: string;
}

export interface Filter {
  training_date_from?: string;
  training_date_to?: string;
  expiry_date_from?: string;
  expiry_date_to?: string;
  certificate?: string;
  charger_model_name?: string;
  certificate_username?: string;
  company_name?: string;
  status?: string;
}

export interface CertificationPayload {
  data: Certification;
  navigate: () => void;
}
const initialState: CertificationState = {
  loading: false,
  suspended: false,
  loaded: false,
  deleting: false,
  list: [],
  selectedIdsExcel: [],
  selectedCertificationIdsExcel: [],
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    training_date_from: '',
    training_date_to: '',
    expiry_date_from: '',
    expiry_date_to: '',
    certificate: '',
    charger_model_name: '',
    certificate_username: '',
    company_name: '',
    status: ''
  },
  exportPdf: [],
};

const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    fetchCertificationList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchCertificationListSuccess(state, action: PayloadAction<ListResponse<Certification>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data || action.payload || [];
    },
    fetchCertificationListFailed(state) {
      state.loading = false;
    },
    selectedIdExport(state, action: PayloadAction<{ keys: React.Key[] }>) {
      // find all certification ids of list current
      const certificationIds = state.list.map((item) => item.id);
      // check exist in keys payload
      const keys = action.payload.keys;
      const uncheckCertifications = certificationIds.filter((item) => !keys.includes(item.toString()));
      // remove unchecked certification from state
      const idsState = state.selectedIdsExcel.filter(
        (item) => !uncheckCertifications.includes(item.toString()),
      );
      const selectedIdsExcel = new Set([...idsState, ...keys]);
      state.selectedIdsExcel = Array.from(selectedIdsExcel);
    },
    unSelectedIdExport(state) {
      state.selectedIdsExcel = [];
    },
    selectedCertificationIdExport(state, action: PayloadAction<{ certificationIds: string[] }>) {
      // find all certification ids of list current
      const certificationIds = state.list.map((item) => item.id);
      // check exist in keys payload
      const ids = action.payload.certificationIds;
      const uncheckCertifications = certificationIds.filter((item) => !ids.includes(item.toString()));
      // remove unchecked certification from state
      const idsState = state.selectedCertificationIdsExcel.filter(
        (item) => !uncheckCertifications.includes(item.toString()),
      );
      const selectedCertificationIdsExcel = new Set([...idsState, ...ids]);
      state.selectedCertificationIdsExcel = Array.from(selectedCertificationIdsExcel);
    },
    unSelectedCertificationIdExport(state) {
      state.selectedCertificationIdsExcel = [];
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
    search(state, action: PayloadAction<{ filter: Filter; pagination?: Pagination }>) {
      const { filter, pagination } = action.payload;
      state.filter = filter;
      state.list = [];
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      }
    },
    suspendCertification(state, action: PayloadAction<any>) {
      state.suspended = true;
    },
    suspendCertificationSuccess(state, action: PayloadAction<any>) {
      state.suspended = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
  },
});

// Actions
export const certificationActions = certificationSlice.actions;

// Selectors
export const selectCertificationList = (state: RootState) => state.certification.list;

export const selectCertificationLoad = (state: RootState) => state.certification.loaded;
export const selectCertificationLoading = (state: RootState) => state.certification.loading;
export const selectCertificationSuspend = (state: RootState) => state.certification.suspended;

export const selectPagination = (state: RootState) => state.certification.pagination;
export const selectFilter = (state: RootState) => state.certification.filter;
export const selectDeleting = (state: RootState) => state.certification.deleting;
export const selectedIdsExcel = (state: RootState) => state.certification.selectedIdsExcel;
export const selectedCertificationIdsExcel = (state: RootState) => state.certification.selectedCertificationIdsExcel;

// Reducer
const certificationReducer = certificationSlice.reducer;
export default certificationReducer;
