import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Asset, IPermanentSolutions } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  b64toBlob,
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getThumbnailForVideo,
  removeExtension,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const ModalActionPermanentSolutions: React.FC<{
  data: IPermanentSolutions;
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  onFinish: any;
}> = ({ data, isModalVisible, handleOk, handleCancel, setIsModalVisible, onFinish }) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [paths, setPaths] = useState<Asset[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([...fileList, list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: resUrl?.link || '',
      };
    };
    if (fileUpload) {
      setPaths([...paths, { url: fileUpload }]);
      const asset = getImage({ thumb: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        note: data?.note || '',
        assets: data?.assets || [],
      });
      const setImgList = async (list: any) => {
        Promise.all(list).then((list) => {
          setFileList(list);
        });
      };
      const getImage = async (asset: Asset) => {
        const url = (asset.thumb ? asset.thumb : asset.url) || '';
        const resUrl = (await fetchImage(url)) as { link: string };
        return {
          uid: resUrl?.link || '',
          url: resUrl?.link || '',
          name: resUrl?.link || '',
        };
      };
      if (data?.assets) {
        const list = data?.assets.map((asset) => getImage(asset));
        setImgList(list);
      } else {
        setImgList([]);
      }
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    onFinish({
      note: form.getFieldValue('note'),
      assets: [...(data?.assets || []), ...paths],
      username: TokenService.getUser().username,
      time: moment(new Date()).toDate(),
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const props: UploadProps = {
    name: 'image',
    fileList: fileList,
    customRequest: async ({ file, filename }) => {
      if ((file as File).type.startsWith('image')) {
        const fileName = generateFileName((file as File).name || '');
        const path = `ticket/permanent_solutions/${fileName}`;
        const resImg = (await fetchImageForUpload(path)) as { link: string };
        const formData = new FormData();
        formData.append('image', file);

        // const config = {
        //   headers: { "content-type": "multipart/form-data" },
        // };
        // axios
        //   .put(resImg?.link, formData, config)
        uploadMedia(resImg?.link, file as Blob)
          .then(async () => {
            setFileUpload(path);
            message.success(`${fileName} file uploaded successfully`);
          })
          .catch((err) => {
            console.log(err);
            message.error(`${fileName} file upload failed.`);
          });
      } else {
        const fileNameVideo = generateFileName((file as File).name || '');
        const resVideo = (await fetchImageForUpload(
          `ticket/permanent_solutions/${fileNameVideo}`,
        )) as { link: string };
        const formData = new FormData();
        formData.append('image', file);
        uploadMedia(resVideo?.link, file as Blob)
          .then(async () => {
            // const fileUrl = URL.createObjectURL(file as Blob);
            // const thumb = await getThumbnailForVideo(fileUrl);
            // const fileNameThumb = generateFileName();
            const fileUrl = URL.createObjectURL(file as Blob);
            const thumb = await getThumbnailForVideo(fileUrl);
            const resThumb = (await fetchImageForUpload(
              `ticket/permanent_solutions/${removeExtension(fileNameVideo) + '.png'}`,
            )) as { link: string };
            const thumbFile = b64toBlob(thumb, 'image/png');
            // new Blob([window.atob(thumb)], {
            //   type: "image/png",
            // });
            const formData = new FormData();
            formData.append('image', thumb);
            uploadMedia(resThumb?.link, thumbFile)
              .then(async () => {
                setFileUpload(
                  `ticket/permanent_solutions/${removeExtension(fileNameVideo) + '.png'}`,
                );
                // setFileList([...fileList, makeFileUpload(resThumb?.link)]);
                message.success(`${filename} file uploaded successfully`);
                // form.setFieldValue("icon", res?.link);
              })
              .catch((err) => {
                console.log(err);
                message.error(`${filename} file upload failed.`);
              });
            // setFileList([...fileList, makeFileUpload(res?.link)]);
            // message.success(`${fileName} file uploaded successfully`);
            // form.setFieldValue("icon", res?.link);
          })
          .catch((err) => {
            console.log(err);
            message.error(`${filename} file upload failed.`);
          });
      }
    },
  };

  const handleChangeItem = (evt: any) => {
    const inputValue = evt.currentTarget.value;
    const isValid = !!inputValue && inputValue !== data?.note;
    setDisabledSubmit(!isValid);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={
        <>{data ? <div>Update Permanent Solutions</div> : <div>Create Permanent Solutions</div>}</>
      }
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item label='Permanent Solutions' name='note'>
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item label='Upload Image' valuePropName='file' name='icon'>
          <Upload
            {...props}
            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            listType='picture-card'
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionPermanentSolutions;
