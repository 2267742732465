import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Badge, Button, Layout } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { authActions } from 'features/auth/authSlice';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { HeaderUser } from './headerUser';

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const name = TokenService.getUser().firstname + ' ' + TokenService.getUser().lastname || '';
  const avatar = TokenService.getUser().avatar;
  const navLogo = TokenService.isAdmin() || TokenService.isSystemAdmin() ? '/overview' : '/ticket';

  const handleLogout = () => {
    TokenService.removeUser();
    dispatch(authActions.logout());
    navigate('/login');
  };
  const handleShowProfile = () => {
    const uid = TokenService.getUser().id;
    navigate(`/user/edit/${uid}`);
  };
  return (
    <Layout.Header className='header header-admnin'>
      <div className='header-main'>
        <div className='header-main--left'>
          <Link to={navLogo}>
            <img src='/images/logo.png' alt='logo' />
          </Link>
        </div>
        <div className='header-main--right'>
          <Button className='btn-search'>
            <img src='/images/icon-search.svg' alt='' />
          </Button>
          <Button className='btn-question'>
            <img src='/images/icon-question.svg' alt='' />
          </Button>
          <Button className='btn-notification'>
            <Badge count={0}>
              <img src='/images/icon-notification.svg' alt='' />
            </Badge>
          </Button>
          <div className='header-user'>
            <HeaderUser avatar={avatar} name={name} />
            <div className='header-user-menu'>
              <Button onClick={handleShowProfile}>
                <ProfileOutlined />
                Profile
              </Button>
              <Button className='btn-logout' type='link' onClick={handleLogout}>
                <LogoutOutlined />
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};
