import { PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectChargerModels } from 'features/settings/charger-model/chargerModelSlice';
import { selectCompanies } from 'features/settings/company/companySlice';
import { selectCountries } from 'features/settings/country/countrySlice';
import { selectProblemCategories } from 'features/settings/problemCategory/problemCategorySlice';
import { provinceActions, selectProvinces } from 'features/settings/province/provinceSlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { selectUserEngineers } from 'features/user/userSlice';
import { Asset, SelectOption, Ticket } from 'models';
import { TicketSeverity, TicketStatus } from 'models/enum/ticket';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  fetchImage,
  fetchImageForUpload,
  formatDatetime,
  generateFileName,
  isMine,
  updateValueObj,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};

const { TextArea } = Input;

const GeneralInfo: React.FC<{
  nextStep: any;
  ticket: Ticket | undefined;
}> = ({ nextStep, ticket }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const isSystemAdmin = TokenService.isSystemAdmin();

  const [optionsAssignTo, setOptionsAssignTo] = useState<{ value: string }[]>([]);
  const [optionCompanies, setOptionCompanies] = useState<{ value: string }[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const [paths, setPaths] = useState<Asset[]>([]);

  const isEdit =
    !ticket ||
    (ticket?.status !== undefined &&
      isOpen(ticket?.status) &&
      (isSystemAdmin || isMine(ticket?.service_engineer || '')));

  const userEngineers = useAppSelector(selectUserEngineers);
  const companies = useAppSelector(selectCompanies);
  const countryOption = useAppSelector(selectCountries);
  const provinceOption = useAppSelector(selectProvinces);
  const chargerModelOption = useAppSelector(selectChargerModels);
  const problemCats = useAppSelector(selectProblemCategories);

  const [serialNumberData, setSerialNumberData] = useState<SelectOption[]>([]);
  const [resultSerialNumber, setResultSerialNumber] = useState<SelectOption[]>();

  const listSerialNumbers = useAppSelector(selectSerialNumbers);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    form.setFieldsValue({
      severity: ticket?.severity,
      reported_date:
        (ticket?.reported_date ? moment(new Date(ticket?.reported_date)) : null) ||
        moment(new Date()),
    });
    if (ticket) {
      form.setFieldsValue({
        ticket_id: ticket?.ticket_id || '',
        ticket_name: ticket?.ticket_name || '',
        service_engineer: ticket?.service_engineer || '',
        assigned_engineer:
          `${ticket.assigned_engineer_name ?? ''}` +
          `${ticket.assigned_engineer ? ` (${ticket.assigned_engineer})` : ''}`,
        problem_description: ticket?.problem_description || '',
        sn_number: ticket?.serial_number?.name || '',
        charger_model: ticket?.charger_model?.id || ticket?.charger_model_id || '',
        charger_owner: ticket?.charger_owner?.name || '',
        country_id: ticket?.country?.id || ticket?.country_id || '',
        province_id: ticket?.province?.id || ticket?.province_id || '',
        time_to_solution: ticket?.time_to_solution || '',
        onsite_duration: ticket?.onsite_duration || '',
        address_detail: ticket?.address_detail || '',
        call_center: ticket?.call_center || '',
        sequence_of_events: ticket?.sequence_of_events ?? '',
        problem_category: ticket?.problem_category?.name ?? '',
      });
      const setImgList = async (list: any) => {
        Promise.all(list).then((list) => {
          setFileList(list);
        });
      };
      const getImage = async (asset: Asset) => {
        const url = (asset.thumb ? asset.thumb : asset.url) || '';
        const resUrl = (await fetchImage(url)) as { link: string };
        return {
          uid: resUrl?.link || '',
          url: resUrl?.link || '',
          name: resUrl?.link || '',
        };
      };
      const assets = ticket?.problem_description_photo?.assets;
      if (assets) {
        const list = assets.map((asset) => getImage(asset));
        setImgList(list);
      } else {
        setImgList([]);
      }
    }
  }, [ticket, form]);

  // Get countries
  useEffect(() => {
    const countryId = form.getFieldValue('country_id');
    if (countryId) {
      const provinceItem = provinceOption.find((province) => province.country_id === countryId);
      form.setFieldValue('province_id', provinceItem?.id);
    }
  }, [provinceOption, form]);

  // Get list assign to
  useEffect(() => {
    const opts = (userEngineers || []).map((item) => {
      return { value: `${item.firstname} ${item.lastname} (${item.username})` };
    });
    setOptionsAssignTo(opts);
  }, [userEngineers]);

  // Get Companies
  useEffect(() => {
    const opts = (companies || []).map((item) => {
      return { value: item.name };
    });
    setOptionCompanies(opts);
  }, [companies]);

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([...fileList, list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: resUrl?.link || '',
      };
    };
    if (fileUpload) {
      setPaths([...paths, { url: fileUpload }]);
      const asset = getImage({ thumb: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  const handleNext = () => {
    const chargerModelId = form.getFieldValue('charger_model');
    const chargerModel = chargerModelOption.find((item) => item.id === chargerModelId);
    const problemCategoryId = form.getFieldValue('problem_category');
    const problemCategory = problemCats.find((item) => item.id === problemCategoryId);
    const assignedEngineer = form.getFieldValue('assigned_engineer').match(/ \([\s\S]*?\)/g) ?? '';

    form.validateFields().then(() => {
      const time = moment(new Date()).toDate();
      const ticketForm: Ticket = form.getFieldsValue();
      const updateObj = {
        ...ticket,
        ...ticketForm,
        problem_description_photo: {
          time,
          username: TokenService.getUser().username,
          assets: paths,
        },
        country: { id: form.getFieldValue('country_id') },
        province: { id: form.getFieldValue('province_id') },
        charger_model: {
          id: chargerModelId,
          name: chargerModel?.name,
          code: chargerModelOption.find((item) => item.id === chargerModelId)?.code,
        },
        serial_number: {
          id: findIdSerialByName(form.getFieldValue('sn_number')),
          name: form.getFieldValue('sn_number'),
        },
        charger_owner: {
          id: findIdOwnerByName(form.getFieldValue('charger_owner')),
          name: form.getFieldValue('charger_owner'),
        },
        charger_owner_check: form.getFieldValue('charger_owner'),
        reported_date: formatDatetime(form.getFieldValue('reported_date')),
        problem_category: problemCategory,
        assigned_engineer: assignedEngineer[0]
          ? assignedEngineer[0].replace(/[{()}]/g, '').trim()
          : '',
      };
      nextStep({ ...updateObj });
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const findIdSerialByName = (name: string) => {
    const serialItem = serialNumberData.find((serial) => serial.label === name);
    return serialItem?.id || '';
  };

  const findIdOwnerByName = (name: string) => {
    const ownerItem = companies.find((owner) => owner.name === name);
    return ownerItem?.id || '';
  };

  const severityOption = [
    { title: 'Urgent', value: TicketSeverity.Urgent },
    { title: 'High', value: TicketSeverity.High },
    { title: 'Medium', value: TicketSeverity.Medium },
    { title: 'Low', value: TicketSeverity.Low },
  ];

  useEffect(() => {
    if (listSerialNumbers) {
      const serialNumbers = updateValueObj(listSerialNumbers);
      setResultSerialNumber(serialNumbers);
      setSerialNumberData(serialNumbers);
    }
  }, [listSerialNumbers]);

  const onSearchAssignTo = (searchText: string) => {
    const result = (userEngineers || [])
      .filter((option) => option.username.includes(searchText))
      .map((item) => {
        return { value: item.username };
      });
    setOptionsAssignTo(result);
  };

  const onSearchCompany = (searchText: string) => {
    const result = (companies || [])
      .filter((option) => option.name.includes(searchText))
      .map((item) => {
        return { value: item.name };
      });
    setOptionCompanies(result);
  };

  const onSelect = (data: string) => {
    console.log('onSelect', data);
  };

  const props: UploadProps = {
    name: 'image',
    fileList: fileList,
    customRequest: async ({ file }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `ticket/problem_description/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async () => {
          setFileUpload(path);
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleChangeCountry = () => {
    dispatch(provinceActions.fetchProvinceList({}));
  };

  const handleChangeChargerModel = (chargerModelId: string) => {
    dispatch(
      serialNumberActions.search({
        filter: {
          name: '',
          chargerModelId,
        },
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  };

  useMemo(() => {
    if ((!resultSerialNumber || resultSerialNumber.length === 0) && serialNumberData.length > 0) {
      setResultSerialNumber(serialNumberData);
    }
  }, [serialNumberData, resultSerialNumber]);

  const handleSearch = (value: string) => {
    const rs = serialNumberData.filter(
      (item) => item.value?.toUpperCase().indexOf(value.toUpperCase()) !== -1,
    );
    setResultSerialNumber(rs);
  };

  return (
    <>
      <Form
        name='form-ticket'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={handleNext}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item label='1. Ticket owner' name='service_engineer'>
          <Select disabled>
            {userEngineers?.map((item, index) => (
              <Select.Option value={item.username} key={index}>
                {item.username}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='2. Ticket Name'
          name='ticket_name'
          rules={[{ required: true, message: 'Please input your Ticket Name!' }]}
        >
          <Input disabled={!isEdit} placeholder='Ticket Name' />
        </Form.Item>
        <Form.Item
          label='3. Problem Description'
          name='problem_description'
          rules={[
            {
              required: true,
              message: 'Please input your Problem Description!',
            },
          ]}
        >
          <TextArea disabled={!isEdit} placeholder='What is the problem' />
        </Form.Item>
        <Form.Item label='4. Problem Description Photo' valuePropName='file' name='icon'>
          <Upload
            {...props}
            // showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            listType='picture-card'
            onPreview={handlePreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item
          label='5. Problem Category'
          name='problem_category'
          rules={[{ required: true, message: 'Please input your Problem Category!' }]}
        >
          <Select disabled={!isEdit}>
            {problemCats?.map((item, index) => (
              <Select.Option value={item?.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='6. Sequence of Events'
          name='sequence_of_events'
          rules={[
            {
              required: true,
              message: 'Please input your Sequence of Events!',
            },
          ]}
        >
          <TextArea disabled={!isEdit} placeholder='What happened before the problem occurred' />
        </Form.Item>
        <Form.Item
          label='7. Occurrence date'
          name='reported_date'
          rules={[{ required: true, message: 'Please input your Occurrence date!' }]}
        >
          <DatePicker disabled={!isEdit} format='YYYY-MM-DD' />
        </Form.Item>

        <Form.Item
          label='8. Charger model'
          name='charger_model'
          rules={[{ required: true, message: 'Please input your Charger model!' }]}
        >
          <Select disabled={!isEdit} onChange={handleChangeChargerModel}>
            {chargerModelOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='9. Serial Number'
          name='sn_number'
          rules={[{ required: true, message: 'Please input your Serial Number!' }]}
        >
          <AutoComplete onSearch={handleSearch} placeholder='Charger SN' disabled={!isEdit}>
            {resultSerialNumber?.map(({ id, value: name }) => (
              <Select.Option key={id} value={name}>
                {name}
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>

        <Form.Item
          label='10. Charger owner'
          name='charger_owner'
          rules={[{ required: true, message: 'Please input charger owner!' }]}
        >
          <AutoComplete
            disabled={!isEdit}
            options={optionCompanies}
            onSearch={onSearchCompany}
            placeholder='charger owner'
          />
        </Form.Item>

        <Form.Item
          label='11. Country'
          name='country_id'
          rules={[{ required: true, message: 'Please input your Country!' }]}
        >
          <Select disabled={!isEdit} onChange={handleChangeCountry}>
            {countryOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='12. Province'
          name='province_id'
          rules={[{ required: true, message: 'Please input your Province!' }]}
        >
          <Select disabled={!isEdit}>
            {provinceOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='13. Detail Address'
          name='address_detail'
          rules={[{ required: true, message: 'Please input your Detail Address!' }]}
        >
          <TextArea disabled={!isEdit} placeholder='Include Lot no. and Floor no. if applicable' />
        </Form.Item>

        <Form.Item
          label='14. Severity'
          name='severity'
          rules={[{ required: true, message: 'Please input your Severity!' }]}
        >
          <Select disabled={!isEdit}>
            {severityOption.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='15. Call Center' name='call_center'>
          <Input disabled={!isEdit} placeholder='Call Center Name/ID' />
        </Form.Item>

        <Form.Item label='16. Assign to' name='assigned_engineer'>
          <AutoComplete
            disabled={!isEdit}
            options={optionsAssignTo}
            onSelect={onSelect}
            onSearch={onSearchAssignTo}
            placeholder='assign to'
          />
        </Form.Item>

        <Form.Item>
          <div className='action-form'>
            <Button
              type='primary'
              htmlType='submit'
              onClick={handleNext}
              className={`btn-submit-form`}
            >
              Next step
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal
        visible={previewOpen}
        title={null}
        footer={null}
        onCancel={handleCancel}
        className='ticket-preview-image'
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default GeneralInfo;
