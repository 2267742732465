import React from 'react';
import FilterForm from './FilterForm';
import TableData from './TableData';

const InspectionPage: React.FC<{ formTypeFilter: string }> = ({ formTypeFilter }) => {
  return (
    <div className='user-wrapper'>
      <div className='user-content'>
      <FilterForm formTypeFilter={formTypeFilter} />
        <TableData formTypeFilter={formTypeFilter} />
      </div>
    </div>
  );
};

export default InspectionPage;
