import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const Step3: React.FC<{
  nextStep: any;
  prevStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const power_distribution_cc_tpl = [
    {
      no: 1,
      Content:
        'The surface of the charger is flat and smooth, without obvious mechanical damage or deformation.',
      Method: 'Visually',
    },
    {
      no: 2,
      Content: 'Warning symbols and electrical hazard signs are complete and clear.',
      Method: 'Visually',
    },
    {
      no: 3,
      Content: 'The name plate is complete, correct and firm.',
      Method: 'Visually',
    },
    {
      no: 4,
      Content: 'Cannot open the door after the door is closed and locked.',
      Method: 'Visually',
    },
    {
      no: 5,
      Content:
        'The cabinet body is placed properly on the foundation, and there are no bolts lost or loose.',
      Method: 'Visually',
    },
    {
      no: 6,
      Content:
        'Electrical schematic diagram, qualification certificate, factory inspection report, key.',
      Method: 'Visually',
    },
    {
      no: 7,
      Content: 'Every breaker’s label represents its respective charger.',
      Method: 'Visually',
    },
    {
      no: 8,
      Content: 'The breakers work normally.',
      Method: 'Visually',
    },
    {
      no: 9,
      Content: 'The power cable is not damaged.',
      Method: 'Visually',
    },
    {
      no: 10,
      Content: 'The wiring is not loose.',
      Method: 'Visually',
    },
    {
      no: 11,
      Content: 'Verification of meter data.',
      Method: 'Visually',
    },
    {
      no: 12,
      Content: 'Check the internal exposed three-phase copper bar protection.',
      Method: 'Visually',
    },
    {
      no: 13,
      Content: 'Not soaked in water after heavy rain or typhoon.',
      Method: 'Visually',
    },
  ];

  useEffect(() => {
    if (data) {
      const power_distribution_cc = data.power_distribution_cc?.map((item) => {
        return {
          [`power_distribution_cc_${item.no}`]: item.value,
          [`power_distribution_cc_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(power_distribution_cc),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = () => {
    const maintenanceForm = form.getFieldsValue();
    const power_distribution_cc = [...(data?.power_distribution_cc || [])].map((item) => {
      return {
        ...item,
        value: maintenanceForm['power_distribution_cc_' + item.no],
        remarks: maintenanceForm['power_distribution_cc_note_' + item.no],
      };
    });

    nextStep({ ...data, power_distribution_cc });
  };

  return (
    <div className='form-wrapper'>
      <h3>Power Distribution Cabinet Check</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.power_distribution_cc || []).map((item, idx) => {
          const recheck_data = power_distribution_cc_tpl.find((data) => data.no === +item.no);
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + recheck_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'power_distribution_cc_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'power_distribution_cc_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Remarks' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step3;
