import { Button, Form, Input, Radio, RadioChangeEvent } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CommissionRegion } from 'models';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const RadioValue = {
  C: '0',
  NC: '1',
  NA: '2',
};

const check_before_PO = [
  {
    no: 1,
    name: 'Temperature',
    tile: '-30℃~50℃',
    value: '1',
  },
  {
    no: 2,
    name: 'Humidity',
    tile: '5%~95%',
    value: '1',
  },
  {
    no: 3,
    name: 'Elevation',
    tile: '≤2000m',
    value: '1',
  },
  {
    no: 4,
    name: 'Input L1 and L2',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 5,
    name: 'Input L1 and L3',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 6,
    name: 'Input L2 and L3',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 7,
    name: 'Input L1 and N',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 8,
    name: 'Input L2 and N',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 9,
    name: 'Input L3 and N',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 10,
    name: 'Input L1 and PE',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 11,
    name: 'Input L2 and PE',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 12,
    name: 'Input L3 and PE',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 13,
    name: 'Input N and PE',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 14,
    name: 'Input L1 and DC+/DC-',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 15,
    name: 'Input L2 and DC+/DC-',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 16,
    name: 'Input L3 and DC+/DC-',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 17,
    name: 'N and DC+/DC-',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 18,
    name: 'DC+ and DC- of charging cable',
    tile: 'Open Circuit',
    value: '1',
    remark: 'test',
  },
  {
    no: 19,
    name: 'DC+ and DC- of output copper bar',
    tile: 'Open Circuit',
    value: '1',
  },
  {
    no: 20,
    name: 'DC+ of charging cable and DC+ of output copper bar',
    tile: 'Close Circuit',
    value: '1',
    remark: 'test',
  },
  {
    no: 21,
    name: 'DC- of charging cable and DC- of output copper bar',
    tile: 'Close Circuit',
    value: '1',
    remark: 'tédt',
  },
  {
    no: 22,
    name: 'All screws of input',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 23,
    name: 'All screws of main breaker',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 24,
    name: 'Screws on the input and output of the power module socket',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 25,
    name: 'All grounding screws',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 26,
    name: 'Screws on DC+ and DC-copper bar',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 27,
    name: 'Each plug on the main control board',
    tile: 'Each plug is firmly without loosening, and the plug connection is firmly without loosening',
    value: '1',
  },
  {
    no: 28,
    name: 'Switch mode power supply',
    tile: 'All screws are fastened without loosening or missing',
    value: '1',
  },
  {
    no: 29,
    name: 'Input voltage of the main breaker',
    tile: '400V（±10%）',
    value: '1',
    remark: 'test',
  },
  {
    no: 30,
    name: 'Charging Cable DC+ and PE',
    tile: '≥1MΩ',
    value: '1',
    remark: 'test',
  },
  {
    no: 31,
    name: 'Charging Cable DC- and PE',
    tile: '≥1MΩ',
    value: '1',
    remark: 'test',
  },
  {
    no: 32,
    name: 'Charging Cable DC+ and DC-',
    tile: '≥1MΩ',
    value: '1',
    remark: 'tédt',
  },
];

export const CheckBeforePowerOn: React.FC<{
  nextStep: any;
  prevStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();
  const [isRequiredRemarks, setIsRequiredRemarks] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (inspOtherRegion) {
      const isDisabledRemarkData: { [key: string]: boolean } = {};
      const check_before_PO = inspOtherRegion.check_before_PO?.map((item) => {
        const fieldName = `check_before_PO_note_${item.no}`;
        isDisabledRemarkData[fieldName] = isRequiredRemark(`${item.no}`, item.value);
        return {
          [`check_before_PO_${item.no}`]: item.value,
          [`check_before_PO_note_${item.no}`]: item.remark,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(check_before_PO),
      };
      setIsRequiredRemarks(isDisabledRemarkData);
      form.setFieldsValue(initForm);
    }
  }, []);

  const isRequiredRemark = (itemNo: string, value: string) => {
    return (
      ['20', '21', '29', '30', '31', '32'].includes(itemNo) &&
      [RadioValue.C, RadioValue.NC].includes(value)
    );
  };

  const handleNext = (values: any) => {
    const inspectForm = form.getFieldsValue();
    const check_before_PO = [...(inspOtherRegion?.check_before_PO || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['check_before_PO_' + item.no],
        remark: inspectForm['check_before_PO_note_' + item.no],
      };
    });

    nextStep({ ...inspOtherRegion, check_before_PO });
  };

  const handlerChangeRadio = (fieldName: string, value: string) => {
    const fieldNameArr = fieldName.split('_');
    setIsRequiredRemarks({
      ...isRequiredRemarks,
      [`${fieldName}`]: isRequiredRemark(fieldNameArr[fieldNameArr.length - 1], value),
    });
    form.validateFields([fieldName]);
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(inspOtherRegion?.check_before_PO || []).map((item, idx) => {
          const check_before_data = check_before_PO.find((data) => data.no === +item.no);
          return (
            <>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + check_before_data?.name + ': ' + check_before_data?.tile}
                key={item.no}
                initialValue={item.value}
                name={'check_before_PO_' + item.no}
              >
                <Radio.Group
                  disabled={disabled}
                  onChange={(value: RadioChangeEvent) =>
                    handlerChangeRadio('check_before_PO_note_' + item.no, value.target.value)
                  }
                >
                  <Radio value={RadioValue.C}>C</Radio>
                  <Radio value={RadioValue.NC}>NC</Radio>
                  <Radio value={RadioValue.NA}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.name}
                key={item.no + '_note'}
                initialValue={item.remark}
                name={'check_before_PO_note_' + item.no}
                rules={[
                  {
                    required: isRequiredRemarks[`check_before_PO_note_${item.no}`],
                    message: 'Please input remark!',
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  className='input-remarks'
                  placeholder='Remarks/measurement value'
                />
              </Form.Item>
            </>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            // onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
