import { Button, Form, Input, notification, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Company } from 'models';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { selectInspectionLoad, companyActions } from '../companySlice';

const ModalActionCompany: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const company = useAppSelector(
    (state) => state.company.list.filter((item: Company) => item.id === id)[0],
  );

  useEffect(() => {
    form.setFieldsValue({
      name: company?.name || '',
    });
  }, [company, form]);
  useEffect(() => {
    if (id !== '') dispatch(companyActions.fetchCompanyDetail({ id }));
  }, [id, dispatch]);

  const loaded = useAppSelector(selectInspectionLoad);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      const idRandom = Math.random().toString(36).substring(2);
      setRandomState(idRandom);
      if (uid === id) {
        notification.success({
          message: 'Company updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else if (id !== '') {
        notification.success({
          message: 'Company updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/settings/company'),
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else {
        notification.success({
          message: 'Company record created successfully',
          placement: 'bottomRight',
          //onClose: () => resetForm(),
          onClose: () => {
            resetForm();
            navigate('/settings/company');
          },
          duration: 2.5,
        });
        setIsModalVisible(false);
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const company: Company = form.getFieldsValue();
    if (id !== '') {
      dispatch(companyActions.updateCompany({ ...company, id }));
    } else
      dispatch(
        companyActions.createCompany({
          ...company,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{id !== '' ? <div>Update Company</div> : <div>Create Company</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Company Name'
          name='name'
          rules={[{ required: true, message: 'Please input Company Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionCompany;
