import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { FlagService } from 'models/enum/chargerModel';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Filter, inspectionActions } from '../inspectionSlice';

const FilterForm: React.FC<{ formTypeFilter: any }> = ({ formTypeFilter }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const chargerModelOption = useAppSelector(selectChargerModels);
  useEffect(() => {
    dispatch(
      chargerModelActions.fetchChargerModelList({
        flag_service: FlagService.CM,
      }),
    );
  }, [dispatch]);

  const findChargerModelById = (id: string) => {
    return chargerModelOption.find((item) => item.id === id)?.name;
  };
  const onSearch = () => {
    const filter: Filter = {
      formTypeFilter,
    };
    const brand = form.getFieldValue('brand');
    const conduct_date = form.getFieldValue('conduct_date')
      ? moment(new Date(form.getFieldValue('conduct_date'))).format('YYYY-MM-DD')
      : '';
    const serial = form.getFieldValue('serial');
    const owner_name = form.getFieldValue('owner_name');
    const address = form.getFieldValue('address');
    if (brand) {
      filter.brand = findChargerModelById(brand);
    }
    if (conduct_date) {
      filter.conduct_date = conduct_date;
    }
    if (serial) {
      filter.serial = serial;
    }
    if (owner_name) {
      filter.owner_name = owner_name;
    }
    if (address) {
      filter.address = address;
    }

    dispatch(inspectionActions.search(filter));
  };
  return (
    <div className='inspection-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Brand/model of EV charger' name='brand'>
              <Select dropdownMatchSelectWidth={false} placeholder='Select Brand' allowClear>
                {chargerModelOption.map((item, index) => (
                  <Select.Option value={item.id} key={index}>
                    <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='S/No' name='serial'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Name of owner/operator' name='owner_name'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Conduct Date' name='conduct_date'>
              <DatePicker format='YYYY-MM-DD' style={{ display: 'block' }} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Address of Installation' name='address'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
