import { Button, Form, Input, InputNumber, Modal, notification, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Category } from 'models/category';
import { IProblemCategory } from 'models/problemCategory';

import React, { useCallback, useEffect, useState } from 'react';
import { formatYYYYMMDD, mappingDataTable } from 'utils/function';
import TokenService from 'utils/token.service';

import {
  problemCategoryActions,
  selectProblemCategoryLoading,
  selectProblemCategoryDeleting,
  selectPagination,
  selectProblemCategories,
} from '../problemCategorySlice';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: IProblemCategory;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData: React.FC<{
  setIsModalVisible: any;
  setIdEdit: any;
  randomState: any;
}> = ({ setIsModalVisible, setIdEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const data = mappingDataTable(useAppSelector(selectProblemCategories));

  const pagination = useAppSelector(selectPagination);
  const [idDelete, setIdDelete] = useState('');
  const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
  const loading = useAppSelector(selectProblemCategoryLoading);
  const deleting = useAppSelector(selectProblemCategoryDeleting);

  const isSystemAdmin = TokenService.isSystemAdmin();

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Problem Category record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  useEffect(() => {
    if (loading) {
      setIsModalVisibleDelete(false);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(problemCategoryActions.search({ name: '', pagination: { size: 1000 } }));
  }, [dispatch]);

  const columns = [
    {
      title: 'No',
      width: '5%',
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
    },
    {
      title: 'Problem Category Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      width: '15%',
      render: (_: unknown, record: Category) => {
        return formatYYYYMMDD(record.createdAt || '');
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      width: '15%',
      render: (_: unknown, record: Category) => {
        return formatYYYYMMDD(record.updatedAt || '');
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: '15%',
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      width: '15%',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '15%',
      render: (_: unknown, record: Category) => {
        return (
          <>
            <span
              onClick={() => {
                setIsModalVisible(true);
                setIdEdit(record.id);
              }}
              style={{ color: '#e98d3e', cursor: 'pointer' }}
            >
              Edit
            </span>{' '}
            {isSystemAdmin && (
              <>
                |{' '}
                <span
                  onClick={() => {
                    setIsModalVisibleDelete(true);
                    setIdDelete(record.id);
                  }}
                  style={{ color: '#e98d3e', cursor: 'pointer' }}
                >
                  Delete
                </span>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleOk = () => {
    dispatch(problemCategoryActions.deleteProblemCategory({ id: idDelete as string }));
  };
  const handleCancel = () => {
    setIsModalVisibleDelete(false);
  };

  const handleChangePage = (evt: any, size: number) => {
    dispatch(problemCategoryActions.changePage({ pageNo: evt, size }));
  };

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName='editable-row'
          pagination={{
            showSizeChanger: true,
            total: pagination.totalRecords,
            onChange: handleChangePage,
            showTotal: showTotal,
          }}
        />
      </Form>
      <Modal
        visible={isModalVisibleDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={loading} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete problem category?</p>
      </Modal>
    </>
  );
};

export default TableData;
