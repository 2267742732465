import { Image, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SCIMMS_API } from 'utils/constants';
import TokenService from 'utils/token.service';

function fetchImage(url: string) {
  return new Promise((resolve) => {
    const jwt = TokenService.getLocalAccessToken();

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + jwt,
    });
    const lastIndex = url.lastIndexOf('/');
    const folder = url.substring(0, lastIndex);
    const filename = url.substring(lastIndex + 1);
    const urlFetch = `${SCIMMS_API}/upload/signedurl-view?folder=${folder}&filename=${filename}`;
    // Make the request and wait for the response
    window.fetch(urlFetch, { headers }).then((response) => {
      const res = response.json();
      return resolve(res);
      // return response;
    });
    // .then((response) => response.blob())
    // .then((blob) => blobToBase64(blob))
    // .then((base64) => resolve(base64));
  });
}

export const ImageCustom: React.FC<{
  url: string;
  className?: string;
  type?: string;
  video?: string;
}> = ({ url, className, type = 'link', video }) => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const [img, setImg] = useState('');
  const [vid, setVid] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (url)
      fetchImage(url).then((res: any) => {
        setImg(res.link || '');
      });
    if (video)
      fetchImage(video).then((res: any) => {
        setVid(res.link || '');
      });
    else setImg('/images/img-avatar.jpg');
  }, [url, type, video]);
  const handleCancel = () => {
    if (vidRef && vidRef.current) {
      vidRef.current.pause();
    }
    setIsModalVisible(false);
  };
  const previewVideo = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      {video ? (
        <Image
          preview={false}
          onClick={previewVideo}
          className={className || ''}
          src={img}
          alt='avatar'
        />
      ) : (
        <Image className={className || ''} src={img} alt='avatar' />
      )}
      <Modal visible={isModalVisible} style={{height: 650}} onCancel={handleCancel} className='video-modal' footer={null}>
        <video width='520' ref={vidRef} controls>
          <source src={vid} type='video/mp4' />
        </video>
      </Modal>
    </>
  );
};
