import { Button, Form, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { Training, TrainingContent } from 'models';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import ModalActionTrainingContent from './ModalActionTrainingContent';
import { mappingDataTable } from 'utils/function';

interface TrainingContentTable extends TrainingContent {
  id?: string;
}

export const TrainingContents: React.FC<{
  nextStep: any;
  prevStep: any;
  training: Training | undefined;
  handleChange: any;
}> = ({ nextStep, prevStep, training, handleChange }) => {
  const [form] = useForm();
  const [trainingContentData, setTrainingContentData] = useState<TrainingContent[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);

  const isSystemAdmin = TokenService.isSystemAdmin();
  const isEdit = !training || isSystemAdmin;

  useEffect(() => {
    if (training?.content_list) {
      const info: TrainingContentTable[] = [];
      const obj = training?.content_list
        ?.slice()
        ?.sort((a, b) => parseFloat(a?.index_name || '') - parseFloat(b?.index_name || ''));

      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: TrainingContentTable = obj[key] as TrainingContentTable;

        info.push({
          training_content: obj2.training_content ?? '',
          id: obj2.id ?? '',
        });
      });

      setTrainingContentData(mappingDataTable(info));
    }
  }, [training?.content_list]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpsert = (data: TrainingContent) => {
    let trainingContent = [...trainingContentData];
    if (idxEdit < 0) {
      trainingContent = [...trainingContent, data];
    } else {
      const tmp = [...trainingContentData];
      tmp[idxEdit] = data;
      trainingContent = tmp;
    }
    setTrainingContentData(trainingContent);
    handleChange({ content_list: trainingContent });
  };

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  const columnsTrainingContent: ColumnsType<TrainingContent> = [
    {
      title: 'Training Content',
      dataIndex: 'training_content',
      key: 'training_content',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, __: unknown, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isEdit ? 'cursor' : 'pointer',
                opacity: !isEdit ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isEdit);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  const handleNext = () => {
    nextStep({ ...training });
  };

  return (
    <div className='form-wrapper'>
      <Table
        pagination={false}
        dataSource={trainingContentData}
        columns={columnsTrainingContent}
        scroll={{ x: 800 }}
      />
      <Form className='list-field' form={form} style={{ marginTop: 30 }}>
        <Form.Item
          className='ant-form-item-submit action-form'
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button
            type='primary'
            icon={<PlusOutlined />}
            htmlType='submit'
            onClick={handleAdd}
            disabled={!isEdit}
            className={`btn-add-form`}
          >
            Add Training Content
          </Button>
        </Form.Item>
      </Form>
      <Form className='list-field' form={form} style={{ marginTop: 30 }}>
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
      <ModalActionTrainingContent
        data={trainingContentData[idxEdit]}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
