import { Button, Form, Input, Modal, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IProblemCategory } from 'models/problemCategory';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { problemCategoryActions, selectProblemCategoryLoad } from '../problemCategorySlice';
import routesString from 'routes_string';

const ModalForm: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const problemCategory = useAppSelector(
    (state) => state.problemCategory.list?.filter((item: IProblemCategory) => item.id === id)[0],
  );

  useEffect(() => {
    form.setFieldsValue({
      name: problemCategory?.name || '',
    });
  }, [problemCategory, form]);
  useEffect(() => {
    if (id !== '') dispatch(problemCategoryActions.fetchProblemCategoryDetail({ id }));
  }, [id, dispatch]);

  const loaded = useAppSelector(selectProblemCategoryLoad);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      const idRandom = Math.random().toString(36).substring(2);
      setRandomState(idRandom);
      if (uid === id) {
        notification.success({
          message: 'Problem Category updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else if (id !== '') {
        notification.success({
          message: 'Problem Category updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate(routesString.SETTINGS_PROBLEM_CATEGORY),
          duration: 2.5,
        });
        setIsModalVisible(false);
      } else {
        notification.success({
          message: 'Problem Category record created successfully',
          placement: 'bottomRight',
          onClose: () => {
            resetForm();
            navigate(routesString.SETTINGS_PROBLEM_CATEGORY);
          },
          duration: 2.5,
        });
        setIsModalVisible(false);
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);

  const onFinish = () => {
    const problemCategory: IProblemCategory = form.getFieldsValue();
    if (id !== '') {
      dispatch(problemCategoryActions.updateProblemCategory({ ...problemCategory, id }));
    } else
      dispatch(
        problemCategoryActions.createProblemCategory({
          ...problemCategory,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={
        <>{id !== '' ? <div>Update Problem Category</div> : <div>Create Problem Category</div>}</>
      }
      forceRender
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Problem Category Name'
          name='name'
          rules={[{ required: true, message: 'Please input Category Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;
