import { User } from 'models';
import { Role } from 'models/enum/role';

const getLocalRefreshToken = () => {
  if (!localStorage.getItem('user')) return null;
  const user = JSON.parse(localStorage.getItem('user') || '');
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  if (!localStorage.getItem('user')) return null;
  const user = JSON.parse(localStorage.getItem('user') || '');
  return user?.accessToken;
};

const updateLocalAccessToken = (token: string) => {
  const user = JSON.parse(localStorage.getItem('user') || '');
  user.accessToken = token;
  localStorage.setItem('user', JSON.stringify(user));
};

const isAdmin = () => {
  return getUser().role === Role.SC_ADMIN;
};

const isSystemAdmin = () => {
  return getUser().role === Role.SYSTEM_ADMIN;
};

const isSCAdminSystemAdmin = () => {
  return isAdmin() || isSystemAdmin();
};

const isDisabled = () => {
  return getUser().role !== Role.SYSTEM_ADMIN;
};

const updateLocalRefreshToken = (token: string) => {
  const user = JSON.parse(localStorage.getItem('user') || '');
  user.refreshToken = token;
  localStorage.setItem('user', JSON.stringify(user));
};

const getUser = (): User => {
  return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null;
};

const setUser = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  getUser,
  setUser,
  removeUser,
  isAdmin,
  isSystemAdmin,
  isDisabled,
  isSCAdminSystemAdmin,
};

export default TokenService;
