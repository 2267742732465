import {
  CalendarOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShakeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import routesString from 'routes_string';
import TokenService from 'utils/token.service';

type MenuItem = Required<MenuProps>['items'][number];

// Keep open menu Admin
const setOpenKeysAdmin = (path: string): string[] => {
  switch (true) {
    case path.includes('inspection/annexB') ||
      path.includes(routesString.COMMISSIONING_OTHER_REGIONS):
      return ['3'];
    case path.includes('inspection/annexC') ||
      path.includes(routesString.MAINTENANCE_OTHER_REGIONS):
      return ['4'];
    case ['settings/country/province'].some((item) => path.includes(item)):
      return ['6', '7'];
    case ['settings/charger-model/serial-number'].some((item) => path.includes(item)):
      return ['6', '9'];
    case ['settings'].some((item) => path.includes(item)):
      return ['6'];
    default:
      return [];
  }
};

// Keep open menu User
const setOpenKeysUser = (path: string): string[] => {
  switch (true) {
    case path.includes('inspection/annexB') ||
      path.includes(routesString.COMMISSIONING_OTHER_REGIONS):
      return ['2'];
    case path.includes('inspection/annexC') ||
      path.includes(routesString.MAINTENANCE_OTHER_REGIONS):
      return ['3'];
    default:
      return [];
  }
};

const setActiveSidebarAdmin = (path: string) => {
  switch (true) {
    case path.includes('overview'):
      return '1';
    case path.includes('ticket'):
      return '2';
    case path.includes('inspection/annexB'):
      return '17';
    case path.includes(routesString.COMMISSIONING_OTHER_REGIONS):
      return '15';
    case path.includes('inspection/annexC'):
      return '18';
    case path.includes(routesString.MAINTENANCE_OTHER_REGIONS):
      return '19';
    case path.includes('user'):
      return '5';
    case path.includes('settings/country/province'):
      return '13';
    case path.includes('settings/country'):
      return '7';
    case path.includes('settings/company'):
      return '8';
    case path.includes('settings/charger-model/serial-number'):
      return '14';
    case path.includes('settings/charger-model'):
      return '9';
    case path.includes('settings/terms'):
      return '10';
    case path.includes('settings/policy'):
      return '11';
    case path.includes(routesString.SETTINGS_CATEGORY):
      return '12';
    case path.includes('settings/certificate-admin'):
      return '20';
    case path.includes('training'):
      return '16';
    case path.includes('certification'):
      return '21';
    case path.includes(routesString.SETTINGS_PROBLEM_CATEGORY):
      return '22';
    default:
      return '1';
  }
};

const setActiveSidebarUser = (path: string) => {
  switch (true) {
    case path.includes('ticket'):
      return '1';
    case path.includes('inspection/annexB'):
      return '4';
    case path.includes(routesString.COMMISSIONING_OTHER_REGIONS):
      return '5';
    case path.includes('inspection/annexC'):
      return '18';
    case path.includes(routesString.MAINTENANCE_OTHER_REGIONS):
      return '19';
    case path.includes('training'):
      return '16';
    case path.includes('certification'):
      return '20';
    default:
      return '1';
  }
};

const removeSelectedMenu = (selector: string) => {
  const element = document.querySelector(`.${selector}.ant-menu-item-selected`);
  if (element && element?.className.indexOf('ant-menu-item-selected') > -1)
    element.className = selector;
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  navigate?: NavigateFunction,
  type?: 'group',
  disableItem?: boolean,
): MenuItem {
  let className;
  switch (key) {
    case '7':
      className = 'country-key';
      break;
    case '9':
      className = 'charger-model-key';
      break;
    default:
      className = '';
      break;
  }
  return {
    key,
    icon,
    children,
    label,
    type,
    className: disableItem ? 'disabled-menu' : className,
    onClick: (evt) => {
      // remove class ant-menu-item-selected for menu
      removeSelectedMenu('ant-menu-submenu-title');
    },
    onTitleClick: (info) => {
      const className = info.domEvent.currentTarget.className;
      if (['7', '9'].includes(info.key)) {
        removeSelectedMenu('ant-menu-submenu-title');
        removeSelectedMenu('ant-menu-item');
      }
      switch (true) {
        case info.key === '7':
          info.domEvent.currentTarget.className = `${className} ant-menu-item-selected`;
          navigate && navigate('/settings/country');
          break;
        case info.key === '9':
          info.domEvent.currentTarget.className = `${className} ant-menu-item-selected`;
          navigate && navigate('/settings/charger-model');
          break;
      }
    },
  } as MenuItem;
}

const itemsAdmin = (navigate: any, isDisabled?: boolean): MenuItem[] => {
  return [
    getItem(
      'Overview',
      '1',
      <Link to='/overview'>
        <CalendarOutlined />
      </Link>,
    ),
    getItem(
      'Tickets management',
      '2',
      <Link to='/ticket'>
        <ShakeOutlined />
      </Link>,
    ),
    getItem('Commissioning', '3', <CalendarOutlined />, [
      getItem('Singapore', '17', <Link to='/inspection/annexB' />),
      getItem('Other Regions', '15', <Link to={routesString.COMMISSIONING_OTHER_REGIONS} />),
    ]),
    getItem('Maintenance', '4', <CalendarOutlined />, [
      getItem('Singapore', '18', <Link to='/inspection/annexC' />),
      getItem('Other Regions', '19', <Link to={routesString.MAINTENANCE_OTHER_REGIONS} />),
    ]),
    getItem(
      'Training',
      '16',
      <Link to='/training'>
        <ShakeOutlined />
      </Link>,
    ),
    getItem(
      'Certification',
      '21',
      <Link to='/certification'>
        <UserOutlined />
      </Link>,
    ),
    getItem(
      'User management',
      '5',
      <Link to='/user'>
        <UserOutlined />
      </Link>,
      undefined,
      undefined,
      undefined,
      isDisabled,
    ),
    getItem('Settings', '6', <SettingOutlined />, [
      getItem(
        'Country',
        '7',
        <Link to='/settings/country' />,
        [getItem('Province', '13', <Link to='/settings/country/province' />)],
        navigate,
      ),
      getItem('Company', '8', <Link to='/settings/company' />),
      getItem(
        'Charger Model',
        '9',
        <Link to='/settings/charger-model' />,
        [getItem('Serial Number', '14', <Link to='/settings/charger-model/serial-number' />)],
        navigate,
      ),
      getItem('Category', '12', <Link to={routesString.SETTINGS_CATEGORY} />),
      getItem('Certificate', '20', <Link to='/settings/certificate-admin' />),
      getItem('Problem Category', '22', <Link to={routesString.SETTINGS_PROBLEM_CATEGORY} />),
      getItem('Terms & Conditions', '10', <Link to='/settings/terms' />),
      getItem('Privacy Policy', '11', <Link to='/settings/policy' />),
    ]),
  ];
};

const itemsUser: MenuItem[] = [
  getItem(
    'Tickets management',
    '1',
    <Link to='/ticket'>
      <ShakeOutlined />
    </Link>,
  ),
  getItem('Commissioning', '2', <CalendarOutlined />, [
    getItem('Singapore', '4', <Link to='/inspection/annexB' />),
    getItem('Other Regions', '5', <Link to={routesString.COMMISSIONING_OTHER_REGIONS} />),
  ]),
  getItem('Maintenance', '3', <CalendarOutlined />, [
    getItem('Singapore', '18', <Link to='/inspection/annexC' />),
    getItem('Other Regions', '19', <Link to={routesString.MAINTENANCE_OTHER_REGIONS} />),
  ]),
  getItem(
    'Training',
    '16',
    <Link to='/training'>
      <ShakeOutlined />
    </Link>,
  ),
];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const isAdmin = TokenService.isAdmin() || TokenService.isSystemAdmin();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    isAdmin ? setActiveSidebarAdmin(location.pathname) : setActiveSidebarUser(location.pathname),
  ]);
  const [openKeys, setOpenKeys] = useState<string[]>(
    isAdmin ? setOpenKeysAdmin(location.pathname) : setOpenKeysUser(location.pathname),
  );
  const navigate = useNavigate();
  // let selectedKeys: string[] = ['1'];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onSelect = (evt: any) => {
    setSelectedKeys(evt.selectedKeys);
  };

  const onOpenChange = (evt: string[]) => {
    const elementChargerModel = document.querySelector(
      `.charger-model-key>.ant-menu-item-selected`,
    );
    const elementCountry = document.querySelector(`.country-key>.ant-menu-item-selected`);
    if (elementChargerModel || elementCountry) {
      setSelectedKeys([]);
    }
    setOpenKeys(evt);
  };

  return (
    <div className={`admin-sidebar ${collapsed ? 'collapsed' : ''}`}>
      <Menu
        onOpenChange={onOpenChange}
        onSelect={onSelect}
        openKeys={openKeys}
        mode='inline'
        inlineCollapsed={collapsed}
        items={isAdmin ? itemsAdmin(navigate, !TokenService.isSystemAdmin()) : itemsUser}
        selectedKeys={selectedKeys}
        inlineIndent={20}
      />
      <div onClick={toggleCollapsed} className='btn-collapse'>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
    </div>
  );
};

export default Sidebar;
