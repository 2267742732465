import { Button, Form, Input, Radio, RadioChangeEvent } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CommissionRegion } from 'models';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const RadioValue = {
  C: '0',
  NC: '1',
  NA: '2',
};

const check_after_PO = [
  {
    no: 1,
    name: 'Output voltage of the main breaker',
    tile: '400V（±10%）',
    value: '1',
    remark: 'test',
  },
  { no: 2, name: 'Touch Screen', tile: 'The screen shows normally', value: '1' },
  { no: 3, name: 'Indicators', tile: 'The indicator lights work normally', value: '1' },
  {
    no: 4,
    name: 'Switch mode power supply',
    tile: 'The switch mode power supply can provide 12V/24V power supply voltage normally and stably',
    value: '1',
  },
  { no: 5, name: 'Configuration', tile: 'Configure according to the requirements', value: '1' },
  { no: 6, name: 'Backend connection', tile: 'Connect successfully', value: '1' },
];

export const CheckAfterPowerOn: React.FC<{
  nextStep: any;
  prevStep: any;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();
  const [isRequiredRemarks, setIsRequiredRemarks] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (inspOtherRegion) {
      const isDisabledRemarkData: { [key: string]: boolean } = {};
      const check_after_PO = inspOtherRegion.check_after_PO?.map((item) => {
        const fieldName = `check_after_PO_note_${item.no}`;
        isDisabledRemarkData[fieldName] = isRequiredRemark(`${item.no}`, item.value);
        return {
          [`check_after_PO_${item.no}`]: item.value,
          [`check_after_PO_note_${item.no}`]: item.remark,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(check_after_PO),
      };
      setIsRequiredRemarks(isDisabledRemarkData);
      form.setFieldsValue(initForm);
    }
  }, []);

  const isRequiredRemark = (itemNo: string, value: string) => {
    return ['1'].includes(itemNo) && [RadioValue.C, RadioValue.NC].includes(value);
  };

  const handleNext = (values: any) => {
    const inspectForm = form.getFieldsValue();
    const check_after_PO = [...(inspOtherRegion?.check_after_PO || [])].map((item) => {
      return {
        ...item,
        value: inspectForm['check_after_PO_' + item.no],
        remark: inspectForm['check_after_PO_note_' + item.no],
      };
    });

    nextStep({ ...inspOtherRegion, check_after_PO });
  };

  const handlerChangeRadio = (fieldName: string, value: string) => {
    const fieldNameArr = fieldName.split('_');
    setIsRequiredRemarks({
      ...isRequiredRemarks,
      [`${fieldName}`]: isRequiredRemark(fieldNameArr[fieldNameArr.length - 1], value),
    });
    form.validateFields([fieldName]);
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(inspOtherRegion?.check_after_PO || []).map((item, idx) => {
          const check_after_data = check_after_PO.find((data) => data.no === +item.no);
          return (
            <>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + check_after_data?.name + ': ' + check_after_data?.tile}
                key={item.no}
                initialValue={item.value}
                name={'check_after_PO_' + item.no}
              >
                <Radio.Group
                  disabled={disabled}
                  onChange={(value: RadioChangeEvent) =>
                    handlerChangeRadio('check_after_PO_note_' + item.no, value.target.value)
                  }
                >
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.name}
                key={item.no + '_note'}
                initialValue={item.remark}
                name={'check_after_PO_note_' + item.no}
                rules={[
                  {
                    required: isRequiredRemarks[`check_after_PO_note_${item.no}`],
                    message: 'Please input remark!',
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  className='input-remarks'
                  placeholder='Remarks/measurement value'
                />
              </Form.Item>
            </>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            // onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
