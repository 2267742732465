import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { ImageCustom } from 'components/common/ImageCustom';
import { IPermanentSolutions, Ticket } from 'models';
import React, { useEffect, useState } from 'react';
import { getUTC8DateTimeFormat, isMine } from 'utils/function';
import ModalActionPermanentSolutions from './ModalActionPermanentSolutions';
import TokenService from 'utils/token.service';
import { TicketStatus } from 'models/enum/ticket';
const isOpen = (status: TicketStatus) => {
  return status === TicketStatus.Open;
};
export const PermanentSolutions: React.FC<{
  ticket: Ticket | undefined;
  data: IPermanentSolutions[];
  handleChange: any;
}> = ({ ticket, data, handleChange }) => {
  const [permanentSolutions, setPermanentSolutions] = useState<IPermanentSolutions[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idxEdit, setIdxEdit] = useState(-1);
  const isSystemAdmin = TokenService.isSystemAdmin();

  const isEdit =
    isSystemAdmin ||
    (ticket?.status !== undefined &&
      isOpen(ticket?.status) &&
      isMine(ticket?.service_engineer || ''));

  useEffect(() => {
    if (data) {
      const info: IPermanentSolutions[] = data as IPermanentSolutions[];
      setPermanentSolutions(info);
    }
  }, [data]);

  const columns: ColumnsType<IPermanentSolutions> = [
    {
      title: 'Permanent Solutions',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_: unknown, record: IPermanentSolutions) => {
        return getUTC8DateTimeFormat(record.time || '');
      },
    },
    {
      title: 'Upload Image',
      dataIndex: 'assets',
      key: 'assets',
      render: (_: unknown, record: IPermanentSolutions) => {
        return record?.assets?.map((item) => (
          <ImageCustom
            key={item.url}
            className='user-avatar'
            type='link'
            url={(item.thumb ? item.thumb : item.url) || ''}
            video={item.thumb ? `${item.url}` : ''}
          />
        ));
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: unknown, record: IPermanentSolutions, index: number) => {
        return (
          <>
            <span
              style={{
                color: '#e98d3e',
                cursor: !isEdit ? 'cursor' : 'pointer',
                opacity: !isEdit ? 0.5 : 1,
              }}
              onClick={() => {
                setIsModalVisible(!!isEdit);
                setIdxEdit(index);
              }}
            >
              Edit
            </span>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsModalVisible(true);
    setIdxEdit(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUpsert = (data: IPermanentSolutions) => {
    let permanentData = [...permanentSolutions];
    if (idxEdit < 0) {
      permanentData = [...permanentData, data];
    } else {
      const tmp = [...permanentSolutions];
      tmp[idxEdit] = data;
      permanentData = tmp;
    }
    setPermanentSolutions(permanentData);
    handleChange({ permanent_solution: permanentData });
  };

  return (
    <div className='form-wrapper'>
      <Table
        dataSource={permanentSolutions}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={false}
      />

      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        <Button type='primary' icon={<PlusOutlined />} onClick={handleAdd} disabled={!isEdit}>
          Add Root Cause
        </Button>
      </div>
      <ModalActionPermanentSolutions
        data={permanentSolutions[idxEdit]}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsModalVisible={setIsModalVisible}
        onFinish={handleUpsert}
      />
    </div>
  );
};
