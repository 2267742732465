import { CommissionRegion, ListParams, ListResponse } from 'models';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const commissionRegionApi = {
  upsert(param: CommissionRegion): Promise<CommissionRegion> {
    const url = `${SCIMMS_API}/commission-region/${param.id}`;
    return axiosClient.put(url, param);
  },

  getById(id: string): Promise<CommissionRegion> {
    const url = `${SCIMMS_API}/commission-region/one?id=${id}`;
    return axiosClient.get(url);
  },

  add(data: CommissionRegion): Promise<CommissionRegion> {
    const url = '/commission-region';
    return axiosClient.post(url, data);
  },

  update(data: Partial<CommissionRegion>): Promise<CommissionRegion> {
    const url = `/commission-region/${data.id}`;
    return axiosClient.patch(url, data);
  },

  remove(id: string): Promise<any> {
    const url = `/commission-region/${id}`;
    return axiosClient.delete(url);
  },
  searchByParams(params: ListParams): Promise<ListResponse<CommissionRegion>> {
    const url = `${SCIMMS_API}/commission-region/find-by-params`;
    return axiosClient.get(url, {
      params,
    });
  },
  deleteInspection(ids: any): Promise<any> {
    const url = `${SCIMMS_API}/commission-region`;
    return axiosClient.put(url, {
      ...ids,
    });
  },
};

export default commissionRegionApi;
