import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectChargerModels } from 'features/settings/charger-model/chargerModelSlice';
import { SerialNumber } from 'models/serialNumber';
import React, { useCallback, useEffect } from 'react';
import TokenService from 'utils/token.service';
import { selectSerialNumberUpserted, serialNumberActions } from '../serialNumberSlice';

const ModalCreate: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const uid = TokenService.getUser().id;
  const serialNumber = useAppSelector(
    (state) => state.serialNumber?.list?.filter((item: SerialNumber) => item.id === id)[0],
  );
  const chargerModelOption = useAppSelector(selectChargerModels);

  useEffect(() => {
    if (serialNumber && id) {
      form.setFieldsValue({
        name: serialNumber?.name || '',
        charger_models: serialNumber?.charger_models?.map((item) => item.id) || [],
      });
    } else {
      resetForm();
    }
  }, [serialNumber, form, id]);
  useEffect(() => {
    if (isModalVisible) {
      dispatch(serialNumberActions.setUpsertedSN({}));
    }
  }, [isModalVisible]);

  const loaded = useAppSelector(selectSerialNumberUpserted);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
      charger_models: [],
    });
  };

  const openNotification = useCallback(() => {
    if (isModalVisible) {
      if (loaded) {
        const idRandom = Math.random().toString(36).substring(2);
        setRandomState(idRandom);
        if (uid === id) {
          notification.success({
            message: 'Serial Number updated successfully',
            placement: 'bottomRight',
            duration: 2.5,
          });
          setIsModalVisible(false);
        } else if (id !== '') {
          notification.success({
            message: 'Serial Number updated successfully',
            placement: 'bottomRight',
            duration: 2.5,
          });
          setIsModalVisible(false);
        } else {
          notification.success({
            message: 'Serial Number record created successfully',
            placement: 'bottomRight',
            //onClose: () => resetForm(),
            onClose: () => {
              resetForm();
            },
            duration: 2.5,
          });
          setIsModalVisible(false);
        }
        resetForm();
      }
    }
  }, [loaded, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const name = form.getFieldValue('name');
    const charger_models = form.getFieldValue('charger_models')?.map((item: string) => {
      return {
        id: item,
      };
    });
    if (id !== '') {
      dispatch(serialNumberActions.updateSerialNumber({ name, charger_models, id }));
    } else
      dispatch(
        serialNumberActions.createSerialNumber({
          name,
          charger_models,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{id !== '' ? <div>Update Serial Number</div> : <div>Create Serial Number</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Serial Number Name'
          name='name'
          rules={[{ required: true, message: 'Please input Serial Number Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Charger Model'
          name='charger_models'
          rules={[{ required: true, message: 'Please input your Charger Model!' }]}
        >
          <Select mode='multiple' allowClear>
            {chargerModelOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreate;
