import { AutoComplete, Button, DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { selectCompanies } from 'features/settings/company/companySlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { MaintenanceRegion } from 'models';
import { SerialNumberList } from 'models/serialNumber';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { findIdSerialByName } from 'utils/function';
import TokenService from 'utils/token.service';

const Step1: React.FC<{
  nextStep: any;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, data }) => {
  const [form] = useForm();

  const disabled = TokenService.isDisabled();
  const [isValid, setIsValid] = useState<boolean>(true);
  const chargerModelOption = useAppSelector(selectChargerModels);
  const dispatch = useAppDispatch();
  const [serialNumberData, setSerialNumberData] = useState<SerialNumberList[]>([]);
  const listSerialNumbers = useAppSelector(selectSerialNumbers);

  const [optionCompanies, setOptionCompanies] = useState<SerialNumberList[]>([]);
  const companies = useAppSelector(selectCompanies);
  useEffect(() => {
    if (companies) {
      const info: SerialNumberList[] = [];
      const obj = companies;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setOptionCompanies(info);
    }
  }, [companies]);

  const [countryData, setCountryData] = useState<SerialNumberList[]>([]);
  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  useEffect(() => {
    if (listCountry) {
      const info: SerialNumberList[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);

  useEffect(() => {
    dispatch(
      serialNumberActions.search({
        filter: { name: '', chargerModelId: '' },
        pagination: { size: 10000 },
      }),
    );

    dispatch(chargerModelActions.search({ name: '', pagination: { size: 1000 } }));
  }, [dispatch]);

  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: SerialNumberList[] = [];
      const obj = listSerialNumbers;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setSerialNumberData(info1);
    }
  }, [listSerialNumbers]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        maintenance_date:
          (data?.maintenance_date ? moment(new Date(data?.maintenance_date)) : null) ||
          format(new Date(), 'yyyy-MM-dd'),
        work_order_ID: data.work_order_ID || '',
        charger_owner: data.charger_owner?.name || data.charger_owner || '',
        country: data.country?.name || data.country || '',
        site_name: data.site_name || '',
        detailed_address: data.detailed_address || '',
        charger_model: data.charger_model?.id ?? '',
        serial_number: data.serial_number?.name ?? '',
      });
      handleFormChange();
    }
  }, [data, form, optionCompanies]);

  const [resultSerialNumber, setResultSerialNumber] = useState<SerialNumberList[]>();

  useMemo(() => {
    if ((!resultSerialNumber || resultSerialNumber.length === 0) && serialNumberData.length > 0) {
      setResultSerialNumber(serialNumberData);
    }
  }, [serialNumberData, resultSerialNumber]);

  const handleSearch = (value: string) => {
    const rs = serialNumberData.filter(
      (item) => item.value?.toUpperCase().indexOf(value.toUpperCase()) !== -1,
    );
    setResultSerialNumber(rs);
  };

  const handleNext = () => {
    const maintenanceForm: MaintenanceRegion = form.getFieldsValue();

    const country = listCountry.find(
      (country) =>
        country.id === form.getFieldValue('country') ||
        country.name === form.getFieldValue('country'),
    );
    const charger_owner = companies.find(
      (company) =>
        company.id === form.getFieldValue('charger_owner') ||
        company.name === form.getFieldValue('charger_owner'),
    );

    const charger_model = chargerModelOption.find(
      (chargerModel) =>
        chargerModel.id === form.getFieldValue('charger_model') ||
        chargerModel.name === form.getFieldValue('charger_model'),
    );
    const serial_number = {
      id: findIdSerialByName(form.getFieldValue('serial_number'), serialNumberData),
      name: form.getFieldValue('serial_number'),
    };

    const updateObj = {
      ...data,
      ...maintenanceForm,
      country,
      charger_owner,
      charger_model,
      serial_number,
    };
    nextStep({ ...updateObj });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormChange = () => {
    if (hasFieldEmpty()) setIsValid(false);
    else {
      const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
      setIsValid(!hasErrors);
    }
  };

  const hasFieldEmpty = () => {
    return Object.keys(form.getFieldsValue()).some((field) => {
      return !form.getFieldValue(field)?.toString()?.trim();
    });
  };

  return (
    <Form
      name='form-other-region'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={handleNext}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
      onFieldsChange={handleFormChange}
    >
      <Form.Item
        label='1. Work Order ID'
        name='work_order_ID'
        rules={[{ required: false, message: '' }]}
      >
        <Input disabled={disabled} placeholder='Value' />
      </Form.Item>
      <Form.Item
        label='2. Charger Model'
        name='charger_model'
        rules={[{ required: true, message: 'Please input Charger Model' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Charger Model'
          allowClear
          disabled={disabled}
        >
          {chargerModelOption.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='3. Serial Number'
        name='serial_number'
        rules={[{ required: true, message: 'Please input Serial Number!' }]}
      >
        <AutoComplete onSearch={handleSearch} placeholder='Value' disabled={disabled}>
          {resultSerialNumber?.map(({ id, value: name }) => (
            <Select.Option key={id} value={name}>
              {name}
            </Select.Option>
          ))}
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label='4. Charger Owner'
        name='charger_owner'
        rules={[{ required: true, message: 'Please input Charger Owner!' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Charger Owner'
          allowClear
          disabled={disabled}
        >
          {optionCompanies.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='5. Maintenance Date'
        name='maintenance_date'
        rules={[{ required: true, message: 'Please input Maintenance Date!' }]}
      >
        <DatePicker disabled={disabled} placeholder='Select Date' />
      </Form.Item>
      <Form.Item
        label='6. Country'
        name='country'
        rules={[{ required: true, message: 'Please input Country!' }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder='Select Country'
          allowClear
          disabled={disabled}
        >
          {countryData.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='7. Site Name'
        name='site_name'
        rules={[{ required: true, message: 'Please input Site Name!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='8. Detailed Address'
        name='detailed_address'
        rules={[{ required: true, message: 'Please input Detailed Address!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <div className='action-form'>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
            disabled={!isValid}
          >
            Next step
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default Step1;
