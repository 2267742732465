import { LoadingOutlined } from '@ant-design/icons';
import { Inspection } from 'models';
import React from 'react';

const ExportPdf: React.FC<{
  record: Inspection;
  onExport: any;
  disabled: boolean;
  loading: boolean;
  // eslint-disable-next-line react/prop-types
}> = ({ record, onExport, disabled, loading }) => {
  const handleExportPdf = () => {
    if (!disabled) onExport(record);
  };
  return (
    <>
      <span style={{ cursor: disabled ? 'default' : 'pointer' }} onClick={() => handleExportPdf()}>
        {loading && <LoadingOutlined />}
        Export pdf
      </span>
      {/* 
      <div
        className="export-pdf"
        style={{ opacity: 0, overflow: "hidden", height: 0 }}
      >
        <div id="page1">
          <Page1 datas={record} />
        </div>
        <div id="page2">
          <Page2 datas={record} />
        </div>
        <div id="page3">
          <Page3 datas={record} />
        </div>
        <div id="page4">
          <Page4 datas={record} />
        </div>
        <div id="page5">
          <Page5 datas={record} />
        </div>
      </div> */}
    </>
  );
};
export default ExportPdf;
