import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { companyActions, selectCompanies } from 'features/settings/company/companySlice';
import { countryActions } from 'features/settings/country/countrySlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { MaintenanceConclusion } from 'models/enum/maintenance';
import { SerialNumberList } from 'models/serialNumber';
import React, { useEffect, useMemo, useState } from 'react';
import { removeEmptyAttributes } from 'utils/function';
import { Filter, maintenanceOtherRegionActions } from '../maintenanceOtherRegionsSlice';

const OtherRegionsFilterForm: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const chargerModelOption = useAppSelector(selectChargerModels);
  // start charger owner
  const [optionCompanies, setOptionCompanies] = useState<{ value: string }[]>([]);
  const companies = useAppSelector(selectCompanies);
  useEffect(() => {
    if (companies) {
      const result = (companies || []).map((item) => {
        return { value: item.name };
      });
      setOptionCompanies(result);
    }
  }, [companies]);
  // end charger owner
  // start serial number
  const [serialNumberData, setSerialNumberData] = useState<SerialNumberList[]>([]);
  const listSerialNumbers = useAppSelector(selectSerialNumbers);
  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: SerialNumberList[] = [];
      const obj = listSerialNumbers;

      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      form.setFieldValue('serial_number', listSerialNumbers[0].name);
      setSerialNumberData(info1);
    }
  }, [listSerialNumbers]);
  // end serial number

  useEffect(() => {
    dispatch(chargerModelActions.search({ name: '', pagination: { size: 1000 } }));
  }, [dispatch]);

  // country start
  const [countryData, setCountryData] = useState<SerialNumberList[]>([]);
  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  useEffect(() => {
    if (listCountry) {
      const info: SerialNumberList[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);
  useEffect(() => {
    dispatch(
      countryActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(companyActions.search({ name: '', pagination: { size: 100000 } }));
    dispatch(
      serialNumberActions.search({
        filter: {
          name: '',
          chargerModelId: '',
        },
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  }, [dispatch]);
  // country end

  const findChargerModelById = (id: string) => {
    return chargerModelOption.find((item) => item.id === id)?.name;
  };
  const onSearch = () => {
    const filter: Filter = {};
    const maintenance_date = form.getFieldValue('maintenance_date');
    const engineer_name = form.getFieldValue('engineer_name');
    let company_id = form.getFieldValue('company_id');
    const charger_owner_name = form.getFieldValue('charger_owner_name');
    const country_name = form.getFieldValue('country_name');
    const site_name = form.getFieldValue('site_name');
    const brand = form.getFieldValue('brand');
    const sn_Number = form.getFieldValue('sn_Number');
    const work_order_ID = form.getFieldValue('work_order_ID');
    const conclusion_result = form.getFieldValue('conclusion_result');
    const specialist_name = form.getFieldValue('specialist_name');
    const specialist_company_name = form.getFieldValue('specialist_company_name');
    const representative_name = form.getFieldValue('representative_name');
    const representative_company_name = form.getFieldValue('representative_company_name');

    if (maintenance_date && maintenance_date.length > 0) {
      filter.maintenance_date_from = maintenance_date[0].format('YYYY-MM-DD');
      filter.maintenance_date_to = maintenance_date[1].format('YYYY-MM-DD');
    }
    if (company_id) {
      const findCompany = companies.find(
        (company) => company.id === company_id || company.name === company_id,
      );
      if (findCompany) {
        company_id = findCompany.id;
      }
      filter.company_id = company_id;
    }
    if (brand) {
      filter.charger_model_name = findChargerModelById(brand);
    }

    filter.engineer_name = engineer_name;
    filter.charger_owner_name = charger_owner_name;
    filter.country_name = country_name;
    filter.site_name = site_name;
    filter.sn_Number = sn_Number;
    filter.work_order_ID = work_order_ID;
    filter.conclusion_result = conclusion_result;
    filter.specialist_name = specialist_name;
    filter.specialist_company_name = specialist_company_name;
    filter.representative_name = representative_name;
    filter.representative_company_name = representative_company_name;

    const finalFilter = removeEmptyAttributes(filter);

    dispatch(maintenanceOtherRegionActions.search(finalFilter));
  };

  const conclusionOption = [
    { title: 'All', value: '' },
    { title: 'Pass', value: MaintenanceConclusion.Pass },
    { title: 'Fail', value: MaintenanceConclusion.Fail },
  ];

  const [resultSerialNumber, setResultSerialNumber] = useState<SerialNumberList[]>();

  useMemo(() => {
    if ((!resultSerialNumber || resultSerialNumber.length === 0) && serialNumberData.length > 0) {
      setResultSerialNumber(serialNumberData);
    }
  }, [serialNumberData, resultSerialNumber]);

  const handleSearch = (value: string) => {
    const rs = serialNumberData.filter(
      (item) => item.value?.toUpperCase().indexOf(value.toUpperCase()) !== -1,
    );
    setResultSerialNumber(rs);
  };

  return (
    <div className='maintenance-other-regions-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Work Order ID' name='work_order_ID' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Model' name='brand' className='short-title'>
              <Select
                dropdownMatchSelectWidth={false}
                placeholder='Select Charger Model'
                allowClear
              >
                {chargerModelOption.map((item, index) => (
                  <Select.Option value={item.id} key={index}>
                    <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Serial Number' name='sn_Number' className='short-title'>
              <AutoComplete onSearch={handleSearch}>
                {resultSerialNumber?.map(({ id, value: name }) => (
                  <Select.Option key={id} value={name}>
                    {name}
                  </Select.Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Owner' name='charger_owner_name' className='short-title'>
              <AutoComplete
                options={optionCompanies}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Maintenance Date' name='maintenance_date' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item className='short-title'>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align='bottom'>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Country' name='country_name' className='short-title'>
              <AutoComplete
                options={countryData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Site Name' name='site_name' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            {' '}
            <Form.Item label='Conclusion' name='conclusion_result' className='short-title'>
              <Select>
                {conclusionOption.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item
              label='Name of equipment specialist'
              name='specialist_name'
              className='short-title'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item
              label='Company of equipment specialist'
              name='specialist_company_name'
              className='short-title'
            >
              <AutoComplete
                options={optionCompanies}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Name of owner / operator representative' name='representative_name'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item
              label='Company of owner / operator representative'
              name='representative_company_name'
            >
              <AutoComplete
                options={optionCompanies}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OtherRegionsFilterForm;
