import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { companyActions, selectCompanies } from 'features/settings/company/companySlice';
import { countryActions } from 'features/settings/country/countrySlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import React, { useEffect, useState } from 'react';
import { Filter, inspOtherRegionActions } from '../inspOtherRegionSlice';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const OtherRegionFilterForm: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const chargerModelOption = useAppSelector(selectChargerModels);
  // start charger owner
  const [optionCompanies, setOptionCompanies] = useState<{ value: string }[]>([]);
  const companies = useAppSelector(selectCompanies);
  useEffect(() => {
    if (companies) {
      const result = (companies || []).map((item) => {
        return { value: item.name };
      });
      setOptionCompanies(result);
    }
  }, [companies]);
  // end charger owner
  // start serial number
  const [serialNumberData, setSerialNumberData] = useState<DataType[]>([]);
  const listSerialNumbers = useAppSelector(selectSerialNumbers);
  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: DataType[] = [];
      const obj = listSerialNumbers;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      form.setFieldValue('serial_number', listSerialNumbers[0].name);
      setSerialNumberData(info1);
    }
  }, [listSerialNumbers]);
  // end serial number

  useEffect(() => {
    dispatch(
      chargerModelActions.fetchChargerModelList({}),
      // chargerModelActions.fetchChargerModelList({
      //   flag_service: FlagService.CM,
      // }),
    );
  }, [dispatch]);

  // country start
  const [countryData, setCountryData] = useState<DataType[]>([]);
  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  useEffect(() => {
    if (listCountry) {
      const info: DataType[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);
  useEffect(() => {
    // dispatch(countryActions.fetchCountryList({}));
    dispatch(
      countryActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(companyActions.search({ name: '', pagination: { size: 100000 } }));
    dispatch(
      serialNumberActions.search({
        filter: {
          name: '',
          chargerModelId: '',
        },
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  }, [dispatch]);
  // country end

  // charger model start
  const [chargerModelId, setChargerModelId] = useState('');
  const [searchName, setSearchName] = useState('');
  const handleChangeChargerModel = (chargerModelId: string) => {
    setChargerModelId(chargerModelId);
    dispatch(
      serialNumberActions.search({
        filter: { name: searchName, chargerModelId },
      }),
    );
  };
  // charger model end

  const findChargerModelById = (id: string) => {
    return chargerModelOption.find((item) => item.id === id)?.name;
  };
  const onSearch = () => {
    const filter: Filter = {};
    const commissioning_date = form.getFieldValue('commissioning_date');
    const engineer_name = form.getFieldValue('engineer_name');
    let company_id = form.getFieldValue('company_id');
    const charger_owner_name = form.getFieldValue('charger_owner_name');
    const country_name = form.getFieldValue('country_name');
    const site_name = form.getFieldValue('site_name');
    const brand = form.getFieldValue('brand');
    const sn_Number = form.getFieldValue('sn_Number');

    if (commissioning_date && commissioning_date.length > 0) {
      filter.commissioning_date_from = commissioning_date[0].format('YYYY-MM-DD');
      filter.commissioning_date_to = commissioning_date[1].format('YYYY-MM-DD');
    }
    if (engineer_name) {
      filter.engineer_name = engineer_name;
    }
    if (company_id) {
      const findCompany = companies.find(
        (company) => company.id === company_id || company.name === company_id,
      );
      if (findCompany) {
        company_id = findCompany.id;
      }
      filter.company_id = company_id;
    }
    if (charger_owner_name) {
      filter.charger_owner_name = charger_owner_name;
    }
    if (country_name) {
      filter.country_name = country_name;
    }
    if (site_name) {
      filter.site_name = site_name;
    }
    if (brand) {
      filter.charger_model_name = findChargerModelById(brand);
    }
    if (sn_Number) {
      filter.sn_Number = sn_Number;
    }

    dispatch(inspOtherRegionActions.search(filter));
  };
  return (
    <div className='commission-other-regions-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16} align='bottom'>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Commissioning Date' name='commissioning_date' className='short-title'>
              {/* <DatePicker format='YYYY-MM-DD' /> */}
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Commissioning Engineer Name' name='engineer_name'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Commissioning Engineer Company' name='company_id'>
              <AutoComplete
                options={optionCompanies}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Owner' name='charger_owner_name' className='short-title'>
              <AutoComplete
                options={optionCompanies}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            {' '}
            <Form.Item label='Country' name='country_name' className='short-title'>
              <AutoComplete
                options={countryData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item className='short-title'>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Site Name' name='site_name' className='short-title'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Charger Model' name='brand' className='short-title'>
              <Select
                dropdownMatchSelectWidth={false}
                placeholder='Select Charger Model'
                allowClear
              >
                {chargerModelOption.map((item, index) => (
                  <Select.Option value={item.id} key={index}>
                    <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Serial Number' name='sn_Number' className='short-title'>
              <AutoComplete
                options={serialNumberData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OtherRegionFilterForm;
