/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Inspection, ListParams, ListResponse } from 'models';
import { sortDateByString } from 'utils/function';

export interface InspectionState {
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  list: Inspection[];
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
  formTypeFilter?: string;
}

export interface Filter {
  brand?: string;
  serial?: string;
  owner_name?: string;
  conduct_date?: string;
  address?: string;
  formTypeFilter?: string;
}

export interface InspectionPayload {
  data: Inspection;
  navigate: any;
}
const initialState: InspectionState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    brand: '',
    serial: '',
    owner_name: '',
    address: '',
    conduct_date: '',
  },
  exportPdf: [],
};

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    fetchInspectionList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchInspectionListSuccess(state, action: PayloadAction<ListResponse<Inspection>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || 0,
      };

      state.list = action.payload.data.sort(sortDateByString);
    },
    fetchInspectionListFailed(state) {
      state.loading = false;
    },
    upsertInspection(state, action: PayloadAction<Inspection>) {
      state.loaded = false;
    },
    upsertInspectionSuccess(state) {
      state.loaded = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    search(state, action: PayloadAction<Filter>) {
      state.list = [];
      state.filter = action.payload;
    },
    deleteInspection(state, action: PayloadAction<{ ids: string[] }>) {
      state.deleting = true;
    },
    deleteInspectionSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    deleteRecord(state, action: PayloadAction<{ id: string; form_type: string }>) {
      state.deleting = true;
    },
    deleteRecordSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    deleteRecordFailed(state) {
      state.deleting = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
    fetchInspectionDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchInspectionDetailSuccess(state, action: PayloadAction<Inspection>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
  },
});

// Actions
export const inspectionActions = inspectionSlice.actions;

// Selectors
export const selectInspection = (state: RootState) => state.inspection;
export const selectInspectionList = (state: RootState) => state.inspection.list;
export const selectInspectionLoad = (state: RootState) => state.inspection.loaded;
export const selectInspectionLoading = (state: RootState) => state.inspection.loading;

export const selectPagination = (state: RootState) => state.inspection.pagination;
export const selectFilter = (state: RootState) => state.inspection.filter;
export const selectDeleting = (state: RootState) => state.inspection.deleting;
export const selectExport = (state: RootState) => state.inspection.exportPdf;
export const selectTotalRecords = (state: RootState) => state.inspection.pagination.totalRecords;

// Reducer
const inspectionReducer = inspectionSlice.reducer;
export default inspectionReducer;
