import { AutoComplete, Button, DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import {
  chargerModelActions,
  selectChargerModelsCertificatedUserList,
} from 'features/settings/charger-model/chargerModelSlice';
import {
  selectSerialNumbers,
  serialNumberActions,
} from 'features/settings/serial-number/serialNumberSlice';
import { Inspection } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const BasicInfo: React.FC<{
  nextStep: any;
  inspection: Inspection | undefined;
}> = ({ nextStep, inspection }) => {
  const [form] = useForm();

  const disabled = TokenService.isDisabled();
  const chargerModelsCertificatedUserList = useAppSelector(selectChargerModelsCertificatedUserList);
  const dispatch = useAppDispatch();
  const [serialNumberData, setSerialNumberData] = useState<DataType[]>([]);
  const listSerialNumbers = useAppSelector(selectSerialNumbers);

  useEffect(() => {
    dispatch(
      serialNumberActions.search({
        filter: { name: '', chargerModelId: '' },
        pagination: { size: 10000 },
      }),
    );
    dispatch(chargerModelActions.fetchChargerModelCertificatedUser({}));
  }, [dispatch]);

  useEffect(() => {
    if (listSerialNumbers.length > 0) {
      const info1: DataType[] = [];
      const obj = listSerialNumbers;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      // form.setFieldValue('serial_number', listSerialNumbers[0].name);
      setSerialNumberData(info1);
    }
  }, [listSerialNumbers]);

  useEffect(() => {
    if (inspection) {
      form.setFieldsValue({
        form_type: inspection?.form_type || '',
        purpose_of_use: inspection?.purpose_of_use || '',
        serial_number: inspection?.serial_number?.name || '',
        charger_model: inspection?.charger_model?.name || inspection?.charger_model_id || '',
        owner_name: inspection?.owner_name || '',
        conduct_date:
          (inspection?.conduct_date ? moment(new Date(inspection?.conduct_date)) : null) ||
          format(new Date(), 'yyyy-MM-dd'),
        address: inspection?.address || '',
        access: inspection?.access || '',
        next_inspection_date:
          (inspection?.next_inspection_date
            ? moment(new Date(inspection?.next_inspection_date), 'yyyy-MM-dd')
            : null) || format(new Date(), 'yyyy-MM-dd'),
        equip_name: inspection?.equip_name || '',
        equip_company: inspection?.equip_company || '',
      });
    }
  }, [inspection, form]);

  const handleNext = (values: any) => {
    const inspectForm: Inspection = form.getFieldsValue();
    const serial_number = {
      id: findIdSerialByName(form.getFieldValue('serial_number')),
      name: form.getFieldValue('serial_number'),
    };
    const chargerModel = findChargerModelByName(form.getFieldValue('charger_model'));
    const charger_model = {
      id: chargerModel?.id,
      name: chargerModel?.name,
    };
    const updateObj = {
      ...inspection,
      ...inspectForm,
      serial_number,
      charger_model,
    };
    nextStep({ ...updateObj });
  };

  const findIdSerialByName = (name: string) => {
    const serialItem = serialNumberData.find((serial) => serial.label === name);
    return serialItem?.id || '';
  };

  const findChargerModelByName = (name: string) => {
    const chargerModelItem = chargerModelsCertificatedUserList.find(
      (model) => model.name.toLowerCase() === name.toLowerCase(),
    );
    return chargerModelItem;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeChargerModel = (item: any) => {
    dispatch(
      serialNumberActions.fetchSerialNumberByChargerModel({
        chargerModelId: item,
      }),
    );
  };

  return (
    <Form
      name='form-inspection'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={handleNext}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Form.Item label='Form' name='form_type'>
        <Select disabled>
          <Select.Option value='Annex_B'>Annex B</Select.Option>
          <Select.Option value='Annex_C'>Annex C</Select.Option>
        </Select>
      </Form.Item>
      {inspection?.form_type === 'Annex_B' && (
        <Form.Item label='Purpose of use' name='purpose_of_use'>
          <Select disabled={disabled}>
            <Select.Option value='0'>Checklist for New charger</Select.Option>
            <Select.Option value='1'>Parts replacement</Select.Option>
            <Select.Option value='2'>Modification of EVCS</Select.Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item label='Brand/model of EV charger' name='charger_model'>
        <Select disabled={disabled} onChange={handleChangeChargerModel}>
          {chargerModelsCertificatedUserList.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='S/No'
        name='serial_number'
        rules={[{ required: true, message: 'Please input your S/No!' }]}
      >
        <AutoComplete
          disabled={disabled}
          options={serialNumberData}
          autoFocus={true}
          filterOption={(inputValue, option) =>
            option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
          }
          // onSelect={onSelect}
          // onChange={onChangeCountry}
        />
      </Form.Item>
      <Form.Item
        label='Name of owner/operator'
        name='owner_name'
        rules={[{ required: true, message: 'Please input Name of owner/operator!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Address of Installation'
        name='address'
        rules={[{ required: true, message: 'Please input Address of Installation!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label='Conduct Date'
        name='conduct_date'
        rules={[{ required: true, message: 'Please input Conduct Date!' }]}
      >
        <DatePicker disabled={disabled} />
      </Form.Item>
      <Form.Item label='Access' name='access'>
        <Select disabled={disabled}>
          <Select.Option value='0'>Restricted access(Private)</Select.Option>
          <Select.Option value='1'>Non-restricted access(Public)</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Next inspection date'
        name='next_inspection_date'
        rules={[
          {
            required: true,
            message: 'Please input Recommended date of next inspection!',
          },
        ]}
      >
        <DatePicker disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <div className='action-form'>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default BasicInfo;
