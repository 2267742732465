import { DownOutlined, ExportOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { format } from 'date-fns';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { Inspection } from 'models';
import { InspectionForm } from 'models/enum/inspection';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { exportExcel } from 'utils/function';
import TokenService from 'utils/token.service';
import {
  inspectionActions,
  selectDeleting,
  selectInspectionLoading,
  selectPagination,
} from '../inspectionSlice';
import { Attachment } from './Attachment';
import ExportPdf from './export-pdf';
import { Page1 } from './export-pdf/page1';
import { Page2 } from './export-pdf/page2';
import { Page3 } from './export-pdf/page3';
import { Page4 } from './export-pdf/page4';
import { Page5 } from './export-pdf/page5';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Inspection;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const purposeOfUse = (value: string) => {
  switch (value) {
    case '0':
      return 'Checklist for New charger';
    case '1':
      return 'Parts replacement';
    case '2':
      return 'Modification of EVCS';
    default:
      return 'N/A';
  }
};

const TableData: React.FC<{ formTypeFilter: string }> = ({ formTypeFilter }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportId, setExportId] = useState<Inspection>();
  const [idDelete, setIdDelete] = useState('');

  const dispatch = useAppDispatch();
  const data = useAppSelector((state) =>
    state.inspection.list.map((item) => {
      return { key: item.id, ...item };
    }),
  );

  const isSystemAdmin = TokenService.isSystemAdmin();
  const loading = useAppSelector(selectInspectionLoading);
  const deleting = useAppSelector(selectDeleting);
  const pagination = useAppSelector(selectPagination);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    dispatch(inspectionActions.changePage({ pageNo: 1 }));
    dispatch(inspectionActions.search({ formTypeFilter }));
  }, [dispatch, formTypeFilter]);

  useMemo(() => {
    if (formTypeFilter) {
      setSelectedRowKeys([]);
    }
  }, [formTypeFilter]);

  const openNotification = useCallback(() => {
    if (deleting) {
      notification.success({
        message: 'Inspection record deleted successfully',
        placement: 'bottomRight',
        duration: 2.5,
      });
    }
  }, [deleting]);
  useEffect(() => {
    if (deleting) {
      openNotification();
    }
  }, [deleting, openNotification]);

  useEffect(() => {
    if (loading) {
      setIsModalVisible(false);
    }
  }, [loading]);

  useEffect(() => {
    const createPDF = async (data: Inspection) => {
      const pdf = new jsPDF('portrait', 'pt', 'a4', true);

      const img = await generateImg(pdf, 'page1');

      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 30;
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.addPage();

      const img2 = await generateImg(pdf, 'page2');
      pdf.addImage(img2, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.addPage();
      const img3 = await generateImg(pdf, 'page3');
      pdf.addImage(img3, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.addPage();
      const img4 = await generateImg(pdf, 'page4');
      pdf.addImage(img4, 'PNG', 0, 0, pdfWidth, pdfHeight);
      if (data?.form_type === InspectionForm.ANNEX_B) {
        pdf.addPage();
        const img5 = await generateImg(pdf, 'page5');
        pdf.addImage(img5, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }
      const date = moment().format('MMDDYYYY');
      const formType = data?.form_type.replace('_', '');
      pdf.save(`TR25_${date}_${formType}.pdf`);
      // dispatch(inspectionActions.exportPdfSuccess());
      setExportId(undefined);
    };
    const generateImg = async (pdf: any, selector: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const element = document.getElementById(selector)!;
      const img = await toPng(element, {
        width: element.offsetWidth,
        height: element.offsetHeight,
      });
      // const data = await html2canvas(document.querySelector(selector)!, {
      //     useCORS: true,
      //     scale: 2,
      // });
      // const img = data.toDataURL("image/png");
      return img;
    };

    if (exportId) {
      createPDF(exportId);
    }
  }, [exportId, dispatch]);

  const handleDeleteInspection = (id: string) => {
    setIdDelete(id);
    setIsModalVisible(true);
  };

  const handleExportPdf = async (id: Inspection) => {
    // dispatch(inspectionActions.exportPdf());
    setExportId(id);
  };

  const columns: any = [
    {
      title: 'No',
      width: 80,
      render: (_: unknown, __: unknown, index: number) =>
        index + (pagination.size || 10) * ((pagination.pageNo || 1) - 1) + 1,
      fixed: 'left',
    },
    // {
    //   title: "Form",
    //   dataIndex: "form_type",
    //   width: 100,
    //   fixed: "left",
    //   render: (_: unknown, record: Inspection) => {
    //     return record.form_type === "Annex_B" ? "Annex B" : "Annex C";
    //   },
    // },
    {
      title: 'Purpose of use',
      dataIndex: 'purpose_of_use',
      width: 200,
      render: (_: unknown, record: Inspection) => {
        return record.form_type === InspectionForm.ANNEX_B
          ? purposeOfUse(record.purpose_of_use)
          : '';
      },
    },
    {
      title: 'Brand/model of EV charger',
      dataIndex: 'charger_model',
      width: 250,
      render: (_: unknown, record: Inspection) => {
        return record?.charger_model?.name || '';
      },
    },
    {
      title: 'S/No',
      dataIndex: 'serial_number',
      width: 150,
      render: (_: unknown, record: Inspection) => {
        return record?.serial_number?.name || '';
      },
    },
    {
      title: 'Name of owner/operator',
      dataIndex: 'owner_name',
      width: 200,
    },
    {
      title: 'Conduct Date',
      dataIndex: 'conduct_date',
      render: (_: unknown, record: Inspection) => {
        return format(new Date(record.conduct_date), 'yyyy-MM-dd');
      },
      width: 150,
    },
    {
      title: 'Address of Installation',
      dataIndex: 'address',
      width: 250,
    },
    {
      title: 'Access',
      dataIndex: 'access',
      width: 250,
      render: (_: unknown, record: Inspection) => {
        return record.access === '0'
          ? 'Restricted access(Private)'
          : 'Non-restricted access(Public)';
      },
    },
    {
      title: 'Recommendation date of next inspection',
      dataIndex: 'next_inspection_date',
      width: 250,
      render: (_: unknown, record: Inspection) => {
        return format(new Date(record.next_inspection_date), 'yyyy-MM-dd');
      },
    },
    {
      title: 'Name of equipment specialist',
      dataIndex: 'equip_name',
      width: 250,
    },
    {
      title: 'Company of Equipment Specialist',
      dataIndex: 'equip_company',
      width: 300,
    },
    {
      title: 'Upload physical copy',
      dataIndex: 'attachment',
      width: 300,
      render: (_: unknown, record: Inspection) => {
        return record.attachment ? <Attachment attachment={record.attachment} /> : '';
      },
    },
    {
      title: 'Action',
      width: 150,
      fixed: 'right',
      render: (_: unknown, record: Inspection) => {
        return (
          <Space size='middle'>
            <Link to={`/inspection/${formTypeFilter}/edit/${record.id}`}>
              {isSystemAdmin ? 'Edit' : 'View'}
            </Link>
            |
            <Dropdown
              overlay={
                <div className='ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light'>
                  <div
                    className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                    style={{ color: '#e98d3e', cursor: 'pointer' }}
                  >
                    <ExportPdf
                      disabled={!!exportId}
                      record={record}
                      loading={exportId?.id === record.id}
                      onExport={handleExportPdf}
                    />
                  </div>
                  {isSystemAdmin && (
                    <div
                      className='ant-dropdown-menu-item ant-dropdown-menu-item-only-child'
                      onClick={() => handleDeleteInspection(record.id)}
                      style={{ color: '#e98d3e', cursor: 'pointer' }}
                    >
                      Delete
                    </div>
                  )}
                </div>
              }
            >
              <a>
                More <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangePage = (evt: number) => {
    console.log(formTypeFilter);
    dispatch(inspectionActions.changePage({ pageNo: evt, formTypeFilter }));
  };

  const handleOk = () => {
    dispatch(
      inspectionActions.deleteRecord({
        id: idDelete as string,
        form_type: formTypeFilter as string,
      }),
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Export Excel
  const handleExportExcelsAll = () => {
    exportExcel(
      '/inspections/export/by-ids',
      { ids: [], form_type: formTypeFilter },
      `inspection_all.xlsx`,
    );
  };

  const handleExportExcelSelected = () => {
    exportExcel(
      '/inspections/export/by-ids',
      { ids: [...selectedRowKeys], form_type: formTypeFilter },
      `inspection_selected.xlsx`,
    );
  };

  return (
    <Form form={form} component={false}>
      <Row gutter={16} justify='end' style={{ marginBottom: 24 }}>
        <Col className='gutter-row' span={4}>
          <Button
            disabled={selectedRowKeys.length === 0}
            onClick={handleExportExcelSelected}
            type='primary'
            icon={<ExportOutlined />}
            style={{ marginRight: 16 }}
            block
            className='btn-export'
          >
            Export Selected To Excel
          </Button>
        </Col>
        <Col className='gutter-row' span={4}>
          <Button
            onClick={handleExportExcelsAll}
            type='primary'
            icon={<ExportOutlined />}
            block
            className='btn-export'
          >
            Export All To Excel
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={
          formTypeFilter === InspectionForm.ANNEX_C
            ? columns.filter((col: { dataIndex: string }) => col.dataIndex !== 'purpose_of_use')
            : columns
        }
        rowClassName='editable-row'
        rowSelection={isSystemAdmin ? rowSelection : undefined}
        pagination={{
          total: pagination.totalRecords,
          pageSize: pagination.size,
          onChange: handleChangePage,
        }}
        scroll={{ x: 2330 }}
      />
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={deleting} onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Do you want delete inspection?</p>
      </Modal>

      {exportId && (
        <div className='export-pdf' style={{ opacity: 0, overflow: 'hidden', height: 0 }}>
          <div id='page1'>
            <Page1 datas={exportId} />
          </div>
          <div id='page2'>
            <Page2 datas={exportId} />
          </div>
          <div id='page3'>
            <Page3 datas={exportId} />
          </div>
          <div id='page4'>
            <Page4 datas={exportId} />
          </div>
          {exportId.form_type === InspectionForm.ANNEX_B && (
            <div id='page5'>
              <Page5 datas={exportId} />
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

export default TableData;
