/* eslint-disable react/no-unescaped-entities */
import { CheckOutlined } from '@ant-design/icons';
import { Image } from 'components/common/Image';
import { Inspection } from 'models';
import React from 'react';
import { SCIMMS_API } from 'utils/constants';
import { Pagination } from './pagination';


const electrical_check_test = [
  {
    no: 1,
    name: 'Perform earth loop impedance test and record value complies with SS 638 (For TT and TNS system)',
    value: '0',
    note: '0.01',
    titleNo: 'E1',
  },
  {
    no: 2,
    name: 'Record the incoming power supply and charging cable insulation resistance value is more than 1 M-Ohm',
    value: '0',
    titleNo: 'E2',
  },
  {
    no: 3,
    name: 'Tripping time of 30mA RCCB type A (min) as measured by an RCCB tester using AC and DC injection curve is acceptable according to SS 97 tripping time for mode 2 and mode 3 chargers \nFor mode 4 charger, the trip setting should comply with 411.5.3L of SS 638:2018 \n',
    value: '0',
    note: '0',
    titleNo: 'E3',
  },
  {
    no: 4,
    name: 'EVCS installation tally with single line diagram (SLD) provided by owner/operator.\nMeans of isolation, lockable at OFF position (2P for 1ph and 3P/4P for 3ph), is provided for maintenance switching \n',
    value: '0',
    titleNo: 'E4',
  },
  {
    no: 5,
    name: 'Rating of incoming cable from source DB and charging cable are adequate for max rated charging current. ',
    value: '0',
    titleNo: 'E5',
  },
];

export const Page4: React.FC<{ datas: Inspection | undefined }> = ({ datas }) => {
  const representative_date = new Date(datas?.representative_date || '')
    .toISOString()
    .split('T')[0];
  const lew_date = new Date(datas?.representative_date || '').toISOString().split('T')[0];
  const equip_date = new Date(datas?.equip_date || '').toISOString().split('T')[0];
  const next_inspection_date = new Date(datas?.next_inspection_date || '')
    .toISOString()
    .split('T')[0];
  const isAnnexB = datas?.form_type === 'Annex_B';
  return (
    <>
      {datas && (
        <div className='page-wrapper'>
          <div className='page-date'>TR 25-1:2022</div>
          <div className='page-content'>
            {!isAnnexB && (
              <>
                <div className='text-input'>
                  <p className='text-input__title'>Name of equipment specialist:</p>
                  <p className='text-input__value'>{datas.equip_name}</p>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Company:</p>
                    <p className='text-input__value'>{datas.equip_company}</p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Designation:</p>
                    <p className='text-input__value'>{datas.equip_designation}</p>
                  </div>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Signature:</p>
                    <p className='text-input__value'>
                      <Image
                        type='base64'
                        className='signature'
                        url={`${SCIMMS_API}/upload/file/${datas.equip_signature || ''}`}
                      />
                    </p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Date:</p>
                    <p className='text-input__value'>{equip_date}</p>
                  </div>
                </div>
                <h4>The inspection was witnessed by owner/operator of EVCS</h4>
                <div className='text-input'>
                  <p className='text-input-title'>Name of owner/operator representative:</p>
                  <p className='text-input__value'>{datas.representative_name}</p>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Company:</p>
                    <p className='text-input__value'>{datas.representative_company}</p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Designation:</p>
                    <p className='text-input__value'>{datas.representative_designation}</p>
                  </div>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Signature:</p>
                    <p className='text-input__value'>
                      <Image
                        type='base64'
                        className='signature'
                        url={`${SCIMMS_API}/upload/file/${datas.representative_signature || ''}`}
                      />
                    </p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Date:</p>
                    <p className='text-input__value'>{representative_date}</p>
                  </div>
                </div>
                <p>(Extract from TR 25-1:2022)</p>
                <p>
                  NOTE1 -It is the responsibility of the operator/owner of the EVCSs to perform the
                  necessary maintenance routines as recommended by the equipment specialist or
                  manufacturer to ensure the safe use of the EVCS and any potential hazard due to
                  lack of maintenance.
                </p>
                <p>NOTE 2 - Fault reporting procedure</p>
                <p>
                  If any EV charging station or charging point is found to be unsafe or unsuitable
                  for operation, the following steps shall be taken:
                </p>
                <ul>
                  <li>a) The supply to the equipment shall be switched off;</li>
                  <li>
                    b) The means of isolation shall be off and locked to prevent re-connection of
                    supply;
                  </li>
                  <li>
                    c) A clear label notifying users that the equipment is out of service shall be
                    displayed prominently;and
                  </li>
                  <li>d) The operator/owner shall be informed immediately.</li>
                </ul>
                <div className='scale-height' />
              </>
            )}
            {isAnnexB && (
              <>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2}></th>
                      <th rowSpan={2}>Description</th>
                      <th colSpan={3}>Compliance</th>
                      <th rowSpan={2}>
                        Remarks/
                        <br />
                        measurement value
                      </th>
                    </tr>
                    <tr>
                      <th>C</th>
                      <th>NC</th>
                      <th>NA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.electrical_check_test?.slice(2).map((item) => {
                      const electrical_data = electrical_check_test.find((data)=>data.no === item.no);
                      return (
                        <tr key={item.no}>
                          <td>{`E${item.no}`}</td>
                          <td>{electrical_data?.name}</td>
                          <td>{item.value === '0' && <CheckOutlined />}</td>
                          <td>{item.value === '1' && <CheckOutlined />}</td>
                          <td>{item.value === '2' && <CheckOutlined />}</td>
                          <td>{item.note}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <p>1) Measured values where required shall be recorded in this report</p>
                <p>
                  2) EV charging station installed in petrol kiosks shall comply with ECDF's
                  requirements.
                </p>
                <p className='mb-0'>
                  <strong>General remarks:</strong>
                </p>
                <p className='text-full-row'>{/* {datas.address} */}</p>
                <div className='text-input'>
                  <strong className='text-input-title'>Recommended date of next inspection:</strong>
                  <p className='text-input__value'>{next_inspection_date}</p>
                </div>
                <div className='text-note'>
                  <p>(Extract from TR 25-1:2022)</p>
                  <p>Non-restricted access EVCS - every 6 months (required)</p>
                </div>
                <div className='text-note'>
                  <p></p>
                  <p>Restricted access EVCS - every 24 months (recommended)</p>
                </div>
                <h4>Endorsed by LEW</h4>
                <p>
                  The results obtained in E (Electrical checks and tests) is acceptable to the best
                  of my knowledge
                </p>
                <div className='text-input text-input-2'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Name of LEW:</p>
                    <p className='text-input__value'>{datas.lew_name}</p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Licence No:</p>
                    <p className='text-input__value'>{datas.lew_license}</p>
                  </div>
                </div>

                <div className='text-input text-input-2'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Signature:</p>
                    <p className='text-input__value'>
                      <Image
                        type='base64'
                        className='signature'
                        url={`${SCIMMS_API}/upload/file/${datas.lew_signature || ''}`}
                      />
                    </p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Date:</p>
                    <p className='text-input__value'>{lew_date}</p>
                  </div>
                </div>

                <h4>Endorsed by equipment specialist</h4>
                <p>
                  I have inspected and tested the EVCS. To the best of my knowledge, I declare that
                  EVCS is <br /> *fit and safe / unfit and unsafe for operation
                </p>
                <p>*Please delete as appropriate</p>
                <div className='text-input'>
                  <p className='text-input__title'>Name of equipment specialist:</p>
                  <p className='text-input__value'>{datas.equip_name}</p>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Company:</p>
                    <p className='text-input__value'>{datas.equip_company}</p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Designation:</p>
                    <p className='text-input__value'>{datas.equip_designation}</p>
                  </div>
                </div>
                <div className='text-input text-input-3'>
                  <div className='text-input-left'>
                    <p className='text-input__title'>Signature:</p>
                    <p className='text-input__value'>
                      <Image
                        type='base64'
                        className='signature'
                        url={`${SCIMMS_API}/upload/file/${datas.equip_signature || ''}`}
                      />
                    </p>
                  </div>
                  <div className='text-input-right'>
                    <p className='text-input__title'>Date:</p>
                    <p className='text-input__value'>{equip_date}</p>
                  </div>
                </div>
              </>
            )}
            <Pagination page={4} totalPage={isAnnexB ? 5 : 4} />
          </div>
        </div>
      )}
    </>
  );
};
