import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  chargerModelActions,
  selectChargerModels,
} from 'features/settings/charger-model/chargerModelSlice';
import { companyActions, selectCompanies } from 'features/settings/company/companySlice';
import { countryActions, selectCountries } from 'features/settings/country/countrySlice';
import { provinceActions } from 'features/settings/province/provinceSlice';
import { serialNumberActions } from 'features/settings/serial-number/serialNumberSlice';
import { Training } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import { formatDatetime, removeEmptyAttributes } from 'utils/function';
import {
  certificateAdminActions,
  selectCertificateAdmins,
} from 'features/settings/certificate-admin/certificateAdminSlice';
import { FlagService } from 'models/enum/chargerModel';

const GeneralInfo: React.FC<{
  nextStep: any;
  training: Training | undefined;
}> = ({ nextStep, training }) => {
  const [form] = useForm();
  const isSystemAdmin = TokenService.isSystemAdmin();
  const [isRequireByMethod, setIsRequireByMethod] = useState(false);

  const isEdit = !training || isSystemAdmin;
  const dispatch = useAppDispatch();

  const companiesOption = useAppSelector(selectCompanies);
  const chargerModelOption = useAppSelector(selectChargerModels);
  const listCountry = useAppSelector(selectCountries);
  const certificateOptions = useAppSelector(selectCertificateAdmins);

  useEffect(() => {
    if (training) {
      dispatch(
        chargerModelActions.search({
          name: '',
          pagination: { size: 1000 },
          flag_service: training?.certificate?.name ? FlagService.CM : FlagService.TI,
        }),
      );
      dispatch(companyActions.search({ name: '', pagination: { size: 100000 } }));
      dispatch(
        countryActions.search({
          name: '',
          pagination: { pageNo: 1, size: 10000 },
        }),
      );
      dispatch(
        certificateAdminActions.search({
          name: '',
          pagination: { pageNo: 1, size: 10000 },
        }),
      );
    }
  }, [dispatch, training]);

  useEffect(() => {
    if (training) {
      form.setFieldsValue({
        id: training?.id || '',
        customer: training?.customer || '',
        charger_model: training?.charger_model.id || '',
        training_date:
          (training?.training_date ? moment(new Date(training?.training_date)) : null) ||
          moment(new Date()),
        trainer_name: training?.trainer_name || '',
        trainer_company: training?.trainer_company.id || '',
        training_method: training?.training_method || '',
        country_id: training?.country?.id || '',
        site_name: training?.site_name || '',
        detailed_address: training?.detailed_address || '',
        certificate: training?.certificate?.id || '',
      });
    }
  }, [training, form]);

  const handleNext = () => {
    const chargerModelId = form.getFieldValue('charger_model');
    const chargerModel = chargerModelOption.find((item) => item.id === chargerModelId);
    const companyId = form.getFieldValue('trainer_company');
    const countryId = form.getFieldValue('country_id');
    const certificateId = form.getFieldValue('certificate');
    const companyModel = companiesOption.find((item) => item.id === companyId);
    const countryModel = listCountry.find((item) => item.id === countryId);
    const certificateModel = certificateOptions.find((item) => item.id === certificateId);
    form.validateFields().then(() => {
      const trainingForm: Training = form.getFieldsValue();
      const updateObj = {
        ...training,
        ...trainingForm,
        company_id: companyModel?.id,
        charger_model: {
          id: chargerModelId,
          name: chargerModel?.name,
          code: chargerModelOption.find((item) => item.id === chargerModelId)?.code,
        },
        country: {
          id: countryModel?.id,
          name: countryModel?.name,
        },
        trainer_company: {
          id: companyModel?.id,
          name: companyModel?.name,
        },
        certificate: certificateModel?.id
          ? {
              id: certificateModel?.id,
              name: certificateModel?.name,
              validity_period: certificateModel?.validity_period,
            }
          : undefined,
        training_date: formatDatetime(form.getFieldValue('training_date')),
      };
      const finalObj = removeEmptyAttributes(updateObj);
      nextStep({ ...finalObj });
    });
  };

  const handleChangeChargerModel = (item: any) => {
    dispatch(
      serialNumberActions.search({
        filter: {
          name: '',
          chargerModelId: item,
        },
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  };

  const handleChangeCountry = (item: string) => {
    dispatch(provinceActions.fetchProvinceByCountry({ countryId: item }));
  };

  // const handleChangeCompanies = (item: string) => {
  //   dispatch(
  //     companyActions.search({
  //       name: item,
  //       pagination: { pageNo: 1, size: 10000 },
  //     }),
  //   );
  // };

  const handleChangeMethod = (item: string) => {
    setIsRequireByMethod(item === 'On-site');
  };

  const trainingMethods = [
    { title: 'Online', value: 'Online' },
    { title: 'On-site', value: 'On-site' },
  ];

  return (
    <>
      <Form
        name='form-ticket'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={handleNext}
        // onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item label='Certificate' name='certificate'>
          <Select>
            {certificateOptions.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Customer'
          name='customer'
          rules={[{ required: true, message: 'Please input your Customer!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Charger Model'
          name='charger_model'
          rules={[{ required: true, message: 'Please input your Charger Model!' }]}
        >
          <Select disabled={!isEdit} onChange={handleChangeChargerModel}>
            {chargerModelOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                <img src={`/images/charger-icon/${item.icon}`} width={40} height={20} alt='' />
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Training Date'
          name='training_date'
          rules={[
            {
              required: true,
              message: 'Please input your Problem Training Date!',
            },
          ]}
        >
          <DatePicker format='YYYY-MM-DD' />
        </Form.Item>
        <Form.Item
          label='Trainer Name'
          name='trainer_name'
          rules={[{ required: true, message: 'Please input your Trainer Name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Trainer Company'
          name='trainer_company'
          rules={[{ required: true, message: 'Please input your Trainer Company!' }]}
        >
          {/* <Select disabled={!isEdit} onChange={handleChangeCompanies}> */}
          <Select disabled={!isEdit}>
            {companiesOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Training Method'
          name='training_method'
          rules={[{ required: true, message: 'Please input your Training Method!' }]}
        >
          <Select disabled={!isEdit} onChange={handleChangeMethod}>
            {trainingMethods.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Country'
          name='country_id'
          rules={[{ required: isRequireByMethod, message: 'Please input Country!' }]}
        >
          <Select disabled={!isEdit} onChange={handleChangeCountry}>
            {listCountry.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Site Name'
          name='site_name'
          rules={[{ required: isRequireByMethod, message: 'Please input your Site Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Detailed Address'
          name='detailed_address'
          rules={[{ required: isRequireByMethod, message: 'Please input your Detailed Address!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <div className='action-form'>
            <Button
              type='primary'
              htmlType='submit'
              onClick={handleNext}
              className={`btn-submit-form`}
            >
              Next step
            </Button>
          </div>
        </Form.Item>
      </Form>
      {/* <Modal
        visible={previewOpen}
        title={null}
        footer={null}
        // onCancel={handleCancel}
        className='ticket-preview-image'
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal> */}
    </>
  );
};

export default GeneralInfo;
