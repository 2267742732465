import { Inspection, ListParams, ListResponse } from 'models';
import moment from 'moment';
import { SCIMMS_API } from 'utils/constants';
import axiosClient from './axiosClient';

const urlApi = `${SCIMMS_API}/inspections/`;

const inspectionApi = {
  getAll(params: ListParams): Promise<ListResponse<Inspection>> {
    const url = `${SCIMMS_API}/inspections/all`;
    return axiosClient.get(url, {
      params,
    });
  },

  upsert(param: Inspection): Promise<Inspection> {
    const url = `${SCIMMS_API}/inspections/${param.id}`;
    return axiosClient.put(url, {
      ...param,
      next_inspection_date: moment(new Date(param.next_inspection_date)).format(
        'YYYY-MM-DD HH:mm:ss',
      ),
      conduct_date: moment(new Date(param.conduct_date)).format('YYYY-MM-DD HH:mm:ss'),
      lew_date: moment(new Date(param.lew_date)).format('YYYY-MM-DD HH:mm:ss'),
      equip_date: moment(new Date(param.equip_date)).format('YYYY-MM-DD HH:mm:ss'),
      representative_date: moment(new Date(param.representative_date)).format(
        'YYYY-MM-DD HH:mm:ss',
      ),
    });
  },

  getById(id: string): Promise<Inspection> {
    const url = `${SCIMMS_API}/inspections/one?id=${id}`;
    return axiosClient.get(url);
  },

  add(data: Inspection): Promise<Inspection> {
    const url = '/inspection';
    return axiosClient.post(url, data);
  },

  update(data: Partial<Inspection>): Promise<Inspection> {
    const url = `/inspection/${data.id}`;
    return axiosClient.patch(url, data);
  },

  remove(id: string): Promise<any> {
    const url = `${urlApi}${id}`;
    return axiosClient.delete(url);
  },
  searchByParams(params: ListParams): Promise<ListResponse<Inspection>> {
    const url = `${SCIMMS_API}/inspections/find-by-params`;
    return axiosClient.get(url, {
      params,
    });
  },
  deleteInspection(ids: any): Promise<any> {
    const url = `${SCIMMS_API}/inspections/multiple`;
    return axiosClient.put(url, {
      ...ids,
    });
  },
};

export default inspectionApi;
