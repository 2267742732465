/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { Company } from '../../../models/company';

export interface CompanyState {
  list: Company[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
  error: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: CompanyState = {
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
  error: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    fetchCompanyList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchAllCompanyList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchCompanyListSuccess(state, action: PayloadAction<ListResponse<Company>>) {
      state.loading = false;
      state.loaded = false;
      state.list = action.payload.data;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
    },
    createCompany(state, action: PayloadAction<Company>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    createCompanySuccess(state, action: PayloadAction<Company>) {
      state.loading = false;
      state.loaded = true;
    },
    updateCompany(state, action: PayloadAction<Company>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    updateCompanySuccess(state, action: PayloadAction<Company>) {
      state.loading = false;
      state.loaded = true;
    },
    search(state, action: PayloadAction<{ name: string; pagination?: Pagination }>) {
      state.list = [];
      state.deleted = false;
      state.error = false;
      const { pagination, name } = action.payload;
      state.filter.name = name;
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo,
          size: pagination.size,
        };
      }
    },
    fetchCompanyDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchCompanySuccess(state, action: PayloadAction<Company>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteCompany(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
      state.deleted = false;
      state.error = false;
    },
    deleteCompanySuccess(state) {
      state.deleting = false;
      state.deleted = true;
    },
    deleteError(state) {
      state.deleting = false;
      state.deleted = true;
      state.error = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const companyActions = companySlice.actions;

// selector
export const selectInspectionLoad = (state: RootState) => state.company.loaded;
export const selectPagination = (state: RootState) => state.company.pagination;
export const selectName = (state: RootState) => state.company.filter.name;
export const selectDeleting = (state: RootState) => state.company.deleting;
export const selectDeleted = (state: RootState) => state.company.deleted;
export const selectError = (state: RootState) => state.company.error;
export const selectCompanyLoading = (state: RootState) => state.company.loading;
export const selectCompanies = (state: RootState) => state.company.list;

// Reducer
const companyReducer = companySlice.reducer;
export default companyReducer;
