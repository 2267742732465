/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import trainingApi from 'api/trainingApi';
import { ListResponse, Training } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Filter,
  Pagination,
  selectFilter,
  selectPagination,
  trainingActions,
} from './trainingSlice';

function* fetchTrainingList() {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Training> = yield call(trainingApi.getAll, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(trainingActions.fetchTrainingListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch training list', error);
    yield put(trainingActions.fetchTrainingListFailed());
  }
}

type Params = { payload: Training; type: string };
function* upsertTraining(param: Params) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield call(trainingApi.upsert, param.payload);
    yield put(trainingActions.upsertTrainingSuccess());
    yield put(trainingActions.search({ filter, pagination }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type PageParams = { payload: Pagination; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* changePage(param: PageParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectPagination);
    yield put(trainingActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to change page', error);
  }
}

type SearchParams = { payload: Filter; type: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<Training> = yield call(trainingApi.searchByParams, {
      ...filter,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(trainingActions.fetchTrainingListSuccess(response));
  } catch (error) {
    console.log('Failed to search training', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteTraining(param: DeleteParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    const pagination: Pagination = yield select(selectFilter);
    const id = param.payload.id;
    yield call(trainingApi.remove, id);
    yield put(trainingActions.deleteTrainingSuccess());
    yield put(trainingActions.search({ filter, pagination }));
  } catch (error) {
    console.log('Failed to delete training', error);
  }
}

type TrainingDetailParams = { payload: { id: string }; type: string };
function* fetchTrainingDetail(param: TrainingDetailParams) {
  try {
    const id = param.payload.id;
    const response: Training = yield call(trainingApi.getById, id);
    yield put(trainingActions.fetchTrainingDetailSuccess(response));
  } catch (error) {
    console.log('Failed to find training detail', error);
  }
}

//type Params = { payload: User; type: string };
function* createTraining(param: Params) {
  try {
    const response: Training = yield call(trainingApi.create, param.payload);
    yield put(trainingActions.createTrainingSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

export default function* trainingSaga() {
  yield takeLatest(trainingActions.fetchTrainingList.type, fetchTrainingList);
  yield takeEvery(trainingActions.upsertTraining.type, upsertTraining);
  yield takeEvery(trainingActions.changePage.type, changePage);
  yield takeLatest(trainingActions.search.type, search);
  yield takeLatest(trainingActions.deleteTraining.type, deleteTraining);
  yield takeEvery(trainingActions.createTraining.type, createTraining);
  yield takeLatest(trainingActions.fetchTrainingDetail.type, fetchTrainingDetail);
}
