/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  ChargerModel,
  InspectionChargerModelCertificatedUser,
  ListParams,
  ListResponse,
  Pagination,
} from 'models';
import { FlagService } from 'models/enum/chargerModel';

export interface ChargerModelState {
  list: ChargerModel[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
  error: boolean;
  pagination: Pagination;
  filter: {
    name: string;
    flag_service?: FlagService;
  };
  chargerModelCertificatedUserList: {
    id: string;
    name: string;
  }[];
}

const initialState: ChargerModelState = {
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
  error: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  chargerModelCertificatedUserList: [],
};

const chargerModelSlice = createSlice({
  name: 'chargerModel',
  initialState,
  reducers: {
    fetchChargerModelList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchChargerModelListSuccess(state, action: PayloadAction<ListResponse<ChargerModel>>) {
      state.loading = false;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data;
    },
    fetchChargerModelCertificatedUser(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.deleted = false;
      state.error = false;
    },
    fetchChargerModelCertificatedUserSuccess(
      state,
      action: PayloadAction<InspectionChargerModelCertificatedUser[]>,
    ) {
      state.loading = false;
      state.loaded = false;
      state.deleted = false;
      state.error = false;

      state.chargerModelCertificatedUserList = action.payload;
    },
    search(
      state,
      action: PayloadAction<{ name: string; pagination?: Pagination; flag_service?: FlagService }>,
    ) {
      state.list = [];
      state.deleted = false;
      state.error = false;
      const { pagination, name, flag_service } = action.payload;
      state.filter.name = name;
      state.filter.flag_service = flag_service;
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo,
          size: pagination.size,
        };
      }
    },
    createChargerModel(state, action: PayloadAction<ChargerModel>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    createChargerModelSuccess(state, action: PayloadAction<ChargerModel>) {
      state.loading = false;
      state.loaded = true;
    },
    updateChargerModel(state, action: PayloadAction<ChargerModel>) {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
      state.error = false;
    },
    updateChargerModelSuccess(state, action: PayloadAction<ChargerModel>) {
      state.loading = false;
      state.loaded = true;
    },
    fetchChargerModelDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchChargerModelSuccess(state, action: PayloadAction<ChargerModel>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteChargerModel(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
      state.deleted = false;
      state.error = false;
    },
    deleteChargerModelSuccess(state) {
      state.loading = true;
      state.deleting = false;
      state.deleted = true;
    },
    deleteError(state) {
      state.deleting = false;
      state.deleting = true;
      state.deleted = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const chargerModelActions = chargerModelSlice.actions;

// selector
export const selectChargeModelLoad = (state: RootState) => state.chargerModel.loaded;
export const selectPagination = (state: RootState) => state.chargerModel.pagination;
export const selectName = (state: RootState) => state.chargerModel.filter.name;
export const selectDeleting = (state: RootState) => state.chargerModel.deleting;
export const selectDeleted = (state: RootState) => state.chargerModel.deleted;
export const selectError = (state: RootState) => state.chargerModel.error;
export const selectChargerModelLoading = (state: RootState) => state.chargerModel.loading;
export const selectChargerModels = (state: RootState) => state.chargerModel.list;
export const selectChargerModelsCertificatedUserList = (state: RootState) =>
  state.chargerModel.chargerModelCertificatedUserList;

// Reducer
const chargerModelReducer = chargerModelSlice.reducer;
export default chargerModelReducer;
