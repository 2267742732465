import { Steps } from 'antd';
import { useAppSelector } from 'app/hooks';
import { CommissionRegion } from 'models';
import React, { useEffect, useState } from 'react';
import BasicInfo from './BasicInfo';
import { ChargingFunction } from './ChargingFunction';
import { CheckAfterPowerOn } from './CheckAfterPowerOn';
import { CheckBeforePowerOn } from './CheckBeforePowerOn';
import { Conclusion } from './Conclusion';
import DetailsEVSE from './DetailsEVSE';
import { InstallationRecheck } from './installationRecheck';
import { SubmitForm } from './submitForm';

export const StepForm: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [current, setCurrent] = useState(0);
  const [hasChange, setHasChange] = useState(false);
  const [data, setData] = useState<CommissionRegion>();
  const inspOtherRegion = useAppSelector(
    (state) => state.inspOtherRegion.detail,
  );

  useEffect(() => {
    if (data) {
      setHasChange(JSON.stringify(data) === JSON.stringify(inspOtherRegion));
    }
  }, [data, inspOtherRegion]);

  useEffect(() => {
    if (inspOtherRegion) {
      setData(inspOtherRegion);
    }
  }, [inspOtherRegion]);

  const next = (datas: CommissionRegion) => {
    setCurrent(current + 1);
    setData({ ...datas });
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: 'Choose form',
      content: <BasicInfo nextStep={next} inspOtherRegion={data} />,
    },
    {
      title: 'Step 2',
      content: <DetailsEVSE nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },

    {
      title: 'Step 3',
      content: <InstallationRecheck nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },
    {
      title: 'Step 4',
      content: <CheckBeforePowerOn nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },
    {
      title: 'Step 5',
      content: <CheckAfterPowerOn nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },
    {
      title: 'Step 6',
      content: <ChargingFunction nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },

    {
      title: 'Step 7',
      content: <Conclusion nextStep={next} prevStep={prev} inspOtherRegion={data} />,
    },
    {
      title: 'Step 8',
      content: <SubmitForm hasChange={hasChange} prevStep={prev} inspOtherRegion={data} />,
    },
  ];
  return (
    <div className='step-form'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
};
