import { notification } from 'antd';
import userApi from 'api/userApi';
import { ListParams, ListResponse, Pagination, SearchUserParams, User } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { selectPagination, selectUsername, userActions, UserEngineer } from './userSlice';

function* fetchUserList(param: ListParams) {
  try {
    const response: ListResponse<User> = yield call(userApi.getAll, {
      pageNo: param.payload._page,
      pageSize: param.payload._limit,
    });
    yield put(userActions.fetchUserListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch user list', error);
  }
}

type UserEngineerParams = { type: string };
function* fetchUserEngineers(param: UserEngineerParams) {
  try {
    const response: UserEngineer[] = yield call(userApi.getServiceEngineers);
    yield put(userActions.fetchUserEngineersSuccess(response));
  } catch (error) {
    console.log('Failed to fetch user engineer', error);
  }
}

type Params = { payload: User; type: string };
function* createUser(param: Params) {
  try {
    const response: User = yield call(userApi.create, param.payload);
    yield put(userActions.createUserSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateUser(param: Params) {
  try {
    const response: User = yield call(userApi.update, param.payload);
    yield put(userActions.updateUserSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type SearchParams = { payload: SearchUserParams; type: string };
function* search(param: SearchParams) {
  const pagination: Pagination = yield select(selectPagination);
  const username: string = yield select(selectUsername);
  try {
    const response: ListResponse<User> = yield call(userApi.findByUsername, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      username: username || '',
    });
    yield put(userActions.fetchUserListSuccess(response));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const username: string = yield select(selectUsername);
    yield put(userActions.search({ username }));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type UserDetailParams = { payload: { id: string }; type: string };
function* fetchUserDetail(param: UserDetailParams) {
  try {
    const id = param.payload.id;
    const response: User = yield call(userApi.findById, id);
    yield put(userActions.fetchUserSuccess(response));
  } catch (error) {
    console.log('Failed to find user by id', error);
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.fetchUserList.type, fetchUserList);
  yield takeEvery(userActions.createUser.type, createUser);
  yield takeEvery(userActions.updateUser.type, updateUser);
  yield takeEvery(userActions.changePage.type, changePage);
  yield takeLatest(userActions.search.type, search);
  yield takeLatest(userActions.fetchUserDetail.type, fetchUserDetail);
  yield takeLatest(userActions.fetchUserEngineers.type, fetchUserEngineers);
}
