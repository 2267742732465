import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { ListResponse, Pagination } from 'models';
import { Province } from 'models/province';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Filter, provinceActions, selectFilter, selectPagination } from './provinceSlice';

type Params = { payload: Province; type: string };

function* fetchProvinceList() {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Province> = yield call(settingApi.getAllProvince, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(provinceActions.fetchProvinceListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Province list', error);
  }
}

type GetProvinceByCountryParams = {
  payload: { countryId: string };
  type: string;
};
function* fetchProvinceByCountry(param: GetProvinceByCountryParams) {
  try {
    const response: ListResponse<Province> = yield call(
      settingApi.getAllProvinceByCountry,
      param.payload.countryId,
    );
    yield put(provinceActions.fetchProvinceListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Province list', error);
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    const filter: Filter = yield select(selectFilter);
    yield put(provinceActions.search({ ...filter }));
  } catch (error) {
    console.log('Failed to create Province', error);
  }
}

function* createProvince(param: Params) {
  try {
    const filter: Filter = yield select(selectFilter);
    const response: Province = yield call(settingApi.createProvince, param.payload);
    yield put(provinceActions.createProvinceSuccess(response));
    yield put(provinceActions.search({ ...filter }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type SearchParams = {
  payload: { name: string; countryId: string };
  type: string;
};
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<Province> = yield call(settingApi.searchProvinceByParams, {
      provinceName: param.payload.name,
      countryId: param.payload.countryId,
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
    });
    yield put(provinceActions.fetchProvinceListSuccess(response));
  } catch (error) {
    console.log('Failed to search company', error);
  }
}

function* updateProvince(param: Params) {
  try {
    const filter: Filter = yield select(selectFilter);
    const response: Province = yield call(settingApi.updateProvince, param.payload);
    yield put(provinceActions.updateProvinceSuccess(response));
    yield put(provinceActions.search({ ...filter }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type ProvinceDetailParams = { payload: { id: string }; type: string };
function* fetchProvinceDetail(param: ProvinceDetailParams) {
  try {
    const id = param.payload.id;
    const response: Province = yield call(settingApi.findProvinceById, id);
    yield put(provinceActions.fetchProvinceSuccess(response));
  } catch (error) {
    console.log('Failed to find Province by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteProvince(param: DeleteParams) {
  try {
    const id = param.payload.id;
    const filter: Filter = yield select(selectFilter);
    yield call(settingApi.removeProvince, id);
    yield put(provinceActions.deleteProvinceSuccess());
    yield put(provinceActions.search({ ...filter }));
  } catch (error) {
    console.log('Failed to delete Province', error);
    yield put(provinceActions.deleteError());
  }
}

export default function* provinceSaga() {
  yield takeLatest(provinceActions.fetchProvinceList.type, fetchProvinceList);
  yield takeEvery(provinceActions.createProvince.type, createProvince);
  yield takeEvery(provinceActions.updateProvince.type, updateProvince);
  yield takeLatest(provinceActions.fetchProvinceDetail.type, fetchProvinceDetail);
  yield takeLatest(provinceActions.deleteProvince.type, deleteProvince);
  yield takeLatest(provinceActions.changePage.type, changePage);
  yield takeLatest(provinceActions.search.type, search);
  yield takeLatest(provinceActions.fetchProvinceByCountry.type, fetchProvinceByCountry);
}
