import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { TrainingContent } from 'models';

const ModalActionTrainingContent: React.FC<{
  data: TrainingContent;
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  onFinish: any;
}> = ({ data, isModalVisible, handleOk, handleCancel, setIsModalVisible, onFinish }) => {
  const [form] = useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        training_content: data?.training_content || '',
      });
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    onFinish({
      training_content: form.getFieldValue('training_content'),
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeItem = () => {
    const training_content = form.getFieldValue('training_content');
    if (data) {
      const isValid = training_content !== data?.training_content && training_content !== '';
      setDisabledSubmit(!isValid);
    } else {
      const isValid = !!training_content;
      setDisabledSubmit(!isValid);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{data ? <div>Update Training Content</div> : <div>Create Training Content</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item label='Training Content' name='training_content'>
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActionTrainingContent;
