import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useForm } from 'antd/lib/form/Form';
import { MediaCustom } from 'components/common/MediaCustom';
import { Asset, ConcurrentSession, Upload as UploadModel } from 'models';
import moment from 'moment';
import { bool } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  fetchImage,
  fetchImageForUpload,
  generateFileName,
  getThumbnailForVideo,
  removeExtension,
  uploadMedia,
} from 'utils/function';
import TokenService from 'utils/token.service';

const ModalConcurrentSession: React.FC<{
  data: ConcurrentSession | undefined;
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  setIsModalVisible: (isModalVisible: boolean) => void;
  onFinish: (data: ConcurrentSession) => void;
}> = ({ data, isModalVisible, handleOk, handleCancel, setIsModalVisible, onFinish }) => {
  const [form] = useForm();
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([]);
  const [videoFileList, setVideoFileList] = useState<UploadFile[]>([]);
  const [imageFileUpload, setImageFileUpload] = useState<Asset>({});
  const [videoFileUpload, setVideoFileUpload] = useState<Asset>({});
  const [imagePaths, setImagePaths] = useState<UploadModel[]>([]);
  const [videoPaths, setVideoPaths] = useState<UploadModel[]>([]);
  const [videoPreview, setVideoPreview] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const vidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (imageFileUpload.url) {
      setFileUploadList([{ url: imageFileUpload.url, thumb: imageFileUpload.thumb }], true);
    }
    setDisabledSubmit(false);
  }, [imageFileUpload]);

  useEffect(() => {
    if (videoFileUpload.url) {
      setFileUploadList([{ url: videoFileUpload.url, thumb: videoFileUpload.thumb }], false);
    }
    setDisabledSubmit(false);
  }, [videoFileUpload]);
  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        connector_ID: data?.connector_ID || '',
        ev_model: data?.ev_model || '',
        output_voltage: data?.output_voltage || '',
        output_current: data?.output_current || '',
        output_power: data?.output_power || '',
        soc: data?.soc || '',
        remark: data?.remark || '',
      });
      
      setFileUploadList(data?.upload?.images, true, true);
      setFileUploadList(data?.upload?.videos, false, true);
      setDisabledSubmit(true);
    }
  }, [data, form, isModalVisible]);

  const onSave = () => {
    setIsModalVisible(false);
    const concurrentSessionForm: ConcurrentSession = form.getFieldsValue();
    onFinish({
      ...concurrentSessionForm,
      upload: {
        videos: [ ...videoPaths],
        images: [ ...imagePaths],
      },
      // username: TokenService.getUser().username,
      // time: moment(new Date()).toDate(),
    });
  };

  const setFileUploadList = async (list: Asset[] | undefined, isImage: boolean, isOpenPopup?: boolean) => {
    const setImgList = async (list: any) => {
      Promise.all(list).then((list) => {
        setImageFileList(isOpenPopup ? list : [...imageFileList, list[0]]);
      });
    };
    const setVideoList = async (list: any) => {
      Promise.all(list).then((list) => {
        setVideoFileList(isOpenPopup ? list : [...videoFileList, list[0]]);
      });
    };
    const uploadList = list?.map((item) => getFileUpload(item)) || [];
    
    if (isImage) {
      setImgList(uploadList);
      if (isOpenPopup) {
        setImagePaths(
          list?.map((item) => {
            return {
              url: item.url,
              thumb: item.thumb,
              username: TokenService.getUser().username,
              time: moment(new Date()).format(),
            };
          }) || [],
        );
        
      } else {
        setImagePaths([
          ...imagePaths,
          {
            url: imageFileUpload.url,
            thumb: imageFileUpload.thumb,
            username: TokenService.getUser().username,
            time: moment(new Date()).format(),
          },
        ]);
      }
    } else {
      setVideoList(uploadList);
      if (isOpenPopup) {
        setVideoPaths(
          list?.map((item) => {
            return {
              url: item.url,
              thumb: item.thumb,
              username: TokenService.getUser().username,
              time: moment(new Date()).format(),
            };
          }) || [],
        );
        
      } else {
        setVideoPaths([
          ...videoPaths,
          {
            url: videoFileUpload.url,
            thumb: videoFileUpload.thumb,
            username: TokenService.getUser().username,
            time: moment(new Date()).format(),
          },
        ]);
      }
    }
  }

  const getFileUpload = async (asset: Asset) => {
    
    const url = asset.thumb || asset.url || '';
    const resUrl = (await fetchImage(url)) as { link: string };
    const result: UploadFile = {
      uid: resUrl?.link || '',
      url: resUrl?.link || '',
      name: resUrl?.link || '',
      preview: asset.url,
    };
    return result;
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const propImages: UploadProps = {
    // multiple: true,
    beforeUpload: (file) => {
      const isPNG = (file as File).type.startsWith('image');
      if (!isPNG) {
        message.error(`${file.name} is not image file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `region_commission/image/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async () => {
          setImageFileUpload({ url: path });
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
  };

  const propVideos: UploadProps = {
    // multiple: true,
    beforeUpload: (file) => {
      const isVideo = (file as File).type === 'video/mp4';
      if (!isVideo) {
        message.error(`${file.name} is not video file`);
      }
      return isVideo || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file, filename }) => {
      const fileNameVideo = `region_commission/video/${generateFileName(
        (file as File).name || '',
      )}`;
      const resVideo = (await fetchImageForUpload(fileNameVideo)) as { link: string };
      uploadMedia(resVideo?.link, file as Blob)
        .then(async () => {
          const fileUrl = URL.createObjectURL(file as Blob);
          const thumb = await getThumbnailForVideo(fileUrl);
          const thumbnail = `${removeExtension(fileNameVideo) + '.jpeg'}`;
          const resThumb = (await fetchImageForUpload(thumbnail)) as {
            link: string;
          };
          fetch(thumb)
            .then((res) => res.blob())
            .then((blob) => {
              uploadMedia(resThumb?.link, blob)
                .then(async () => {
                  setVideoFileUpload({ url: fileNameVideo, thumb: thumbnail });
                  message.success(`${filename} file uploaded successfully`);
                })
                .catch((err) => {
                  console.log(err);
                  message.error(`${filename} file upload failed.`);
                });
            });
        })
        .catch((err) => {
          console.log(err);
          message.error(`${filename} file upload failed.`);
        });
    },
  };

  const handleChangeItem = () => {
    if (disabledSubmit) {
      setDisabledSubmit(false);
    }
  };

  const handleCloseModal = () => {
    if (vidRef && vidRef.current) {
      vidRef.current.pause();
    }
    setPreviewOpen(false);
  };

  const handleCancelModal = () => {
    setImageFileList([]);
    setVideoFileList([]);
    handleCancel();
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setVideoPreview(file.preview || '');
    setPreviewOpen(true);
  };

  const handleRemoveImage = async (file: UploadFile) => {
    setImageFileList(imageFileList.filter((item)=>item.preview!==file.preview));
    setImagePaths(imagePaths.filter((item)=>item.url!==file.preview));
    setImageFileUpload({});
  };

  const handleRemoveVideo = async (file: UploadFile) => {
    setVideoFileList(videoFileList.filter((item)=>item.preview!==file.preview));
    setVideoPaths(videoPaths.filter((item)=>item.url!==file.preview));
    setVideoFileUpload({});
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Modal
      onCancel={handleCancelModal}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={
        <>{data ? <div>Update Concurrent Sessions</div> : <div>Add Concurrent Sessions</div>}</>
      }
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onSave}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Connector'
          name='connector_ID'
          rules={[{ required: true, message: 'Please input Connector!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='EV Model'
          name='ev_model'
          rules={[{ required: true, message: 'Please input EV Model!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='Output Voltage (V)'
          name='output_voltage'
          rules={[{ required: true, message: 'Please input Output Voltage (V)!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='Output Current (A)'
          name='output_current'
          rules={[{ required: true, message: 'Please input Output Current (A)!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='Output Power (kW)'
          name='output_power'
          rules={[{ required: true, message: 'Please input Output Power (kW)!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='SOC (%)'
          name='soc'
          rules={[{ required: true, message: 'Please input SOC (%)!' }]}
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item
          label='Remark'
          name='remark'
        >
          <Input onChange={handleChangeItem} />
        </Form.Item>
        <Form.Item label='Images' valuePropName='imagesFile'>
          <Upload
            {...propImages}
            fileList={imageFileList}
            listType='picture-card'
            onPreview={handlePreview}
            onRemove={handleRemoveImage}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item label='Videos' valuePropName='videosFile'>
          <Upload
            {...propVideos}
            fileList={videoFileList}
            listType='picture-card'
            onPreview={handlePreview}
            onRemove={handleRemoveVideo}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit' disabled={disabledSubmit}>
            Submit
          </Button>
        </Form.Item>
        <MediaCustom
            url={videoPreview || ''}
            isModalVisible={previewOpen}
            handleCancel={handleCloseModal}
            // urlFetched={setUrlFetched}
          />
      </Form>
    </Modal>
  );
};

export default ModalConcurrentSession;
