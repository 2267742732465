import { useAppDispatch } from 'app/hooks';
import React, { useEffect } from 'react';
import { StepTicketForm } from './steps/Index';
import { ticketActions } from '../ticketSlice';
const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) dispatch(ticketActions.fetchTicketDetail({ id }));
  }, [id, dispatch]);

  return <StepTicketForm id={id} />;
};

export default FormEdit;
