import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Country } from 'models';
import { TicketSeverity, TicketStatus } from 'models/enum/ticket';
import React, { useEffect, useState } from 'react';
import { countryActions } from '../../settings/country/countrySlice';
import { Filter, ticketActions } from '../ticketSlice';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const FilterForm: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = useForm();

  const [countryData, setCountryData] = useState<DataType[]>([]);
  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  useEffect(() => {
    if (listCountry) {
      const info: DataType[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: Country = obj[key] as Country;
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      countryActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  }, [dispatch]);

  const severityOption = [
    { title: 'All', value: '' },
    { title: 'Urgent', value: TicketSeverity.Urgent },
    { title: 'High', value: TicketSeverity.High },
    { title: 'Medium', value: TicketSeverity.Medium },
    { title: 'Low', value: TicketSeverity.Low },
  ];

  const onSearch = () => {
    const val = form.getFieldsValue();
    const searchParams: Filter = {};
    let created_date_from = '';
    let created_date_to = '';
    if (val.createdAt && val.createdAt.length > 0) {
      created_date_from = val.createdAt[0].format('YYYY-MM-DD');
      created_date_to = val.createdAt[1].format('YYYY-MM-DD');
      searchParams['created_date_from'] = created_date_from;
      searchParams['created_date_to'] = created_date_to;
    }

    let closed_date_from = '';
    let closed_date_to = '';
    if (val.closed_date && val.closed_date.length > 0) {
      closed_date_from = val.closed_date[0].format('YYYY-MM-DD');
      closed_date_to = val.closed_date[1].format('YYYY-MM-DD');
      searchParams['closed_date_from'] = closed_date_from;
      searchParams['closed_date_to'] = closed_date_to;
    }
    if (val.ticket_name) {
      searchParams['ticketSearch'] = val.ticket_name;
    }
    if (val.status !== '' && val.status !== undefined) {
      searchParams['status'] = val.status;
    }
    if (val.severity !== '' && val.severity !== undefined) {
      searchParams['severity'] = val.severity;
    }
    if (val.country !== '' && val.country !== undefined) {
      searchParams['countryName'] = val.country;
    }
    if (val.siteAppointment && val.siteAppointment.length > 0) {
      created_date_from = val.siteAppointment[0].format('YYYY-MM-DD');
      created_date_to = val.siteAppointment[1].format('YYYY-MM-DD');
      searchParams['appointment_date_from'] = created_date_from;
      searchParams['appointment_date_to'] = created_date_to;
    }
    if (val.siteAttendance && val.siteAttendance.length > 0) {
      created_date_from = val.siteAttendance[0].format('YYYY-MM-DD');
      created_date_to = val.siteAttendance[1].format('YYYY-MM-DD');
      searchParams['attendance_date_from'] = created_date_from;
      searchParams['attendance_date_to'] = created_date_to;
    }
    if (val.call_center) {
      searchParams['call_center'] = val.call_center;
    }
    dispatch(ticketActions.unSelectedIdExport());
    dispatch(ticketActions.unSelectedTicketIdExport());
    dispatch(ticketActions.search({ filter: searchParams, pagination: { pageNo: 1 } }));
  };
  return (
    <div className='ticket-filter'>
      <Form
        name='basic'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete='off'
      >
        <Row gutter={16} align='bottom'>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Ticket By Name, ID or Serial Number' name='ticket_name'>
              <Input />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Date Created' name='createdAt' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Date Closed' name='closed_date' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Status' name='status' className='short-title'>
              <Select>
                <Select.Option value={''}>All</Select.Option>
                <Select.Option value={TicketStatus.Open}>Open</Select.Option>
                <Select.Option value={TicketStatus.Closed}>Closed</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Severity' name='severity' className='short-title'>
              <Select>
                {severityOption.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item className='short-title'>
              <Button
                className='user-content-btn'
                type='primary'
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Country' name='country' className='short-title'>
              <AutoComplete
                options={countryData}
                filterOption={(inputValue, option) =>
                  option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Site Appointment Date' name='siteAppointment' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Site Attendance Date' name='siteAttendance' className='short-title'>
              <RangePicker format='YYYY-MM-DD' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={4}>
            <Form.Item label='Call Center' name='call_center'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
