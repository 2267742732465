import React from 'react';
import FilterForm from './FilterForm';
import TableData from './TableData';

const TrainingPage: React.FC = () => {
  return (
    <div className='camis-page-wrapper training-dashboard'>
      <div className='user-content training-content'>
        <FilterForm />
        <TableData />
      </div>
    </div>
  );
};

export default TrainingPage;
