/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { SerialNumber } from 'models/serialNumber';

export interface Filter {
  name: string;
  chargerModelId: string;
}

export interface SerialNumberState {
  list: SerialNumber[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  deleted: boolean;
  upserted: boolean;
  error: boolean;
  pagination: Pagination;
  filter: Filter;
}

const initialState: SerialNumberState = {
  loading: false,
  loaded: false,
  deleting: false,
  deleted: false,
  upserted: false,
  error: false,
  list: [],
  filter: {
    name: '',
    chargerModelId: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const serialNumberSlice = createSlice({
  name: 'serialNumber',
  initialState,
  reducers: {
    fetchSerialNumberList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload._page,
        size: action.payload._limit,
      };
    },
    fetchSerialNumberListSuccess(state, action: PayloadAction<ListResponse<SerialNumber>>) {
      state.loading = false;
      state.loaded = true;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      // console.log(action.payload.data);
      state.list = action.payload.data || action.payload || [];
    },
    createSerialNumber(state, action: PayloadAction<SerialNumber>) {
      state.loading = true;
      state.upserted = false;
      state.deleted = false;
    },
    createSerialNumberSuccess(state, action: PayloadAction<SerialNumber>) {
      state.loading = false;
      state.upserted = true;
    },
    setUpsertedSN(state, action: PayloadAction<{}>) {
      state.upserted = false;
    },
    updateSerialNumber(state, action: PayloadAction<SerialNumber>) {
      state.loading = true;
      state.upserted = false;
      state.deleted = false;
    },
    updateSerialNumberSuccess(state, action: PayloadAction<SerialNumber>) {
      state.loading = false;
      state.loaded = true;
      state.upserted = true;
    },
    fetchSerialNumberDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchSerialNumberByChargerModel(
      state,
      action: PayloadAction<{ chargerModelId: string | undefined }>,
    ) {},
    deleteError(state, action: PayloadAction) {
      state.deleting = false;
      state.error = true;
      state.deleted = true;
    },
    search(state, action: PayloadAction<{ filter: Filter; pagination?: Pagination }>) {
      state.list = [];
      const { pagination, filter } = action.payload;
      state.deleted = false;
      state.error = false;
      state.filter = filter;
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo,
          size: pagination.size,
        };
      }
    },
    fetchSerialNumberSuccess(state, action: PayloadAction<SerialNumber>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteSerialNumber(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
      state.deleted = false;
      state.error = false;
    },
    deleteSerialNumberSuccess(state) {
      state.loading = true;
      state.deleting = false;
      state.deleted = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const serialNumberActions = serialNumberSlice.actions;

// selector
export const selectSerialNumberLoad = (state: RootState) => state.serialNumber.loaded;
export const selectSerialNumberUpserted = (state: RootState) => state.serialNumber.upserted;
export const selectPagination = (state: RootState) => state.serialNumber.pagination;
export const selectName = (state: RootState) => state.serialNumber.filter.name;
export const selectFilter = (state: RootState) => state.serialNumber.filter;
export const selectDeleting = (state: RootState) => state.serialNumber.deleting;
export const selectError = (state: RootState) => state.serialNumber.error;
export const selectSerialNumbers = (state: RootState) => state.serialNumber.list;
export const selectSerialNumberLoading = (state: RootState) => state.serialNumber.loading;

// Reducer
const serialNumberReducer = serialNumberSlice.reducer;
export default serialNumberReducer;
