/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CommissionRegion, ListParams, ListResponse } from 'models';
import { sortDateByStringOtherRegion } from 'utils/function';

export interface InspOtherRegionState {
  loading: boolean;
  deleting: boolean;
  loaded: boolean;
  list: CommissionRegion[];
  detail: CommissionRegion | null;
  pagination: Pagination;
  filter: Filter;
  exportPdf: string[];
}

export interface Pagination {
  pageNo?: number;
  size?: number;
  totalRecords?: number;
}

export interface Filter {
  commissioning_date_from?: string;
  commissioning_date_to?: string;
  engineer_name?: string;
  company_id?: string;
  charger_owner_name?: string;
  country_name?: string;
  site_name?: string;
  charger_model_name?: string;
  sn_Number?: string;
}

export interface InspOtherRegionPayload {
  data: CommissionRegion;
  navigate: any;
}
const initialState: InspOtherRegionState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  detail: null,
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
  filter: {
    commissioning_date_from: '',
    commissioning_date_to: '',
    engineer_name: '',
    company_id: '',
    charger_owner_name: '',
    country_name: '',
    site_name: '',
    charger_model_name: '',
    sn_Number: '',
  },
  exportPdf: [],
};

const inspOtherRegionSlice = createSlice({
  name: 'inspOtherRegion',
  initialState,
  reducers: {
    fetchInspectionList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchInspectionListSuccess(state, action: PayloadAction<ListResponse<CommissionRegion>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || 0,
      };

      state.list = action.payload.data.sort(sortDateByStringOtherRegion);
    },
    fetchInspectionListFailed(state) {
      state.loading = false;
    },
    upsertInspection(state, action: PayloadAction<CommissionRegion>) {
      state.loaded = false;
    },
    upsertInspectionSuccess(state) {
      state.loaded = true;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    search(state, action: PayloadAction<Filter>) {
      state.list = [];
      state.filter = action.payload;
    },
    deleteInspection(state, action: PayloadAction<{ ids: string[] }>) {
      state.deleting = true;
    },
    deleteInspectionSuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    deleteInspectionFailed(state) {
      state.deleting = false;
    },
    exportPdf(state) {
      state.loading = true;
    },
    exportPdfSuccess(state) {
      state.loading = false;
    },
    fetchOtherRegionDetail(state, action: PayloadAction<{ id: string | undefined }>) {
      state.detail = null;
    },
    fetchOtherRegionDetailSuccess(state, action: PayloadAction<CommissionRegion>) {
      state.detail = action.payload
    },
  },
});

// Actions
export const inspOtherRegionActions = inspOtherRegionSlice.actions;

// Selectors
export const selectInspection = (state: RootState) => state.inspOtherRegion;
export const selectInspectionList = (state: RootState) => state.inspOtherRegion.list;
export const selectInspectionLoad = (state: RootState) => state.inspOtherRegion.loaded;
export const selectInspectionLoading = (state: RootState) => state.inspOtherRegion.loading;

export const selectPagination = (state: RootState) => state.inspOtherRegion.pagination;
export const selectFilter = (state: RootState) => state.inspOtherRegion.filter;
export const selectDeleting = (state: RootState) => state.inspOtherRegion.deleting;
export const selectExport = (state: RootState) => state.inspOtherRegion.exportPdf;
export const selectTotalRecords = (state: RootState) =>
  state.inspOtherRegion.pagination.totalRecords;

// Reducer
const inspOtherRegionReducer = inspOtherRegionSlice.reducer;
export default inspOtherRegionReducer;
