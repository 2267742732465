import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import inspectionReducer from 'features/inspection/inspectionSlice';
import userReducer from 'features/user/userSlice';
import ticketReducer from 'features/tickets/ticketSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import countryReducer from 'features/settings/country/countrySlice';
import companyReducer from 'features/settings/company/companySlice';
import certificateAdminReducer from 'features/settings/certificate-admin/certificateAdminSlice';
import termsReducer from 'features/settings/terms/termsSlice';
import policyReducer from 'features/settings/policy/policySlice';
import chargerModelReducer from 'features/settings/charger-model/chargerModelSlice';
import categoryReducer from 'features/settings/category/categorySlice';
import problemCategoryReducer from 'features/settings/problemCategory/problemCategorySlice';
import provinceReducer from 'features/settings/province/provinceSlice';
import serialNumberReducer from 'features/settings/serial-number/serialNumberSlice';
import trainingReducer from 'features/training/trainingSlice';
import certificationReducer from 'features/certification/certificationSlice';
import inspOtherRegionReducer from 'features/inspOtherRegion/inspOtherRegionSlice';
import maintenanceOtherRegionsReducer from 'features/maintenanceOtherRegion/maintenanceOtherRegionsSlice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    inspection: inspectionReducer,
    inspOtherRegion: inspOtherRegionReducer,
    user: userReducer,
    ticket: ticketReducer,
    country: countryReducer,
    category: categoryReducer,
    province: provinceReducer,
    serialNumber: serialNumberReducer,
    company: companyReducer,
    certificateAdmin: certificateAdminReducer,
    terms: termsReducer,
    policy: policyReducer,
    chargerModel: chargerModelReducer,
    training: trainingReducer,
    certification: certificationReducer,
    maintenanceOtherRegion: maintenanceOtherRegionsReducer,
    problemCategory: problemCategoryReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
