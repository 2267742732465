import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { countryActions, selectCountries } from 'features/settings/country/countrySlice';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import { provinceActions } from '../provinceSlice';
import ModalCreate from './ModalCreate';
import TableData from './TableData';

const ProvincePage: React.FC = () => {
  const isSCAdminSystemAdmin = TokenService.isSCAdminSystemAdmin();
  const dispatch = useAppDispatch();
  const countryOption = useAppSelector(selectCountries);

  const onSearch = () => {
    dispatch(
      provinceActions.search({
        name: searchName,
        countryId: countryId,
        pagination: { pageNo: 1 },
      }),
    );
  };
  useEffect(() => {
    dispatch(countryActions.search({ name: '', pagination: { size: 300 } }));
  }, [dispatch]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomState, setRandomState] = useState('');
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const [idEdit, setIdEdit] = useState('');
  const [countryId, setCountryId] = useState('');
  const [searchName, setSearchName] = useState('');
  const handleChangeCountry = (countryId: string) => {
    setCountryId(countryId);
    dispatch(
      provinceActions.search({
        name: searchName,
        countryId,
        pagination: { pageNo: 1 },
      }),
    );
  };

  const handleChangeName = (evt: any) => {
    setSearchName(evt.currentTarget.value);
  };

  return (
    <>
      {isSCAdminSystemAdmin && (
        <div className='user-wrapper'>
          <div className='user-head'>
            <div className='user-head-search'>
              <Row style={{ width: '97%' }}>
                <Col span={10}>
                  <Select
                    dropdownMatchSelectWidth={false}
                    placeholder='Select Country'
                    allowClear
                    style={{ width: '90%' }}
                    onChange={handleChangeCountry}
                  >
                    {countryOption.map((item, index) => (
                      <Select.Option value={item.id} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={10}>
                  <Input placeholder='province name' onChange={handleChangeName} />
                </Col>
                <Col span={4} style={{ paddingLeft: '3%' }}>
                  <Button
                    style={{ padding: '4px 15px' }}
                    icon={<SearchOutlined />}
                    className='ant-btn-primary'
                    onClick={onSearch}
                  >
                    Search
                  </Button>
                </Col>
              </Row>

              {/* <SearchCustom handleSearch={onSearch} /> */}
              {/* <SearchCustom handleSearch={onSearch} /> */}
            </div>
          </div>
          <div className='user-content'>
            <div className='user-content-add'>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsModalVisible(true);
                  setIdEdit('');
                }}
              >
                Add Province
              </Button>
            </div>
            <TableData
              setIsModalVisible={setIsModalVisible}
              setIdEdit={setIdEdit}
              randomState={randomState}
            />
          </div>
          <ModalCreate
            id={idEdit}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            setRandomState={setRandomState}
          />
        </div>
      )}
    </>
  );
};

export default ProvincePage;
