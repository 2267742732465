import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  CommissionRegion,
  Conclusion as ConclusionData,
  ConcurrentSession as ConcurrentSessionModel,
} from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TokenService from 'utils/token.service';
import { ConcurrentSession } from './ConcurrentSession';

export const Conclusion: React.FC<{
  nextStep: (data: CommissionRegion) => void;
  prevStep: () => void;
  inspOtherRegion: CommissionRegion | undefined;
}> = ({ nextStep, prevStep, inspOtherRegion }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();
  const [conclusionData, setConclusionData] = useState<ConclusionData[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (inspOtherRegion) {
      const conclusion = [...inspOtherRegion.conclusion].sort((left, right) => {
        return moment.utc(left.updatedAt).diff(moment.utc(right.updatedAt));
      });
      setConclusionData(conclusion);
      form.setFieldsValue({
        conclusion: conclusionData,
      });
    }
  }, []);

  const handleNext = () => {
    if (inspOtherRegion) nextStep({ ...inspOtherRegion, conclusion: conclusionData });
  };

  const handleFormChange = () => {
    setIsValid(hasFieldEmpty() ? false : true);
  };

  useEffect(() => {
    handleFormChange();
  }, [conclusionData]);

  const hasFieldEmpty = () => {
    return conclusionData.some((item) => item.session_list.length === 0);
  };

  const addNewCharging = () => {
    setConclusionData([
      ...conclusionData,
      {
        name: `charging test ${
          +(conclusionData.at(-1)?.name.replace('charging test ', '') || 0) + 1
        }`,
        session_list: [],
        id: '',
      },
    ]);
    form.setFieldsValue({
      conclusion: conclusionData,
    });
  };

  const handleChange = (concurrentSession: ConcurrentSessionModel[] | undefined, index: number) => {
    if (concurrentSession) {
      const tmp = [...conclusionData];
      tmp[index] = {
        ...tmp[index],
        session_list: concurrentSession,
      };
      setConclusionData(tmp);
    }
  };

  return (
    <div className='form-wrapper'>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {conclusionData.length ? (
          ''
        ) : (
          <div className='charging-empty'>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={addNewCharging}
              disabled={disabled}
            >
              Add Charging Test
            </Button>
          </div>
        )}
        {conclusionData.map((item, index) => {
          return (
            <>
              <div style={{ paddingBottom: '15px' }}>
                <div
                  className='title'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 15,
                    justifyContent: 'space-between',
                  }}
                >
                  <h3>{item.name}</h3>
                  <div>
                    {
                      <>
                        {index === 0 ? (
                          <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={addNewCharging}
                            disabled={disabled}
                          >
                            Add Charging Test
                          </Button>
                        ) : (
                          ''
                        )}
                      </>
                    }
                  </div>
                </div>
                <div className='content'>
                  <ConcurrentSession
                    data={[...item.session_list].sort((left, right) => {
                      return moment.utc(left.updatedAt).diff(moment.utc(right.updatedAt));
                    })}
                    handleChange={(data?: ConcurrentSessionModel[]) => handleChange(data, index)}
                  ></ConcurrentSession>
                </div>
              </div>
            </>
          );
        })}

        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
            disabled={!isValid}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
