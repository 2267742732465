import { Button, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { MaintenanceRegion } from 'models';
import React, { Fragment, useEffect } from 'react';
import TokenService from 'utils/token.service';
interface ICheck {
  [key: string]: string;
}
const mergeObjectInArray = (data: ICheck[]) => {
  return data.reduce((prev, current) => Object.assign(current, prev), {});
};

const Step2: React.FC<{
  nextStep: any;
  prevStep: () => void;
  data: MaintenanceRegion | undefined;
}> = ({ nextStep, prevStep, data }) => {
  const [form] = useForm();
  const disabled = TokenService.isDisabled();

  const station_inspection_tpl = [
    {
      no: 1,
      Content: 'There is charging procedure information.',
      Method: 'Visually',
    },
    {
      no: 2,
      Content: 'CCTV camera works normally.',
      Method: 'Visually',
    },
    {
      no: 3,
      Content:
        'Equipped with fire extinguishers and other safety facilities. Safety facilities are available.',
      Method: 'Visually',
    },
    {
      no: 4,
      Content: 'Equipped with parking bars.',
      Method: 'Visually',
    },
    {
      no: 5,
      Content: 'No flammable, explosive and something dangerous around.',
      Method: 'Visually',
    },
    {
      no: 6,
      Content: 'The surrounding of the station is suitable (no dust, oil, humidity, weeds).',
      Method: 'Visually',
    },
    {
      no: 7,
      Content: 'The canopy is not damaged.',
      Method: 'Visually',
    },
    {
      no: 8,
      Content: 'Check that the terrain is leveled, not around flood discharge point.',
      Method: 'Visually',
    },
  ];

  useEffect(() => {
    if (data) {
      const station_inspection = data.station_inspection?.map((item) => {
        return {
          [`station_inspection_${item.no}`]: item.value,
          [`station_inspection_note_${item.no}`]: item.remarks,
        };
      }) as ICheck[];
      const initForm = {
        ...mergeObjectInArray(station_inspection),
      };
      form.setFieldsValue(initForm);
    }
  }, []);

  const handleNext = () => {
    const maintenanceForm = form.getFieldsValue();
    const station_inspection = [...(data?.station_inspection || [])].map((item) => {
      return {
        ...item,
        value: maintenanceForm['station_inspection_' + item.no],
        remarks: maintenanceForm['station_inspection_note_' + item.no],
      };
    });

    nextStep({ ...data, station_inspection });
  };

  return (
    <div className='form-wrapper'>
      <h3>Station Inspection</h3>
      <Form className='list-field' onFinish={handleNext} form={form}>
        {(data?.station_inspection || []).map((item, idx) => {
          const recheck_data = station_inspection_tpl.find((data) => data.no === +item.no);
          return (
            <Fragment key={idx}>
              <Form.Item
                className='annexfield'
                label={idx + 1 + '. ' + recheck_data?.Content}
                key={item.no}
                initialValue={item.value}
                name={'station_inspection_' + item.no}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={'0'}>C</Radio>
                  <Radio value={'1'}>NC</Radio>
                  <Radio value={'2'}>NA</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className='annexfield-note'
                label={item.content}
                key={item.no + '_note'}
                initialValue={item.remarks}
                name={'station_inspection_note_' + item.no}
              >
                <Input disabled={disabled} className='input-remarks' placeholder='Remarks' />
              </Form.Item>
            </Fragment>
          );
        })}
        <Form.Item className='ant-form-item-submit action-form'>
          <Button htmlType='submit' onClick={prevStep} className={`btn-back`}>
            Back
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            onClick={handleNext}
            className={`btn-submit-form`}
          >
            Next step
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step2;
