import { notification } from 'antd';
import settingApi from 'api/settingApi';
import { ListParams, ListResponse, Terms } from 'models';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { termsActions } from './termsSlice';

type Params = { payload: Terms; type: string };

function* fetchTermsList(param: ListParams) {
  try {
    const response: ListResponse<Terms> = yield call(settingApi.getAllTerms, {
      pageNo: param._page,
      pageSize: 200,
    });
    yield put(termsActions.fetchTermsListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch Terms list', error);
  }
}

function* createTerms(param: Params) {
  try {
    const response: Terms = yield call(settingApi.createTerms, param.payload);
    yield put(termsActions.createTermsSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* updateTerms(param: Params) {
  try {
    const response: Terms = yield call(settingApi.updateTerms, param.payload);
    yield put(termsActions.updateTermsSuccess(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

function* toggleStatusTerms(param: Params) {
  try {
    const response: Terms = yield call(settingApi.publicTerms, param.payload);
    yield put(termsActions.updateTermsSuccess(response));
    yield put(termsActions.fetchTermsList(response));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type TermsDetailParams = { payload: { id: string }; type: string };
function* fetchTermsDetail(param: TermsDetailParams) {
  try {
    const id = param.payload.id;
    const response: Terms = yield call(settingApi.findTermsById, id);
    yield put(termsActions.fetchTermsSuccess(response));
  } catch (error) {
    console.log('Failed to find Terms by id', error);
  }
}

type DeleteParams = { payload: { id: string }; type: string };
function* deleteTerms(param: DeleteParams) {
  try {
    const id = param.payload.id;

    yield call(settingApi.removeTerms, id);
    yield put(termsActions.deleteTermsSuccess());
    yield put(termsActions.fetchTermsList({}));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

function* findForPublic(param: ListParams) {
  try {
    const response: ListResponse<Terms> = yield call(settingApi.findForPublicTerm, {
      pageNo: 1,
      pageSize: 200,
    });
    yield put(termsActions.fetchTermsListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch public', error);
  }
}

export default function* TermsSaga() {
  yield takeLatest(termsActions.fetchTermsList.type, fetchTermsList);
  yield takeEvery(termsActions.createTerms.type, createTerms);
  yield takeEvery(termsActions.updateTerms.type, updateTerms);
  yield takeLatest(termsActions.fetchTermsDetail.type, fetchTermsDetail);
  yield takeLatest(termsActions.deleteTerms.type, deleteTerms);
  yield takeLatest(termsActions.toggleStatusTerms.type, toggleStatusTerms);
  yield takeLatest(termsActions.findForPublic.type, findForPublic);
}
