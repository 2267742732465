import { notification } from 'antd';
import commissionRegionApi from 'api/commissionRegionApi';
import { CommissionRegion, Inspection, ListResponse } from 'models';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  Filter,
  inspOtherRegionActions,
  Pagination,
  selectFilter,
  selectPagination,
} from './inspOtherRegionSlice';

function* fetchInspectionList(formTypeFilter: any) {
  // console.log(formTypeFilter);
  try {
    const pagination: Pagination = yield select(selectPagination);
    const response: ListResponse<CommissionRegion> = yield call(commissionRegionApi.searchByParams, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      formTypeFilter: formTypeFilter?.payload?.formTypeFilter,
    });
    yield put(inspOtherRegionActions.fetchInspectionListSuccess(response));
  } catch (error) {
    console.log('Failed to fetch inspection list', error);
    yield put(inspOtherRegionActions.fetchInspectionListFailed());
  }
}

type Params = { payload: CommissionRegion; type: string };
function* upsertInspection(param: Params) {
  try {
    yield call(commissionRegionApi.upsert, param.payload);
    yield put(inspOtherRegionActions.upsertInspectionSuccess());
    yield put(inspOtherRegionActions.fetchInspectionList({ _page: 1, _limit: 10 }));
  } catch (error: any) {
    notification.error({
      message: error.response.data.message,
      placement: 'bottomRight',
      duration: 2.5,
    });
  }
}

type PageParams = { payload: Pagination; type: string };
function* changePage(param: PageParams) {
  try {
    yield put(inspOtherRegionActions.fetchInspectionList({}));
  } catch (error) {
    console.log('Failed to create user', error);
  }
}

type SearchParams = { payload: Filter; type: string };
function* search(param: SearchParams) {
  try {
    const pagination: Pagination = yield select(selectPagination);
    const filter: Filter = yield select(selectFilter);
    const response: ListResponse<CommissionRegion> = yield call(commissionRegionApi.searchByParams, {
      pageNo: pagination.pageNo,
      pageSize: pagination.size,
      ...filter,
      ...param.payload,
    });
    yield put(inspOtherRegionActions.fetchInspectionListSuccess(response));
  } catch (error) {
    console.log('Failed to search users', error);
  }
}

type DeleteParams = { payload: { ids: string[] }; type: string };
function* deleteInspection(param: DeleteParams) {
  try {
    yield call(commissionRegionApi.deleteInspection, {
      ...param.payload,
    });
    yield put(inspOtherRegionActions.deleteInspectionSuccess());
    yield put(inspOtherRegionActions.search({}));
  } catch (error) {
    yield put(inspOtherRegionActions.deleteInspectionFailed());
    console.log('Failed to delete other region', error);
  }
}

type InspectionDetailParams = { payload: { id: string }; type: string };
function* fetchOtherRegionDetail(param: InspectionDetailParams) {
  try {
    const id = param.payload.id;
    const response: CommissionRegion = yield call(commissionRegionApi.getById, id);
    yield put(inspOtherRegionActions.fetchOtherRegionDetailSuccess(response));
  } catch (error) {
    console.log('Failed to find inspection by id', error);
  }
}

export default function* inspOtherRegionSaga() {
  yield takeLatest(inspOtherRegionActions.fetchInspectionList.type, fetchInspectionList);
  yield takeEvery(inspOtherRegionActions.upsertInspection.type, upsertInspection);
  yield takeEvery(inspOtherRegionActions.changePage.type, changePage);
  yield takeLatest(inspOtherRegionActions.search.type, search);
  yield takeLatest(inspOtherRegionActions.deleteInspection.type, deleteInspection);
  yield takeLatest(inspOtherRegionActions.fetchOtherRegionDetail.type, fetchOtherRegionDetail);
}
