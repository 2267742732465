import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse } from 'models';
import { RootState } from './../../app/store';
import { User } from './../../models/user';

export interface LoginPayload {
  username: string;
  password: string;
}

export interface AuthState {
  isLoggedIn: boolean;
  logging?: boolean;
  hasError?: boolean;
  currentUser?: User;
  trainees: User[];
  loading: boolean;
  loaded: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  hasError: false,
  currentUser: undefined,
  trainees: [],
  loaded: false,
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.logging = true;
      state.hasError = false;
    },
    loginSuccess(state, action: PayloadAction<User>) {
      state.isLoggedIn = true;
      state.logging = false;
      state.currentUser = action.payload;
    },
    loginFailed(state) {
      state.logging = false;
      state.hasError = true;
    },

    logout(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
    },
    fetchTraineeList(state, _action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchTraineeListSuccess(state, action: PayloadAction<ListResponse<User>>) {
      state.loading = false;
      state.loaded = false;
      state.trainees = action.payload.data || action.payload || [];
    },
    fetchTraineeListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLogging = (state: RootState) => state.auth.logging;
export const selectIsError = (state: RootState) => state.auth.hasError;

export const selectTraineeList = (state: RootState) => state.auth.trainees;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
