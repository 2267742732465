import { PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { format } from 'date-fns';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Asset, User } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchImage, fetchImageForUpload, generateFileName, uploadMedia } from 'utils/function';
import TokenService from 'utils/token.service';
import { companyActions } from '../../settings/company/companySlice';
import { countryActions } from '../../settings/country/countrySlice';
import { selectInspectionLoad, userActions } from '../userSlice';
import { certificationActions } from 'features/certification/certificationSlice';
import { Role } from 'models/enum/role';

interface DataType {
  label?: string;
  value?: string;
  id?: string;
}

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUpload, setFileUpload] = useState('');
  const user = useAppSelector((state) => state.user.list.filter((item: User) => item.id === id)[0]);
  const loaded = useAppSelector(selectInspectionLoad);
  const disabledForm = !TokenService.isSystemAdmin();

  useEffect(() => {
    const setImgAsset = async (list: any) => {
      Promise.all(list).then((list) => {
        setFileList([...fileList, list[0]]);
      });
    };
    const getImage = async (asset: Asset) => {
      const url = (asset.thumb ? asset.thumb : asset.url) || '';
      const resUrl = (await fetchImage(url)) as { link: string };
      return {
        uid: resUrl?.link || '',
        url: resUrl?.link || '',
        name: resUrl?.link || '',
      };
    };
    if (fileUpload) {
      const asset = getImage({ thumb: fileUpload });
      setImgAsset([asset]);
    }
  }, [fileUpload]);

  const [countryData, setCountryData] = useState<DataType[]>([]);
  const [companyData, setCompanyData] = useState<DataType[]>([]);

  const listCountry = useAppSelector((state) => state.country.list) || undefined;
  const listCompany = useAppSelector((state) => state.company.list);
  const listCertification = useAppSelector((state) => state.certification.list);

  useEffect(() => {
    if (listCountry) {
      const info: DataType[] = [];
      const obj = listCountry;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCountryData(info);
    }
  }, [listCountry]);

  useEffect(() => {
    if (listCompany) {
      const info1: DataType[] = [];
      const obj = listCompany;
      (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
        const obj2: any = obj[key];
        info1.push({
          label: obj2.name || '',
          value: obj2.name || '',
          id: obj2.id || '',
        });
      });
      setCompanyData(info1);
    }
  }, [listCompany]);

  useEffect(() => {
    dispatch(
      companyActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
    dispatch(
      countryActions.search({
        name: '',
        pagination: { pageNo: 1, size: 10000 },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    console.log('user:', user);
    if (user?.username) {
      dispatch(
        certificationActions.search({
          filter: {
            certificate_username: user?.username,
            status: 'Valid',
          },
          pagination: { pageNo: 1, size: 10000 },
        }),
      );
    }
    form.setFieldsValue({
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      username: user?.username || '',
      email: user?.email || '',
      phone: user?.phone || '',
      status: user?.status || '1',
      password: user?.password || '',
      role: user?.role || 'user',
      country: user?.country?.name || '',
      company: user?.company?.name || '',
    });
    if (user?.avatar) {
      const setImgList = async (list: any) => {
        Promise.all(list).then((list) => {
          setFileList(list);
        });
      };
      const getImage = async (asset: Asset) => {
        const url = (asset.thumb ? asset.thumb : asset.url) || '';
        const resUrl = (await fetchImage(url)) as { link: string };
        return {
          uid: resUrl?.link || '',
          url: resUrl?.link || '',
          name: resUrl?.link || '',
        };
      };
      setImgList([getImage({ url: user.avatar })]);
    }
  }, [user, form]);

  useEffect(() => {
    if (id) dispatch(userActions.fetchUserDetail({ id }));
  }, [id, dispatch]);

  const resetForm = () => {
    form.setFieldsValue({
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      phone: '',
      status: '1',
      password: '',
      role: 'user',
      avatar: '',
      company: '',
      country: '',
    });
  };

  const openNotification = useCallback(() => {
    if (loaded) {
      if (uid === id) {
        notification.success({
          message: 'Profile updated successfully',
          placement: 'bottomRight',
          duration: 2.5,
        });
      } else if (id) {
        notification.success({
          message: 'User record updated successfully',
          placement: 'bottomRight',
          onClose: () => navigate('/user'),
          duration: 2.5,
        });
      } else {
        notification.success({
          message: 'User record created successfully',
          placement: 'bottomRight',
          onClose: () => resetForm(),
          duration: 2.5,
        });
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const user: User = form.getFieldsValue();
    if (!user.password) delete user['password'];
    if (!user.country) delete user['country'];
    if (id) {
      let updateData = {
        ...user,
        avatar: fileUpload || user.avatar,
        id,
        company: { id: findIdCompanyByName(form.getFieldValue('company')) },
      };
      if (user.country) {
        updateData = {
          ...updateData,
          country: { id: findIdCountryByName(form.getFieldValue('country')) },
        };
      }
      dispatch(userActions.updateUser(updateData));
    } else {
      let updateData = {
        ...user,
        avatar: fileUpload || '',
        status: '1',
        company: { id: findIdCompanyByName(form.getFieldValue('company')) },
      };
      if (user.country) {
        updateData = {
          ...updateData,
          country: { id: findIdCountryByName(form.getFieldValue('country')) },
        };
      }
      dispatch(userActions.createUser(updateData));
    }
  };

  const findIdCompanyByName = (name: string) => {
    const companyItem = listCompany.find((company) => company.name === name);
    return companyItem?.id || '';
  };

  const findIdCountryByName = (name: string) => {
    const countryItem = listCountry.find((country) => country.name === name);
    return countryItem?.id || '';
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const props: UploadProps = {
    name: 'image',
    fileList: fileList,
    customRequest: async ({ onSuccess, onError, file, filename }) => {
      const fileName = generateFileName((file as File).name || '');
      const path = `avatar/${fileName}`;
      const resImg = (await fetchImageForUpload(path)) as { link: string };
      const formData = new FormData();
      formData.append('image', file);
      uploadMedia(resImg?.link, file as Blob)
        .then(async (resp) => {
          setFileUpload(path);
          message.success(`${fileName} file uploaded successfully`);
        })
        .catch((err) => {
          console.log(err);
          message.error(`${fileName} file upload failed.`);
        });
    },
    onRemove(file) {
      setFileList([]);
      setFileUpload('');
    },
  };

  return (
    <Form
      name='form-inspection'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Form.Item
        label='First Name'
        name='firstname'
        rules={[{ required: true, message: 'Please input your First Name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Last Name'
        name='lastname'
        rules={[{ required: true, message: 'Please input your Last Name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Username'
        name='username'
        rules={[
          { required: true, message: 'Please input your username!' },
          {
            min: 6,
            message: 'The username must be at least 6 characters.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='email' label='Email' rules={[{ type: 'email' }, { required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label='Phone'
        name='phone'
        rules={[{ required: true, message: 'Please input your Phone!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Company'
        name='company'
        rules={[{ required: true, message: 'Please input your Company!' }]}
      >
        <AutoComplete
          disabled={disabledForm}
          // value={inputValue}
          options={companyData}
          autoFocus={true}
          style={{ width: 200 }}
          filterOption={(inputValue, option) =>
            option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
          }
          // onSelect={onSelect}
          // onChange={onChangeCompany}
        />
      </Form.Item>
      <Form.Item label='Country' name='country'>
        <AutoComplete
          // value={inputValue}
          options={countryData}
          autoFocus={true}
          style={{ width: 200 }}
          filterOption={(inputValue, option) =>
            option?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
          }
          // onSelect={onSelect}
          // onChange={onChangeCountry}
        />
      </Form.Item>
      <Form.Item label='Upload' valuePropName='file' name='avatar'>
        <Upload {...props} listType='picture-card'>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

      {
        <Form.Item
          label='Password'
          name='password'
          rules={[id ? {} : { required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
      }
      {id && (
        <Form.Item name='status' label='Status'>
          <Radio.Group disabled={disabledForm}>
            <Radio value='0' disabled>
              Inactive
            </Radio>
            <Radio value='1'>Active</Radio>
            <Radio value='2'>Hold</Radio>
          </Radio.Group>
        </Form.Item>
      )}
      <Form.Item label='Role' name='role'>
        <Select disabled={disabledForm}>
          <Select.Option value={Role.SYSTEM_ADMIN}>System Admin</Select.Option>
          <Select.Option value={Role.SC_ADMIN}>SC Admin</Select.Option>
          <Select.Option value={Role.SC_CSE_LEAD}>SC CSE Lead</Select.Option>
          <Select.Option value={Role.SC_CSE}>SC CSE</Select.Option>
          <Select.Option value={Role.USER}>User</Select.Option>
        </Select>
      </Form.Item>
      {listCertification?.length > 0 ? (
        <Form.Item label='Certification' name='certification'>
          <div
            style={{
              width: '100%',
            }}
          >
            <table
              style={{
                border: '1px solid #E88D3E',
                width: '100%',
              }}
            >
              <tr style={{ backgroundColor: '#E88D3E' }}>
                <th style={{ color: 'white', textAlign: 'start', paddingLeft: 5, paddingRight: 5 }}>
                  Certificate
                </th>
                <th style={{ color: 'white', textAlign: 'start', paddingLeft: 5, paddingRight: 5 }}>
                  Chager Model
                </th>
                <th style={{ color: 'white', textAlign: 'start', paddingLeft: 5, paddingRight: 5 }}>
                  Expiry Date
                </th>
              </tr>
              {listCertification.map((val, key) => {
                return (
                  <tr
                    key={key}
                    style={{
                      border: '1px solid #E88D3E',
                      width: '100%',
                    }}
                  >
                    <td
                      style={{
                        border: '1px solid #E88D3E',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {val.training.certificate.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid #E88D3E',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {val.training.charger_model.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid #E88D3E',
                        paddingLeft: 5,
                        paddingRight: 5,
                        width: 100,
                      }}
                    >
                      {val.training.expiry_date
                        ? format(new Date(val.training.expiry_date), 'yyyy-MM-dd')
                        : ''}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </Form.Item>
      ) : null}
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormEdit;
