/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { IProblemCategory } from 'models/problemCategory';

export interface problemCategoryState {
  list: IProblemCategory[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: problemCategoryState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const problemCategorySlice = createSlice({
  name: 'problemCategory',
  initialState,
  reducers: {
    fetchProblemCategoryList(state, _action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchProblemCategoryListSuccess(state, action: PayloadAction<ListResponse<IProblemCategory>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data;
    },
    createProblemCategory(state, _action: PayloadAction<IProblemCategory>) {
      state.loading = true;
      state.loaded = false;
    },
    createProblemCategorySuccess(state, _action: PayloadAction<IProblemCategory>) {
      state.loading = false;
      state.loaded = true;
    },
    updateProblemCategory(state, _action: PayloadAction<IProblemCategory>) {
      state.loading = true;
      state.loaded = false;
    },
    updateProblemCategorySuccess(state, _action: PayloadAction<IProblemCategory>) {
      state.loading = false;
      state.loaded = true;
    },
    search(state, action: PayloadAction<{ name: string; pagination?: Pagination }>) {
      const { pagination } = action.payload;
      state.list = [];
      state.filter = action.payload;
      if (pagination) {
        state.pagination = {
          ...state.pagination,
          pageNo: pagination.pageNo || state.pagination.pageNo,
          size: pagination.size || state.pagination.size,
        };
      }
    },
    fetchProblemCategoryDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchProblemCategorySuccess(state, action: PayloadAction<IProblemCategory>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteProblemCategory(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.deleting = false;
    },
    deleteProblemCategorySuccess(state) {
      state.loading = false;
      state.deleting = true;
    },
    deleteProblemCategoryFailed(state) {
      state.loading = true;
      state.deleting = false;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const problemCategoryActions = problemCategorySlice.actions;

// selector
export const selectProblemCategoryLoad = (state: RootState) => state.problemCategory.loaded;
export const selectPagination = (state: RootState) => state.problemCategory.pagination;
export const selectProblemCategoryName = (state: RootState) => state.problemCategory.filter.name;
export const selectProblemCategoryDeleting = (state: RootState) => state.problemCategory.deleting;
export const selectProblemCategoryLoading = (state: RootState) => state.problemCategory.loading;
export const selectProblemCategories = (state: RootState) => state.problemCategory.list;

// Reducer
const problemCategoryReducer = problemCategorySlice.reducer;
export default problemCategoryReducer;
