/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListParams, ListResponse, Pagination } from 'models';
import { RootState } from 'app/store';
import { Category } from 'models/category';

export interface CategoryState {
  list: Category[];
  loading: boolean;
  loaded: boolean;
  deleting: boolean;
  pagination: Pagination;
  filter: {
    name: string;
  };
}

const initialState: CategoryState = {
  loading: false,
  loaded: false,
  deleting: false,
  list: [],
  filter: {
    name: '',
  },
  pagination: {
    pageNo: 1,
    size: 10,
    totalRecords: 0,
  },
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    fetchCategoryList(state, action: PayloadAction<ListParams>) {
      state.loading = true;
    },
    fetchCategoryListSuccess(state, action: PayloadAction<ListResponse<Category>>) {
      state.loading = false;
      state.loaded = false;
      state.pagination = {
        ...state.pagination,
        pageNo: action.payload.pageNo,
        size: action.payload.pageSize,
        totalRecords: action.payload?.totalRecords || action.payload?.total || 0,
      };
      state.list = action.payload.data;
    },
    createCategory(state, action: PayloadAction<Category>) {
      state.loading = true;
      state.loaded = false;
    },
    createCategorySuccess(state, action: PayloadAction<Category>) {
      state.loading = false;
      state.loaded = true;
    },
    updateCategory(state, action: PayloadAction<Category>) {
      state.loading = true;
      state.loaded = false;
    },
    updateCategorySuccess(state, action: PayloadAction<Category>) {
      state.loading = false;
      state.loaded = true;
    },
    search(state, action: PayloadAction<{ name: string }>) {
      state.list = [];
      state.filter = action.payload;
    },
    fetchCategoryDetail(state, action: PayloadAction<{ id: string | undefined }>) {},
    fetchCategorySuccess(state, action: PayloadAction<Category>) {
      if (state.list.length)
        state.list.map((el) => {
          if (el.id === action.payload.id) {
            return Object.assign({}, el, action.payload);
          }
          return el;
        });
      else state.list = [action.payload];
    },
    deleteCategory(state, action: PayloadAction<{ id: string }>) {
      state.deleting = true;
    },
    deleteCategorySuccess(state) {
      state.loading = true;
      state.deleting = false;
    },
    changePage(state, action: PayloadAction<Pagination>) {
      state.loading = true;
      const pageNo = action.payload.pageNo || state.pagination.pageNo;
      const size = action.payload.size || state.pagination.size || 10;
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        pageNo,
        size,
      };
    },
  },
});

// Actions
export const categoryActions = categorySlice.actions;

// selector
export const selectCategoryLoad = (state: RootState) => state.category.loaded;
export const selectPagination = (state: RootState) => state.category.pagination;
export const selectName = (state: RootState) => state.category.filter.name;
export const selectDeleting = (state: RootState) => state.category.deleting;
export const selectCategoryLoading = (state: RootState) => state.category.loading;
export const selectCategories = (state: RootState) => state.category.list;

// Reducer
const categoryReducer = categorySlice.reducer;
export default categoryReducer;
