import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectCountries } from 'features/settings/country/countrySlice';
import { Province } from 'models/province';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenService from 'utils/token.service';
import { provinceActions, selectProvinceUpserted } from '../provinceSlice';

const ModalCreate: React.FC<{
  id: string | '';
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
  setIsModalVisible: any;
  setRandomState: any;
}> = ({ id, isModalVisible, handleOk, handleCancel, setIsModalVisible, setRandomState }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = TokenService.getUser().id;
  const province = useAppSelector(
    (state) => state.province?.list?.filter((item: Province) => item.id === id)[0],
  );
  const countryOption = useAppSelector(selectCountries);

  useEffect(() => {
    form.setFieldsValue({
      name: province?.name || '',
      country_id: province?.country_id || '',
    });
  }, [province, form]);
  // useEffect(() => {
  //   if (id !== "" && isModalVisible)
  //     dispatch(provinceActions.fetchProvinceDetail({ id }));
  // }, [id, dispatch]);

  const loaded = useAppSelector(selectProvinceUpserted);
  const resetForm = () => {
    form.setFieldsValue({
      name: '',
      countryId: '',
    });
  };

  const openNotification = useCallback(() => {
    if (isModalVisible) {
      if (loaded) {
        const idRandom = Math.random().toString(36).substring(2);
        setRandomState(idRandom);
        if (uid === id) {
          notification.success({
            message: 'Province updated successfully',
            placement: 'bottomRight',
            duration: 2.5,
          });
          setIsModalVisible(false);
        } else if (id !== '') {
          notification.success({
            message: 'Province updated successfully',
            placement: 'bottomRight',
            duration: 2.5,
          });
          setIsModalVisible(false);
        } else {
          notification.success({
            message: 'Province record created successfully',
            placement: 'bottomRight',
            onClose: () => {
              resetForm();
            },
            duration: 2.5,
          });
          setIsModalVisible(false);
        }
      }
    }
  }, [loaded, navigate, id, uid]);
  useEffect(() => {
    openNotification();
  }, [loaded, openNotification]);
  const onFinish = (values: any) => {
    const province: Province = form.getFieldsValue();
    if (id !== '') {
      dispatch(provinceActions.updateProvince({ ...province, id }));
    } else
      dispatch(
        provinceActions.createProvince({
          ...province,
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      onCancel={handleCancel}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      title={<>{id !== '' ? <div>Update Province</div> : <div>Create Province</div>}</>}
    >
      <Form
        name='form-inspection'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='Province Name'
          name='name'
          rules={[{ required: true, message: 'Please input Province Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Country'
          name='country_id'
          rules={[{ required: true, message: 'Please input your Province!' }]}
        >
          <Select>
            {countryOption.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreate;
