import { useAppDispatch } from 'app/hooks';
import React, { useEffect } from 'react';
import { maintenanceOtherRegionActions } from '../maintenanceOtherRegionsSlice';
import { StepForm } from './steps/Index';

const FormEdit: React.FC<{ id: string | undefined }> = ({ id }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) dispatch(maintenanceOtherRegionActions.fetchMaintenanceOtherRegionDetail({ id }));
  }, [id, dispatch]);
  return <StepForm id={id} />;
};
export default FormEdit;
