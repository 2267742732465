export enum CertificationStatus {
    Valid,
    Expired,
    Suspended
  }
  
  export enum CertificationSeverity {
    Urgent,
    High,
    Medium,
    Low,
  }
  